import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

const DialogIfefo = styled(Dialog)`
  .MuiDialog-paper {
    margin: 0 !important;
    max-width: none !important;
    min-width: 700px !important;
    width: 860px !important;
    height: 800px !important;
    min-height: 500px !important;
    max-height: 90% !important;
    border-radius: 0 !important;
    display: flex !important;
    flex-flow: column nowrap !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }

  &.dialog-big .MuiDialog-paper {
    width: 95% !important;
    min-width: 1000px !important;
  }

  @media (max-width: 1100px) {
    .MuiDialog-paper {
      width: 95% !important;
    }
  }

  @media (max-width: 767px) {
    .MuiDialog-paper {
      width: calc(100% - 24px) !important;
      min-width: auto !important;
    }
  }

  @media (max-height: 524px) {
    .MuiDialog-paper {
      height: calc(100vh - 24px) !important;
      min-height: auto !important;
    }
  }
`

export default DialogIfefo
