import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { MdChevronRight } from 'react-icons/md'
import { FaEnvelope, FaSms, FaWhatsapp } from 'react-icons/fa'
import { ClipLoader } from 'react-spinners'
import { getHistoricoInteracoes, getSaldoInteracoes } from '../../services/api'
import './style.css'

const Highcharts = require('highcharts/highstock')

export default class Interacoes extends Component {
  state = {
    loader: true,
    saldoInteracoes: [],
    historicoInteracoes: []
  }

  componentDidMount() {
    this.setState({ loader: true })
    this.getIteracoesGrafico()
    this.saldoInteracoes()
    this.historicoInteracoes()
  }

  getIteracoesGrafico = () => {
    Highcharts.stockChart('graficoInteracoes', {
      rangeSelector: {
        enabled: true
      },
      title: {
        text: 'Historico de Interações'
      },
      legend: {
        enabled: true,
        layout: 'horizontal',
        align: 'center',
        verticalAlign: 'bottom',
        symbolWidth: 5,
        symbolHeight: 5,
        symbolRadius: 5
      },
      colors: ['#90ed7d', '#7cb5ec', '#5f5f5f'],
      tooltip: {
        pointFormat:
          '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> Interações<br/>',
        split: true
      },
      series:
        this.state.historicoInteracoes.length === 0
          ? [{ name: 'No hay datos para mostrar' }]
          : this.state.historicoInteracoes
    })
  }

  saldoInteracoes = async () => {
    try {
      const saldo = await getSaldoInteracoes()
      if (saldo.token === null) {
        return
      }
      if (saldo.status === 'error') throw new Error(saldo.msg)
      await this.setState({ saldoInteracoes: saldo.msg })
      this.setState({ loader: false })
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
  }

  historicoInteracoes = async () => {
    try {
      const historico = await getHistoricoInteracoes()
      if (historico.token === null) {
        return
      }
      if (historico.status === 'error') throw new Error(historico.msg)
      await this.setState({ historicoInteracoes: historico.msg })
      this.getIteracoesGrafico()
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
    this.setState({ loader: false })
  }

  render() {
    const { saldoInteracoes } = this.state

    return (
      <>
        <Menu menuDrop="1" />
        <Header />
        <div className="container pb-0">
          <div className="breadcrumb" id="breadcrumb">
            <div>
              <h3>INTERAÇÕES</h3>
              <p>
                Conversão{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                Interações <span></span>
              </p>
            </div>
          </div>
          <div className="row pb-0">
            <div className="col-12 pd-0 pl-0">
              {saldoInteracoes.map((s, index) => (
                <div key={index} className="col-4">
                  <div
                    className={
                      s.canal === 'Whatsapp' ? 'box-pop bloqueio' : 'box-pop'
                    }
                  >
                    <div className="pop-icon min">
                      <span>
                        {s.canal === 'Whatsapp' ? (
                          <FaWhatsapp />
                        ) : s.canal === 'Email' ? (
                          <FaEnvelope />
                        ) : (
                          <FaSms />
                        )}
                      </span>
                    </div>
                    <div
                      className={
                        s.canal === 'Whatsapp'
                          ? 'pop-content min bloqueio'
                          : 'pop-content min'
                      }
                    >
                      <h6>
                        {s.canal === undefined || s.canal === null
                          ? 'Carregando...'
                          : s.canal}
                      </h6>
                      <p>
                        {s.saldo === undefined || s.saldo === null
                          ? 'Carregando...'
                          : s.canal === 'Whatsapp'
                          ? ''
                          : s.saldo}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="graficoInteracoes" id="graficoInteracoes"></div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}
