export const statusPedidoColor = (
  s,
  useBackground = false,
  useBoxShadow = false
) => {
  if (useBackground) {
    // console.log('%cstatusPedidoColor %o %o', 'color: blue', s, useBackground)
  }
  const prop = useBackground ? 'backgroundColor' : 'color'
  const extendedProps = useBackground ? { color: '#fff' } : {}
  switch (s) {
    case -1:
      return {
        [prop]: '#573631',
        ...extendedProps,
        ...(useBoxShadow
          ? {
              boxShadow: `inset 0 3px 0 0 #573631`
            }
          : {})
      }
    case 3:
      return {
        [prop]: '#ff8f02',
        ...extendedProps,
        ...(useBoxShadow
          ? {
              boxShadow: `inset 0 3px 0 0 #ff8f02`
            }
          : {})
      }
    case 4:
    case 15:
    case 24:
      return {
        [prop]: '#00a200',
        ...extendedProps,
        ...(useBoxShadow
          ? {
              boxShadow: `inset 0 3px 0 0 #00a200`
            }
          : {})
      }
    case 8:
    default:
      return {
        [prop]: '#e50f10',
        ...extendedProps,
        ...(useBoxShadow
          ? {
              boxShadow: `inset 0 3px 0 0 #e50f10`
            }
          : {})
      }
  }
}

export const statusPedidoCellStyle = (
  params,
  useBackground = false,
  useBoxShadow = false
) => {
  if (!params.data) return
  const { status, id_status: idStatus } = (params || {}).data
  const s = (typeof status === 'number' ? status : idStatus) | 0
  return statusPedidoColor(s, useBackground, useBoxShadow)
}
