import React, { Component } from 'react'
import './style.css'
import ImgIndisponivel from '../../img/img-indisponivel.png'
import { imgPerfil } from '../../services/api'

import counterpart from 'counterpart'
import pt from '../../lang/pt'
import es from '../../lang/es'

counterpart.registerTranslations('pt', pt)
counterpart.registerTranslations('es', es)
counterpart.setLocale('pt')

export default class Header extends Component {
  state = {
    img: '',
    lang: ''
  }

  onLangChange = (e) => {
    this.setState({ lang: e.target.value })
    counterpart.setLocale(e.target.value)
  }

  componentDidMount() {
    this.getImg()
  }

  getImg = async () => {
    const img = await imgPerfil()
    const blob = new Blob([img])
    let file = URL.createObjectURL(blob)
    this.setState({ img: file })
  }

  render() {
    const { img } = this.state
    return (
      <div className="header">
        <ul>
          <li>
            <span className="profile-pic">
              <img src={!img ? ImgIndisponivel : img} alt="" />
            </span>
            {localStorage.getItem('nome')}
          </li>
        </ul>
        {/* <select value={this.state.lang} onChange={this.onLangChange}>
          <option value="pt">PT</option>
          <option value="es">ES</option>
        </select> */}
      </div>
    )
  }
}
