import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Modal from '../../components/Modal'
import swal from 'sweetalert'
import Switch from 'react-switch'
import { MdAdd, MdChevronRight, MdClose } from 'react-icons/md'
import { FaFilter } from 'react-icons/fa'
import {
  ativaInativaUsuario,
  getFiltroUsuarios,
  getListaUsuariosCriados,
  UpdadeUser
} from '../../services/api'
import { Link } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import './style.css'

export default class Usuarios extends Component {
  constructor(props) {
    super(props)

    this.handleSubmit = this.alterarSenha.bind(this)

    this.state = {
      filtros: {
        usuario: '',
        email: '',
        data: '',
        status: ''
      },
      senha1: '',
      senha2: '',
      usuarios: [],
      exibirDetalhes: false,
      filtroIsOpen: false,
      filtroBlock: false,
      modalIsOpen: false,
      loader: false,
      idUsuario: 0,
      dadosModal: {}
    }
  }

  async componentDidMount() {
    this.listaUsuariosCriados()
  }

  listaUsuariosCriados = async () => {
    this.setState({ loader: true })

    const lista = await getListaUsuariosCriados()

    if (lista.token === null) {
      return
    }

    try {
      if (lista.status === 'error') throw new Error(lista.msg)

      this.setState({ usuarios: lista.msg })
    } catch (error) {
      swal({ icon: 'info', text: error.message })
    }

    this.setState({ loader: false })
  }

  filtrar = async () => {
    // filtrar anuncios da página
    const { usuario, email, status } = this.state.filtros
    this.setState({ loader: true })

    try {
      const filtro = await getFiltroUsuarios(usuario, email, status)

      if (filtro.token === null) {
        return
      }

      // Executa filtro
      if (filtro.status === 'error') throw new Error(filtro.msg)
      // Se erro gera mensagem de erro ocorrido
      this.setState({ usuarios: filtro.msg, loader: false })
      // Estancia anuncios filtrados
    } catch (e) {
      this.setState({ loader: false })
    }
  }

  toggleFiltro = () => {
    // Abre e fecha menu de filtros
    if (!this.state.filtroBlock)
      this.setState({
        filtroIsOpen: !this.state.filtroIsOpen,
        filtros: { usuario: '', email: '', data: '', status: '' }
      })
    // Se não estiver bloqueado por detalhe aberto reseta o filtro na abertura e fechamento
    this.filtrar()
    // Limpa os filtros
  }

  handleFiltro = async (e) => {
    await this.setState({
      filtros: { ...this.state.filtros, [e.target.name]: e.target.value }
    })
    this.filtrar()
  }

  handleAtivaInativa = async (e) => {
    await this.setState({ checked: !this.state.checked })
    this.ativaInativaUsuario()
  }

  // Função para lidar com alteração nos inputs do filtro

  handleAtivaInativa = async (e) => {
    await this.setState({ checked: !this.state.checked })
    this.ativaInativaUsuario()
  }
  // Função para lidar com alteração nos inputs do filtro

  // Função para lidar com alteração nos inputs do filtro

  ativaInativaUsuario = async (id, ativo) => {
    try {
      const ativaInativa = await ativaInativaUsuario({ id, ativo })

      if (ativaInativa.token === null) {
        return
      }

      if (ativaInativa.status === 'error') throw new Error(ativaInativa.msg)
      swal({
        icon: 'success',
        text: ativaInativa.msg,
        buttons: {
          confirm: {
            value: true,
            text: 'Ok',
            closeModal: true,
            visible: true
          }
        }
      })
      this.listaUsuariosCriados()
    } catch (e) {
      swal({
        icon: 'info',
        text: e.message
      })
    }
  }

  // openModal = () => {
  //   this.setState({
  //     modalIsOpen:true
  //   })
  // }

  toggleModal = (event, g) => {
    this.setState({ senha1: '', senha2: '' })
    event.preventDefault()
    this.setState({ modalIsOpen: !this.state.modalIsOpen })
    if (g !== undefined) {
      this.setState({ dadosModal: g })
    }
  }
  toggleModalOutside = (event) => {
    let modal = document.getElementById('myModal')
    if (event.target === modal) {
      this.setState({ modalIsOpen: false })
    }
  }

  senha1 = (event) => {
    this.setState({ senha1: event.target.value })
  }
  senha2 = (event) => {
    this.setState({ senha2: event.target.value })
  }

  alterarSenha = async () => {
    this.setState({ modalIsOpen: false })
    this.setState({ loader: true })
    // if((this.state.senha1 !== '' || this.state.senha2 !== '') && (this.state.senha1 === this.state.senha2)){
    try {
      if (this.state.senha1 === '' || this.state.senha2 === '')
        throw new Error('Favor digitar as 2 senhas!')
      if (this.state.senha2.length <= 4)
        throw new Error('A nova senha deve ter mais de 4 digitos!')
      const data = await UpdadeUser({
        senhaAntiga: this.state.senha1,
        senhaNova: this.state.senha2,
        id: this.state.dadosModal.id_usuario
      })
      if (data.status === 'error') throw new Error(data.msg)
      swal({
        icon: 'success',
        text: 'Senha alterada com sucesso',
        buttons: {
          confirm: {
            value: true,
            text: 'Ok',
            closeModal: true,
            visible: true
          }
        }
      })
    } catch (e) {
      swal({
        icon: 'info',
        text: e.message,
        buttons: {
          confirm: {
            value: true,
            text: 'Ok',
            closeModal: true,
            visible: true
          }
        }
      })
    }
    this.setState({ loader: false })
  }

  render() {
    const { exibirDetalhes } = this.state
    const usuarios =
      this.state.usuarios.length === 0 ? (
        <tr>
          <td colSpan="5">Não há usuários criados</td>
        </tr>
      ) : (
        this.state.usuarios.map((g, index) => (
          <tr className="usuario" key={index}>
            <td>{g.usuario}</td>
            <td>{g.email}</td>
            <td>{g.data}</td>
            <td>
              <Link className="link" to={`/usuarios/alterar/${g.id_usuario}`}>
                Alterar Informações
              </Link>
            </td>
            <td className="text-center">
              <div style={{ display: 'inline-flex' }}>
                <strong className={g.status === 'Inativo' ? 'text-red' : ''}>
                  <small>Inativo&nbsp;&nbsp;</small>
                </strong>
                <Switch
                  checked={g.status === 'Ativo' ? true : false}
                  onChange={() =>
                    this.ativaInativaUsuario(
                      g.id_usuario,
                      g.status === 'Ativo' ? 0 : 1
                    )
                  }
                  offColor="#d11a1a"
                  onColor="#109C33"
                  id="icon-switch"
                  onHandleColor="#fff"
                  handleDiameter={21}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                  activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                  height={20}
                  width={38}
                  className="react-switch"
                />
                <strong className={g.status === 'Ativo' ? 'text-green' : ''}>
                  <small>&nbsp;&nbsp;Ativo</small>
                </strong>
              </div>
            </td>
          </tr>
        ))
      )

    return (
      <>
        <Menu menuDrop="2" />
        <Header />
        <div className="container pb-0">
          <div className="breadcrumb" id="breadcrumb">
            <div>
              <h3>USUÁRIOS</h3>
              <p>
                Configurações{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                Usuários
              </p>
            </div>
            <Link to="/usuarios/criar">
              <button className="btn">
                <span>
                  <MdAdd />
                </span>{' '}
                Criar usuário
              </button>
            </Link>
          </div>
          <div className="row pb-0">
            <div
              id="ListaCampanhas"
              className={exibirDetalhes === true ? 'col-6' : 'col-12'}
            >
              <div className="box">
                <div className="box-title">
                  <h4>Lista de usuários</h4>
                  <div>
                    {this.state.filtroIsOpen ? (
                      <button
                        className="btn btn-red"
                        onClick={() => this.toggleFiltro()}
                      >
                        <span>
                          <MdClose />
                        </span>{' '}
                        Fechar Filtro
                      </button>
                    ) : (
                      <button
                        className="btn"
                        onClick={() => this.toggleFiltro()}
                      >
                        <span>
                          <FaFilter />
                        </span>{' '}
                        Filtrar
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className={this.state.filtroIsOpen ? 'box-content' : 'd-none'}
                >
                  <div className="d-flex-sb filtro-box">
                    <input
                      value={this.state.filtros.usuario}
                      name="usuario"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-6"
                      type="text"
                      placeholder="Nombre"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value={this.state.filtros.email}
                      name="email"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-6"
                      type="email"
                      placeholder="Correo electrónico"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      defaultValue={this.state.filtros.status}
                      name="status"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-2"
                    >
                      <option value="">Status</option>
                      <option value="Ativo">Ativo</option>
                      <option value="Inativo">Inativo</option>
                    </select>
                  </div>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th>Data Cadastro</th>
                        <th>Senha</th>
                        <th className="text-center">Status</th>
                      </tr>
                    </thead>
                    <tbody>{usuarios}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
        <Modal
          active={this.state.modalIsOpen}
          toggleModal={this.toggleModal}
          outside={this.toggleModalOutside}
          dadosModal={this.state.dadosModal}
          alterarSenha={this.alterarSenha}
          senha1={this.state.senha1}
          senha2={this.state.senha2}
          funcaoSenha1={this.senha1}
          funcaoSenha2={this.senha2}
        />
      </>
    )
  }
}
