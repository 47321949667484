import React, { Component } from 'react'
import Switch from 'react-switch'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Blank from '../../img/indisponivel.png'
import DatePicker from 'react-datepicker'
import CurrencyInput from 'react-currency-input'
import Swal from 'sweetalert'
import * as _ from 'lodash'
import {
  customImage,
  dispararCampanhaNova,
  getCD,
  getCD2,
  getCidades,
  getCNPJS,
  getDestinatarios,
  getEstados,
  getFefo,
  getFefo2,
  getFiltro,
  getPdfClienteCd,
  getProdutos,
  getProdutosList,
  getRascunho,
  getTipologia,
  salvaRascunho,
  sendDataAfterModifyPost
} from '../../services/api'
import {
  MdArrowBack,
  MdArrowForward,
  MdAutorenew,
  MdCheck,
  MdChevronRight,
  MdClose,
  MdDeleteForever,
  MdErrorOutline,
  MdExpandLess,
  MdExpandMore
} from 'react-icons/md'
import { FaBell, FaEnvelope, FaSms, FaWhatsapp } from 'react-icons/fa'
import { ClipLoader } from 'react-spinners'
import { Link, withRouter } from 'react-router-dom'
import { Combobox, Multiselect } from 'react-widgets'
import 'react-datepicker/dist/react-datepicker.css'
import '../../assets/css/multi-select-style.css'
import '../../assets/css/search-select.css'
import './style.css'
import formatCurrency from '../../components/util/formatCurrency'

const href = '#'

class NovoImpulsionamentoCampanha extends Component {
  state = {
    label: '',
    hashId: '',
    cnpjsInvalidos: '',
    stepper: '1',
    fefo: [],
    listaProdutosNews: [],
    arrProdutosNews: [],
    produtos: [],
    listas: [],
    tipologias: [],
    estados: [],
    cidades: [],
    cds: [],
    cnpjs: [],
    alcance: [],
    showDetails: false,
    loader: false,
    editando: false,
    verificaCheck: false,
    alcanceReal: 0,
    alcanceRealStatus: false,
    hoje: new Date(),
    debounce: null,
    checked: '',
    step1: {
      ok: '',
      titulo: '',
      assunto: '',
      whats: false,
      sms: false,
      souk: false,
      mail: false,
      titleStart: 0,
      bodyStart: 0,
      titleLimit: 50,
      bodyLimit: 35
    },
    step2: {
      ok: '',
      clientes: [],
      tipologia: [],
      estado: [],
      cidade: [],
      produto1: [],
      produto2: [],
      condicoes: '',
      number: null,
      opNumber: 1,
      op: '',
      opTicketMedio: '',
      valorTicketMedio: '',
      eou: '',
      allStep2: null
    },
    step3: {
      ok: '',
      historico: 'Nenhum',
      condicao: '',
      condicaoOp: '',
      tipologia: [],
      produtos: [],
      fl_filtro: false,
      dataIni: new Date(),
      dataFim: new Date()
    },
    step4: {
      ok: '',
      produto: '',
      produtos: [],
      preco: '',
      centroDistribuicao: [],
      dataIni: new Date(),
      dataFim: new Date(),
      urlImage: ''
    },
    lastE: null
  }

  constructor (props) {
    super(props)
    this.setState({
      on: false,
      onFile: false,
      disableFiles: false
    })
    this.debounce = _.debounce(() => {
      this.sendDataAfterModify()
    }, 1000)
    this.debounceCustom = _.debounce(() => {
      if (this.state.step4.produtos.length >= 2 && this.state.step4.produtos.length <= 4)
        this.handleCustomImage()
    }, 2000)
  }

  sendDataAfterModify = async () => {
    if (!this.state.on) {
      let valueDays = parseInt(this.state.step2.number) * parseInt(this.state.step2.opNumber)
      let ticketMedio = this.state.step2.valorTicketMedio.replace('R$', '').replace(',', '.')
      const data = {
        frequencia: {
          op: this.state.step2.op,
          valor: valueDays
        },
        tipologia: this.state.step2.tipologia,
        uf: this.state.step2.estado,
        cidade: this.state.step2.cidade,
        produtos: {
          cond: this.state.step2.condicoes,
          op: this.state.step2.eou,
          valor: [...this.state.step2.produto1, ...this.state.step2.produto2]
        },
        faturamento: {
          op: this.state.step2.opTicketMedio,
          valor: ticketMedio
        }
      }
      const info = await sendDataAfterModifyPost(data)
      this.setState({
        cnpjs: info.msg.cnpjsValidos,
        alcance: info.msg.cnpjsValidos,
        cnpjsInvalidos: info.msg.cnpjsErro,
        alcanceReal: 0,
        alcanceRealStatus: false
      })
      return info
    } else {
      const cnpjs = await getCNPJS(this.state.step2.clientes)
      this.setState({
        cnpjs: ((cnpjs || {}).msg || {}).cnpjsValidos,
        alcance: ((cnpjs || {}).msg || {}).cnpjsValidos,
        cnpjsInvalidos: ((cnpjs || {}).msg || {}).cnpjsErro,
        alcanceReal: 0,
        alcanceRealStatus: false
      })
    }
  }

  handleCustomImage = async () => {
    let image = await customImage(this.state.step4.produtos.reduce((p, c) => {
        if (Array.isArray(c) && c.length > 0) {
          p.push(c[0])
        }
        return p
      },
      []
    ))
    if (image !== null && image.msg) {
      const img = document.getElementById('productimgnew')
      img.setAttribute('src', image.msg.path)
      this.setState({ step4: { ...this.state.step4, urlImage: image.msg.path } })
    } else {
      const img = document.getElementById('productimgnew')
      img.setAttribute('src', Blank)
      this.setState({ step4: { ...this.state.step4, urlImage: '' } })
    }
  }

  handleSelectForms = () => {
    if (this.state.on)
      this.debounce()
    this.handleSelectFormsCall()
  }

  handleSelectFormsCall = () => {
    this.setState({
      on: !this.state.on,
      alcance: [],
      step2: {
        ...this.state.step2, clientes: [],
      },
      step4: {
        ...this.state.step4,
        ok: '',
        produto: '',
        produtos: [],
        preco: '',
        centroDistribuicao: [],
        dataIni: new Date(),
        dataFim: new Date()
      },
    })
  }

  handleSelectOnFile = () => {
    this.setState({
      onFile: !this.state.onFile,
    })
    //fileApp
    if (!this.state.onFile)
      this.debounceCustom()
    const img = document.getElementById('productimgnew')
    img.setAttribute('src', Blank)
    this.setState({ step4: { ...this.state.step4, urlImage: '', fileApp: '' } })
  }

  handleCheckFiles = (event) => {
    if (this.state.step4.produtos.length === 0) {
      Swal({
        icon: 'error',
        text: 'Selecione o mínimo de 2 (dois) Produtos na formação do MIX das Campanhas'
      })
      return false
    } else if (this.state.step4.produtos.length < 2) {
      Swal({
        icon: 'error',
        text: 'Para iniciativas com apenas 01 (um) produto, utilize a opção Conversão > Impulsionamento'
      })
      return false
    } else if (this.state.step4.produtos.length > 4) {
      Swal({ icon: 'error', text: 'São permitidos o máximo de 04 (quatro) Produtos na formação do MIX das Campanhas' })
      return false
    }
    if (event === true)
      this.callLancaCampanha()
  }

  callLancaCampanha = () => {
    Swal({
      icon: 'warning',
      title: 'Tem certeza?',
      text:
        'Deseja realmente lançar a campanha? Uma vez lançado os disparos não poderão ser cancelados.',
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: null,
          visible: true,
          className: '',
          closeModal: true
        },
        confirm: {
          text: 'Lançar Campanha',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      }
    }).then((value) => {
      if (value) {
        this.lancaCampanha()
      }
    })
  }

  handleLimitCaracteresTitle = (value, limit, type) => {
    if (type === 1) {
      this.setState({
        step1:
          { ...this.state.step1, titleStart: this.state.step1.titleStart + value }
      })
    }
  }

  async componentDidMount () {
    await this.getListas()
    // Pega as listas
    await this.getProds()
    // Pega produtos do filtro
    const impulsionamento = window.location.pathname.split('/')

    if (impulsionamento[3] !== undefined && impulsionamento[3] !== '') {
      this.setState({ loader: true, hashId: impulsionamento[3] })
      const imp = await getRascunho(impulsionamento[3])
      console.log('imp..............', imp)
      if (imp.status === 'error')
        Swal({ icon: 'error', text: imp.msg }).then(() =>
          this.props.history.push('/impulsionamento')
        )
      await this.setState({
        step2: { clientes: imp.msg.clientes },
        step1: {
          ok: '',
          whats: imp.msg.rasc[0].whats,
          sms: imp.msg.rasc[0].sms,
          souk: imp.msg.rasc[0].souk,
          mail: imp.msg.rasc[0].mail,
          titulo: imp.msg.rasc[0].titulo,
          assunto: imp.msg.rasc[0].assunto
        }
      })
      this.setState({ loader: false })
    }
  }

  getListas = async () => {
    try {
      const listas = await getDestinatarios(2, true)

      if (listas.token === null) {
        return
      }

      // Consulta listas
      if (listas.status === 'error') throw new Error(listas.msg)
      // Se erro gera mensagem de erro
      await this.setState({ listas: listas.msg })
      // seta listas
    } catch (e) {
      await Swal({ icon: 'info', text: e.message })
    }
  }

  getProds = async () => {
    try {
      const produtos = await getProdutos()

      if (produtos.token === null) {
        return
      }

      // Consulta listas
      if (produtos.status === 'error') throw new Error(produtos.msg)
      // Se erro gera mensagem de erro
      this.setState({ produtos: produtos.msg })
      // seta listas
    } catch (e) {
      await Swal({ icon: 'info', text: e.message })
    }
  }

  handleRadio = async (opt) => {
    this.state.step3.historico === opt
      ? this.setState({ step3: { ...this.state.step3, historico: '' } })
      : this.setState({ step3: { ...this.state.step3, historico: opt } })

    await this.handleFiltros()
  }

  handleRadioC = async (opt) => {
    this.state.step3.condicaoOp === opt
      ? await this.setState({ step3: { ...this.state.step3, condicaoOp: '' } })
      : await this.setState({
        step3: { ...this.state.step3, condicaoOp: opt }
      })

    await this.handleFiltros()
  }

  handleDataIni = (date) =>
    this.setState({ step4: { ...this.state.step4, dataIni: date } })

  handleDataFim = (date) =>
    this.setState({ step4: { ...this.state.step4, dataFim: date } })

  handleFiltroIni = async (date) => {
    await this.setState({ step3: { ...this.state.step3, dataIni: date } })
    await this.handleFiltros()
  }

  handleFiltroFim = async (date) => {
    await this.setState({ step3: { ...this.state.step3, dataFim: date } })
    await this.handleFiltros()
  }

  toggleDetails = () => this.setState({ showDetails: !this.state.showDetails })

  toggleCanal = async (btn) =>
    this.setState({
      step1: { ...this.state.step1, [btn]: !this.state.step1[btn] }
    })

  handleSelectQuatro = async (e) => {
    await this.setState({
      step4: { ...this.state.step4, produto: e.id },
      loader: true
    })

    const { produto } = this.state.step4

    const prod = await this.state.fefo[produto].CODIGO_PRODUTO

    const semana = await this.state.fefo[produto].semanas

    const cnpjs = this.state.alcance

    const cds = await getCD(prod, semana, cnpjs)

    if (cds.token === null) {
      return
    }

    if (cds.status === 'success') {
      this.setState({
        step4: { ...this.state.step4, centroDistribuicao: cds.msg }
      })

      let alcanceReal = await this.state.step4.centroDistribuicao.reduce(
        (a, b) => a + parseInt(b.totalClientes),
        0
      )
      await this.setState({ alcanceReal })
    }

    this.setState({ loader: false })
  }

  handleSelectTres = async (e) => {
    const { name, value } = e.target

    if (value !== '') {
      await this.setState({ step3: { ...this.state.step3, condicaoOp: 'ou' } })
    }
    if (value === '') {
      await this.setState({ step3: { ...this.state.step3, condicaoOp: '' } })
    }
    await this.setState({ step3: { ...this.state.step3, [name]: value } })
    await this.handleFiltros()
  }

  handleMultiSelect = async (e, step, nome) => {
    await this.setState({
      [step]: {
        ...this.state[step],
        [nome]: Array.from(e, (item) => item.codigo_produto || item.id)
      }
    })

    if (step === 'step2') {
      this.debounce()
    }

    if (step === 'step3')
      await this.handleFiltros()
  }

  handleFiltros = async () => {
    const {
      historico,
      dataIni,
      dataFim,
      condicao,
      condicaoOp,
      produtos,
      fl_filtro
    } = this.state.step3

    if (fl_filtro) return this.setState({ alcance: this.state.cnpjs })

    const filtra = await getFiltro(
      this.state.cnpjs,
      historico,
      dataIni,
      dataFim,
      condicao,
      condicaoOp,
      produtos
    )

    if (filtra.token === null) {
      return
    }

    if (filtra.status === 'success') this.setState({ alcance: filtra.msg })

    this.setState({
      step4: {
        ...this.state.step4,
        produto: '',
        preco: '',
        centroDistribuicao: [],
        dataIni: new Date(),
        dataFim: new Date()
      }
    })
  }

  handleMultiSelect2 = async (e) => {
    this.setState({ lastE: e })
    if (e.length === 0) {
      this.setState({
        step4: { ...this.state.step4, centroDistribuicao: [] }
      })
      let alcanceReal = await this.state.step4.centroDistribuicao.reduce(
        (a, b) => a + parseInt(b.totalClientes),
        0
      )
      await this.setState({ alcanceReal })
      return
    }

    const cnpjs = this.state.alcance

    const arrProdSem = e.map((p) => [p.id, p.semanas])
    const id_distribuidor = localStorage.getItem('id_distribuidor')

    let cds = await getCD2(id_distribuidor, arrProdSem, cnpjs)

    if (cds.status === 'sucess') {
      this.setState({
        step4: {
          ...this.state.step4,
          centroDistribuicao: cds.msg,
          produtos: arrProdSem,
        },
      })
      let alcanceReal = await this.state.step4.centroDistribuicao.reduce(
        (a, b) => a + parseInt(b.totalClientes),
        0
      )
      await this.setState({ alcanceReal, alcanceRealStatus: this.state.step4.centroDistribuicao <= 0 })
    }
  }

  fefo = async () => {
    const fefo = await getFefo()

    if (fefo.token === null) {
      return
    }

    this.setState({ fefo: fefo.msg })
  }

  fefo2 = async () => {
    const fefo2 = await getFefo2()

    if (fefo2.token === null) {
      return
    }

    this.setState({ fefo2: fefo2.msg })
  }

  toggleStep = async (s, orig = null) => {
    switch (s) {
      // Informações
      case '1':
        this.setState({ stepper: '1', step1: { ...this.state.step1, ok: '' } })
        break

      // Destinatários
      case '2':
        await this.setState({
          step2: { ...this.state.step2, ok: '' },
          loader: true
        })
        try {
          const { whats, souk, sms, mail, titulo, assunto } = this.state.step1

          const tipologias = await getTipologia()
          const estados = await getEstados()
          const produtos = await getProdutosList()

          if (tipologias.token === null) return

          if (tipologias.status === 'error') throw new Error(tipologias.msg)

          if (produtos.status === 'error') throw new Error(produtos.msg)

          if (estados.status === 'error') throw new Error(estados.msg)

          this.setState({
            tipologias: tipologias.msg,
            estados: estados.msg || [],
            produtos: produtos.msg || [],
          })

          if (
            whats === false &&
            souk === false &&
            sms === false &&
            mail === false
          )
            throw new Error(
              'Por favor selecione ao menos um canal para veiculação.'
            )

          if (titulo === '')
            throw new Error('Por favor informe o título da campanha.')

          if (assunto === '')
            throw new Error('Por favor informe o corpo da campanha.')

          await this.setState({
            step1: { ...this.state.step1, ok: true },
            stepper: '2',
            loader: false
          })

          const listaProdutosNews = await getFefo2()

          if (listaProdutosNews.token === null) {
            return
          }

          if (listaProdutosNews.status === 'error')
            throw new Error(listaProdutosNews.msg)

          this.setState({
            listaProdutosNews: listaProdutosNews.msg.map((a) => {
              a.key = `${a.id}-${a.semanas}`
              return a
            })
          })

        } catch (e) {
          this.setState({
            step1: { ...this.state.step1, ok: false },
            stepper: '1',
            loader: false
          })

          await Swal({
            text: e.message,
            icon: 'info'
          })
        }
        break

      // Filtros
      case '3':
        await this.setState({
          step3: { ...this.state.step3, ok: '' },
          loader: true
        })

        try {
          const { clientes } = this.state.step2
          if (this.state.step1.ok !== true)
            throw new Error('Conclua o passo 1 para prosseguir.')

          if (clientes.length === 0 && this.state.on)
            throw new Error('Por favor selecione uma lista para disparo')

          if (this.state.on) {
            if (clientes.length === 0)
              throw new Error('Por favor selecione uma lista para disparo')

            const tipologias = await getTipologia()

            if (tipologias.token === null) {
              return
            }

            const listaProdutosNews = await getFefo2()

            if (listaProdutosNews.token === null) {
              return
            }

            if (listaProdutosNews.status === 'error')
              throw new Error(listaProdutosNews.msg)

            this.setState({
              listaProdutosNews: listaProdutosNews.msg.map((a) => {
                a.key = `${a.id}-${a.semanas}`
                return a
              })
            })
          }

          this.setState({
            step2: { ...this.state.step2, ok: true },
            stepper: '3',
            loader: false
          })
        } catch (e) {
          if (this.state.step1.ok !== true) {
            this.setState({
              step1: { ...this.state.step1, ok: false },
              stepper: '1',
              loader: false
            })
            await Swal({
              text: e.message,
              icon: 'info'
            })

            return
          }

          this.setState({
            step2: { ...this.state.step2, ok: false },
            stepper: '2',
            loader: false
          })
          await Swal({
            text: e.message,
            icon: 'info'
          })
        }
        break

      // Mecânica
      case '4':

        // if (document.getElementById('rw_9_taglist')){
        //   let listSectedProcucts = document.getElementById("rw_9_taglist");
        //   listSectedProcucts.innerHTML = '';
        // }

        this.setState({ step4: { ...this.state.step4, ok: '' }, loader: true })
        try {
          if (this.state.step1.ok !== true)
            throw new Error('Conclua o passo 1 para prosseguir.')

          if (this.state.step2.ok !== true)
            throw new Error('Conclua o passo 2 para prosseguir.')

          const {
            historico,
            condicao,
            condicaoOp,
            tipologia,
            produtos,
            fl_filtro,
            dataIni,
            dataFim
          } = this.state.step3

          const dataIniFormatt =
            dataIni.getMonth() +
            1 +
            '/' +
            dataIni.getDate() +
            '/' +
            dataIni.getFullYear()
          const dataFimFormatt =
            dataFim.getMonth() +
            1 +
            '/' +
            dataFim.getDate() +
            '/' +
            dataFim.getFullYear()
          const dataNow = new Date()

          if (
            dataFim.toDateString() > dataNow.toDateString() ||
            dataFim.toDateString() > dataNow.toDateString()
          )
            throw new Error('Corrija as datas')

          if (
            dataFimFormatt === dataIniFormatt &&
            historico === '' &&
            tipologia.length === 0 &&
            (condicao === '' || condicaoOp === '' || produtos.length === 0) &&
            fl_filtro === false
          ) {
            throw new Error(
              'Selecione ao menos uma condição para o filtro ou selecione "Ignorar Filtros"'
            )
          }
          if (condicao !== '' && produtos.length === 0)
            throw new Error('Selecione ao menos um produto para prosseguir')
          await this.fefo()
          await this.fefo2()

          this.setState({
            step3: { ...this.state.step3, ok: true },
            stepper: '4',
            loader: false
          })
        } catch (e) {
          if (this.state.step1.ok !== true) {
            this.setState({
              step1: { ...this.state.step1, ok: false },
              stepper: '1',
              loader: false
            })
            await Swal({
              text: e.message,
              icon: 'info'
            })

            return
          }
          if (this.state.step2.ok !== true) {
            this.setState({
              step2: { ...this.state.step2, ok: false },
              stepper: '2',
              loader: false
            })
            await Swal({
              text: e.message,
              icon: 'info'
            })

            return
          }
          this.setState({
            step3: { ...this.state.step3, ok: false },
            stepper: '3',
            loader: false
          })
          await Swal({
            text: e.message,
            icon: 'info'
          })
        }
        break

      default:
        break
    }
  }

  handleCheck = async () => {
    this.setState({ verificaCheck: !this.state.verificaCheck })
    this.setState({
      step3: {
        historico: '',
        dataIni: new Date(),
        dataFim: new Date(),
        tipologia: [],
        condicao: '',
        condicaoOp: '',
        produtos: [],
        fl_filtro: !this.state.step3.fl_filtro
      }
    })

    await this.handleFiltros()
  }

  toggleLabel = (label) => this.setState({ label })

  getStep1 = () => {
    const { ok } = this.state.step1

    if (ok === '') return <span className="stepper-icon">1</span>

    if (ok === true)
      return (
        <span className="stepper-icon success">
          <MdCheck/>
        </span>
      )

    if (ok === false)
      return (
        <span className="stepper-icon error">
          <MdErrorOutline/>
        </span>
      )
  }
  getStep2 = () => {
    const { ok } = this.state.step2

    if (ok === '') return <span className="stepper-icon">2</span>

    if (ok === true)
      return (
        <span className="stepper-icon success">
          <MdCheck/>
        </span>
      )

    if (ok === false)
      return (
        <span className="stepper-icon error">
          <MdErrorOutline/>
        </span>
      )
  }
  getStep3 = () => {
    const { ok } = this.state.step3

    if (ok === '') return <span className="stepper-icon">3</span>

    if (ok === true)
      return (
        <span className="stepper-icon success">
          <MdCheck/>
        </span>
      )

    if (ok === false)
      return (
        <span className="stepper-icon error">
          <MdErrorOutline/>
        </span>
      )
  }
  getStep4 = () => {
    const { ok } = this.state.step4

    if (ok === '') return <span className="stepper-icon">4</span>

    if (ok === true)
      return (
        <span className="stepper-icon success">
          <MdCheck/>
        </span>
      )

    if (ok === false)
      return (
        <span className="stepper-icon error">
          <MdErrorOutline/>
        </span>
      )
  }

  lancaCampanha = async () => {
    this.setState({ loader: true })

    const { whats, sms, souk, mail, titulo, assunto } = this.state.step1
    const { alcance, hoje, hashId } = this.state
    const {
      historico,
      dataIni: dataIniF,
      dataFim: dataFimF,
      tipologia,
      condicao,
      condicaoOp,
      produtos,
      fl_filtro
    } = this.state.step3
    const {
      dataIni,
      dataFim,
      centroDistribuicao,
      preco,
      fileApp,
      urlImage
    } = this.state.step4

    try {

      if (!this.state.onFile && (fileApp === '' || fileApp === undefined))
        throw new Error('Não é possível enviar campanha sem imagem! Por favor faça upload da imagem ou selecione outra mecânica.')

      if ((!`${urlImage}`.length || !this.state.onFile) && (fileApp === '' || fileApp === undefined))
        throw new Error('Não é possível enviar campanha sem imagem! Por favor faça upload da imagem ou selecione outra mecânica.')

      if (this.state.step4.produtos.length < 2)
        throw new Error('Para iniciativas com apenas 01 (um) produto, utilize a opção Conversão > Impulsionamento')

      if (this.state.step4.produtos.length > 4)
        throw new Error('São permitidos o máximo de 04 (quatro) Produtos na formação do MIX das Campanhas')

      if (dataIni < hoje)
        throw new Error(
          'A data inicial da veiculação não pode ser anterior a data de hoje.'
        )

      if (dataFim <= dataIni)
        throw new Error(
          'A data final da veiculação não pode ser anterio a inicial.'
        )

      if (centroDistribuicao.length === 0)
        throw new Error(
          'Por favor selecione ao menos um centro de distribuição.'
        )

      centroDistribuicao.forEach((c) => {
        if (c.valorImpulsionamento === '' || c.valorImpulsionamento === '0,00')
          throw new Error(
            `Por favor informe o valor do impulsionamento do CD ${c.nomeCd}.`
          )
        if (parseFloat(c.valorImpulsionamento) > parseFloat(c.valorAtual))
          throw new Error(
            `O valor do impulsionamento não pode ser maior que o atual, por favor informe um valor adequado para o CD ${c.nomeCd}.`
          )
        if (parseFloat(c.percDesconto) > 80 || parseFloat(c.percDesconto) < 0)
          throw new Error(
            `O desconto do CD ${c.nomeCd} está muito alto, ele não deve ultrapassar 80%, por favor informe um novo valor`
          )
      })

      // if (produto === "")
      //   throw new Error("Selecione o produto do impulsionamento.");

      const id_distribuidor = localStorage.getItem('id_distribuidor')

      let formData = new FormData()
      formData.append('file', fileApp)
      if (hashId !== '') {
        formData.append('hashId', hashId)
      }
      if (`${urlImage}`.length > 0) {
        formData.append('image', urlImage)
      }
      formData.append('cobertura', this.state.cnpjs.length)
      formData.append('whats', whats)
      formData.append('sms', sms)
      formData.append('souk', souk)
      formData.append('mail', mail)
      formData.append('titulo', titulo)
      formData.append('assunto', assunto)
      formData.append('alcance', JSON.stringify(alcance))
      formData.append('historico', historico)
      formData.append('dataIniF', dataIniF)
      formData.append('dataFimF', dataFimF)
      formData.append('tipologia', JSON.stringify(tipologia))
      formData.append('condicao', condicao)
      formData.append('condicaoOp', condicaoOp)
      formData.append('prodFiltro', JSON.stringify(produtos))
      formData.append('fl_filtro', fl_filtro)
      formData.append('dataIni', dataIni)
      formData.append('dataFim', dataFim)
      formData.append('centroDistribuicao', JSON.stringify(centroDistribuicao))
      formData.append('preco', parseFloat(preco))
      formData.append('listas', JSON.stringify(this.state.step2.clientes))
      formData.append('fl_campanha', 1)
      formData.append('id_distribuidor', id_distribuidor)
      formData.append('fefo', JSON.stringify(this.state.fefo))
      formData.append('produtos', JSON.stringify(this.state.step4.produtos))

      const dispara = await dispararCampanhaNova(formData)

      if (dispara.token === null) {
        return
      }

      if (dispara.status === 'error') throw new Error(dispara.msg)

      this.setState({ loader: false })

      Swal({
        icon: 'success',
        text: dispara.msg,
        buttons: {
          confirm: {
            value: true,
            text: 'Ok',
            closeModal: true,
            visible: true
          }
        }
      }).then((v) => {
        if (v) {
          this.props.history.push('/campanhas')
        }
      })
    } catch (e) {
      this.setState({ loader: false })

      Swal({
        icon: 'info',
        text: e.message
      })
    }
  }
  handleStateChangeClear = async (event) => {
    console.log(event)
    const estado = Array.from(event, (i) => i.id)
    const cidades = await getCidades(estado)
    this.setState({
      cidades: Array.isArray(cidades.msg) ? cidades.msg : [],
      step2: { ...this.state.step2, cidades: [], estado }
    })
  }

  salvarRascunho = async () => {
    this.setState({ loader: true })
    const { whats, sms, souk, mail, titulo, assunto } = this.state.step1
    const { alcance, hashId } = this.state
    const {
      historico,
      dataIni: dataIniF,
      dataFim: dataFimF,
      tipologia,
      condicao,
      condicaoOp,
      produtos,
      fl_filtro
    } = this.state.step3

    let body = {}
    if (hashId !== '') {
      body = {
        hashId,
        cobertura: this.state.cnpjs.length,
        whats,
        sms,
        souk,
        mail,
        titulo,
        assunto,
        alcance,
        historico,
        dataIniF,
        dataFimF,
        tipologia,
        condicao,
        condicaoOp,
        prodFiltro: produtos,
        fl_filtro,
        listas: this.state.step2.clientes
      }
    } else {
      body = {
        cobertura: this.state.cnpjs.length,
        whats,
        sms,
        souk,
        mail,
        titulo,
        assunto,
        alcance,
        historico,
        dataIniF,
        dataFimF,
        tipologia,
        condicao,
        condicaoOp,
        prodFiltro: produtos,
        fl_filtro,
        listas: this.state.step2.clientes
      }
    }

    try {
      const salva = await salvaRascunho(body)

      if (salva.token === null) {
        return
      }

      if (salva.status === 'error') throw new Error(salva.msg)
      this.setState({ loader: false })
      Swal({ icon: 'success', text: salva.msg }).then(() => {
        this.props.history.push('/impulsionamento')
      })
    } catch (e) {
      this.setState({ loader: false })
      Swal({ icon: 'warning', text: e.message })
    }
  }

  handleValor = async (e, maskedvalue, floatvalue) => {
    // check qty products
    this.handleCheckFiles(false)

    const i = await e.target

    const vb = await this.state.step4.centroDistribuicao[i.id].valorBase

    const v = await this.state.step4.centroDistribuicao[i.id].valorAtual

    const p = parseFloat(100 - (100 * floatvalue) / parseFloat(vb)).toFixed(2)

    const c = this.state.step4.centroDistribuicao

    const n = {
      idCd: c[i.id].idCd,
      nomeCd: c[i.id].nomeCd,
      valorAtual: c[i.id].valorAtual,
      valorImpulsionamento: parseFloat(
        i.value.replace(/[.]+/g, '').replace(/[,]+/g, '.')
      ),
      percDesconto: p,
      totalClientes: c[i.id].totalClientes,
      valorBase: c[i.id].valorBase
    }

    c[i.id] = await n

    await this.setState({
      step4: { ...this.state.step4, centroDistribuicao: c }
    })
  }
  limpaData = async (data) => {
    const date = await data.split('T')
    return date[0]
  }

  exportPdf = async (idCd) => {
    this.setState({ loader: true })
    try {
      const { dataIni, dataFim } = this.state.step4

      const arquivo = await getPdfClienteCd(
        idCd,
        this.state.alcance,
        this.state.step1.titulo,
        `De ${dataIni.toLocaleDateString()} à ${dataFim.toLocaleDateString()}`
      )

      const file = new Blob([arquivo], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(file)
      window.open(pdfUrl)
    } catch (e) {
      await Swal({ icon: 'info', text: 'Falha ao exportar pdf' })
    }

    this.setState({ loader: false })
  }

  removeCd = async (i) => {
    this.state.step4.centroDistribuicao.splice(i, 1)
    await this.setState({
      step4: {
        ...this.state.step4,
        centroDistribuicao: this.state.step4.centroDistribuicao
      }
    })
    let alcanceReal = await this.state.step4.centroDistribuicao.reduce(
      (a, b) => a + parseInt(b.totalClientes),
      0
    )
    await this.setState({ alcanceReal })
  }

  handleChangeFile = async (selectorFiles) => {
    await this.setState({
      step4: { ...this.state.step4, fileApp: selectorFiles[0] }
    })

    if (selectorFiles && selectorFiles[0]) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const img = document.getElementById('productimgnew')
        img.setAttribute('src', e.target.result)
      }
      reader.readAsDataURL(selectorFiles[0])
    }
  }

  restoreAll = () => {
    if (!this.state.lastE)
      return
    this.handleMultiSelect2(this.state.lastE)
  }

  render () {
    const { mail, whats, sms, souk } = this.state.step1
    const step = this.state.stepper
    const step1 = this.getStep1()
    const step2 = this.getStep2()
    const step3 = this.getStep3()
    const step4 = this.getStep4()

    const cds =
      this.state.step4.centroDistribuicao.length === 0 ? (
        <>
          <tr>
            <td colSpan="6">
              Não há CDs disponíveis para o produto selecionado que atendem a
              essa seleção de clientes
            </td>
          </tr>
        </>
      ) : (
        <>
          {
            this.state.step4.centroDistribuicao.map((cd, index) => (
              <tr key={index}>
                <td style={{ width: '160px' }}>{cd.nomeCd.toLowerCase()}</td>
                <td
                  className="text-left"
                  style={{
                    fontStyle: 'italic',
                    textDecoration: 'line-through',
                    color: '#999'
                  }}
                >
                  R$ {formatCurrency(cd.valorBase)}
                </td>
                <td className="text-left">R$ {formatCurrency(cd.valorAtual)}</td>
                <td className="text-center" style={{ width: '80px' }}>
                  <CurrencyInput
                    className={
                      this.state.step4.centroDistribuicao[index]
                        .valorImpulsionamento === ''
                        ? 'form-control'
                        : 'form-control check'
                    }
                    onChangeEvent={
                      (e, x, y) =>
                        this.handleValor(e, x, y)
                    }
                    name="preco"
                    id={index}
                    step="step4"
                    precision="2"
                    decimalSeparator=","
                    thousandSeparator="."
                    value={
                      this.state.step4.centroDistribuicao[index]
                        .valorImpulsionamento
                    }
                  />
                </td>
                <td className="text-center">
                  {this.state.step4.centroDistribuicao[index].percDesconto === ''
                    ? ''
                    : parseFloat(
                      this.state.step4.centroDistribuicao[index].percDesconto
                    )}{' '}
                  %
                </td>
                <td className="text-center">
                  {this.state.step4.centroDistribuicao[index].totalClientes ===
                  '' ? (
                    ''
                  ) : (
                    <a
                      className="link"
                      href={href}
                      onClick={() => this.exportPdf(cd.idCd)}
                    >
                      {this.state.step4.centroDistribuicao[index].totalClientes}
                    </a>
                  )}
                </td>
                <td className="text-center">
                  <button
                    onClick={() => this.removeCd(index)}
                    className="btn btn-red btn-icon-nano"
                  >
                  <span>
                    <MdClose/>
                  </span>
                  </button>
                </td>
              </tr>
            ))}
        </>
      )

    return (
      <>
        <Menu menuDrop="19"/>
        <Header/>
        <div className="container">
          <div className="breadcrumb">
            <div>
              <h3>CAMPANHA</h3>
              <p>
                Campanha{' '}
                <span>
                  <MdChevronRight/>
                </span>{' '}
                <Link to="/campanhas" className="text-white link">
                  Nova Campanha
                </Link>{' '}
                <span>
                  <MdChevronRight/>
                </span>{' '}
                Criar
              </p>
            </div>
            <span> </span>
          </div>
          <div className="row">
            <div className="col-7">
              <div className="box">
                <div className="box-title">
                  <h4>Nova campanha</h4>
                  <span> </span>
                </div>
                <div className="box-content">
                  <div className="stepper-box">
                    <div className="stepper-title">
                      <h5>{step1}Informações</h5>
                    </div>
                    <div
                      className={
                        step === '1' ? 'stepper-item' : 'stepper-item d-none'
                      }
                    >
                      <div className="form-group stepper-form">
                        <div className="form-item">
                          <label>Canais</label>
                          <div className="box-item">
                            <div
                              onClick={() => this.toggleCanal('whats')}
                              className={whats ? 'item active' : 'item'}
                            >
                              <span>
                                <FaWhatsapp/>
                              </span>
                              <p>WhatsApp</p>
                            </div>
                            <div
                              className={souk ? 'item active' : 'item'}
                              onClick={() => this.toggleCanal('souk')}
                            >
                              <span>
                                <FaBell/>
                              </span>
                              <p>Push</p>
                            </div>
                            <div
                              className={mail ? 'item active' : 'item'}
                              onClick={() => this.toggleCanal('mail')}
                            >
                              <span>
                                <FaEnvelope/>
                              </span>
                              <p>E-mail</p>
                            </div>
                            <div
                              className={sms ? 'item active' : 'item'}
                              onClick={() => this.toggleCanal('sms')}
                            >
                              <span>
                                <FaSms/>
                              </span>
                              <p>SMS</p>
                            </div>
                          </div>
                        </div>
                        <div className="form-item">
                          <label
                            onClick={() => {this.toggleLabel('1')}}
                            className={
                              this.state.step1.titulo !== '' ||
                              this.state.label === '1'
                                ? 'input-title active placeholder-title-active'
                                : 'input-title'
                            }
                          >
                            Título exibido ao cliente *  {' '}
                            <span className="text-placeholder">
                              Máximo de 50 caracteres permitidos {' '}
                            </span>
                            <span className="d-none-not-important text-placeholder placeholder-limit-title">
                              - {this.state.step1.titleStart} / {this.state.step1.titleLimit} (restantes)
                            </span>
                          </label>
                          <input
                            value={this.state.step1.titulo}
                            onFocus={() => this.toggleLabel('1')}
                            onBlur={() => this.toggleLabel('')}
                            type="text"
                            className={
                              this.state.step1.titulo === ''
                                ? 'form-control'
                                : 'form-control check'
                            }
                            step="step1"
                            onChange={(e) => {
                              this.setState({
                                step1: {
                                  ...this.state.step1,
                                  titulo: e.target.value,
                                  titleStart: e.target.value.length,
                                  titleLimit: 50 - e.target.value.length
                                }
                              })
                            }
                            }
                            maxLength="50"
                            name="titulo"
                          />
                        </div>
                        <div className="form-item">
                          <label
                            onClick={() => this.toggleLabel('2')}
                            className={
                              this.state.step1.assunto !== '' ||
                              this.state.label === '2'
                                ? 'input-title active placeholder-title-active'
                                : 'input-title'
                            }
                          >
                            Frase chamativa para cliente *
                            <span className="text-placeholder">
                              Máximo de 35 caracteres permitidos {' '}
                            </span>
                            <span className="d-none-not-important text-placeholder placeholder-limit-title">
                              - {this.state.step1.bodyStart} / {this.state.step1.bodyLimit} (restantes)
                            </span>
                          </label>
                          <input
                            value={this.state.step1.assunto}
                            onFocus={() => this.toggleLabel('2')}
                            onBlur={() => this.toggleLabel('')}
                            type="text"
                            className={
                              this.state.step1.assunto === ''
                                ? 'form-control'
                                : 'form-control check'
                            }
                            maxLength="35"
                            step="step1"
                            onChange={(e) =>
                              this.setState({
                                step1: {
                                  ...this.state.step1,
                                  assunto: e.target.value,
                                  bodyStart: e.target.value.length,
                                  bodyLimit: 35 - e.target.value.length
                                }
                              })
                            }
                            name="assunto"
                          />
                        </div>
                        <div className="form-footer">
                          <button
                            className="btn"
                            onClick={() => {
                              // noinspection JSValidateTypes
                              this.toggleStep('2') &&
                              (!this.state.on && this.debounce())
                            }
                            }
                          >
                            Seguinte{' '}
                            <span>
                              <MdArrowForward/>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="stepper-title">
                      <h5>{step2}Destinatário</h5>
                    </div>

                    <div
                      className={
                        step === '2' ? 'stepper-item' : 'stepper-item d-none'
                      }
                    >
                      {/* step 2 - new design */}
                      <div className="form-group stepper-form" id="step-2">

                        <div className="row pt-0 pb-0">
                          <div className="col-12 text-right pt-0 pb-0" id="main-base-input-switch"
                               style={{ marginBottom: '-20px' }}>

                            <Switch
                              checked={this.state.on}
                              onChange={this.handleSelectForms}
                              width={175}
                              offColor="#fb8501"
                              onColor="#109C33"
                              uncheckedIcon={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: 'white',
                                    paddingRight: 2,
                                  }}>
                                  Banco de Dados
                                </div>
                              }
                              checkedIcon={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: 'white',
                                    paddingRight: 2
                                  }}>
                                  Lista de Upload
                                </div>
                              }
                            />
                          </div>
                        </div>
                        {/* step 2 - new design files */}
                        {this.state.on &&
                        <div className="row pt-0 pb-0">
                          <div className="col-12 pt-0 pb-0">
                            <div className="row">
                              <div className="col-12 pt-0 pb-0">

                                <div className="form-item">
                                  <label>Clientes</label>
                                  {this.state.step2.clientes.length > 0 && (
                                    <Multiselect
                                      data={this.state.listas}
                                      minLength={3}
                                      valueField="id"
                                      textField="lista"
                                      filter="contains"
                                      className={
                                        this.state.step2.clientes.length === 0
                                          ? ''
                                          : 'check'
                                      }
                                      placeholder="Selecione a lista de clientes"
                                      onChange={(e) => {
                                        this.handleMultiSelect(e, 'step2', 'clientes')
                                      }
                                      }
                                      defaultValue={this.state.step2.clientes}
                                      /*messages={{ emptyList: "teste" }}*/
                                    />
                                  )}
                                  {this.state.step2.clientes.length === 0 && (
                                    <Multiselect
                                      data={this.state.listas}
                                      minLength={3}
                                      valueField="id"
                                      textField="lista"
                                      filter="contains"
                                      className={
                                        this.state.step2.clientes.length === 0
                                          ? ''
                                          : 'check'
                                      }
                                      placeholder="Selecione a lista de clientes"
                                      onChange={(e) => {
                                        this.handleMultiSelect(e, 'step2', 'clientes')
                                      }
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        }

                        {/* step 2 - new design form */}
                        {!this.state.on &&
                        <>
                          <div className="row adjust-spacement show-design-form" id="">
                            <div className="">
                              <label
                                style={{
                                  fontSize: '0.85em',
                                  fontWeight: '500',
                                  paddingLeft: '20px'
                                }}
                              >
                                Clientes
                              </label>
                            </div>
                            <div className="form-item-padding">
                              <label
                                style={{
                                  fontSize: '0.72em',
                                  fontWeight: '500',
                                  paddingLeft: '20px'
                                }}
                              >
                                Configure os parâmetros
                              </label>
                            </div>
                          </div>
                          <div className="row show-design-form">
                            <div className="col-12 ">
                              <div className="row">
                                <div className="col-12">
                                  <label>
                                  <span className="font-size-0-85em">
                                    Frequência{' '}
                                  </span>
                                    <span className="font-size-0-72rem">
                                    (Data da última compra)
                                  </span>
                                  </label>
                                </div>
                                <div className="col-4">
                                  <div className="form-item">
                                    <Combobox
                                      defaultValue={'>'}
                                      value={this.state.step2.op}
                                      name="op"
                                      data={['=', '>', '>=', '<', '<=']}
                                      defalutlValue=">"
                                      onChange={(value) => {
                                        this.setState({
                                          step2: {
                                            ...this.state.step2,
                                            op: value
                                          }
                                        })
                                        this.debounce()
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-4 adjust-form-input">
                                  <div className="form-item">
                                    <input
                                      className="form-control"
                                      name="number"
                                      type="text"
                                      value={this.state.step2.number}
                                      onChange={(evt) => {
                                        this.setState({
                                          step2: {
                                            ...this.state.step2,
                                            number: evt.target.value
                                          }
                                        })
                                        this.debounce()
                                      }}
                                      id=""
                                      placeholder=""
                                    />
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="form-item">
                                    <Combobox
                                      value={this.state.step2.opNumber}
                                      name="opNumber"
                                      data={[
                                        { id: 1, name: 'Dias' },
                                        { id: 7, name: 'Semanas' },
                                        { id: 30, name: 'Meses' }
                                      ]}
                                      valueField="id"
                                      defalutlValue={1}
                                      textField="name"
                                      onChange={(value) => {
                                        this.setState({
                                          step2: {
                                            ...this.state.step2,
                                            opNumber: value.id
                                          }
                                        })
                                        this.debounce()
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <label>
                                    {' '}
                                    <span className="font-size-0-85em">
                                    Tipologia
                                  </span>
                                  </label>
                                  <Multiselect
                                    data={this.state.tipologias}
                                    caseSensitive={false}
                                    minLength={3}
                                    valueField="typology_code"
                                    textField="typology"
                                    filter="contains"
                                    className={
                                      this.state.step2.tipologia.length === 0
                                        ? ''
                                        : 'check'
                                    }
                                    placeholder="Selecione as tipologias"
                                    onChange={(e) => {
                                      this.handleMultiSelect(
                                        e,
                                        'step2',
                                        'tipologia'
                                      )
                                      this.debounce()
                                    }}
                                  />
                                </div>
                                <div className="col-12">
                                  <div className="form-item">
                                    <label>
                                      <span className="font-size-0-85em">UF</span>
                                    </label>
                                    <Multiselect
                                      data={this.state.estados}
                                      valueField="id"
                                      textField="estado"
                                      filter="contains"
                                      className={
                                        this.state.step2.estado.length === 0
                                          ? ''
                                          : 'check'
                                      }
                                      placeholder="Selecione os estados"
                                      onChange={(e) => {
                                        this.handleStateChangeClear(
                                          e,
                                          'step2',
                                          'estado'
                                        )
                                        this.debounce()
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12">
                                  <div className="form-item">
                                    <label>
                                    <span className="font-size-0-85em">
                                      Cidade
                                    </span>
                                    </label>
                                    <Multiselect
                                      data={this.state.cidades}
                                      caseSensitive={false}
                                      valueField="id"
                                      textField="cidade"
                                      filter="contains"
                                      className={
                                        this.state.step2.cidade.length === 0
                                          ? ''
                                          : 'check'
                                      }
                                      placeholder="Selecione os estados"
                                      onChange={(e) => {
                                        this.handleMultiSelect(
                                          e,
                                          'step2',
                                          'cidade'
                                        )
                                        this.debounce()
                                      }}
                                    />
                                  </div>
                                </div>

                                {/*2*/}

                                <div className="col-12 pb-0" style={{ marginTop: '-15px"' }}>
                                  <label>
                                    {' '}
                                    <span className="font-size-0-85em">
                                    Condições
                                  </span>
                                  </label>
                                </div>
                                <div className="col-12 adjust-radio">
                                  <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step2.condicoes === '1'
                                        ? 'rdio checked'
                                        : 'rdio'
                                    }
                                    onClick={(e) =>
                                      this.setState(
                                        {
                                          step2: {
                                            ...this.state.step2,
                                            condicoes: '1'
                                          }
                                        },
                                        this.debounce()
                                      )
                                    }
                                  ></span>
                                    <label className="check-label">Compram</label>
                                  </div>
                                  <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step2.condicoes === '2'
                                        ? 'rdio checked'
                                        : 'rdio'
                                    }
                                    onClick={(e) =>
                                      this.setState(
                                        {
                                          step2: {
                                            ...this.state.step2,
                                            condicoes: '2'
                                          }
                                        },
                                        this.debounce()
                                      )
                                    }
                                  ></span>
                                    <label className="check-label">
                                      Não Compram
                                    </label>
                                  </div>
                                  <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step2.condicoes === '0'
                                        ? 'rdio checked'
                                        : 'rdio'
                                    }
                                    onClick={(e) =>
                                      this.setState(
                                        {
                                          step2: {
                                            ...this.state.step2,
                                            condicoes: '0'
                                          }
                                        },
                                        this.debounce()
                                      )
                                    }
                                  ></span>
                                    <label className="check-label">
                                      Não se Aplica
                                    </label>
                                  </div>
                                </div>
                                <div className="col-12 adjust-select">
                                  <div className="form-item">
                                    <Multiselect
                                      data={this.state.produtos}
                                      caseSensitive={false}
                                      valueField="codigo_produto"
                                      textField="produto"
                                      filter="contains"
                                      className={
                                        this.state.step2.produto1.length === 0
                                          ? ''
                                          : 'check'
                                      }
                                      placeholder="Selecione os produtos"
                                      onChange={(e) => {
                                        this.handleMultiSelect(
                                          e,
                                          'step2',
                                          'produto1'
                                        )
                                        this.debounce()
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 adjust-radio">
                                  <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step2.eou === 'e'
                                        ? 'rdio checked'
                                        : 'rdio'
                                    }
                                    onClick={(e) =>
                                      this.setState(
                                        {
                                          step2: {
                                            ...this.state.step2,
                                            eou: 'e'
                                          }
                                        },
                                        this.debounce()
                                      )
                                    }
                                  ></span>
                                    <label className="check-label">E</label>
                                  </div>
                                  <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step2.eou === 'ou'
                                        ? 'rdio checked'
                                        : 'rdio'
                                    }
                                    onClick={(ou) =>
                                      this.setState(
                                        {
                                          step2: {
                                            ...this.state.step2,
                                            eou: 'ou'
                                          }
                                        },
                                        this.debounce()
                                      )
                                    }
                                  ></span>
                                    <label className="check-label">Ou</label>
                                  </div>
                                </div>
                                <div className="col-12 adjust-select">
                                  <div className="form-item">
                                    <Multiselect
                                      data={this.state.produtos}
                                      caseSensitive={false}
                                      valueField="codigo_produto"
                                      textField="produto"
                                      filter="contains"
                                      className={
                                        this.state.step2.produto2.length === 0
                                          ? ''
                                          : 'check'
                                      }
                                      placeholder="Selecione os produtos"
                                      onChange={(e) => {
                                        this.handleMultiSelect(
                                          e,
                                          'step2',
                                          'produto2'
                                        )
                                        this.debounce()
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-12 pb-0">
                                  <label>
                                  <span className="font-size-0-85em">
                                    Ticket médio
                                  </span>
                                  </label>
                                </div>
                                <div className="col-4">
                                  <div className="form-item">
                                    <Combobox
                                      defaultValue={'>='}
                                      value={this.state.step2.opTicketMedio}
                                      name="opTicketMedio"
                                      data={['=', '>', '>=', '<', '<=']}
                                      defalutlValue=">"
                                      onChange={(value) => {
                                        this.setState({
                                          step2: {
                                            ...this.state.step2,
                                            opTicketMedio: value
                                          }
                                        })
                                        this.debounce()
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-8 adjust-form-input">
                                  <div className="form-item">
                                    <CurrencyInput
                                      className="form-control"
                                      prefix="R$ "
                                      decimalSeparator=","
                                      thousandSeparator="."
                                      placeholder="Digite o valor em reais"
                                      name="valorTicketMedio"
                                      type="text"
                                      value={this.state.step2.valorTicketMedio}
                                      onChange={(value) => {
                                        this.setState({
                                          step2: {
                                            ...this.state.step2,
                                            valorTicketMedio: value
                                          }
                                        })
                                        this.debounce()
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <label>
                                    <span
                                      className=""
                                      style={{ fontWeight: '700' }}
                                    >
                                      Cobertura
                                    </span>{' '}
                                <span style={{ fontSize: '12px' }}>
                                      (<span>{this.state.step2.alcance ||
                                (this.state.alcance === undefined ||
                                this.state.alcance === ''
                                  ? ''
                                  : this.state.alcance.length)}{' '}</span>{' '}
                                  CLIENTES)
                                    </span>
                              </label>
                            </div>
                          </div>
                        </>
                        }
                        {/* end step 2 form */}

                        <div className="form-footer">
                          <button
                            className="btn"
                            onClick={() => {
                              this.toggleStep('1')
                            }}
                          >
                            <span>
                              <MdArrowBack/>
                            </span>{' '}
                            Passo 1
                          </button>
                          <button
                            className="btn"
                            onClick={() => {
                              this.toggleStep('3') &&
                              (!this.state.on && this.debounce())
                            }}
                          >
                            Seguinte{' '}
                            <span>
                              <MdArrowForward/>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="stepper-title">
                      <h5>{step3}Fíltros</h5>
                    </div>
                    <div
                      className={
                        step === '3' ? 'stepper-item' : 'stepper-item d-none'
                      }
                    >
                      <div className="form-group stepper-form">
                        <div className="form-item checkbox pb-3">
                          <input
                            type="checkbox"
                            id="fl_filtro"
                            onChange={() => this.handleCheck()}
                            checked={this.state.verificaCheck}
                          />
                          <label
                            htmlFor="fl_filtro"
                            className="fakebox"
                          ></label>
                          <label htmlFor="fl_filtro" className="check-label padding-left-8">
                            {' '} Ignorar Filtros
                          </label>
                        </div>
                        <div
                          className={
                            this.state.verificaCheck ? 'd-none' : 'd-block'
                          }
                          style={{ paddingTop: '16px' }}
                        >
                          <div className="form-item ">
                            <label>Historico App</label>
                            <div className="rdio-group row">
                              <div className="radio-col col-6">
                                <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step3.historico === 'ContraOferta'
                                        ? 'rdio checked'
                                        : 'rdio'
                                    }
                                    onClick={() => this.handleRadio('ContraOferta')}
                                  ></span>
                                  <label
                                    onClick={() => this.handleRadio('ContraOferta')}
                                    className="check-label"
                                  >
                                    Só ContraOferta
                                  </label>
                                </div>
                                <div className="clear"></div>
                                <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step3.historico === 'Carrinho'
                                        ? 'rdio checked rdio-fix'
                                        : 'rdio rdio-fix'
                                    }
                                    onClick={() => this.handleRadio('Carrinho')}
                                  ></span>
                                  <label
                                    onClick={() => this.handleRadio('Carrinho')}
                                    className="check-label"
                                  >
                                    Só Carrinho
                                  </label>
                                </div>
                              </div>
                              <div className="radio-col col-6">
                                <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step3.historico ===
                                      'ContraOferta e Carrinho'
                                        ? 'rdio checked rdio-fix'
                                        : 'rdio rdio-fix'
                                    }
                                    onClick={() =>
                                      this.handleRadio('ContraOferta e Carrinho')
                                    }
                                  ></span>
                                  <label
                                    onClick={() =>
                                      this.handleRadio('ContraOferta e Carrinho')
                                    }
                                    className="check-label"
                                  >
                                    ContraOferta e Carrinho
                                  </label>
                                </div>
                                <div className="clear"></div>
                                <div className="radio-group">
                                  <span
                                    className={
                                      this.state.step3.historico === 'Nenhum'
                                        ? 'rdio checked rdio-fix'
                                        : 'rdio rdio-fix'
                                    }
                                    onClick={() => this.handleRadio('Nenhum')}
                                  ></span>
                                  <label
                                    onClick={() => this.handleRadio('Nenhum')}
                                    className="check-label"
                                  >
                                    Nenhum
                                  </label>
                                </div>
                              </div>
                              <div className="clear"></div>
                            </div>
                          </div>
                          <div className="clear"></div>
                          {/* start tipologias step 3 */}

                          {this.state.on &&
                          <div className="form-item" style={{ marginTop: '10px' }}>
                            <label>
                              {' '}
                              <span className="font-size-0-85em">
                                    Tipologia
                                  </span>
                            </label>
                            <Multiselect
                              data={this.state.tipologias}
                              caseSensitive={false}
                              minLength={3}
                              valueField="typology_code"
                              textField="typology"
                              filter="contains"
                              className={
                                this.state.step3.tipologia.length === 0
                                  ? ''
                                  : 'check'
                              }
                              placeholder="Selecione as tipologias"
                              onChange={(e) => {
                                this.handleMultiSelect(
                                  e,
                                  'step3',
                                  'tipologia'
                                )
                                this.debounce()
                              }}
                            />
                          </div>
                          }
                          {/* end tipologias step 3 */}
                          <div className="clear"></div>
                          <br/>
                          <div className="form-item">
                            <label>Período do pedido realizado no App</label>
                            <div className="d-flex-sb">
                              <DatePicker
                                selected={this.state.step3.dataIni}
                                onChange={this.handleFiltroIni}
                                dateFormat="dd/MM/yyyy"
                                timeCaption="time"
                                className={
                                  this.state.step3.dataIni === null
                                    ? 'form-control'
                                    : 'form-control check'
                                }
                                name="dataIni"
                              />
                              até
                              <DatePicker
                                selected={this.state.step3.dataFim}
                                onChange={this.handleFiltroFim}
                                dateFormat="dd/MM/yyyy"
                                timeCaption="time"
                                className={
                                  this.state.step3.dataFim === null
                                    ? 'form-control'
                                    : 'form-control check'
                                }
                                name="dataFim"
                              />
                            </div>
                          </div>
                          <div className="form-item">
                            <br/>
                            <p>
                              <strong>Alcance real da campanha: </strong>{' '}
                              {this.state.alcance === undefined ||
                              this.state.alcance === ''
                                ? ''
                                : this.state.alcance.length}{' '}
                              Clientes
                            </p>
                          </div>
                        </div>
                        <div className="form-footer">
                          <button
                            className="btn"
                            onClick={() => {
                              this.toggleStep('2') &&
                              (!this.state.on && this.debounce())
                            }}
                          >
                            <span>
                              <MdArrowBack/>
                            </span>{' '}
                            Passo 2
                          </button>
                          <button
                            className="btn"
                            onClick={() => {
                              this.toggleStep('4')
                            }}
                          >
                            Próximo{' '}
                            <span>
                              <MdArrowForward/>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="stepper-title">
                      <h5>{step4}Mecânica</h5>
                    </div>
                    <div
                      className={
                        step === '4' ? 'stepper-item' : 'stepper-item d-none'
                      }
                    >
                      <div className="form-group stepper-form">

                        <div className="row pt-0 pb-0">
                          <div className="text-right pt-0 pb-0" id="main-base-input-switch-on-image"
                               style={{ marginBottom: '-20px' }}>
                            <div className="form-item">
                              <label className="label-obs">
                                Escolha uma das opções *
                              </label>
                            </div>
                            <Switch
                              checked={this.state.onFile}
                              onChange={this.handleSelectOnFile}
                              width={200}
                              offColor="#fb8501"
                              onColor="#109C33"
                              uncheckedIcon={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: 'white',
                                    paddingRight: 2,
                                  }}>
                                  Upload de Arte
                                </div>
                              }
                              checkedIcon={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: 'white',
                                    paddingRight: 2
                                  }}>
                                  Imagem do Banco ifefo
                                </div>
                              }
                            />
                          </div>
                        </div>
                        {/*  hidden if switch is true */}
                        {!this.state.onFile &&
                        <div className="form-item">
                          <div className="d-flex-sb"
                               style={{ paddingBottom: '0', marginBottom: '0', marginTop: '-10px' }}>
                            <input
                              name="marketingapp"
                              type="file"
                              accept=".jpg,.jpeg,.png"
                              onChange={(e) =>
                                this.handleChangeFile(e.target.files)
                              }
                              id="file-1" className="inputfile inputfile-1"/>
                            <label htmlFor="file-1">
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                                <path
                                  d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"/>
                              </svg>
                              <span>Selecione uma imagem&hellip;</span></label>
                          </div>
                          <label className="label-obs">
                            Obs: Somente imagens no formato JPG ou PNG
                          </label>
                        </div>
                        }
                        <div className="form-item" style={{ marginTop: '12px' }}>
                          <label>Duração da campanha *</label>
                          <div className="d-flex-sb">
                            <DatePicker
                              selected={this.state.step4.dataIni}
                              onChange={this.handleDataIni}
                              dateFormat="dd/MM/yyyy"
                              timeCaption="time"
                              className={
                                this.state.step4.dataIni === null
                                  ? 'form-control'
                                  : 'form-control check'
                              }
                              name="dataIni"
                            />
                            até
                            <DatePicker
                              selected={this.state.step4.dataFim}
                              onChange={this.handleDataFim}
                              dateFormat="dd/MM/yyyy"
                              timeCaption="time"
                              className={
                                this.state.step4.dataFim <=
                                this.state.step4.dataIni
                                  ? 'form-control'
                                  : 'form-control check'
                              }
                              name="dataFim"
                            />
                          </div>
                        </div>

                        <div className="form-item margin-top-15">
                          <label>Produtos</label>
                          <Multiselect
                            defaultValue={this.state.listaProdutosNews}
                            data={this.state.listaProdutosNews}
                            caseSensitive={false}
                            valueField="key"
                            textField="produto"
                            filter="contains"
                            className={
                              this.state.listaProdutosNews.length === 0
                                ? ''
                                : 'check'
                            }
                            placeholder="Selecione produto"
                            onChange={e => {
                              if (e.length > 4) {
                                Swal({
                                  icon: 'error',
                                  text: 'São permitidos o máximo de 04 (quatro) Produtos na formação do MIX das Campanhas'
                                })
                              }
                              this.handleMultiSelect2(e)
                              if (this.state.onFile) {
                                this.debounceCustom()
                              }
                            }}
                          />
                        </div>

                        <div className="form-item">
                          <table className="table">
                            <thead>
                            <tr>
                              <th>CD</th>
                              <th className="text-left">$ Cheio</th>
                              <th className="text-left">$ Fefo</th>
                              <th
                                className="text-left"
                                style={{ padding: '5px 1px 0px' }}
                              >
                                $ Final.
                              </th>
                              <th className="text-center">% Desc</th>
                              <th className="text-center">Clientes</th>
                              <th
                                className="text-center"
                                style={{ width: 80 }}
                              >
                                  <span style={{ fontSize: '1.6em' }}>
                                    <MdDeleteForever/>
                                  </span>
                                <MdAutorenew
                                  onClick={() => this.restoreAll()}
                                />
                              </th>
                            </tr>
                            </thead>
                            <tbody>{cds}</tbody>
                          </table>
                        </div>
                        <br/>
                        {this.state !== undefined &&
                        this.state.alcance !== undefined ? (
                          <>
                            <p>
                              <strong>Alcance real da campanha: </strong>{' '}
                              {this.state.alcanceReal === 0 && this.state.alcanceRealStatus === false
                                ? this.state.alcance.length
                                : this.state.alcanceReal}{' '}
                              Clientes
                            </p>
                            <br/>
                          </>
                        ) : (
                          ''
                        )}
                        <div className="form-footer">
                          <button
                            className="btn btn-red"
                            name="cancelar"
                            onClick={() => {
                              Swal({
                                icon: 'warning',
                                title: 'Tem certeza?',
                                text:
                                  'Se optar por cancelar a campanha, todos os dados serão perdidos. Se preferir salve um rascunho antes.',
                                buttons: {
                                  confirm: {
                                    text: 'Sim',
                                    value: true,
                                    visible: true,
                                    closeModal: true
                                  },
                                  cancel: {
                                    text: 'Não',
                                    value: false,
                                    visible: true,
                                    closeModal: true
                                  }
                                }
                              }).then((value) => {
                                if (value) {
                                  this.props.history.push('/impulsionamento')
                                }
                              })
                            }
                            }
                          >
                            Cancelar
                          </button>

                          <button
                            className="btn"
                            onClick={() => {
                              this.toggleStep('3', '4')
                            }}
                          >
                            <span>
                              <MdArrowBack/>
                            </span>{' '}
                            Passo 3
                          </button>
                          <button
                            className="btn btn-green"
                            name="lancar"
                            onClick={() => this.handleCheckFiles(true)}
                          >
                            Lançar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="box">
                <div className="box-title">
                  <h4>Prévia da Campanha</h4>
                  <span> </span>
                </div>
                <div className="box-content">
                  <div className="form-group">
                    <div className="form-item">
                      <div className="img-produto-prev">
                        {this.state.step4.produto === '' ? (
                          <img
                            id="productimgnew"
                            src={Blank}
                            alt="imagem indisponível"
                            style={{ maxWidth: '90%' }}
                          />
                        ) : (
                          <img
                            id="productimgnew"
                            src={this.state.fefo[this.state.step4.produto].img}
                            alt={
                              this.state.fefo[this.state.step4.produto]
                                .nome_produto
                            }
                            style={{ maxWidth: '90%' }}
                          />
                        )}
                      </div>
                      <div className="content-prev">
                        <div className="form-item">
                          <h4>
                            {this.state.step1.titulo === ''
                              ? 'Título'
                              : this.state.step1.titulo}
                          </h4>
                          <p className="p-inline">
                            {this.state.step1.assunto === ''
                              ? 'Corpo'
                              : this.state.step1.assunto}
                          </p>
                          <p>
                            {this.state.step4.produto === ''
                              ? 'Produto'
                              : this.state.fefo[this.state.step4.produto]
                                .nome_produto}
                          </p>
                          {this.state !== undefined &&
                          this.state.alcance !== undefined ? (
                            <>
                              <p>
                                <strong>Alcance real da campanha: </strong>{' '}
                                {this.state.alcanceReal === 0 && this.state.alcanceRealStatus === false
                                  ? this.state.alcance.length
                                  : this.state.alcanceReal}{' '}
                                Clientes
                              </p>
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        {this.state.showDetails ? (
                          <p
                            className="link text-right"
                            onClick={() => this.toggleDetails()}
                          >
                            Ver menos{' '}
                            <span>
                              <MdExpandLess/>
                            </span>
                          </p>
                        ) : (
                          <p
                            className="link text-right"
                            onClick={() => this.toggleDetails()}
                          >
                            Ver mais{' '}
                            <span>
                              <MdExpandMore/>
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className={this.state.showDetails ? '' : 'd-none'}>
                      <div className="form-item">
                        <h4>Informações</h4>
                        <p>
                          <strong>Canais: </strong>
                          {whats ? (
                            <span className="icon-canal active">WhatsApp,</span>
                          ) : (
                            ''
                          )}

                          {souk ? (
                            <span className="icon-canal active">Push,</span>
                          ) : (
                            ''
                          )}

                          {mail ? (
                            <span className="icon-canal active">E-mail,</span>
                          ) : (
                            ''
                          )}

                          {sms ? (
                            <span className="icon-canal active">SMS</span>
                          ) : (
                            ''
                          )}
                        </p>
                        <p>
                          <strong>Título: </strong>
                          {this.state.step1.titulo}
                        </p>
                        <p>
                          <strong>Corpo: </strong>
                          {this.state.step1.assunto}
                        </p>
                      </div>
                      <div className="form-item">
                        {/*Start Here*/}
                        <h4>Destinatário</h4>
                        <p>
                          <strong>Tipologias: </strong>
                          {this.state.tipologias
                            .filter(
                              (l) =>
                                this.state.step2.tipologia.filter(
                                  (c) => c === l.tipologia
                                ).length > 0
                            )
                            .reduce((a, b) => a + b.tipologia + ', ', '')}
                        </p>
                        <p>
                          <strong>Estados(UFs): </strong>
                          {(this.state.estados || [])
                            .filter(
                              (l) =>
                                this.state.step2.estado.filter(
                                  (c) => c === l.estado
                                ).length > 0
                            )
                            .reduce((a, b) => a + b.estado + ', ', '')}
                        </p>
                        <p>
                          <strong>Cidades: </strong>
                          {(this.state.cidades || [])
                            .filter(
                              (l) =>
                                this.state.step2.cidade.filter(
                                  (c) => c === l.cidade
                                ).length > 0
                            )
                            .reduce((a, b) => a + b.cidade + ', ', '')}
                        </p>
                        <p>
                          {this.state !== undefined &&
                          this.state.cnpjs !== undefined &&
                          this.state.cnpjsInvalidos !== undefined ? (
                            <>
                              <strong>Cobertura: </strong>
                              {this.state.cnpjs.length} clientes.
                              <br/> Não levando mais em consideração:
                              <br/>
                              {this.state.cnpjsInvalidos.ultilizaPlataforma ===
                              undefined
                                ? ''
                                : this.state.cnpjsInvalidos
                                  .ultilizaPlataforma}{' '}
                              clientes que não ultilizam o App
                              <br/>
                              {this.state.cnpjsInvalidos.status === undefined
                                ? ''
                                : this.state.cnpjsInvalidos.status}{' '}
                              clientes inativos
                              <br/>
                              {this.state.cnpjsInvalidos.saldo === undefined
                                ? ''
                                : this.state.cnpjsInvalidos.saldo}{' '}
                              clientes com saldo mínimo insuficiente
                            </>
                          ) : (
                            ''
                          )}
                        </p>
                      </div>
                      <div className="form-item">
                        <h4>Fíltro</h4>
                        <p>
                          <strong>Histórico: </strong>
                          {this.state.step3.historico}
                        </p>
                        <p>
                          <strong>Período: </strong>De{' '}
                          {this.state.step3.dataIni.toLocaleDateString()}
                          &nbsp; Até{' '}
                          {this.state.step3.dataFim.toLocaleDateString()}
                        </p>
                      </div>
                      <div className="form-item">
                        <h4>Mecânica</h4>
                        <p>
                          <strong>Duração: </strong>De{' '}
                          {this.state.step4.dataIni.toLocaleDateString()}
                          &nbsp; Até{' '}
                          {this.state.step4.dataFim.toLocaleDateString()}
                        </p>
                        <p>
                          <strong>Produto: </strong>
                          {this.state.step4.produto === ''
                            ? ''
                            : this.state.fefo[this.state.step4.produto]
                              .nome_produto}
                        </p>
                        <p>
                          <strong>CD's: </strong>
                          {this.state.step4.centroDistribuicao.map(
                            (cd) => cd.nomeCd + ', '
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}

export default withRouter(NovoImpulsionamentoCampanha)
