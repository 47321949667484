import React, { Fragment, useEffect, useState } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { ClipLoader } from 'react-spinners'
import { MdChevronRight } from 'react-icons/md'
import _ from 'lodash'
import {
  checkPermissionsForUpdate,
  getPermissoes,
  UpdadeUser,
  updatePermicoesUsuario
} from '../../services/api'
import { useParams } from 'react-router-dom'

const AlteracaoUsuario = () => {
  const [senha1, setSenha1] = useState('')
  const [senha2, setSenha2] = useState('')
  const [loader, setLoader] = useState(false)
  const [label, setLabel] = useState('')
  const [permissao, setPermissao] = useState([])
  const [checkAll, setCheckAll] = useState([])
  const [abaAtiva, setAbaAtiva] = useState([])

  // const id = window.location.pathname.split('/')

  const { id } = useParams()

  useEffect(() => {
    const requisicao = async () => {
      setLoader(true)
      const dados = await getPermissoes()
      const dadosSetCheck = await checkPermissionsForUpdate(id)
      const newItems = _.cloneDeep(dados.msg)

      if (dadosSetCheck) {
        dadosSetCheck.data.map((permissaoTemporaria) => {
          // newItems.filter(e => e.telaId === permissaoTemporaria.id_tela)[0].permissoes.filter(a => a.id === permissaoTemporaria.id_permissoes)[0].cond = 1
          // ALR - Nova forma para corrigir erro
          for (let i = 0; i < newItems.length; i++) {
            if (newItems[i].telaId === permissaoTemporaria.id_tela) {
              for (let x = 0; x < newItems[i].permissoes.length; x++) {
                if (
                  newItems[i].permissoes[x].id ===
                  permissaoTemporaria.id_permissoes
                ) {
                  newItems[i].permissoes[x].cond = 1
                }
              }
            }
          }

          return permissaoTemporaria
        })
      }
      setPermissao(newItems)
      setLoader(false)
    }
    requisicao().then((response) => response)
  }, [id])

  let verificaCategoria = []

  const handleCheck = (telaId, permissaoIndex, cond) => {
    const newItems = _.cloneDeep(permissao)
    const telaIndex = newItems.findIndex((x) => x.telaId === telaId)
    newItems[telaIndex].permissoes[permissaoIndex].cond = cond === 0 ? 1 : 0
    setPermissao(newItems)
    const listaPermissoes = newItems[telaIndex].permissoes
    let verificaCheck
    for (let i = 0; i < listaPermissoes.length; i++) {
      if (listaPermissoes[i].cond === 0) {
        verificaCheck = false
        break
      } else {
        verificaCheck = true
      }
    }
    setCheckAll({ ...checkAll, [`tela-${telaId}`]: verificaCheck })
  }
  const verificaCheckAll = (telaId) => {
    const newItems = _.cloneDeep(permissao)
    const telaIndex = newItems.findIndex((x) => x.telaId === telaId)
    if (checkAll[`tela-${telaId}`] === true) {
      newItems[telaIndex].permissoes.forEach((item) => {
        item.cond = 0
      })
      setPermissao(newItems)
      setCheckAll({ ...checkAll, [`tela-${telaId}`]: false })
    } else {
      newItems[telaIndex].permissoes.forEach((item) => {
        item.cond = 1
      })
      setPermissao(newItems)
      setCheckAll({ ...checkAll, [`tela-${telaId}`]: true })
    }
  }

  const handleAbaAtiva = (id) => {
    setAbaAtiva({ ...abaAtiva, [id]: !abaAtiva[id] })
  }

  const handleCadastro = async () => {
    setLoader(true)

    try {
      if (!id) throw new Error({ error: 'Id user não informado' })

      const up = await updatePermicoesUsuario(id, JSON.stringify(permissao))

      if (up.token === null) {
        return
      }
      if (up.status === 'error') throw new Error(up.msg)
      setLoader(false)
      swal({
        icon: 'success',
        text: up.msg
      })
    } catch (e) {
      setLoader(false)
      swal({
        icon: 'error',
        text: e.message
      })
    }
  }

  const alterarSenha = async () => {
    setLoader(true)
    // if((this.state.senha1 !== '' || this.state.senha2 !== '') && (this.state.senha1 === this.state.senha2)){
    try {
      if (senha1 === '' || senha2 === '')
        throw new Error('Favor digitar as 2 senhas!')
      if (senha2.length <= 4)
        throw new Error('A nova senha deve ter mais de 4 digitos!')

      const data = await UpdadeUser({
        senhaAntiga: senha1,
        senhaNova: senha2,
        id: id
      })
      if (data.status === 'error') throw new Error(data.msg)
      swal({
        icon: 'success',
        text: 'Senha alterada com sucesso',
        buttons: {
          confirm: {
            value: true,
            text: 'Ok',
            closeModal: true,
            visible: true
          }
        }
      })
    } catch (e) {
      swal({
        icon: 'info',
        text: e.message,
        buttons: {
          confirm: {
            value: true,
            text: 'Ok',
            closeModal: true,
            visible: true
          }
        }
      })
    }
    setLoader(false)
  }

  return (
    <>
      <Menu menuDrop="2" />
      <Header />
      <div className="container">
        <div className="breadcrumb">
          <div>
            <h3>CLIENTES DIGITAIS</h3>
            <p>
              Configurações{' '}
              <span>
                <MdChevronRight />
              </span>
              Alteração de Usuário
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="box">
              <div className="box-title">
                <h4>Alterar Senha</h4>
                <button
                  style={{ marginRight: 40 }}
                  className="btn"
                  onClick={() => alterarSenha()}
                >
                  ALTERAR SENHA
                </button>
              </div>
              <div className="box-content pt-0">
                <div className="form-group pt-0">
                  <div className="col-12 pb-0">
                    <div className="form-item">
                      <label
                        className={
                          senha1 !== '' || label === '0'
                            ? 'input-title active'
                            : 'input-title'
                        }
                      >
                        Senha Antiga
                      </label>
                      <input
                        id="senha1"
                        name="senha1"
                        value={senha1}
                        onFocus={() => setLabel('0')}
                        onBlur={() => setLabel('')}
                        type="password"
                        className={
                          senha1 === '' ? 'form-control' : 'form-control check'
                        }
                        onChange={(e) => setSenha1(e.target.value)}
                      />
                    </div>
                    <div className="form-item">
                      <label
                        className={
                          senha2 !== '' || label === '1'
                            ? 'input-title active'
                            : 'input-title'
                        }
                      >
                        Senha Nova
                      </label>
                      <input
                        id="senha2"
                        name="senha2"
                        value={senha2}
                        onFocus={() => setLabel('1')}
                        onBlur={() => setLabel('')}
                        type="password"
                        className={
                          senha2 === '' ? 'form-control' : 'form-control check'
                        }
                        onChange={(e) => setSenha2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="clear" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="box">
              <div className="box-title">
                <h4>Permissões</h4>
                <button
                  style={{ marginRight: 40 }}
                  className="btn"
                  onClick={() => handleCadastro()}
                >
                  ALTERAR PERMISSÕES
                </button>
              </div>
              <div className="box-content">
                {permissao.length > 0 && (
                  <div className="form-group permissoes">
                    {permissao
                      .filter((c) => {
                        if (!verificaCategoria.includes(c.aba)) {
                          verificaCategoria = [...verificaCategoria, c.aba]
                          return true
                        }
                        return false
                      })
                      .map((c) => {
                        return (
                          <Fragment key={`categorias-${c.telaId}`}>
                            <div
                              onClick={() =>
                                handleAbaAtiva(`categoria-${c.aba}`)
                              }
                            >
                              <span>
                                <MdChevronRight
                                  className={
                                    abaAtiva[`categoria-${c.aba}`]
                                      ? 'arrow active'
                                      : 'arrow'
                                  }
                                />
                              </span>
                              {c.aba}
                            </div>
                            <div
                              className="arrow-open"
                              id={`categoria-${c.aba}`}
                              style={{
                                display: abaAtiva[`categoria-${c.aba}`]
                                  ? 'block'
                                  : 'none'
                              }}
                            >
                              {permissao
                                .filter((t) => {
                                  if (t.aba.includes(c.aba)) {
                                    return true
                                  }
                                  return false
                                })
                                .map((t) => {
                                  return (
                                    <Fragment key={`tela-${t.telaId}`}>
                                      <div
                                        onClick={() =>
                                          handleAbaAtiva(`tela-${t.telaId}`)
                                        }
                                      >
                                        <span>
                                          <MdChevronRight
                                            className={
                                              abaAtiva[`${`tela-${t.telaId}`}`]
                                                ? 'arrow active'
                                                : 'arrow'
                                            }
                                          />
                                        </span>
                                        {t.telaNome}
                                      </div>
                                      <div
                                        className="arrow-open"
                                        id={`tela-${t.telaId}`}
                                        style={{
                                          display: abaAtiva[`tela-${t.telaId}`]
                                            ? 'block'
                                            : 'none'
                                        }}
                                      >
                                        <div className="form-item checkbox">
                                          <input
                                            type="checkbox"
                                            id={`input-tela-${t.telaId}`}
                                            onChange={() =>
                                              verificaCheckAll(t.telaId)
                                            }
                                            checked={
                                              checkAll[`tela-${t.telaId}`]
                                            }
                                          />
                                          <label
                                            htmlFor={`input-tela-${t.telaId}`}
                                            className="fakebox"
                                          />
                                          <label
                                            htmlFor={`input-tela-${t.telaId}`}
                                            className="check-label"
                                          >
                                            Selecionar todos
                                          </label>
                                        </div>
                                        {t.permissoes.map((p, index) => {
                                          const permissaoIndex = index
                                          return (
                                            <Fragment key={`permissao-${p.id}`}>
                                              <div className="form-item checkbox checkbox-sub">
                                                <input
                                                  type="checkbox"
                                                  id={`input-permissao-${t.telaId}-${p.id}`}
                                                  onChange={() =>
                                                    handleCheck(
                                                      t.telaId,
                                                      permissaoIndex,
                                                      p.cond
                                                    )
                                                  }
                                                  checked={p.cond !== 0}
                                                />
                                                <label
                                                  htmlFor={`input-permissao-${t.telaId}-${p.id}`}
                                                  className="fakebox"
                                                />
                                                <label
                                                  htmlFor={`input-permissao-${t.telaId}-${p.id}`}
                                                  className="check-label"
                                                >
                                                  {p.descricao}
                                                </label>
                                              </div>
                                            </Fragment>
                                          )
                                        })}
                                      </div>
                                    </Fragment>
                                  )
                                })}
                            </div>
                          </Fragment>
                        )
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className={loader ? 'loader show' : 'loader'}>
        <ClipLoader sizeUnit={'px'} size={90} color={'#FB8501'} loading />
      </div>
    </>
  )
}

export default AlteracaoUsuario
