import React from 'react'

const GradeLogistica = ({ data }) => {
  return (
    <div className="dialog-form__wrapper">
      <div className="dialog-form__full">
        <div className="ifefo-typo__subtitle">{data.centro_distribuicao}</div>
        <table className="ifefo-table">
          <thead>
            <tr>
              <th>Segunda-feira</th>
              <th>Terça-feira</th>
              <th>Quarta-feira</th>
              <th>Quinta-feira</th>
              <th>Sexta-feira</th>
              <th>Sábado</th>
              <th>Domingo</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{data.segunda ? 'Sim' : 'Não'}</td>
              <td>{data.terca ? 'Sim' : 'Não'}</td>
              <td>{data.quarta ? 'Sim' : 'Não'}</td>
              <td>{data.quinta ? 'Sim' : 'Não'}</td>
              <td>{data.sexta ? 'Sim' : 'Não'}</td>
              <td>{data.sabado ? 'Sim' : 'Não'}</td>
              <td>{data.domingo ? 'Sim' : 'Não'}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default GradeLogistica
