import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { MdChevronRight } from 'react-icons/md'
import { getPedidoHome } from '../../services/api'
import './style.css'
import counterpart from 'counterpart'
import pt from '../../lang/pt'
import es from '../../lang/es'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import ActionsRenderer from '../../utils/grid/renderers/actionsRenderer'
import { cnpjFormatter } from '../../utils/grid/formatters/cnpj.formatter'
import ModalSwitcher from './ModalSwitcher'
import useResize from '../../hooks/useResize'
import gridOptions from '../../utils/grid/options/grid-options'
import { decimalFormatter } from '../../utils/grid/formatters/decimal.formatter'
import { currencyFormatter } from '../../utils/grid/formatters/currency.formatter'
import numberValueParser from '../../utils/grid/value-parsers/number.parser'
import { statusPedidoCellStyle } from '../../utils/grid/cell-styles/statusPedido-cell-style'
import useInterval from '../../hooks/useInterval'
import { render } from 'react-dom'
import 'ag-grid-enterprise'

counterpart.registerTranslations('pt', pt)
counterpart.registerTranslations('es', es)
counterpart.setLocale('pt')

let selectedStatus = 'TODOS'

const Pedidos = () => {
  const [reload, setReload] = useState(false)

  const [rowData, setRowData] = useState([])
  const [gridApi, setGridApi] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [currentModal, setCurrentModal] = useState('')
  const [modalData, setModalData] = useState({})

  const [statuses, setStatuses] = useState([])
  const [stateSelectedStatus, setStateSelectedStatus] = useState(selectedStatus)

  const groupIncludeFooter = true
  const groupIncludeTotalFooter = true

  const closeModal = () => {
    setModalData({})
    setCurrentModal('')
    setShowModal(false)
    setReload(!reload)
  }

  const externalFilterChanged = (newValue) => {
    selectedStatus = newValue
    gridApi.onFilterChanged()
    setStateSelectedStatus(newValue)
  }

  const isExternalFilterPresent = () => {
    return selectedStatus !== 'TODOS'
  }

  const doesExternalFilterPass = (node) => {
    if (node === 'TODOS') return true
    return node.data.status === selectedStatus
  }

  useEffect(() => {
    const fun = async () => {
      try {
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const pedidos = await getPedidoHome(idDistribuidor)

        if (pedidos.token === null) {
          return
        }

        if (pedidos.status === 'error') {
          throw new Error(
            'Erro ao carregar a lista de pedidos, por favor tente novamente.'
          )
        }

        const { msg: pedidosArr = [] } = pedidos || {}

        const gotResult = pedidosArr.map((item) => {
          item.aprovado =
            item.id_status === 3 ? 0 : item.id_status === 4 ? 1 : 2
          return item
        })

        const reOrder = gotResult.sort(function (a, b) {
          let x = a.data_pedido.split('/').reverse().join(),
            y = b.data_pedido.split('/').reverse().join()
          return x > y ? -1 : x < y ? 1 : 0
        })
        setRowData(reOrder)

        const gotStatuses = gotResult.reduce((acc, curr) => {
          const { status: currStatus, id_status: idStatus } = curr
          if (!~acc.findIndex((item) => item.idStatus === idStatus))
            acc.push({ status: currStatus, idStatus })
          return acc
        }, [])
        gotStatuses.sort((a, b) => {
          return a.status > b.status ? 1 : -1
        })
        if (gotStatuses.length) {
          gotStatuses.unshift({ status: 'TODOS', idStatus: -1 })
        }

        setStatuses(gotStatuses)
      } catch (e) {
        swal({ icon: 'info', text: e.message })
      }
    }
    fun()
  }, [reload])

  useInterval(() => {
    if (!showModal) {
      setReload(!reload)
    }
  }, 60)

  const onGridReady = (params) => {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
  }

  useResize(() => {
    console.log('Starting resize listener')
    if (gridApi && gridApi.sizeColumnsToFit) {
      gridApi.sizeColumnsToFit()
    }
  })

  const componentParent = {
    actions: [
      {
        type: 'info',
        color: '#f77303',
        callback(rowNode) {
          setCurrentModal('detalhesPedido')
          setModalData(rowNode.data)
          setShowModal(true)
        }
      }
    ]
  }

  const statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left'
      }
    ]
  }

  const SumBoxes = (params) => {
    let sum = 0
    params.values.forEach((value) => (sum += value))
    return sum
  }

  return (
    <>
      <Menu menuDrop="20" />
      <Header />
      <div className="container  container--flex">
        <div className="breadcrumb" id="breadcrumb">
          <div>
            <h3>Pedidos</h3>
            <p>
              Pedido{' '}
              <span>
                <MdChevronRight />
              </span>{' '}
              Listagem
            </p>
          </div>
          <div className="grid-tabs">
            {statuses.map((status, index) => (
              <button
                key={status.idStatus + index}
                className={`grid-tabs__button${
                  stateSelectedStatus === status.status
                    ? ' grid-tabs__button--active'
                    : ''
                }`}
                type="button"
                name={status.status}
                style={statusPedidoCellStyle(
                  { data: { status: status.idStatus } },
                  true,
                  true
                )}
                onClick={(event) => {
                  externalFilterChanged(event.target.name)
                }}
              >
                {status.status}
              </button>
            ))}
          </div>
        </div>
        <div className="container__body">
          <div id="ListaClientes" className="col-12 h100">
            <div className="box h100  box--flex">
              <div className="box-content scroll-table scroll-table--lonely">
                <div
                  className="ag-theme-balham"
                  style={{ height: '100%', width: '100%' }}
                >
                  <AgGridReact
                    groupIncludeFooter={groupIncludeFooter}
                    groupIncludeTotalFooter={groupIncludeTotalFooter}
                    statusBar={statusBar}
                    rowSelection="false"
                    frameworkComponents={{
                      actionsRenderer: ActionsRenderer
                    }}
                    gridOptions={gridOptions}
                    context={{ componentParent }}
                    animateRows={true}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                    rowData={rowData}
                    onGridReady={onGridReady}
                  >
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="ID"
                      hide={true}
                      sortable={true}
                      field="id_comprador"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Código"
                      sortable={true}
                      field="cod_pedido"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={false}
                      width={150}
                      headerName="Status"
                      sortable={true}
                      cellStyle={statusPedidoCellStyle}
                      field="status"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={false}
                      width={150}
                      headerName="Data"
                      sortable={true}
                      field="data_pedido"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={false}
                      width={150}
                      headerName="SKU"
                      sortable={true}
                      field="sku"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={false}
                      width={100}
                      headerName="Origem"
                      sortable={true}
                      field="centro_distribuicao"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={false}
                      width={150}
                      headerName="Aceite"
                      sortable={true}
                      field="aceite"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={150}
                      valueFormatter={cnpjFormatter}
                      headerName="CNPJ"
                      sortable={true}
                      field="cnpj"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={100}
                      headerName="Comprador"
                      sortable={true}
                      field="comprador"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={100}
                      valueFormatter={decimalFormatter}
                      headerName="Caixas"
                      sortable={true}
                      filter="agNumberColumnFilter"
                      filterParams={{
                        numberParser: numberValueParser(true)
                      }}
                      field="volume"
                      aggFunc="sum"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={150}
                      valueFormatter={currencyFormatter('BRL')}
                      valueParser={numberValueParser(true)}
                      headerName="Fat Total (R$)"
                      sortable={true}
                      filter="agNumberColumnFilter"
                      filterParams={{
                        numberParser: numberValueParser(true)
                      }}
                      field="valor_total_pedido"
                      aggFunc="sum"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={100}
                      headerName="Cód Ped Ind"
                      sortable={true}
                      field="cod_pedido_industria"
                    />
                    <AgGridColumn
                      width={90}
                      filter={false}
                      headerName="Ações"
                      sortable={false}
                      colId="params"
                      editable={false}
                      field="value"
                      cellRenderer="actionsRenderer"
                    />
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {showModal && currentModal && (
        <ModalSwitcher
          modal={currentModal}
          data={modalData}
          onClose={closeModal}
        />
      )}
    </>
  )
}

export default Pedidos
