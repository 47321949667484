const castFn = (fn, bind, defaultOutput) =>
  Object.prototype.toString.call(fn) === '[object Function]'
    ? bind
      ? fn.bind(bind)
      : fn
    : Object.prototype.toString.call(defaultOutput) === '[object Function]'
    ? defaultOutput()
    : () => {
        return defaultOutput
      }

export default castFn
