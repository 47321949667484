export const faixaRenderer = (params) => {
  switch (((params || {}).value || '').toLowerCase()) {
    case 'amarela':
    case '2':
      return `<div style="line-height: 15px; border-radius: 3px; padding: 2px 5px; display: inline-block; background-color: #f1d528;color: #ffffff;">${
        (params || {}).value
      }</div>`
    case 'verde':
    case '1':
      return `<div style="line-height: 15px; border-radius: 3px; padding: 2px 5px; display: inline-block; background-color: #639D22;color: #ffffff;">${
        (params || {}).value
      }</div>`
    case 'laranja':
    case '3':
      return `<div style="line-height: 15px; border-radius: 3px; padding: 2px 5px; display: inline-block; background-color: #F6AE38;color: #ffffff;">${
        (params || {}).value
      }</div>`
    case 'vermelha':
    case '4':
      return `<div style="line-height: 15px; border-radius: 3px; padding: 2px 5px; display: inline-block; background-color: #AE3526;color: #ffffff;">${
        (params || {}).value
      }</div>`
    default:
      return `<div style="line-height: 15px; border-radius: 3px; padding: 2px 5px; display: inline-block; background-color: #f7f7f7;color: #666;">${
        (params || {}).value || 'N/A'
      }</div>`
  }
}
