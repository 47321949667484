import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { FaEye } from 'react-icons/fa'
import { MdAdd, MdChevronRight, MdClose } from 'react-icons/md'
import { Link } from 'react-router-dom'
import {
  filtraImpulsionamento,
  getEngajamentosRelatorios,
  getEngajamentosRelatoriosById,
  removerRascunho,
  setStatusEngajamento
} from '../../services/api'
import { ClipLoader } from 'react-spinners'
import './style.css'

export default class Engajamento extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tabAtiva: '',
      topoAtiva: [],
      filtros: {
        titulo: '',
        produto: '',
        status: ''
      },
      anuncios: [],
      detalhes: [],
      rascunhos: [],
      exibirDetalhes: false,
      filtroIsOpen: false,
      filtroBlock: false,
      loader: false
    }
  }

  componentDidMount() {
    const asyncMount = async () => {
      await this.start()
    }

    asyncMount().finally(() => {})
  }

  async changeStatus(_id) {
    try {
      const x = await setStatusEngajamento(_id, 'pausar')
      if (x.msg === 'ok') {
        await swal({
          icon: 'success',
          text: 'Engajamento parado com sucesso!'
        })
      } else {
        await swal({
          icon: 'error',
          text: 'Não foi possível parar o engajamento'
        })
      }
      await this.start()
    } catch (e) {
      await swal({
        icon: 'error',
        text: 'Não foi possível parar o engajamento'
      })
    }
  }

  async start() {
    this.setState({ loader: true })
    try {
      const ads = await getEngajamentosRelatorios()
      if (ads.token === null) {
        return
      }
      if (ads.status === 'success') {
        this.setState({
          anuncios: ads.msg
        })
      } else {
        await swal({ icon: 'info', text: ads.msg })
      }
    } catch (e) {
      this.setState({ loader: false })
    }
    this.setState({ loader: false })
  }

  toggleDesc = async (e) => {
    if (this.state.exibirDetalhes) return

    if (e === this.state.tabAtiva) {
      this.setState({ tabAtiva: '' })
    } else {
      this.setState({ tabAtiva: e })
    }
  }

  toggleTopo = async (e) =>
    this.setState({
      topoAtiva: { ...this.state.topoAtiva, [e]: !this.state.topoAtiva[e] }
    })

  handleFiltro = async (e) => {
    await this.setState({
      filtros: { ...this.state.filtros, [e.target.name]: e.target.value }
    })
    await this.filtrar()
  }

  filtrar = async () => {
    const { titulo, produto, status } = this.state.filtros
    this.setState({ loader: true })

    try {
      const filtro = await filtraImpulsionamento(titulo, produto, status, true)
      if (filtro.token === null) return
      if (filtro.status === 'error') throw new Error(filtro.msg)
      this.setState({
        anuncios: filtro.msg.impulsionamentos,
        rascunhos: filtro.msg.rascunhos,
        loader: false
      })
    } catch (e) {
      this.setState({ loader: false })
      await swal({ icon: 'error', text: e.message })
    }
  }

  removerRascunhoDb = async (rascunho) => {
    this.setState({ loader: true })
    await removerRascunho(rascunho.id_tbl_engajamento)
    await this.filtrar()
  }

  verDetalhes = async (d) => {
    this.setState({ loader: true })
    if (d !== '') {
      try {
        this.setState({ detaque: this.state.anuncios[d].titulo })
        const detalhes = await getEngajamentosRelatoriosById(
          this.state.anuncios[d].id_tbl_engajamento
        )
        if (detalhes.token === null) {
          return
        }
        if (detalhes.status === 'error') throw new Error(detalhes.msg)
        this.setState({ detalhes: detalhes.msg })
      } catch (e) {
        await swal({ icon: 'info', text: e.message })
      }
    }
    this.setState({
      exibirDetalhes: true,
      filtroBlock: true,
      filtroIsOpen: false,
      loader: false
    })
    document.getElementById('breadcrumb').scrollIntoView()
  }

  fecharDetalhes = () => {
    this.setState({
      exibirDetalhes: false,
      filtroBlock: false,
      filtroIsOpen: false,
      loader: false
    })
    document.getElementById('breadcrumb').scrollIntoView()
  }

  render() {
    const { tabAtiva, topoAtiva, exibirDetalhes, detalhes } = this.state
    const listaDetalhes =
      detalhes.length === 0 ? (
        <tr>
          <td colSpan="5">Não há informações para exibir</td>
        </tr>
      ) : (
        detalhes.map((t, index) => (
          <>
            <tr
              key={index}
              className={topoAtiva[index] ? 'topoAtiva active' : 'topoAtiva'}
            >
              <td className="text-left td-fix">{t.tipologia}</td>
              <td className="text-center td-fix">{t.cd}</td>
              <td className="text-center td-fix">{t.alcance}</td>
              <td className="text-center td-fix">{t.cliques}</td>
              <td></td>
            </tr>
          </>
        ))
      )

    const anuncios =
      this.state.anuncios.length === 0 ? (
        <tr>
          <td colSpan="8">Não há lançamentos liberados para mostrar</td>
        </tr>
      ) : (
        this.state.anuncios.map((a, index) => (
          <>
            <tr
              key={index}
              className={tabAtiva === index ? 'listaTr active' : 'listaTr'}
            >
              <td className="width-7">{a.data}</td>
              <td className="">{a.titulo}</td>
              <td className="text-center">{a.programada}</td>
              <td className="text-center">{a.duracao}</td>
              <td className="text-center">{a.alcance}</td>
              <td className="text-center">{a.cliques}</td>
              <td className="btnGroupFix width-5">
                {a.Status !== 'Encerrado' ? (
                  <label
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.changeStatus(a.id_tbl_engajamento)}
                  >
                    <span className="label-red">Parar</span>
                  </label>
                ) : null}
              </td>
              <td className="btnGroupFix width-5">
                {a.Status === 'Ativo' ? (
                  <span className="label-success">Ativo</span>
                ) : (
                  <span className="label-red">Encerrado</span>
                )}
              </td>
              <td className="btnGroupFix width-3">
                <label
                  htmlFor={`input-${index}`}
                  className="btn btnFix multi-toggle"
                  title="Ver mais"
                >
                  <span>
                    <FaEye />
                  </span>
                </label>
              </td>
            </tr>

            <tr key={index + 1}>
              <td colSpan="10">
                <input
                  type="checkbox"
                  name="toggle"
                  id={`input-${index}`}
                  className="input-toggle"
                />
                <div className="tab-ativa">
                  <div className="box-desc-Engajamento">
                    <div>
                      <div className="data-desc-Engajamento">
                        <p>
                          <strong>Data</strong>: {a.data}
                        </p>
                        <p>
                          <strong>Programada</strong>: {a.programada}
                        </p>
                        <p>
                          <strong>Título</strong>: {a.titulo}
                        </p>
                      </div>
                      <div className="data-desc-Engajamento">
                        <p>
                          <strong>Programado</strong>: {a.programada}
                        </p>
                        <p>
                          <strong>Alcance</strong>: {a.alcance}
                        </p>
                        <p>
                          <strong>Duração</strong>: {a.duracao}
                        </p>
                        <p>
                          <strong>Cliques</strong>: {a.cliques}
                        </p>
                      </div>
                      <div className="acao-desc-Engajamento">
                        <button
                          className="btn"
                          onClick={() => this.verDetalhes(index)}
                        >
                          Ver detalles
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </>
        ))
      )

    return (
      <>
        <Menu menuDrop="25" />
        <Header />
        <div className="container">
          <div className="breadcrumb" id="breadcrumb">
            <div>
              <h3>Engajamentos</h3>
              <p>
                Engajamentos{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                Listar
              </p>
            </div>
            <Link to="/engajamento/criar">
              <button className="btn">
                <span>
                  <MdAdd />
                </span>{' '}
                Criar Engajamento
              </button>
            </Link>
          </div>
          <div className="row pb-0">
            <div
              id="ListaEngajamento"
              className={exibirDetalhes === true ? 'col-7' : 'col-12'}
            >
              <div className="box">
                <div className="box-title"></div>
                <div
                  className={this.state.filtroIsOpen ? 'box-content' : 'd-none'}
                >
                  <div className="d-flex-sb filtro-box">
                    <input
                      value={this.state.filtros.titulo}
                      name="titulo"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-6"
                      type="text"
                      placeholder="Título"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value={this.state.filtros.produto}
                      name="produto"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-6"
                      type="text"
                      placeholder="Produto"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      defaultValue={this.state.filtros.status}
                      name="status"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-3"
                    >
                      <option value="">Status</option>
                      <option value="Ativo">Ativos</option>
                      <option value="Encerrado">Encerrado</option>
                    </select>
                  </div>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Título</th>
                        <th className="text-center">Programado</th>
                        <th className="text-center">Duração</th>
                        <th className="text-center">Alcance</th>
                        <th className="text-center">Cliques</th>
                        <th></th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{anuncios}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              id="DetalhesCampanha"
              className={exibirDetalhes === true ? 'col-5' : 'd-none'}
            >
              <div className="box">
                <div className="box-title">
                  <h4>{this.state.detaque}</h4>
                  <button
                    className="btn btn-red"
                    onClick={() => this.fecharDetalhes()}
                  >
                    <span>
                      <MdClose />
                    </span>
                  </button>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-center"></th>
                        <th className="text-center">Alcance</th>
                        <th className="text-center">Cliques</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{listaDetalhes}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}
