import React, { useEffect, useState } from 'react'
import InputMask from 'react-input-mask'
import { Controller, useForm } from 'react-hook-form'
import swal from 'sweetalert'
import { formatCNPJ } from '../../../../utils/grid/formatters/cnpj.formatter'
import { formatCurrency } from '../../../../utils/grid/formatters/currency.formatter'
import { pattern } from '../../../../utils/patterns'
import { editaCliente } from '../../../../services/api'
import promised from '../../../../utils/promised'
import { formatCPF } from '../../../../utils/grid/formatters/cpf.formatter'

const DadosCadastrais = ({ data, childRef, onClose, setChanged }) => {
  const [phoneMask, setPhoneMask] = useState('(99) 9999-9999')
  const [phoneMaskFix, setPhoneMaskFix] = useState('(99) 9999-9999')

  const { register, handleSubmit, errors, control, watch } = useForm({
    defaultValues: {
      responsavel: data.responsavel,
      email: data.email,
      celular:
        data.celular !== null && (data.celular || '').toString().length > 1
          ? data.celular
          : '',
      telefone:
        data.telefone !== null && (data.telefone || '').toString().length > 1
          ? data.telefone
          : '',
      status: data.status_id
        ? data.status_id.toString()
        : data.status === 'ATIVO'
        ? '1'
        : '2'
    },
    mode: 'onBlur'
  })
  const onSubmit = async (model) => {
    const statusId = 1 // data.status_id || data.status === 'ATIVO' ? 1 : 2
    const [error, response] = await promised(
      editaCliente(data.id_comprador, { ...model, status: statusId }).then(
        () => {
          setChanged(true)
        }
      )
    )
    if (error || (response || {}).status === 'error') {
      swal({
        icon: 'error',
        text: 'Não foi possível salvar o cliente, tente mais tarde.'
      })
      return
    }
    onClose({ ...model, id_comprador: data.id_comprador })
    await swal({ icon: 'success', text: 'Cliente salvo com sucesso' })
  }

  const watchTelefone = watch(['celular'])
  const watchTelefoneFix = watch(['telefone'])

  useEffect(() => {
    if (
      !(watchTelefone || {}).hasOwnProperty('celular') ||
      watchTelefone.celular === null
    ) {
    } else {
      const mask =
        (watchTelefone.celular || '').replace(/\D/gi, '').charAt(2) === '9'
          ? '(99) 9 9999-9999'
          : '(99) 9999-9999'
      setPhoneMask(mask)
    }
    if (
      !(watchTelefoneFix || {}).hasOwnProperty('telefone') ||
      watchTelefoneFix.telefone === null
    ) {
    } else {
      const maskPhone =
        (watchTelefoneFix.telefone || '').replace(/\D/gi, '').charAt(2) === '9'
          ? '(99) 9 9999-9999'
          : '(99) 9999-9999'
      setPhoneMaskFix(maskPhone)
    }
  }, [watchTelefoneFix, watchTelefone])
  return (
    <div className="dialog-form__wrapper">
      <div className="dialog-form__left">
        <ul className="ifefo-data__list">
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">CNPJ:</div>
            <div className="ifefo-data__value">{formatCNPJ(data.cnpj)}</div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">CPF:</div>
            <div className="ifefo-data__value">{formatCPF(data.cpf)}</div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">Nome:</div>
            <div className="ifefo-data__value">{data.nome}</div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">Status:</div>
            <div className="ifefo-data__value">{data.status}</div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">Saldo:</div>
            <div className="ifefo-data__value">
              {formatCurrency('BRL')(data.saldo)}
            </div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">Ped. mínimo:</div>
            <div className="ifefo-data__value">
              {formatCurrency('BRL')(data.pedido_minimo)}
            </div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">Endereço:</div>
            <div className="ifefo-data__value">{data.endereco}</div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">Município:</div>
            <div className="ifefo-data__value">{data.cidade}</div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">UF:</div>
            <div className="ifefo-data__value">{data.estado}</div>
          </li>
          <li className="ifefo-data__list-item">
            <div className="ifefo-data__label">Cd de entrega:</div>
            <div className="ifefo-data__value">
              {data.cd_nome || ''}
              {/*{data.id_distribuidor || '-'}*/}
              {/*{data.centro_distribuicao || '-'}*/}
            </div>
          </li>
        </ul>
      </div>
      <div className="dialog-form__right">
        <figure className="dialog-form__avatar">
          <img src={data.foto} alt="" />
        </figure>
        <form
          className="dialog-form"
          noValidate
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          ref={childRef}
        >
          <div className="dialog-form__field">
            <label htmlFor="responsavel" className="dialog-form__label">
              Responsável:
            </label>
            <input
              type="text"
              name="responsavel"
              id="responsavel"
              ref={register({
                required: {
                  value: true,
                  message: 'Nome do responsável obrigatório'
                }
              })}
              className="dialog-form__text"
            />
            {errors.responsavel && (
              <span className="dialog-form__error">
                Por favor informe o responsável
              </span>
            )}
          </div>
          <div className="dialog-form__field">
            <label htmlFor="email" className="dialog-form__label">
              E-mail:
            </label>
            <input
              type="email"
              name="email"
              id="email"
              ref={register({
                required: { value: true, message: 'E-mail obrigatório' },
                pattern: {
                  value: pattern.email,
                  message: 'E-mail em formato inválido'
                }
              })}
              className="dialog-form__text"
            />
            {(errors || {}).email && (
              <span className="dialog-form__error">
                {((errors || {}).email || {}).message}
              </span>
            )}
          </div>
          <div className="dialog-form__field">
            <label htmlFor="celular" className="dialog-form__label">
              Celular:
            </label>
            <Controller
              id="celular"
              type="phone"
              as={InputMask}
              control={control}
              mask={phoneMask}
              name="celular"
              defaultValue=""
              className="dialog-form__text"
              rules={{
                required: {
                  value: true,
                  message: 'Número de telefone obrigatório'
                },
                pattern: {
                  value: pattern.brazilianPhoneNumber,
                  message: 'Número de telefone em formato incorreto'
                }
              }}
            />

            {(errors || {}).celular && (
              <span className="dialog-form__error">
                {((errors || {}).celular || {}).message}
              </span>
            )}
          </div>
          <div className="dialog-form__field">
            <label htmlFor="telefone" className="dialog-form__label">
              Telefone:
            </label>
            <Controller
              id="telefone"
              type="phone"
              as={InputMask}
              control={control}
              mask={phoneMaskFix}
              name="telefone"
              defaultValue=""
              className="dialog-form__text"
              rules={{
                required: {
                  value: true,
                  message: 'Número de telefone obrigatório'
                },
                pattern: {
                  value: pattern.brazilianPhoneNumber,
                  message: 'Número de telefone em formato incorreto'
                }
              }}
            />

            {(errors || {}).telefone && (
              <span className="dialog-form__error">
                {((errors || {}).telefone || {}).message}
              </span>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

export default DadosCadastrais
