import React, { Component } from 'react'
import { MdClose } from 'react-icons/md'
import './style.css'

export default class Modal extends Component {
  render() {
    return (
      <>
        <div
          onClick={this.props.outside}
          id="myModal"
          className={this.props.active ? 'modal-editar active' : 'modal-editar'}
        >
          <div className="modal-editar-container">
            <div className="modal-editar-content">
              <div className="modal-editar-close">
                <button
                  className="btn btn-red"
                  onClick={this.props.toggleModal}
                >
                  <span>
                    <MdClose />
                  </span>
                </button>
              </div>
              <h4 className="text-center">{this.props.dadosModal.senha}</h4>
              <div className="form-group">
                <div className="form-item">
                  <label htmlFor="senha1">Senha Antiga</label>
                  <input
                    id="senha1"
                    name="senha1"
                    type="text"
                    value={this.props.senha1}
                    onChange={this.props.funcaoSenha1}
                    className="form-control"
                  />
                </div>
                <div className="form-item">
                  <label htmlFor="senha2">Senha Nova</label>
                  <input
                    id="senha2"
                    name="senha2"
                    type="text"
                    value={this.props.senha2}
                    onChange={this.props.funcaoSenha2}
                    className="form-control"
                  />
                </div>
                <div className="form-item d-flex justify-content-flex-end">
                  <button className="btn" onClick={this.props.alterarSenha}>
                    Alterar Senha
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
