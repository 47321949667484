import React, { useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import { downloadPedido } from '../../services/api'
import './style.css'
import { format, subDays } from 'date-fns'
import { DateRangePicker } from 'react-date-range'
import { ptBR } from 'react-date-range/dist/locale'
import {
  defaultInputRanges,
  defaultStaticRanges
} from 'react-date-range/dist/defaultRanges'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

const IntegracaoDownload = () => {
  const [state, setState] = useState([
    {
      startDate: subDays(new Date(), 6),
      endDate: new Date(),
      key: 'selection'
    }
  ])

  const staticRangesLabels = {
    Today: 'Hoje',
    Yesterday: 'Ontem',
    'This Week': 'Esta semana',
    'Last Week': 'Semana passada',
    'This Month': 'Este mês',
    'Last Month': 'Mês passado'
  }

  const inputRangesLabels = {
    'days up to today': 'Dias até hoje',
    'days starting today': 'Dias a partir de hoje'
  }

  function translateRange(dictionary) {
    return (item) =>
      dictionary[item.label] ? { ...item, label: dictionary[item.label] } : item
  }

  const ruStaticRanges = defaultStaticRanges.map(
    translateRange(staticRangesLabels)
  )

  const ruInputRanges = defaultInputRanges.map(
    translateRange(inputRangesLabels)
  )

  const handleDownload = async (xls = false) => {
    const [data] = state
    if (!data) return
    const startDate = format(data.startDate, 'yyyy-MM-dd')
    const endDate = format(data.endDate, 'yyyy-MM-dd')
    await downloadPedido(startDate, endDate, xls)
  }

  return (
    <>
      <Menu menuDrop="22" />
      <Header />
      <div className="container">
        <div className="breadcrumb">
          <div>
            <h3>INTEGRAÇÃO</h3>
            <p>
              Integração&nbsp;
              <span>
                <MdChevronRight />
              </span>
              &nbsp; Download
            </p>
          </div>
          <span />
        </div>
        <div className="row pb-0">
          <div className="col-12">
            <div className="box">
              <div className="box-content">
                <div
                  className="form-group"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <DateRangePicker
                    onChange={(item) => setState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={2}
                    ranges={state}
                    direction="horizontal"
                    locale={ptBR}
                    staticRanges={ruStaticRanges}
                    inputRanges={ruInputRanges}
                    color="#f26406"
                    rangeColors={['#f26406']}
                    maxDate={new Date()}
                  />
                </div>
              </div>
              <div className="box-title">
                <button onClick={() => handleDownload()} className="btn">
                  Fazer download CSV
                </button>
                <button onClick={() => handleDownload(true)} className="btn">
                  Fazer download Excel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default IntegracaoDownload
