import React from 'react'
import ModalEditaCliente from './ModalEditaCliente'
import DialogIfefo from '../../../components/DialogIfefo'

const ModalSwitcher = ({ modal, data, onClose }) => {
  return (
    <DialogIfefo aria-labelledby="simple-dialog-title" open={true}>
      {modal &&
        {
          editaCliente: <ModalEditaCliente data={data} onClose={onClose} />
        }[modal]}
    </DialogIfefo>
  )
}

export default ModalSwitcher
