import React from 'react'

import * as S from './styled'

const Loader = (props) => (
  <S.Loader>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.001 24" {...props}>
      <path
        d="M3.868,3.868A11.242,11.242,0,0,0,12,23,10.766,10.766,0,0,1,1.581,9.927C4.283-2.179,21.881-.5,22,12.048A1,1,0,1,0,24,12C23.576,1.189,10.98-3.511,3.868,3.868Z"
        fill="#f77303"
      />
    </svg>
  </S.Loader>
)

export default Loader
