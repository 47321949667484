import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
// import App from './App3'
import './style.css'

import * as serviceWorker from './serviceWorker'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-balham.css'

import { LicenseManager } from 'ag-grid-enterprise'

LicenseManager.setLicenseKey(
  'IFEFO_NDc1NjU4OTk5OTk5OQ==53da83297673eda33aef07ca7741a7a6'
)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
