import React, { useCallback, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import DadosProduto from './dados-produto'
import GestoresProduto from './gestores-produto'
import * as S from './styled'
import {
  EditaProdutoProvider,
  useEditaProdutoContext
} from './EditaProdutoContext/edita-produto.context'
import { gravaGestoresProduto } from '../../../../services/api'
import promised from '../../../../utils/promised'
import swal from 'sweetalert'

export const ModalEditaProdutoChild = ({ data, onClose }) => {
  const [currentTab, setCurrentTab] = useState('detalhesProduto')

  const { gestores, canSubmit } = useEditaProdutoContext()

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
      const divisor = gestores
        .filter((g) => {
          const { fl_ativo, isActive } = g
          return !!fl_ativo !== !!isActive
        })
        .reduce(
          (acc, curr) => {
            if (curr.isActive) {
              acc.toAdd.push(curr.id_usuario)
            } else {
              acc.toRemove.push(curr.id_rel_produto_gestor)
            }
            return acc
          },
          {
            toAdd: [],
            toRemove: []
          }
        )
      console.log(divisor)
      const [error, response] = await promised(
        gravaGestoresProduto(data.id_produto, divisor)
      )
      if (error || response.status === 'error') {
        swal({
          icon: 'error',
          text:
            'Não foi possível gravar os gestores. Tente novamente mais tarde.'
        })
        return
      }
      swal({
        icon: 'success',
        text: 'Gestores gravados com sucesso'
      })
      onClose()
    },
    [data, gestores, onClose]
  )

  return (
    <>
      <div className="dialog__header">
        <div className="dialog__header-left">Editar produto: {data.sku}</div>
        <div className="dialog__header-right">
          <button className="dialog__close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
      </div>
      <S.ProductInfoWrapper>
        <div className="product-info">
          <header className="product-info__data">
            <figure className="product-info__figure">
              <img className="product-info__image" src={data.foto} alt="" />
            </figure>
            <h2 className="product-info__title">{data.produto}</h2>
            <p className="product-info__description">
              PESO: {data.peso_caixa} | Caixa: {data.qtd_itens_caixa} | Marca:{' '}
              {data.marca}
            </p>
          </header>
        </div>
      </S.ProductInfoWrapper>
      <nav className="dialog__navigation">
        <ul className="dialog-nav__list">
          <li className="dialog-nav__list-item">
            <button
              onClick={() => setCurrentTab('detalhesProduto')}
              type="button"
              href=""
              className={`ifefo__button${
                currentTab === 'detalhesProduto' ? ' active' : ''
              }`}
            >
              Detalhes
            </button>
            <button
              onClick={() => setCurrentTab('gestoresProduto')}
              type="button"
              href=""
              className={`ifefo__button${
                currentTab === 'gestoresProduto' ? ' active' : ''
              }`}
            >
              Gestores
            </button>
          </li>
        </ul>
      </nav>
      <div className="dialog__body p24">
        {
          {
            detalhesProduto: <DadosProduto data={data} />,
            gestoresProduto: <GestoresProduto data={data} />
          }[currentTab]
        }
      </div>
      <div className="dialog__footer">
        <div className="dialog__footer-left">
          <button onClick={onClose} type="button" className="ifefo__button">
            Cancelar
          </button>
        </div>
        <div className="dialog__footer-right">
          {canSubmit ? (
            <button
              type="button"
              className="ifefo__button primary"
              onClick={handleSubmit}
            >
              Salvar
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </>
  )
}

const ModalEditaProduto = ({ data, onClose }) => (
  <EditaProdutoProvider>
    <ModalEditaProdutoChild data={data} onClose={onClose} />
  </EditaProdutoProvider>
)

export default ModalEditaProduto
