import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import Logo from '../../img/logo ifefo-02.png'
import Swal from 'sweetalert'
import { Login } from '../../services/api'
import { login, logout } from '../../services/auth'
import { ClipLoader } from 'react-spinners'
import './style.css'
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import pt from '../../lang/pt'
import es from '../../lang/es'

counterpart.registerTranslations('pt', pt)
counterpart.registerTranslations('es', es)
counterpart.setLocale('pt')

class SignIn extends Component {
  constructor() {
    super()
    this.state = {
      user: '',
      pass: '',
      label: '',
      logado: '',
      redirect: false,
      lang: ''
    }
    localStorage.setItem('language', 'pt')
    logout()

    this.state = { loader: false }
  }

  onLangChangeOn = (e) => {
    this.setState({ lang: e })
    counterpart.setLocale(e)
    localStorage.setItem('language', e)
  }

  handleInput = (e) =>
    this.setState({ ...this.state, [e.target.name]: e.target.value })

  toggleLabel = (label) => this.setState({ label })

  handleSignIn = async (e) => {
    e.preventDefault()
    const { user, pass } = this.state

    try {
      this.setState({ loader: true })
      const loga = await Login(user, pass)
      if (loga.status === 'error') {
        this.setState({ loader: false })
        throw new Error(loga.msg)
      }
      this.setState({ loader: false })
      login(loga.token)
      this.setState({ redirect: true })
    } catch (e) {
      this.setState({ loader: false })
      Swal({
        icon: 'error',
        text: e.message
      })
    }
  }

  render() {
    return (
      <>
        {this.state.redirect && <Redirect to={{ pathname: '/' }} />}
        <div className="login-container">
          <div className="login-box">
            <div className="login-form">
              <div className="login-logo">
                <img src={Logo} alt="Logo IFEFO" />
              </div>
              <div className="box-content">
                <form onSubmit={this.handleSignIn}>
                  <div className="form-group">
                    <div className="form-item">
                      <label
                        onClick={() => this.toggleLabel('1')}
                        className={
                          this.state.user !== '' || this.state.label === '1'
                            ? 'input-title active'
                            : 'input-title'
                        }
                      ></label>
                      <Translate
                        content="rot_emaildeacesso"
                        component="label"
                      />
                      <input
                        type="email"
                        className={
                          this.state.user === ''
                            ? 'form-control'
                            : 'form-control check'
                        }
                        name="user"
                        onChange={(e) => this.handleInput(e)}
                        value={this.state.user}
                        autoComplete="off"
                        onFocus={() => this.toggleLabel('1')}
                        onBlur={() => this.toggleLabel('')}
                      />
                    </div>
                    <div className="form-item">
                      <label
                        onClick={() => this.toggleLabel('2')}
                        className={
                          this.state.pass !== '' || this.state.label === '2'
                            ? 'input-title active'
                            : 'input-title'
                        }
                      ></label>
                      <Translate content="rot_senhasecreta" component="label" />
                      <input
                        type="password"
                        className={
                          this.state.pass === ''
                            ? 'form-control'
                            : 'form-control check'
                        }
                        name="pass"
                        onChange={(e) => this.handleInput(e)}
                        value={this.state.pass}
                        autoComplete="off"
                        onFocus={() => this.toggleLabel('2')}
                        onBlur={() => this.toggleLabel('')}
                      />
                    </div>
                    <div className="form-item">
                      <button type="submit" className="btn btn-green btn-block">
                        <Translate content="rot_btn_entrar" />
                      </button>
                    </div>
                    <div className="form-item meio">
                      <br />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}

export default withRouter(SignIn)
