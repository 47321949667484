import { useEffect, useRef } from 'react'

const useResize = (handler) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    console.log('Starting event listener from useResize')
    const eventListener = (event) => savedHandler.current(event)

    window.addEventListener('resize', eventListener)

    return () => {
      console.log('Removing event listener from useResize')
      window.removeEventListener('resize', eventListener)
    }
  }, [])
}

export default useResize
