import React, { Component, Fragment } from 'react'
import ReactDOM from 'react-dom'
import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import interacoesItem from '../InteracaoCliente/interacoes.json'
import logo from '../../img/logo ifefo-02.png'
import logoR9 from '../../img/logo-re9.png'
import font from './Muli-Bold.ttf'
import font2 from './Muli-Regular.ttf'
import { getDadosPdfCaptura, getListaInteracoes } from '../../services/api'
import './style.css'
import castFn from '../../utils/cast-fn'

Font.register({
  family: 'Muli',
  format: 'truetype',
  fontWeight: 'bold',
  src: font
})

Font.register({
  family: 'Muli',
  format: 'truetype',
  fontWeight: 'regular',
  src: font2
})

// Create styles
const styles = StyleSheet.create({
  PDFViewer: {
    width: '100%',
    height: '100%',
    fontFamily: 'Muli',
    fontWeight: 'regular'
  },
  hrBold: {
    marginTop: 5,
    borderBottom: 2,
    borderColor: '#000',
    borderStyle: 'solid'
  },
  hrLight: {
    marginTop: 5,
    borderBottom: 1,
    borderColor: '#CCC',
    borderStyle: 'solid'
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#FFF'
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 20,
    fontSize: 16,
    fontFamily: 'Muli',
    fontWeight: 'bold'
  },
  logo: {
    width: 90
  },
  titulo: {
    fontSize: 16,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 20
  },
  tituloCaptura: {
    fontFamily: 'Muli',
    fontWeight: 'bold'
  },
  relatorioGroup: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: 40,
    marginBottom: 10,
    fontSize: 11
  },
  relatorioCol: {
    flexDirection: 'column'
  },
  relatorioItem: {
    flexDirection: 'row',
    marginBottom: 5
  },
  relatorioDetalhadoGroup: {
    flexDirection: 'column',
    margin: 40,
    marginBottom: 0,
    marginTop: 0,
    fontSize: 10,
    fontWeight: 'bold'
  },
  relatorioDetalhadoTitle: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontFamily: 'Muli',
    fontWeight: 'bold'
  },
  relatorioDetalhadoContent: {
    marginTop: 5,
    marginLeft: 5,
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  relatiorioItem: {
    width: 100
  },
  rodape: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 40,
    width: '100%',
    position: 'absolute',
    bottom: 0
  },
  logomarca: {
    flexDirection: 'row',
    fontSize: 10,
    alignItems: 'center'
  },
  logoR9: {
    width: 30
  },
  pagina: {
    flexDirection: 'row',
    fontSize: 12
  }
})

// Create Document Component
export default class Pdf extends Component {
  constructor(props) {
    super(props)

    this.state = {
      interacoes: interacoesItem,
      tipologias: [],
      newItems: [],
      id: window.location.pathname.split('/'),
      cabecalho: []
    }
  }

  async componentDidMount() {
    const token = localStorage.getItem('@clientes-digitais')
    if (token) {
      /*
      if (this.state.id[2].toString().length <= 0)
        return;
      const dados = await getDadosPdfCaptura()
      const { msg } = await getListaInteracoes()
      const idInterger = parseInt(this.state.id[2])
      // if (!msg) return alert('faz alguma coisa aqui')
      const cabecalho = castFn((msg || []).filter)(
        (a) => a.id_fluxo === idInterger
      )
      this.setState({
        cabecalho: Array.isArray(cabecalho) ? cabecalho[0] : [],
        tipologias: dados.dados
      })
       */
    }
  }

  render() {
    return (
      <div className="pdf-container">
        {this.state.cabecalho && this.state.tipologias.length > 0 && (
          <PDFViewer style={styles.PDFViewer}>
            <Document>
              <Page size="A4" style={styles.page}>
                <View style={styles.header} fixed>
                  <Image src={logo} style={styles.logo} />
                  <Text>intelligence in shelf life</Text>
                </View>
                <View style={styles.titulo}>
                  <Text>Relatório de Envios</Text>
                </View>
                <View style={styles.relatorioGroup}>
                  <View style={styles.relatorioCol}>
                    <View style={styles.relatorioItem}>
                      <Text style={styles.tituloCaptura}>
                        Título:{' '}
                        {this.state.cabecalho !== undefined
                          ? this.state.cabecalho.titulo
                          : ''}
                      </Text>
                    </View>
                    <View style={styles.relatorioItem}>
                      <Text>
                        Data:{' '}
                        {this.state.cabecalho !== undefined
                          ? this.state.cabecalho.data
                          : ''}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.relatorioCol}>
                    <View style={styles.relatorioItem}>
                      <Text>
                        Canais:{' '}
                        {this.state.cabecalho !== undefined
                          ? this.state.cabecalho.canais
                          : ''}
                      </Text>
                    </View>
                    <View style={styles.relatorioItem}>
                      <Text>
                        Cobertura:{' '}
                        {this.state.cabecalho !== undefined
                          ? this.state.cabecalho.cobertura
                          : ''}
                      </Text>
                    </View>
                    <View style={styles.relatorioItem}>
                      <Text>
                        Alcance:{' '}
                        {this.state.cabecalho !== undefined
                          ? this.state.cabecalho.alcance
                          : ''}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.relatorioDetalhadoGroup}>
                  {this.state.tipologias.length > 0 &&
                    this.state.tipologias.map((t, index) => (
                      <Fragment key={index}>
                        <View style={styles.relatorioDetalhadoTitle}>
                          <Text>Tipologia: {t.tipologia}</Text>
                        </View>
                        <View style={styles.hrBold} />
                        <View style={styles.relatorioDetalhadoContent}>
                          <Text style={styles.relatiorioItem}>Whatsapp</Text>
                          <Text style={styles.relatiorioItem}>
                            Enviados:{' '}
                            {t.mecanismo === 'Whatsapp' ? t.envios : ''}
                          </Text>
                          <Text style={styles.relatiorioItem}>
                            Entregues:{' '}
                            {t.mecanismo === 'Whatsapp' ? t.recebidas : ''}
                          </Text>
                          <Text style={styles.relatiorioItem}>
                            Respondidos:{' '}
                            {t.mecanismo === 'Whatsapp' ? t.respondidos : ''}
                          </Text>
                        </View>
                        <View style={styles.hrLight} />
                        <View style={styles.relatorioDetalhadoContent}>
                          <Text style={styles.relatiorioItem}>SMS</Text>
                          <Text style={styles.relatiorioItem}>
                            Enviados: {t.mecanismo === 'SMS' ? t.envios : ''}
                          </Text>
                          <Text style={styles.relatiorioItem}>
                            Entregues:{' '}
                            {t.mecanismo === 'SMS' ? t.recebidas : ''}
                          </Text>
                          <Text style={styles.relatiorioItem}>
                            Respondidos:{' '}
                            {t.mecanismo === 'SMS' ? t.respondidos : ''}
                          </Text>
                        </View>
                        <View style={styles.hrLight} />
                        <View style={styles.relatorioDetalhadoContent}>
                          <Text style={styles.relatiorioItem}>E-Mail</Text>
                          <Text style={styles.relatiorioItem}>
                            Enviados: {t.mecanismo === 'Email' ? t.envios : ''}
                          </Text>
                          <Text style={styles.relatiorioItem}>
                            Entregues:{' '}
                            {t.mecanismo === 'Email' ? t.recebidas : ''}
                          </Text>
                          <Text style={styles.relatiorioItem}>
                            Respondidos:{' '}
                            {t.mecanismo === 'Email' ? t.respondidos : ''}
                          </Text>
                        </View>
                        <View style={styles.hrLight} />
                      </Fragment>
                    ))}
                </View>
                <View style={styles.rodape} fixed>
                  <View style={styles.logomarca}>
                    <Image style={styles.logoR9} src={logoR9} />
                    <Text>&copy; 2020 RE9IT - Your Experience</Text>
                  </View>
                  <View style={styles.pagina}>
                    <Text
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber} de ${totalPages}`
                      }
                      fixed
                    />
                  </View>
                </View>
              </Page>
            </Document>
          </PDFViewer>
        )}
      </div>
    )
  }
}

ReactDOM.render(<Pdf />, document.getElementById('root'))
