import styled from 'styled-components'

export const Loader = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  fill: currentColor;
  margin: 0 6px 0 0;
  padding: 0;
  animation: rotateClockwise 2.5s infinite linear;
  transform-origin: center;

  @keyframes rotateClockwise {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
