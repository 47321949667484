import React, { useEffect, useState } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import './style.css'
import swal from 'sweetalert'
import { listaClientes, listaClientesV2 } from '../../services/api'
import { MdChevronRight } from 'react-icons/md'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { ClipLoader } from 'react-spinners'
import { cnpjFormatter } from '../../utils/grid/formatters/cnpj.formatter'
import { currencyFormatter } from '../../utils/grid/formatters/currency.formatter'
import { imageRenderer } from '../../utils/grid/renderers/image.renderer'
import ModalSwitcher from './ModalSwitcher'
import useResize from '../../hooks/useResize'
import ActionsRenderer from '../../utils/grid/renderers/actionsRenderer'
import gridOptions from '../../utils/grid/options/grid-options'
import { cpfFormatter } from '../../utils/grid/formatters/cpf.formatter'
import { StatusToggleCellRenderer } from '../../utils/grid/renderers/status-toggle.renderer'
import { maskPhone } from '../../utils/grid/renderers/image.renderer'

const Clientes = (props) => {
  const [gridApi, setGridApi] = useState(null)
  const [gridColumnApi, setGridColumnApi] = useState(null)
  const [rowData, setRowData] = useState([])
  const [loader, setLoader] = useState(false)
  const [reload, setReload] = useState(false)
  const [countCustomers, setCountCustomers] = useState(0)
  const [allData, setAllData] = useState([])

  const [showModal, setShowModal] = useState(false)
  const [currentModal, setCurrentModal] = useState('')
  const [modalData, setModalData] = useState({})
  const [idDistribuidor, setIdDistribuidor] = useState(undefined)
  const [rowSize, srs] = useState(100000)
  const [filters, setFilters] = useState([])
  const [currentNode, setCurrentNode] = useState(0)

  const getContextMenuItems = (params) => {
    return []
  }

  let myPage = 1

  // Filters
  const [filterCnpj, setFilterCnpj] = useState([])

  const onCellValueChanged = (event) => {
    console.log('Data after change is', event.data)
  }

  /**
   * @param countCustomers
   * @returns {{getRows: ((function(*): Promise<void>)|*)}}
   */
  const serverDataSource = (countCustomers) => {
    return {
      getRows: async (params) => {
        const { endRow = rowSize, filterModel = filters } = params.request
        let search = ''
        if (typeof filterModel === 'object') {
          if (filterModel['cpf'] !== undefined) {
            search += `&cpf=${filterModel['cpf']['filter']}`
          } else {
            search += `&cpf=`
          }
          if (filterModel['cnpj'] !== undefined) {
            search += `&cnpj=${filterModel['cnpj']['filter']}`
          } else {
            search += `&cnpj=`
          }
          if (filterModel['celular'] !== undefined) {
            search += `&celular=${filterModel['celular']['filter']}`
          } else {
            search += `&celular=`
          }
          if (filterModel['email'] !== undefined) {
            search += `&email=${filterModel['email']['filter']}`
          } else {
            search += `&email=`
          }
          if (filterModel['fantasia'] !== undefined) {
            search += `&fantasia=${filterModel['fantasia']['filter']}`
          } else {
            search += `&fantasia=`
          }
        }
        if (countCustomers.length === 0) {
          return
        }
        try {
          const ads = await listaClientesV2(
            idDistribuidor,
            rowSize,
            myPage++,
            search,
            filterModel
          )

          const { msg: customers = [] } = ads || { msg: [] }

          if (ads.total) {
            setAllData(customers)
            setCountCustomers(ads.total_registros)
            params.successCallback(customers, ads.total)
          } else {
            setAllData(customers)
            setCountCustomers(ads.total_registros)
            params.failCallback()
          }
        } catch (error) {
          params.failCallback()
        }
      }
    }
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    setGridColumnApi(params.columnApi)
  }

  const componentParent = {
    methods: {
      reload() {
        setReload(!reload)
      }
    },
    actions: [
      {
        type: 'edit',
        callback(rowNode) {
          setCurrentNode(rowNode.id)
          setCurrentModal('editaCliente')
          setModalData(rowNode.data)
          setShowModal(true)
        }
      }
    ]
  }

  const closeModal = (data, event, changed = true) => {
    if ('id_comprador' in data) {
      // console.log(data)
      rowData.find((i) => i.id_comprador === data.id_comprador).telefone =
        data.telefone
      rowData.find((i) => i.id_comprador === data.id_comprador).responsavel =
        data.responsavel
      rowData.find((i) => i.id_comprador === data.id_comprador).email =
        data.email
      rowData.find((i) => i.id_comprador === data.id_comprador).celular =
        data.celular
      setRowData(rowData)
    }
    if (event) {
      event.preventDefault()
    }
    setModalData({})
    setCurrentModal('')
    setShowModal(false)
    // if (changed) {
    //   setReload(!reload)
    // }
  }

  useResize(() => {
    console.log('Starting resize listener')
    if (gridApi && gridApi.sizeColumnsToFit) {
      gridApi.sizeColumnsToFit()
    }
  })

  useEffect(() => {
    ;(async () => {
      setLoader(true)
      try {
        let string = '&page=1&limit=50000&cpf=&cnpj=&celular=&email=&fantasia='
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const ads = await listaClientes(idDistribuidor, string)
        if (ads.token === null) {
          return
        }
        const { msg: customers = [] } = ads || { msg: [] }
        setRowData(customers)
        setCountCustomers(customers.length)
        setLoader(false)
      } catch (e) {
        setLoader(false)
        swal({ icon: 'warning', text: e.message }).then((r) => {})
      }
    })()
  }, [reload])

  return (
    <>
      <Menu menuDrop="20" />
      <Header />
      <div className="container  container--flex">
        <div className="breadcrumb" id="breadcrumb">
          <div>
            <h3>Clientes</h3>
            <p>
              Clientes{' '}
              <span>
                <MdChevronRight />
              </span>{' '}
              Listar clientes
            </p>
          </div>
        </div>
        <div className="container__body" id="clients">
          <div id="ListaClientes" className="col-12 h100">
            <div className="box h100  box--flex">
              <div className="grid-title">Total: {countCustomers}</div>
              <div className="box-content scroll-table scroll-table--lonely">
                <div
                  className="ag-theme-balham"
                  style={{ height: '100%', width: '100%' }}
                >
                  <AgGridReact
                    rowSelection="false"
                    frameworkComponents={{
                      actionsRenderer: ActionsRenderer,
                      statusToggleRenderer: StatusToggleCellRenderer
                    }}
                    gridOptions={{
                      ...gridOptions,
                      statusBar: {
                        // statusPanels: [{ statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }]
                      }
                    }}
                    context={{ componentParent }}
                    rowBuffer={0}
                    // rowModelType={'serverSide'}
                    rowData={rowData}
                    pagination={true}
                    paginationPageSize={1000}
                    onCellValueChanged={onCellValueChanged}
                    // getContextMenuItems={getContextMenuItems}
                    // infiniteInitialRowCount={rowSize}
                    // cacheBlockSize={rowSize}
                    // maxBlocksInCache={rowSize}
                    onGridReady={onGridReady}
                  >
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="ID"
                      sort="asc"
                      hide={true}
                      sortable={true}
                      field="id_comprador"
                    />
                    <AgGridColumn
                      floatingFilter={false}
                      hide={true}
                      cellRenderer={imageRenderer}
                      width={60}
                      resizable={false}
                      headerName="Foto"
                      sortable={true}
                      field="foto"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={150}
                      valueFormatter={cnpjFormatter}
                      headerName="CNPJ"
                      sortable={true}
                      filter="agTextColumnFilter"
                      field="cnpj"
                    />
                    <AgGridColumn
                      width={100}
                      floatingFilter={true}
                      headerName="Nome"
                      filter="agTextColumnFilter"
                      sortable={true}
                      field="nome"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={150}
                      valueFormatter={cpfFormatter}
                      headerName="CPF"
                      filter="agTextColumnFilter"
                      sortable={true}
                      field="cpf"
                      hide={true}
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={100}
                      headerName="Responsável"
                      sortable={true}
                      filter="agSetColumnFilter"
                      field="responsavel"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Fantasia"
                      filter="agTextColumnFilter"
                      sortable={true}
                      field="fantasia"
                    />
                    <AgGridColumn
                      hide={true}
                      filter="agNumberColumnFilter"
                      floatingFilter={true}
                      width={100}
                      headerName="Saldo"
                      valueFormatter={currencyFormatter('BRL')}
                      sortable={true}
                      field="saldo"
                    />
                    <AgGridColumn
                      filter="agTextColumnFilter"
                      floatingFilter={true}
                      hide={true}
                      headerName="Pedido min."
                      sortable={true}
                      field="pedido_minimo"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      filter="agTextColumnFilter"
                      headerName="Tipologia"
                      sortable={true}
                      field="tipologia"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Telefone"
                      filter="agTextColumnFilter"
                      sortable={true}
                      field="telefone"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Celular"
                      filter="agTextColumnFilter"
                      sortable={true}
                      width={120}
                      field="celular"
                      valueFormatter={maskPhone}
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="E-mail"
                      filter="agTextColumnFilter"
                      sortable={true}
                      field="email"
                      width={120}
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="CEP"
                      filter="agTextColumnFilter"
                      sortable={true}
                      field="CEP"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Cd Cliente"
                      filter="agTextColumnFilter"
                      sortable={true}
                      width={80}
                      field="cd_nome"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Segunda"
                      sortable={true}
                      field="segunda"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Terça"
                      sortable={true}
                      field="terca"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Quarta"
                      sortable={true}
                      field="quarta"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Quinta"
                      sortable={true}
                      field="quinta"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Sexta"
                      sortable={true}
                      field="sexta"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Sábado"
                      sortable={true}
                      field="sabado"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Domingo"
                      sortable={true}
                      field="domingo"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={70}
                      headerName="UF"
                      sortable={true}
                      filter="agSetColumnFilter"
                      field="estado"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Cidade"
                      sortable={true}
                      filter="agSetColumnFilter"
                      field="cidade"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={90}
                      headerName="Endereço"
                      filter="agTextColumnFilter"
                      sortable={true}
                      field="endereco"
                    />
                    <AgGridColumn
                      floatingFilter={false}
                      width={80}
                      headerName="Status"
                      sortable={true}
                      cellRenderer="statusToggleRenderer"
                      filter="agSetColumnFilter"
                      field="status"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Acessou App"
                      filter="agSetColumnFilter"
                      sortable={true}
                      width={70}
                      field="acessou_app"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      hide={true}
                      headerName="Responsável"
                      filter="agTextColumnFilter"
                      width={40}
                      sortable={true}
                      field="responsavel"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Data cadastro"
                      hide={true}
                      sortable={true}
                      field="data_cadastro"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="1ª compra"
                      width={80}
                      filter="agDateColumnFilter"
                      sortable={true}
                      field="data_primeira_compra"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Última compra"
                      width={80}
                      filter="agDateColumnFilter"
                      sortable={true}
                      field="data_ultima_compra"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Pedidos"
                      width={80}
                      sortable={true}
                      field="pedidos"
                    />
                    <AgGridColumn
                      width={80}
                      headerName="Ações"
                      sortable={false}
                      colId="params"
                      editable={false}
                      field="value"
                      cellRenderer="actionsRenderer"
                    />
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className={loader ? 'loader show' : 'loader'}>
        <ClipLoader
          sizeUnit={'px'}
          size={90}
          color={'#FB8501'}
          loading={true}
        />
      </div>
      {showModal && currentModal && (
        <ModalSwitcher
          modal={currentModal}
          data={modalData}
          onClose={(e) => {
            closeModal(e)
          }}
        />
      )}
    </>
  )
}

export default Clientes
