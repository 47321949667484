import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import { ThemeProvider as StyledProvider } from 'styled-components'
import Routes from './routes'
import GlobalStyles from './assets/css/global'

function App() {
  const theme = {
    palette: {
      primary: {
        main: '#f26204',
        contrast: '#FFF'
      },
      secondary: {
        main: '#2BB887',
        contrast: '#FFF'
      },
      tertiary: {
        main: '#573631',
        contrast: '#FFF'
      },
      error: {
        main: '#CD3838',
        contrast: '#FFF'
      },
      geralContrast: {
        main: '#FFF'
      },
      shadow: '#333',
      hr: '#CCC'
    }
  }
  return (
    <>
      <GlobalStyles />
      <StyledProvider theme={createMuiTheme(theme)}>
        <ThemeProvider theme={createMuiTheme(theme)}>
          <Routes />
        </ThemeProvider>
      </StyledProvider>
    </>
  )
}

export default App
