import React from 'react'

const MeiosPagamento = ({ data }) => {
  return (
    <div className="dialog-form__wrapper">
      <div className="dialog-form__full">
        <div className="ifefo-typo__subtitle">Meios de pagamento aceitos</div>
        <p>{data.forma_pagamento}</p>
      </div>
    </div>
  )
}

export default MeiosPagamento
