const gridOptions = {
  defaultColDef: {
    editable: true,
    sortable: true,
    resizable: true,
    filter: 'agSetColumnFilter',
    localeText: { noRowsToShow: 'Não há dados' },
  }
}

export default gridOptions
