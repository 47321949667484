export const formatCPF = (cpf) => {
  if (typeof cpf !== 'string') return cpf
  const cpfNum = cpf.replace(/\D+/gi, '')
  if (cpfNum.length !== 11) return cpf
  return `${cpfNum.slice(0, 3)}.${cpfNum.slice(3, 6)}.${cpfNum.slice(
    6,
    9
  )}-${cpfNum.slice(9, 11)}`
}

export const cpfFormatter = (params) => {
  return formatCPF(params.value)
}
