import { getNumber } from '../../parsers/get-number'

export const formatDecimalPercent = (value) => {
  if (typeof value === 'undefined' || value === null) return ''
  const valueNum = getNumber(value)
  if (isNaN(valueNum)) return ''
  return `${valueNum.toFixed(2).replace(/0+$/gm, '').replace(/\.$/gm, '')}%`
}

export const decimalPercentFormatter = (params) => {
  return formatDecimalPercent(params.value)
}
