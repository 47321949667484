import React, { useCallback, useEffect, useRef, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import DadosCadastrais from './dados-cadastrais'
import MeiosPagamento from './meios-pagamento'
import GradeLogistica from './grade-logistica'

const ModalEditaCliente = ({ data, onClose }) => {
  const refForm = useRef()

  const [formVisible, setFormVisible] = useState(false)
  const [currentTab, setCurrentTab] = useState('dadosCadastro')
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    setFormVisible(refForm.current)
  }, [refForm, currentTab])

  const handleOnClose = useCallback(
    (event) => {
      onClose(event, changed)
    },
    [changed, onClose]
  )

  return (
    <>
      <div className="dialog__header">
        <div className="dialog__header-left">Editar cliente: {data.nome}</div>
        <div className="dialog__header-right">
          <button className="dialog__close-button" onClick={handleOnClose}>
            <FaTimes />
          </button>
        </div>
      </div>
      <nav className="dialog__navigation">
        <ul className="dialog-nav__list">
          <li className="dialog-nav__list-item">
            <button
              onClick={() => setCurrentTab('dadosCadastro')}
              type="button"
              href=""
              className={`ifefo__button${
                currentTab === 'dadosCadastro' ? ' active' : ''
              }`}
            >
              Dados de cadastro
            </button>
            <button
              onClick={() => setCurrentTab('meiosPagamento')}
              type="button"
              href=""
              className={`ifefo__button${
                currentTab === 'meiosPagamento' ? ' active' : ''
              }`}
            >
              Meios de pagamento
            </button>
            <button
              onClick={() => setCurrentTab('gradeLogistica')}
              type="button"
              href=""
              className={`ifefo__button${
                currentTab === 'gradeLogistica' ? ' active' : ''
              }`}
            >
              Grade logística
            </button>
          </li>
        </ul>
      </nav>
      <div className="dialog__body">
        {
          {
            dadosCadastro: (
              <DadosCadastrais
                data={data}
                onClose={onClose}
                childRef={refForm}
                setChanged={setChanged}
              />
            ),
            meiosPagamento: <MeiosPagamento data={data} />,
            gradeLogistica: <GradeLogistica data={data} />
          }[currentTab]
        }
      </div>
      <div className="dialog__footer">
        <div className="dialog__footer-left">
          <button
            onClick={handleOnClose}
            type="button"
            className="ifefo__button"
          >
            Cancelar
          </button>
        </div>
        <div className="dialog__footer-right">
          {formVisible && (
            <button
              type="button"
              className="ifefo__button primary"
              onClick={() => {
                refForm.current.dispatchEvent(new Event('submit'))
              }}
            >
              Salvar
            </button>
          )}
        </div>
      </div>
    </>
  )
}

export default ModalEditaCliente
