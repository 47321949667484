export default {
  rot_btn_idioma_pt: 'Idioma portugues',
  rot_btn_idioma_es: 'Idioma español',
  rot_emaildeacesso: 'E-mail',
  rot_senhasecreta: 'Contraseña',
  rot_btn_entrar: 'Ingresar',
  rot_mnu_inicio: 'Inicio',
  rot_mnu_campanha: 'CAMPAÑAS',
  rot_mnu_campanha_m: 'Campañas',
  rot_mnu_lance: 'PROPUESTAS',
  rot_mnu_lance_lista: 'Listado',
  rot_mnu_lance_aprovacao: 'Aprobación automática',
  rot_mnu_conversao: 'CONVERSION',
  rot_mnu_conversao_cockpit: 'Cockpit',
  rot_mnu_conversao_impulsiona: 'Impulsionamento',
  rot_mnu_conversao_importar: 'Importar clientes',
  rot_mnu_conversao_interacoes: 'Interacciones',
  rot_mnu_captura: 'CAPTURA',
  rot_mnu_captura_relatorio: 'Reporte',
  rot_mnu_captura_upload: 'Upload',
  rot_mnu_captura_interacao: 'Interação',
  rot_mnu_configuracoes: 'AJUSTES',
  rot_mnu_configuracoes_usuarios: 'Del usuario',
  rot_mnu_logs: 'REGISTROS',
  rot_mnu_logs_registro: 'registro de acciones',
  rot_mnu_pedidos: 'Pedidos',
  rot_mnu_sair: 'Salir',
  rot_mnu_rodape_dev: 'Desarrollado por',
  rot_home_dest_1: 'Ofertas abiertas',
  rot_home_dest_2: 'Volumen Abierto',
  rot_home_dest_3: 'Volumen aceptado',
  rot_home_dest_4: 'Volumen total disponible',
  rot_home_box_1_tit: 'Volumen disponible',
  rot_home_box_1_sub_tit_1: 'PRODUCTO',
  rot_home_box_1_sub_tit_2: 'LOCALIDAD',
  rot_home_box_1_sub_tit_3: 'PLAZO DE VALIDAD',
  rot_home_box_2_tit: 'Historial de ventas',
  rot_home_lista_lance_tit: 'Lista de ofertas',
  rot_home_lista_lance_tooltip_on: 'Aprobar',
  rot_home_lista_lance_tooltip_off: 'Rechazar',
  rot_campanhas_tit: 'LISTA DE CAMPAÑAS',
  rot_campanhas_tit_breadcrumb_1: 'Campañas',
  rot_campanhas_tit_breadcrumb_2: 'Lista de Campañas',
  rot_campanhas_buttonTitleAdd: 'Crear campaña',
  listadepedidos: 'FECHA SOLICITADA',
  listadeestoque: 'LISTA COMÚN',
  listadeprodutos: 'LISTA DE PRODUCTOS',
  rot_mnu_estoques: 'Cepo',
  rot_mnu_produtos: 'Productos',
  rot_mnu_clientes: 'Clientes',
  rot_home_lista_pedidos_tooltip_on: 'Detalles',
  rot_mnu_dash: 'Dashboards'
}
