import React from 'react'
import DialogIfefo from '../../../components/DialogIfefo'
import ModalDetalhesPedido from './ModalDetalhesPedido'

const ModalSwitcher = ({ modal, data, onClose }) => {
  return (
    <DialogIfefo
      classes={{
        root: 'dialog-big'
      }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      {modal &&
        {
          detalhesPedido: <ModalDetalhesPedido data={data} onClose={onClose} />
        }[modal]}
    </DialogIfefo>
  )
}

export default ModalSwitcher
