import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { MdChevronRight } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { getLogs } from '../../services/api'
import './style.css'

export default class Logs extends Component {
  state = {
    loader: false,
    logs: []
  }

  async componentDidMount() {
    await this.getListaLogsClientes()
  }

  async getListaLogsClientes() {
    this.setState({ loader: true })
    try {
      const listaLogs = await getLogs()

      if (listaLogs.token === null) {
        return
      }

      if (listaLogs.status === 'error')
        throw new Error(
          'Erro ao carregar a lista de logs, por favor tente novamente.'
        )

      this.setState({ logs: listaLogs.msg })
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
    this.setState({ loader: false })
  }

  render() {
    const { logs } = this.state
    return (
      <>
        <Menu menuDrop="5" />
        <Header />
        <div className="container">
          <div className="breadcrumb">
            <div>
              <h3>LOGS</h3>
              <p>
                Logs{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                <span>Registro de Logs</span>
              </p>
            </div>
            <span></span>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="box table-logs">
                <div className="box-title">
                  <h4>Listas Cadastradas</h4>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Usuário</th>
                        <th>Ação</th>
                        <th>Data</th>
                        <th>Tela</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs.length === 0 ? (
                        <tr>
                          <td colSpan="4">Não há logs para exibir...</td>
                        </tr>
                      ) : (
                        logs.map((l, index) => (
                          <tr key={index}>
                            <td>{l.nome}</td>
                            <td>
                              <strong>{l.acao}</strong>
                            </td>
                            <td>{l.data}</td>
                            <td>{l.tela}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}
