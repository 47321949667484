import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import { ExpandLessOutlined } from '@material-ui/icons'

export default createGlobalStyle`
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }
  html{
    max-height: 100%;
  }
  body{
    height: 100vh;
    margin: 0;
  }
  .root{
    height: 100vh;
  }
  .d-none{
    display: none;
  }
  .d-flex{
    display: flex;
  }
  .d-block{
    display: block;
  }
  .scale0{
    transform: scale(0, 0);
  }
  .scale1{
    transform: scale(1, 1);
  }
  .icon-invert{
    transform: scaleX(-1);
  }
  .action-icon{
    color: ${(props) => props.theme.palette.geralContrast.main};
    border-radius: 100%;
  }
  .action-icon.green{
    background: ${(props) => props.theme.palette.secondary.main};
  }
  .action-icon.red{
    background: ${(props) => props.theme.palette.error.main};
  }

  .w50{
    &&{
      width: calc(50% - 10px);
    }
  }
`

export const StyledScrollbar = styled(PerfectScrollbar)``

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: #666;

  &:hover {
    color: #555;
  }
`

export const ScrollContainer = styled.div`
  height: 100vh;
  background: ${(props) => props.theme.palette.primary.dark};
`

export const StyledButton = styled(Button).attrs({
  variant: 'contained',
  color: 'primary'
})``

export const StyledBox = styled(Box)`
  display: flex;
`

export const StyledMain = styled.main`
  /* margin-top: 64px; */
  width: 100%;
`

export const StyledMainHome = styled(StyledMain)`
  height: 100%;
  display: grid;
  grid: auto auto 1fr / 1fr 1fr;
  gap: 24px;
  position: relative;

  .grid-1,
  .grid-2 {
    .stretch {
      padding-top: 24px;
    }
  }

  .grid-1,
  .grid-2,
  .grid-3 {
    position: relative;
  }

  .grid-3 {
    grid-column: 1 / -1;
  }

  .grid-title {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #fff;
    color: #333333;
    font-size: 15px;
    line-height: 24px;
    padding: 0 12px;
    border-radius: 5px 5px 0 0;
    z-index: 1;
    backface-visibility: hidden;
    cursor: default;
    pointer-events: none;
    text-rendering: optimizeLegibility;
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    text-transform: uppercase;
  }
  .margin-top-2em {
    margin-top: 2em;
  }
`

export const StyledInput = withStyles({
  root: {
    '& input:valid + fieldset': {
      borderColor: 'green',
      borderWidth: 2
    },
    '& input:invalid + fieldset': {
      borderColor: 'red',
      borderWidth: 2
    },
    '& input:valid:focus + fieldset': {
      borderLeftWidth: 6,
      padding: '4px !important'
    }
  }
})(TextField)

export const ButtonGreen = withStyles((theme) => ({
  root: {
    display: 'block',
    width: '100%',
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button)

export const StyledButtonGreen = styled(ButtonGreen)`
  && {
    inline-size: -webkit-fill-available;
  }
`

export const StyledContainer = styled(Container)`
  && {
    max-width: 100%;
    padding: 24px;
  }
`

// const isMobile = (!!(/Mobile|iPhone|iPod|BlackBerry|Windows Phone/i).test(navigator.userAgent || navigator.vendor || window.opera))

export const StyledAppBar = styled(AppBar)``

export const StyledTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background: ${(props) =>
      props.activeprops === 'red'
        ? props.theme.palette.error.main
        : props.activeprops === 'green'
        ? props.theme.palette.secondary.main
        : props.theme.palette.primary.main};
  }
`

export const StyledTab = styled(Tab)`
  && {
    ${(props) =>
      props.activeprops && props.activeprops === 'red'
        ? `color: ${props.theme.palette.error.main} !important;`
        : props.activeprops && props.activeprops === 'green'
        ? `color: ${props.theme.palette.secondary.main} !important;`
        : props.activeprops && props.activeprops === 'blue'
        ? `color: ${props.theme.palette.primary.main} !important;`
        : 'color: #000 !important;'}
  }
`

export const StyledTypography = styled(Typography)``

export const StyledIconFilter = styled(IconButton)`
  && {
    margin-left: auto;
    margin-right: auto;
    color: ${(props) => props.theme.palette.geralContrast.main};
  }
`

export const StyledMaterialTable = styled.div`
  width: 100%;
  position: relative;
  && {
    &.w50 {
      width: calc(50% - 10px);
      min-height: 300px;
      max-height: 350px;
      .MuiPaper-root {
        min-height: 100%;
      }
    }

    h6 {
      font-weight: bold;
    }
    /* margin-bottom: 50px; */
    ::placeholder {
      font-size: 12px;
    }
    th {
      border-bottom: none;
      min-width: 100px;
      font-size: 12px;
      font-weight: bold;
      z-index: 9999;
    }
    tbody td {
      font-size: 12px;

      .MuiTextField-root {
        width: 100%;

        input {
          width: 100%;
          font-size: 12px;
        }
      }
    }
    .MuiToolbar-root {
      min-width: 300px;
    }
    &.fixed {
      tr {
        td:first-child {
          font-weight: bold;
        }
      }
    }
  }
`

export const StyledImageTable = styled.img`
  width: 50px;
  height: 50px;
`

export const StyledMTableToolbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledFilterTable = styled.div`
  color: #000;
  cursor: pointer;
  margin-right: 20px;

  &:hover {
    color: #6b6b6b;
  }
`

export const StyledToggleButton = styled(ExpandLessOutlined)`
  margin-right: 20px;
  font-size: 28px !important;
  cursor: pointer;

  &:hover {
    color: #6b6b6b;
  }
`
