export const formatPhone = (phone) => {
  if (typeof phone !== 'string') return phone
  const phoneNumber = phone.replace(/\D+/gi, '')
  if (phoneNumber.length === 10) {
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber.slice(
      2,
      6
    )}-${phoneNumber.slice(6, 10)}`
  } else if (phoneNumber.length === 11) {
    return `(${phoneNumber.slice(0, 2)}) ${phoneNumber[2]} ${phoneNumber.slice(
      3,
      7
    )}-${phoneNumber.slice(7, 11)}`
  }
  return phone
}

export const phoneFormatter = (params) => {
  return formatPhone(params.value)
}
