import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { FaCheck, FaTimes } from 'react-icons/fa'
import {
  aceitarPedido,
  alterarCodigoIndustria,
  editarVolumeItem,
  getMotivosCancelamento,
  getPedidoHomeDetalhes,
  notifyPartner,
  rejeitarPedido
} from '../../../../services/api'
import promised from '../../../../utils/promised'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import * as S from './styled'
import { formatCurrency } from '../../../../utils/grid/formatters/currency.formatter'
import Loader from '../../../../components/Loader'
import swal from 'sweetalert'
import { getNumber } from '../../../../utils/parsers/get-number'
import { statusPedidoColor } from '../../../../utils/grid/cell-styles/statusPedido-cell-style'
import * as _ from 'lodash'
import { dotsSimpleFormatter } from '../../../../utils/grid/formatters/decimal.formatter'
import '../../style.css'

const dateFormat = (dt) => dt.replace(/(\d{2}).(\d{2}).(\d{4})/, '$3-$2-$1')

const ModalDetalhesPedido = ({ data, onClose }) => {
  const [loading, setLoading] = useState(false)
  const [detalhes, setDetalhes] = useState([])
  const [motivosCancelamento, setMotivosCancelamento] = useState([])
  const [codigoIndustria, setCodigoIndustria] = useState(
    data.cod_pedido_industria || ''
  )
  const [dataEmbarque, setDataEmbarque] = useState(
    data.data_embaque ? dateFormat(data.data_embaque) : ''
  )
  const [dataEntrega, setDataEntrega] = useState(
    data.data_entrega ? dateFormat(data.data_entrega) : ''
  )

  const [hasPendingItems, setHasPendingItems] = useState(false)
  const [valorTotal, setValorTotal] = useState(0)
  const [totalCaixas, setTotalCaixas] = useState(0)
  const [totalItensCaixas, setTotalItensCaixas] = useState(0)

  const [checkStatus, setCheckStatus] = useState({})
  const [motivos, setMotivos] = useState({})

  const [caixas, setCaixas] = useState({})
  const [valorKg, setValorKg] = useState({})
  const [decimals, setDecimals] = useState(0)

  const handleValues = _.debounce((index, boxes, unit, valueItem) => {
    handleCalcUnits(index, boxes, unit, valueItem)
  }, 500)

  useEffect(() => {
    setHasPendingItems(true)
  }, [codigoIndustria, dataEmbarque, dataEntrega])

  const handleAllow = useCallback((item = {}) => {
    console.log('handleAllow')
    if (item.hasOwnProperty('id_produtos_pedido')) {
      setCheckStatus((prevState) => ({
        ...prevState,
        [item.id_produtos_pedido]: true
      }))
    }
  }, [])

  const handleRefuse = useCallback((item = {}) => {
    console.log('handleRefuse')
    if (item.hasOwnProperty('id_produtos_pedido')) {
      setCheckStatus((prevState) => ({
        ...prevState,
        [item.id_produtos_pedido]: false
      }))
    }
  }, [])

  const handleChangeMotivo = useCallback((event) => {
    console.log(event)
    const { name, value } = event.target
    if (name && value) {
      const [, gotId] = name.split('-')
      setMotivos((prevState) => ({
        ...prevState,
        [gotId]: value
      }))
    }
  }, [])

  const FormatKilogram = () => (value) => {
    let middle = value.toString().replace('.', '')
    if (middle.length <= 3) {
      return middle
    } else {
      let parts = middle.match(/.{1,3}/g)
      return parts.join('.')
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    const model = detalhes
      .filter((item) => item.situacao === 'ABERTO')
      .reduce(
        (acc, curr) => {
          if (!checkStatus.hasOwnProperty(curr.id_produtos_pedido)) {
            acc.pending.push({
              idItemPedido: curr.id_produtos_pedido
            })
          } else if (checkStatus[curr.id_produtos_pedido] === true) {
            acc.toAccept.push({
              idItemPedido: curr.id_produtos_pedido
            })
          } else {
            const motivo = motivos[curr.id_produtos_pedido] | 0
            if (motivo) {
              acc.toReject.push({
                idItemPedido: curr.id_produtos_pedido,
                idMotivoCancelamento: motivo
              })
            } else {
              acc.pending.push({
                idItemPedido: curr.id_produtos_pedido
              })
            }
          }
          return acc
        },
        {
          toAccept: [],
          toReject: [],
          pending: []
        }
      )
    if (model.pending.length) {
      // TODO @gama MOSTRAR MENSAGEM DE CAMPOS NÃO PREENCHIDOS
      return
    }
    setLoading(true)

    const saveCodigoIndustria = []
    if (
      (codigoIndustria || dataEmbarque || dataEntrega) &&
      (codigoIndustria !== data.cod_pedido_industria ||
        dataEmbarque !== data.data_embaque ||
        dataEntrega !== data.data_entrega)
    ) {
      saveCodigoIndustria.push({
        codigoIndustria,
        dataEmbarque,
        dataEntrega
      })
    }

    Promise.all([
      ...(data.id_status === 3
        ? [
            ...Object.entries(caixas).map(async (item) => {
              const [id, qty] = item
              await editarVolumeItem(id, qty)
            }),
            ...model.toAccept.map(async (item) => await aceitarPedido(item)),
            ...model.toReject.map(async (item) => await rejeitarPedido(item))
          ]
        : []),
      ...saveCodigoIndustria.map(
        async ({ codigoIndustria, dataEmbarque, dataEntrega }) =>
          await alterarCodigoIndustria(data.id_pedidos, {
            codigoIndustria,
            dataEmbarque,
            dataEntrega
          })
      )
    ])
      .then(() => {
        if (data.id_status !== 8) {
          notifyPartner(data.id_pedidos)
        }
        onClose()
        swal({
          icon: 'success',
          text: 'Dados salvos com sucesso'
        })
      })
      .catch(() => {
        swal({
          icon: 'error',
          title: 'Oops...',
          text: 'Tente novamente mais tarde'
        })
        setLoading(false)
      })
  }

  const getActiveClass = useCallback(
    (item, buttonId) => {
      console.log('getActiveClass')
      if (item.hasOwnProperty('id_produtos_pedido')) {
        if (checkStatus.hasOwnProperty(item.id_produtos_pedido)) {
          if (buttonId === 'allow') {
            return checkStatus[item.id_produtos_pedido]
              ? ' active'
              : ' inactive'
          } else if (buttonId === 'refuse') {
            return checkStatus[item.id_produtos_pedido]
              ? ' inactive'
              : ' active'
          }
        }
      }
      return ''
    },
    [checkStatus]
  )

  const handleCalcUnits = (index, boxes, unit, valueItem) => {
    const next = [...detalhes]
    let items = boxes * unit
    let total = items * valueItem
    next[index] = {
      ...next[index],
      peso: items,
      valor_total: total,
      volume: boxes
    }
    setDetalhes(next)
    HandleCalcTotal(next)
    HandleTotalItensCaixas(next)
  }

  const HandleCalcTotal = (next) => {
    setValorTotal(
      (next || []).reduce((pro, i) => {
        if (i.status !== 'REJEITADO') {
          pro += i.status !== 'REJEITADO' ? getNumber(i.valor_total) : 0
          return pro
        }
      }, 0)
    )
  }

  const HandleTotalItensCaixas = (next) => {
    setTotalItensCaixas(
      (next || []).reduce((pro, i) => {
        pro +=
          i.status !== 'REJEITADO' ? getNumber(i.volume) * i.qtd_itens_caixa : 0
        return pro
      }, 0)
    )
    setTotalCaixas(
      (next || []).reduce((pro, i) => {
        pro += i.status !== 'REJEITADO' ? getNumber(i.volume) : 0
        return pro
      }, 0)
    )
  }

  useEffect(() => {
    ;(async () => {
      if (!(data || {}).id_pedidos || !(setDetalhes instanceof Function)) return
      const [error, response] = await promised(
        getPedidoHomeDetalhes(data.id_pedidos)
      )
      if (!error) {
        setDetalhes(response.msg || [])
        setHasPendingItems(
          (response.msg || []).filter((item) => item.status === 'ABERTO').length
        )
        setValorTotal(
          (response.msg || []).reduce((acc, curr) => {
            acc += curr.status !== 'REJEITADO' ? getNumber(curr.valor_total) : 0
            return acc
          }, 0)
        )
        setTotalItensCaixas(
          (response.msg || []).reduce((acc, curr) => {
            acc +=
              curr.status !== 'REJEITADO'
                ? getNumber(curr.volume) * curr.qtd_itens_caixa
                : 0
            return acc
          }, 0)
        )
        setTotalCaixas(
          (response.msg || []).reduce((pro, i) => {
            pro += i.status !== 'REJEITADO' ? getNumber(i.volume) : 0
            return pro
          }, 0)
        )
        setCaixas(
          (response.msg || []).reduce((acc, curr) => {
            acc[curr.id_produtos_pedido] = getNumber(curr.caixas)
            return acc
          }, {})
        )
      }

      const [motivoError, motivosResponse] = await promised(
        getMotivosCancelamento()
      )
      if (!motivoError && motivosResponse) {
        setMotivosCancelamento(motivosResponse.msg || [])
      }
    })()
  }, [data, setDetalhes])

  return (
    <S.ModalDetalhesPedidoWrapper>
      {loading && (
        <div className="dialog__overlay dialog__overlay--light">
          <Loader />
        </div>
      )}
      <div className="dialog__header">
        <div className="dialog__header-left">
          Detalhes do pedido #{data.cod_pedido}
        </div>
        <div className="dialog__header-right">
          <button className="dialog__close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
      </div>
      <div className="dialog__body p24">
        <div className="dialog__title mt12 mb24">Pedido</div>

        <div className="dialog__sheet dialog__sheet--pedido mb24">
          <div className="dialog__sheet-item">
            <strong>CNPJ</strong>
            <span>{data.cnpj}</span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Nome do cliente</strong>
            <span>{data.comprador}</span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Data</strong>
            <span>{data.data_pedido}</span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Total de caixas</strong>
            <span>{dotsSimpleFormatter(totalCaixas)}</span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Origem</strong>
            <span>{data.centro_distribuicao}</span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Total itens caixas</strong>
            <span>{totalItensCaixas}</span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Cód. pedido indústria</strong>
            <span>
              <input
                className="dialog__sheet-field"
                type="text"
                value={codigoIndustria}
                onChange={(event) => {
                  setCodigoIndustria(event.target.value)
                }}
              />
            </span>
          </div>
          {/*  ---- */}
          <div className="dialog__sheet-item">
            <strong>Fat. total</strong>
            <span>{formatCurrency('BRL')(valorTotal)}</span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Data de embarque</strong>
            <span>
              <input
                className="dialog__sheet-field"
                type="date"
                value={dataEmbarque}
                onChange={(event) => {
                  setDataEmbarque(event.target.value)
                }}
              />
            </span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Data de entrega</strong>
            <span>
              <input
                className="dialog__sheet-field"
                type="date"
                value={dataEntrega}
                onChange={(event) => {
                  setDataEntrega(event.target.value)
                }}
              />
            </span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Status do pedido</strong>
            <span style={statusPedidoColor(data.id_status)}>
              <b>{data.status_detalhe}</b>
            </span>
          </div>
          <div className="dialog__sheet-item">
            <strong>Pedido mínimo do cliente</strong>
            <span>{formatCurrency('BRL')(data.pedido_minimo)}</span>
          </div>
        </div>

        <table className="ifefo-table w100">
          <thead>
            <tr>
              <th>Ações</th>
              <th>Situação</th>
              <th>Origem</th>
              <th>Vol. parc.</th>
              <th>Produtos</th>
              <th>SKU</th>
              <th>Validade</th>
              <th>Caixas estoque</th>
              <th>Caixas</th>
              <th>Itens caixa</th>
              <th>Valor (unid.)</th>
              <th>Valor total</th>
            </tr>
          </thead>
          <tbody>
            {detalhes.map((detalhe, index) => (
              <Fragment key={index}>
                <tr
                  className={
                    ~['REJEITADO'].indexOf(detalhe.status) ? 'denied' : ''
                  }
                  style={{
                    backgroundColor: index % 2 === 0 ? '#fff' : '#f7f7f7'
                  }}
                >
                  <td style={{ textAlign: 'center' }}>
                    {detalhe.status === 'ABERTO' && (
                      <>
                        <button
                          type="button"
                          className={`btn btn-info ifefo-action__button ifefo-action__button--cancel${getActiveClass(
                            detalhe,
                            'refuse'
                          )}`}
                          onClick={() => handleRefuse(detalhe)}
                        >
                          <FaTimes />
                        </button>
                        <button
                          type="button"
                          className={`btn btn-info ifefo-action__button ifefo-action__button--accept${getActiveClass(
                            detalhe,
                            'allow'
                          )}`}
                          onClick={() => handleAllow(detalhe)}
                        >
                          <FaCheck />
                        </button>
                      </>
                    )}
                  </td>
                  <td
                    style={{
                      color: ~['REJEITADO', 'CANCELADO'].indexOf(detalhe.status)
                        ? '#c00'
                        : detalhe.status === 'ACEITO'
                        ? '#009933'
                        : 'inherit'
                    }}
                  >
                    {detalhe.status}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {detalhe.status_local
                      ? 'ContraOferta aceita'
                      : 'Compra rápida'}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {detalhe.aceite_parcial}
                  </td>
                  <td style={{ textAlign: 'center' }}>{detalhe.produto}</td>
                  <td style={{ textAlign: 'right' }}>{detalhe.sku}</td>
                  <td style={{ textAlign: 'center' }}>{detalhe.validade}</td>
                  <td style={{ textAlign: 'right' }}>
                    {dotsSimpleFormatter(detalhe.volume_estoque)}
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {data.id_status === 3 ? (
                      <input
                        className="dialog__sheet-field"
                        type="number"
                        value={caixas[detalhe.id_produtos_pedido]}
                        min="0"
                        max={detalhe.caixas}
                        onChange={(event) => {
                          const { value } = event.target
                          // id_produtos_pedido
                          setCaixas((prevState) => ({
                            ...prevState,
                            [detalhe.id_produtos_pedido]:
                              getNumber(value) > detalhe.caixas
                                ? detalhe.caixas.toString()
                                : value // getNumber(value)
                          }))
                          handleValues(
                            index,
                            event.target.value,
                            detalhe.qtd_itens_caixa,
                            detalhe.valor_kg
                          )
                        }}
                      />
                    ) : (
                      detalhe.caixas
                    )}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    {detalhe.peso}
                    {/*{dotsSimpleFormatter(detalhe.peso) >>> 0}*/}
                  </td>
                  <td className="text-right">
                    {formatCurrency('BRL')(detalhe.valor_kg)}
                  </td>
                  <td className="text-right">
                    {formatCurrency('BRL')(detalhe.valor_total)}
                  </td>
                </tr>
                {checkStatus[detalhe.id_produtos_pedido] === false && (
                  <tr
                    style={{
                      backgroundColor: index % 2 === 0 ? '#f7f7f7' : '#fff'
                    }}
                  >
                    <td className="motivo" colSpan="10">
                      <div className="motivo-flex">
                        <div>Motivo do cancelamento:</div>
                        <div>
                          <FormControl
                            style={{ width: '300px' }}
                            error={!motivos[detalhe.id_produtos_pedido]}
                          >
                            <Select
                              labelId={`motivo-${detalhe.id_produtos_pedido}`}
                              id={`motivo-${detalhe.id_produtos_pedido}`}
                              name={`motivo-${detalhe.id_produtos_pedido}`}
                              onChange={handleChangeMotivo}
                              inputProps={{ 'aria-label': 'Without label' }}
                            >
                              {motivosCancelamento.map((motivo, index) => (
                                <MenuItem
                                  key={index}
                                  value={motivo.id_motivo_cancelamento}
                                >
                                  {motivo.descricao}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="11" className="text-right uppercase">
                <strong>Valor total</strong>
              </td>
              <td className="text-right font-size-1-1">
                <strong>{formatCurrency('BRL')(valorTotal)}</strong>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="dialog__footer">
        <div className="dialog__footer-left">
          <button onClick={onClose} type="button" className="ifefo__button">
            Cancelar
          </button>
        </div>
        <div className="dialog__footer-right">
          {hasPendingItems ? (
            <button
              type="button"
              className="ifefo__button primary"
              onClick={handleSubmit}
            >
              Salvar
            </button>
          ) : (
            ''
          )}
        </div>
      </div>
    </S.ModalDetalhesPedidoWrapper>
  )
}

export default ModalDetalhesPedido
