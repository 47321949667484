export const formatCurrency = (locale = 'BRL') => (amount) => {
  if (!~['string', 'number'].indexOf(typeof amount)) return amount
  switch (locale) {
    case 'USD':
      return parseFloat(amount).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD'
      })
    case 'BRL':
    default:
      return parseFloat(amount).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
  }
}

export const currencyFormatter = (locale = 'BRL') => (params) => {
  return formatCurrency(locale)(params.value)
}

export const newCurrencyFormatter = (locale = 'BRL') => (params) => {
  let y = params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
  return "R$ " + y.replace('R$', '').replace('.', ',');
}
