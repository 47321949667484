import React, { useCallback, useEffect, useState } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { MdChevronRight } from 'react-icons/md'
import {
  getListaLancesAutomaticAprovalNew,
  inserirAprovacaoAutoNewest
} from '../../services/api'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import gridOptions from '../../utils/grid/options/grid-options'
import { faixaRenderer } from '../../utils/grid/renderers/faixa.renderer'
import {
  editableCellStyle,
  editableGreenCellStyle,
  editableRedCellStyle
} from '../../utils/grid/cell-styles/editable.cell-style'
import promised from '../../utils/promised'
import { getNumber } from '../../utils/parsers/get-number'
import {
  currencyFormatter,
  newCurrencyFormatter
} from '../../utils/grid/formatters/currency.formatter'
import { resolveLanceValues } from '../../utils/grid/handlers/resolve-lance-values'
import { decimalPercentFormatter } from '../../utils/grid/formatters/decimal-percent.formatter'
import { centerCellStyle } from '../../utils/grid/cell-styles/center.cell-style'
import CurrencyInput from 'react-currency-input'
import Switch from 'react-switch'
import CustomTooltip from './custom-tooltip'
import ActionsRenderer from '../../utils/grid/renderers/actionsRenderer'

const Lances = () => {
  const [changedRows, setChangedRows] = useState([])

  const [gridApi, setGridApi] = useState(null)
  const [rowData, setRowData] = useState([])
  const [onKey, setOnKey] = useState(false)
  const [setShowModal] = useState(false)
  const [setCurrentModal] = useState('')
  const [setModalData] = useState({})

  // region Bulk operations
  const [bulkMode, setBulkMode] = useState('percent')
  const [bulkApproveAbove, setBulkApproveAbove] = useState('')
  const [bulkApproveBelow, setBulkApproveBelow] = useState('')
  const fixedGridOptions = {
    ...gridOptions,
    suppressSorting: true,
    suppressMovable: true,
    sortable: true,
    suppressMovableColumns: true,
    defaultColDef: {
      ...gridOptions.defaultColDef
    }
  }
  const bulkHandleClick = useCallback(
    (event) => {
      event.preventDefault()
      const selectedRows = gridApi.getSelectedNodes()
      selectedRows.forEach((row) => {
        const maxKey = `${bulkMode === 'percent' ? 'perc_' : ''}max`
        const minKey = `${bulkMode === 'percent' ? 'perc_' : ''}min`
        row.setDataValue(minKey, parseFloat(bulkApproveBelow))
        row.setDataValue(maxKey, parseFloat(bulkApproveAbove))
        row.setData({
          ...row.data,
          pristine: false
        })
      })
    },
    [gridApi, bulkMode, bulkApproveBelow, bulkApproveAbove]
  )

  const bulkHandleChange = (event) => {
    const { name, value } = event.target
    switch (name) {
      case 'approveAbove':
        setBulkApproveAbove(value)
        break
      case 'approveBelow':
        setBulkApproveBelow(value)
        break
      case 'bulkMode':
        setBulkMode(value)
        break
      default:
    }
  }
  // endregion

  const componentParent = {
    actions: [
      {
        type: 'info',
        color: '#573631',
        callback(rowNode) {
          setCurrentModal('detalhesLances')
          setModalData(rowNode.data)
          setShowModal(true)
        }
      }
    ]
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    params.api.sizeColumnsToFit()
  }

  useEffect(() => {
    const fun = async () => {
      try {
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const lance = await getListaLancesAutomaticAprovalNew(idDistribuidor)
        if (lance.token === null) return
        if (lance.status === 'error')
          throw new Error(
            'Erro ao carregar a lista, por favor tente novamente.'
          )
        let Info = lance.msg.filter(function (lance) {
          return lance.fl_tipo === 0
        })
        setRowData(Info)
      } catch (e) {
        await swal({ icon: 'info', text: e.message })
      }
    }
    fun()
  }, [])

  const numberToDecimal = (value) => {
    return value.toString().replace(',', '.')
  }

  const handleSave = useCallback(
    async (event) => {
      event.preventDefault()
      const payload = []
      gridApi.forEachNode((node, index) => {
        if (~changedRows.indexOf(node.data.id)) {
          payload.push({
            idAuto: node.data.id,
            max: getNumber(numberToDecimal(node.data.max)),
            min: getNumber(numberToDecimal(node.data.min)),
            v_perc_max: getNumber(numberToDecimal(node.data.perc_max)),
            v_perc_min: getNumber(numberToDecimal(node.data.perc_min))
          })
        }
      })
      const [error] = await promised(inserirAprovacaoAutoNewest(payload))
      if (error) {
        await swal({ icon: 'error', text: error.message })
        return
      }
      setChangedRows([])
      await swal({ icon: 'success', text: 'Salvo com sucesso' })
    },
    [gridApi, changedRows]
  )
  const handleListShow = async () => {
    let status = !onKey
    setOnKey(status)
    await handleListProducts(status)
  }

  const handleListProducts = async (status) => {
    const idDistribuidor = localStorage.getItem('id_distribuidor')
    if (!status) {
      const lance = await getListaLancesAutomaticAprovalNew(idDistribuidor)
      console.log(lance.msg)
      let Info = lance.msg.filter(function (lance) {
        return lance.fl_tipo === 0
      }, [])
      setRowData(Info)
    } else {
      const lance = await getListaLancesAutomaticAprovalNew(idDistribuidor)
      // console.log(lance)
      let Info = lance.msg.filter(function (lance) {
        return lance.fl_tipo === 1
      }, [])
      // console.log(Info)
      setRowData(Info)
    }
  }

  return (
    <>
      {/* {
        redirect && <Redirect to='/lances' />
      } */}
      <Menu menuDrop="6" />
      <Header />
      <div className="container  container--flex">
        <div className="breadcrumb">
          <div>
            <h3>APROVAÇÃO AUTOMÁTICA</h3>
            <p>
              ContraOferta{' '}
              <span>
                <MdChevronRight />
              </span>{' '}
              <span>Aprovação Automática</span>
            </p>
          </div>
          <span />
        </div>
        <div className="container__body">
          <div className="col-12 h100">
            <div className="box h100  box--flex">
              <div
                className="row text-center padding-y-0"
                style={{ padding: '0', margin: '-5px 0' }}
              >
                <div className="col-12">
                  <Switch
                    checked={onKey}
                    onChange={handleListShow}
                    width={175}
                    offColor="#fb8501"
                    onColor="#109C33"
                    uncheckedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 13,
                          fontWeight: 500,
                          color: 'white',
                          paddingRight: 2
                        }}
                      >
                        Catálogo
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          fontSize: 13,
                          fontWeight: 500,
                          color: 'white',
                          paddingRight: 2
                        }}
                      >
                        Campanhas
                      </div>
                    }
                  />
                </div>
              </div>
              <div
                className="box-content scroll-table scroll-table--lonely"
                style={{ marginBottom: '-20px' }}
              >
                <div
                  className="ag-theme-balham"
                  style={{ height: '100%', width: '100%' }}
                >
                  {rowData.length ? (
                    <AgGridReact
                      rowSelection="false"
                      frameworkComponents={{
                        actionsRenderer: ActionsRenderer
                        // customTooltip: CustomTooltip
                      }}
                      gridOptions={gridOptions}
                      context={{ componentParent }}
                      onGridReady={onGridReady}
                      rowData={rowData}
                      animateRows={false}
                      rowSelection="multiple"
                      // tooltipShowDelay={0}
                      // defaultColDef={{ tooltipComponent: 'customTooltip', resizable: true, }}
                    >
                      <AgGridColumn
                        floatingFilter={true}
                        headerName="ContraOferta"
                        suppressMovable={true}
                        marryChildren={true}
                        children={[
                          <AgGridColumn
                            floatingFilter={true}
                            headerName="SKU"
                            // tooltipField="produtos_detalhe"
                            sortable={true}
                            field="sku"
                            width={120}
                          />,
                          <AgGridColumn
                            floatingFilter={true}
                            headerName="Faixa"
                            sortable={true}
                            filter="agSetColumnFilter"
                            cellRenderer={faixaRenderer}
                            // tooltipField="produtos_detalhe"
                            cellStyle={centerCellStyle}
                            field="faixa"
                            width={100}
                          />,
                          <AgGridColumn
                            floatingFilter={true}
                            headerName="Validade"
                            sortable={true}
                            filter="agSetColumnFilter"
                            cellRenderer={faixaRenderer}
                            tooltipField="produtos_detalhe"
                            cellStyle={centerCellStyle}
                            field="data"
                            width={100}
                          />,
                          <AgGridColumn
                            floatingFilter={true}
                            headerName="Nome"
                            // tooltipField="produtos_detalhe"
                            sortable={true}
                            field="produto"
                            width={240}
                          />,
                          <AgGridColumn
                            floatingFilter={true}
                            headerName="Categoria"
                            sortable={true}
                            field="categoria"
                            // tooltipField="produtos_detalhe"
                            width={200}
                          />,
                          <AgGridColumn
                            floatingFilter={true}
                            headerName="Origem"
                            sortable={true}
                            // tooltipField="produtos_detalhe"
                            field="cd"
                            columnGroupShow={80}
                          />,
                          <AgGridColumn
                            floatingFilter={true}
                            headerName="Valor"
                            sortable={true}
                            // tooltipField="produtos_detalhe"
                            field="valor_ifefo"
                            valueFormatter={currencyFormatter('BRL')}
                            columnGroupShow={80}
                          />
                        ]}
                      />
                      <AgGridColumn
                        headerName="Calibração por porcentagem (%)"
                        marryChildren={true}
                        suppressMovable={true}
                        children={[
                          <AgGridColumn
                            floatingFilter={true}
                            filter="agNumberColumnFilter"
                            headerName="Aprovar acima de:"
                            suppressMovable={true}
                            field="perc_max"
                            sortable={true}
                            editable={true}
                            cellStyle={editableCellStyle}
                            valueFormatter={decimalPercentFormatter}
                            onCellValueChanged={(params) => {
                              if (!~changedRows.indexOf(params.data.id)) {
                                setChangedRows((prevState) => [
                                  ...prevState,
                                  ...[params.data.id]
                                ])
                              }
                              resolveLanceValues('perc_max', params)
                            }}
                          />,
                          <AgGridColumn
                            floatingFilter={true}
                            filter="agNumberColumnFilter"
                            headerName="Rejeitar abaixo de:"
                            suppressMovable={true}
                            field="perc_min"
                            sortable={true}
                            editable={true}
                            cellStyle={editableCellStyle}
                            valueFormatter={decimalPercentFormatter}
                            onCellValueChanged={(params) => {
                              if (!~changedRows.indexOf(params.data.id)) {
                                setChangedRows((prevState) => [
                                  ...prevState,
                                  ...[params.data.id]
                                ])
                              }
                              resolveLanceValues('perc_min', params)
                            }}
                          />
                        ]}
                      />
                      <AgGridColumn
                        headerName="Calibração por preço (R$)"
                        marryChildren={true}
                        suppressMovable={true}
                        children={[
                          <AgGridColumn
                            floatingFilter={true}
                            filter="agNumberColumnFilter"
                            headerName="Aprovar acima de:"
                            suppressMovable={true}
                            field="max"
                            sortable={true}
                            editable={true}
                            cellStyle={editableGreenCellStyle}
                            valueFormatter={newCurrencyFormatter('BRL')}
                            onCellValueChanged={(params) => {
                              if (!~changedRows.indexOf(params.data.id)) {
                                setChangedRows((prevState) => [
                                  ...prevState,
                                  ...[params.data.id]
                                ])
                              }
                              resolveLanceValues('max', params)
                            }}
                          />,
                          <AgGridColumn
                            floatingFilter={true}
                            filter="agNumberColumnFilter"
                            headerName="Rejeitar abaixo de:"
                            suppressMovable={true}
                            field="min"
                            sortable={true}
                            editable={true}
                            cellStyle={editableRedCellStyle}
                            valueFormatter={newCurrencyFormatter('BRL')}
                            onCellValueChanged={(params) => {
                              if (!~changedRows.indexOf(params.data.id)) {
                                setChangedRows((prevState) => [
                                  ...prevState,
                                  ...[params.data.id]
                                ])
                              }
                              resolveLanceValues('min', params)
                            }}
                          />
                        ]}
                      />
                      <AgGridColumn
                        checkboxSelection={true}
                        headerCheckboxSelection={true}
                        headerCheckboxSelectionFilteredOnly={true}
                      />
                    </AgGridReact>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="box-actions">
                <div className="box-actions__form">
                  <div className="box-actions__formgroup">
                    <h2 className="box-actions__title">Ação em massa</h2>
                  </div>
                  <div
                    className="box-actions__formgroup width-less"
                    style={{ minWidth: '20%' }}
                  >
                    <label>Aprovação por:</label>
                    <div
                      className="box-actions__radio-group"
                      style={{ marginBottom: '-2px' }}
                    >
                      <label>
                        <input
                          name="bulkMode"
                          value="percent"
                          type="radio"
                          onChange={bulkHandleChange}
                          checked={bulkMode === 'percent'}
                        />{' '}
                        Porcentagem (%)
                      </label>
                      <label>
                        <input
                          name="bulkMode"
                          value="price"
                          type="radio"
                          onChange={bulkHandleChange}
                          checked={bulkMode === 'price'}
                        />{' '}
                        Preço (R$)
                      </label>
                    </div>
                  </div>
                  <div
                    className="box-actions__formgroup width-less"
                    style={{ minWidth: '15%' }}
                  >
                    <label htmlFor="bulk_aprovar_acima">
                      Aprovar acima de:
                    </label>
                    {bulkMode === 'percent' && (
                      <>
                        <input
                          className="input-default"
                          name="approveAbove"
                          onChange={bulkHandleChange}
                          value={bulkApproveAbove}
                          type="number"
                          id="bulk_aprovar_acima"
                        />
                      </>
                    )}
                    {bulkMode === 'price' && (
                      <>
                        <CurrencyInput
                          className="input-default"
                          name="approveAbove"
                          onChangeEvent={bulkHandleChange}
                          value={bulkApproveAbove}
                          precision="2"
                          decimalSeparator=","
                          thousandSeparator="."
                          type="text"
                          id="bulk_aprovar_acima"
                        />
                      </>
                    )}
                  </div>
                  <div
                    className="box-actions__formgroup width-less"
                    style={{ minWidth: '15%' }}
                  >
                    <label htmlFor="bulk_aprovar_abaixo">
                      Rejeitar abaixo de:
                    </label>
                    {bulkMode === 'percent' && (
                      <>
                        <input
                          className="input-default"
                          name="approveBelow"
                          onChange={bulkHandleChange}
                          value={bulkApproveBelow}
                          type="number"
                          id="bulk_aprovar_abaixo"
                        />
                      </>
                    )}
                    {bulkMode === 'price' && (
                      <>
                        <CurrencyInput
                          className="input-default"
                          name="approveBelow"
                          onChangeEvent={bulkHandleChange}
                          value={bulkApproveBelow}
                          precision="2"
                          decimalSeparator=","
                          thousandSeparator="."
                          type="text"
                          id="bulk_aprovar_abaixo"
                        />
                      </>
                    )}
                  </div>
                  <div className="box-actions__formgroup">
                    <button
                      type="button"
                      className="btn-default"
                      onClick={bulkHandleClick}
                    >
                      Atualizar selecionados
                    </button>
                  </div>
                  <div className="box-actions__buttons">
                    <button className="btn-default" onClick={handleSave}>
                      Salvar alterações
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Lances
