import React, { useEffect, useState } from 'react'
import { MdChevronRight } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { PowerBIEmbed } from 'powerbi-client-react'
import * as pbi from 'powerbi-client'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { getPowerBiDemo } from '../../services/api'
import './style.css'

const PowerBi = () => {
  const [pbiConfig, setPbiConfig] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const bootstrap = async () => {
      const response = await getPowerBiDemo()
      if (response && response.msg) {
        setPbiConfig(response.msg)
      }
      setLoading(false)
    }
    bootstrap()
  }, [])

  return (
    <>
      <Menu menuDrop="999" />
      <Header />
      <div className="container  container--flex">
        <div className="breadcrumb" id="breadcrumb">
          <div>
            <h3>Dashboards</h3>
            <p>
              Relatório{' '}
              <span>
                <MdChevronRight />
              </span>{' '}
              BI
            </p>
          </div>
        </div>
        {pbiConfig && (
          <div className="container__body">
            <PowerBIEmbed
              embedConfig={{
                type: pbiConfig.reportType,
                id: pbiConfig.reportId,
                embedUrl: pbiConfig.reportUrl,
                accessToken: pbiConfig.accessToken,
                tokenType: pbi.models.TokenType.Aad,
                settings: {
                  panes: {
                    filters: {
                      expanded: false,
                      visible: false
                    }
                  }
                }
              }}
              cssClassName="full"
            />
          </div>
        )}
      </div>
      <Footer />
      <div className={loading ? 'loader show' : 'loader'}>
        <ClipLoader sizeUnit="px" size={90} color="#FB8501" loading />
      </div>
    </>
  )
}

export default PowerBi
