import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { TiCloudStorage } from 'react-icons/ti'
import { MdChevronRight } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { getListaUsuarios, Upload } from '../../services/api'
import './style.css'

export default class Up extends Component {
  state = {
    lista: '',
    arquivo: '',
    nomeArquivo: '',
    tabAtiva: '',
    listaUsr: [],
    loader: false
  }

  componentDidMount() {
    this.listaUsuarios()
  }

  listaUsuarios = async () => {
    this.setState({ loader: true })

    try {
      const listas = await getListaUsuarios()

      if (listas.token === null) {
        return
      }

      if (listas.status === 'error')
        throw new Error('Erro ao carregar a lista, por favor tente novamente.')

      this.setState({ listaUsr: listas.msg })
    } catch (error) {
      swal({ icon: 'info', text: error.message })
    }
    this.setState({ loader: false })
  }

  handleUpload = async () => {
    this.setState({ loader: true })

    try {
      let { lista, arquivo } = this.state

      if (lista === '')
        throw new Error('Por favor informe um nomeLista para a lista')

      if (arquivo === '')
        throw new Error('Por favor faça o upload de uma lista')

      let body = new FormData()

      body.append('doc', arquivo)
      body.append('nomeLista', lista)
      body.append('tipoLista', 2)

      const up = await Upload(body)

      if (up.token === null) {
        return
      }

      if (up.status === 'error') throw new Error(up.msg)

      this.setState({ lista: '', arquivo: '', nomeArquivo: '', loader: false })

      swal({
        icon: 'success',
        text: up.msg
      }).then(() => window.location.reload())
    } catch (e) {
      this.setState({ lista: '', arquivo: '', nomeArquivo: '', loader: false })

      swal({
        icon: 'error',
        text: e.message
      }).then(() => window.location.reload())
    }
  }

  fakeLoader = async (e) => {
    this.setState({ loader: true })

    const numArquivo = e.target.files.length > 1 ? e.target.files.length - 1 : 0

    await this.setState({
      arquivo: e.target.files[numArquivo],
      nomeArquivo:
        e.target.files[numArquivo] === undefined
          ? 'Upload Cancelado, por favor carregue um arquivo'
          : e.target.files[numArquivo].name
    })

    setTimeout(() => this.setState({ loader: false }), 1000)
  }

  render() {
    const { listaUsr, tabAtiva } = this.state

    const lista =
      listaUsr.length === 0 ? (
        <tr>
          <td colSpan="5">Não há usuários criados</td>
        </tr>
      ) : (
        listaUsr.map((l, index) => (
          <tr
            key={index}
            className={
              tabAtiva === index
                ? 'lista-cadastrados active'
                : 'lista-cadastrados'
            }
          >
            <td>{l.lista}</td>
            <td>{l.qtd}</td>
            <td>{l.data_cadastro}</td>
            <td>{l.usuario}</td>
          </tr>
        ))
      )

    return (
      <>
        <Menu menuDrop="1" />
        <Header />
        <div className="container pb-0">
          <div className="breadcrumb">
            <div>
              <h3>IMPORTAR CLIENTES</h3>
              <p>
                Conversão{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                <span>Importar Clientes</span>
              </p>
            </div>
            <span></span>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-title">
                  <h4>Upload de Clientes</h4>
                  <button onClick={() => this.handleUpload()} className="btn">
                    Salvar
                  </button>
                </div>
                <div className="box-content">
                  <div className="form-group">
                    <div className="form-item">
                      <label>Nome da lista: </label>&nbsp;
                      <input
                        className="form-control inline"
                        type="text"
                        name="nomeLista"
                        value={this.state.lista}
                        placeholder="Ex: Clientes X"
                        onChange={(e) =>
                          this.setState({ lista: e.target.value })
                        }
                      />
                    </div>
                    <div className="form-item">
                      <label className="upload" htmlFor="up">
                        <span>
                          {this.state.arquivo === '' ? (
                            <>
                              <i id="icon-upload">
                                <TiCloudStorage />
                              </i>
                              Clique aqui para adicionar
                            </>
                          ) : (
                            this.state.nomeArquivo
                          )}
                        </span>
                      </label>
                      <input
                        type="file"
                        id="up"
                        className="d-none"
                        onChange={this.fakeLoader}
                      />
                      <br />
                      <p>
                        <strong>Atenção: </strong>Envie somente arquivos em .CSV
                        que contenham cnpj's com 14 digitos e estejam
                        classificados como texto e cabeçalho com o nome "cnpj".
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="box">
                <div className="box-title">
                  <h4>Listas Cadastradas</h4>
                </div>
                <div className="box-content">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nome Lista</th>
                        <th>QTD de Clientes</th>
                        <th>Data Cadastro</th>
                        <th>Usuário</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{lista}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}
