import React from 'react'
import DialogIfefo from '../../../components/DialogIfefo'
import ModalDetalhesLances from '../../../components/ModalDetalhesLances'

const ModalSwitcher = ({ modal, data, onClose }) => {
  return (
    <DialogIfefo
      classes={{
        root: 'dialog-big'
      }}
      aria-labelledby="simple-dialog-title"
      open={true}
    >
      {modal &&
        {
          detalhesLances: <ModalDetalhesLances data={data} onClose={onClose} />
        }[modal]}
    </DialogIfefo>
  )
}

export default ModalSwitcher
