export default {
  rot_btn_idioma_pt: 'Idioma Português',
  rot_btn_idioma_es: 'Idioma Espanhol',
  rot_emaildeacesso: 'E-mail de acesso',
  rot_senhasecreta: 'Senha secreta',
  rot_btn_entrar: 'Entrar',
  rot_mnu_inicio: 'Início',
  rot_mnu_campanha: 'CAMPANHAS',
  rot_mnu_campanha_m: 'Campanhas',
  rot_mnu_lance: 'CONTRAOFERTAS',
  rot_mnu_lance_lista: 'Listagem',
  rot_mnu_lance_aprovacao: 'Aprovação Automática',
  rot_mnu_conversao: 'CONVERSÃO',
  rot_mnu_conversao_cockpit: 'Cockpit',
  rot_mnu_conversao_impulsiona: 'Impulsionamento',
  rot_mnu_conversao_importar: 'Importar Clientes',
  rot_mnu_conversao_interacoes: 'Interações',
  rot_mnu_captura: 'CAPTURA',
  rot_mnu_captura_relatorio: 'Relatório',
  rot_mnu_captura_upload: 'Upload',
  rot_mnu_captura_interacao: 'Interação',
  rot_mnu_configuracoes: 'CONFIGURAÇÕES',
  rot_mnu_configuracoes_usuarios: 'Usuários',
  rot_mnu_logs: 'LOGS',
  rot_mnu_logs_registro: 'Registro de Log',
  rot_mnu_pedidos: 'Pedidos',
  rot_mnu_sair: 'Sair',
  rot_mnu_rodape_dev: 'Desenvolvido por',
  rot_home_dest_1: 'ContraOfertas em Aberto',
  rot_home_dest_2: 'Volume em Aberto',
  rot_home_dest_3: 'Volume Aceito',
  rot_home_dest_4: 'Volume Total Disponível',
  rot_home_box_1_tit: 'Volume Disponível',
  rot_home_box_1_sub_tit_1: 'CATEGORIA',
  rot_home_box_1_sub_tit_2: 'ORIGEM',
  rot_home_box_1_sub_tit_3: 'PRAZO DE VALIDADE',
  rot_home_box_2_tit: 'Histórico de Vendas',
  rot_home_lista_lance_tit: 'Lista de ContraOfertas',
  rot_home_lista_lance_tooltip_on: 'Aprovar',
  rot_home_lista_lance_tooltip_off: 'Rejeitar',
  rot_campanhas_tit: 'LISTA DE CAMPANHAS',
  rot_campanhas_tit_breadcrumb_1: 'Campanhas',
  rot_campanhas_tit_breadcrumb_2: 'Lista de Campanhas',
  rot_campanhas_buttonTitleAdd: 'Criar Campanha',
  listadepedidos: 'LISTA DE PEDIDOS',
  listadeestoque: 'LISTA DE ESTOQUE',
  listadeprodutos: 'LISTA DE PRODUTOS',
  rot_mnu_estoques: 'Estoques',
  rot_mnu_produtos: 'Produtos',
  rot_mnu_clientes: 'Clientes',
  rot_home_lista_pedidos_tooltip_on: 'Detalhes',
  rot_mnu_captura_integracao: 'INTEGRAÇÃO',
  rot_mnu_captura_download: 'Download',
  rot_mnu_dash: 'Dashboards',
  rot_mnu_engajamento: 'Engajamento',
  rot_engajamento_buttonTitleAdd: 'Criar Engajamento'
}
