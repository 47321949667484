/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { ClipLoader } from 'react-spinners'
import swal from 'sweetalert'
import { GavelOutlined } from '@material-ui/icons'
import { GiWeight } from 'react-icons/gi'
import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'

// eslint-disable-next-line import/no-named-as-default
import Menu from '../../components/Menu'
// eslint-disable-next-line import/no-named-as-default
import Footer from '../../components/Footer'
import { StyledMainHome } from '../../utils/styles'

import {
  StyledCard,
  StyledCardContainer,
  StyledCardIcon,
  StyledCardTitle,
  StyledCardValue,
  StyledCardValueContainer
} from './styles'
import {
  getLancesAbertos,
  getVolumeAberto,
  getVolumeAceito,
  getVolumeDisponivelEstoque,
  listaHistoricoVendas,
  listaLancesDistribuidor,
  listaVolumeDisponivel,
  sendAcaoAprovaRejeitarLance
} from '../../services/api'

import './style.css'
import pt from '../../lang/pt'
import es from '../../lang/es'
import useInterval from '../../hooks/useInterval'
import { currencyFormatter } from '../../utils/grid/formatters/currency.formatter'
import ActionsRenderer from '../../utils/grid/renderers/actionsRenderer'
import gridOptions from '../../utils/grid/options/grid-options'
import { imageRenderer } from '../../utils/grid/renderers/image.renderer'
import { cnpjFormatter } from '../../utils/grid/formatters/cnpj.formatter'
import {
  dotsFormatter,
  dotsSimpleFormatter
} from '../../utils/grid/formatters/decimal.formatter'
import ModalSwitcher from './ModalSwitcher'
import { VolumeDisponivelCategoriaCellRenderer } from '../../utils/grid/renderers/volume-disponivel-categoria.renderer'
import { VolumeDisponivelOrigemCellRenderer } from '../../utils/grid/renderers/volume-disponivel-origem.renderer'
import { VolumeDisponivelPrazoValidadeCellRenderer } from '../../utils/grid/renderers/volume-disponivel-prazo-validade.renderer'
import CustomTooltip from './custom-tooltip'

// import ImgIndisponivel from '../../img/img-indisponivel.png'

counterpart.registerTranslations('pt', pt)
counterpart.registerTranslations('es', es)

const Home = () => {
  const [loader, setLoader] = useState(false)
  const [cond, setCond] = useState(false)
  const [condLances, setCondLances] = useState(false)

  const [, setGridApi] = useState(null)
  const [, setColumnApi] = useState(null)
  const [rowData, setRowData] = useState([])
  const [rowDataVolumeDisponivel, setRowDataVolumeDisponivel] = useState([])
  const [colHistoricoVendas, setColHistoricoVendas] = useState([])
  const [rowDataHistoricoVendas, setRowDataHistoricoVendas] = useState([])
  const [historicoVendasReady, setHistoricoVendasReady] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [currentModal, setCurrentModal] = useState('')
  const [modalData, setModalData] = useState({})

  const closeModal = () => {
    setModalData({})
    setCurrentModal('')
    setShowModal(false)
  }

  const homeGridOptions = {
    ...gridOptions,
    localeText: { noRowsToShow: 'Não há contraofertas em aberto' },
    statusPanels: []
  }

  const fixedGridOptions = {
    ...gridOptions,
    suppressSorting: true,
    suppressMovable: true,
    suppressMovableColumns: true,
    defaultColDef: {
      ...gridOptions.defaultColDef,
      sortable: false
    },
    statusPanels: []
  }

  const handleApprovalOrRejact = async (gotRowData, acao) => {
    const idLance = gotRowData.id

    try {
      const aprovacao = await sendAcaoAprovaRejeitarLance(idLance, acao)

      if (aprovacao.token === null) {
        return
      }

      if (aprovacao.status === 'error')
        throw new Error('Erro ao inserir contraoferta.')

      setCondLances(!condLances)
    } catch (e) {
      await swal({ icon: 'info', text: e.message })
    }
  }

  const componentParent = {
    actions: [
      {
        type: 'info',
        callback(rowNode) {
          setCurrentModal('detalhesLances')
          setModalData(rowNode.data)
          setShowModal(true)
        }
      },
      {
        type: 'reject',
        // icon: <FaTimes />,
        color: '#f72003',
        callback: async (rowNode) => {
          setLoader(true)
          await handleApprovalOrRejact(rowNode.data, 8)
          setCondLances(!condLances)
          setLoader(false)
        }
      },
      {
        type: 'accept',
        // icon: <FaCheck />,
        color: '#02c646',
        callback: async (rowNode) => {
          setLoader(true)
          await handleApprovalOrRejact(rowNode.data, 4)
          setCondLances(!condLances)
          setLoader(false)
        }
      }
    ]
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    setColumnApi(params.columnApi)
    params.api.sizeColumnsToFit()
  }

  const onVolumeDisponivelGridReady = (params) => {
    params.api.sizeColumnsToFit()
  }

  const onHistoricoVendasGridReady = (params) => {
    params.api.sizeColumnsToFit()
    const lastCol = colHistoricoVendas[colHistoricoVendas.length - 1]
    params.api.ensureColumnVisible(lastCol)
  }

  /* ALR - Base */
  const InforLanceAbertos = async () => {
    try {
      const idDistribuidor = localStorage.getItem('id_distribuidor')
      const totlances = await getLancesAbertos(idDistribuidor)
      if (totlances.token === null) {
        return
      }
      if (totlances.status === 'error')
        throw new Error('Erro ao carregar o total de contraofertas.')
      if (document.getElementById('tot_lances')) {
        if (totlances.sucess.length === 0) {
          ReactDOM.render(0, document.getElementById('tot_lances'))
        } else {
          ReactDOM.render(
            dotsSimpleFormatter(totlances.sucess[0].qtd),
            document.getElementById('tot_lances')
          )
        }
      }
    } catch (e) {
      await swal({ icon: 'info', text: e.message })
    }
  }

  const InforVolumeAberto = async () => {
    try {
      const idDistribuidor = localStorage.getItem('id_distribuidor')
      const totvolumeaberto = await getVolumeAberto(idDistribuidor)

      if (totvolumeaberto.token === null) {
        return
      }
      if (totvolumeaberto.status === 'error')
        throw new Error('Erro ao carregar o total de contraofertas.')
      if (document.getElementById('tot_volume_aberto')) {
        if (totvolumeaberto.sucess.length === 0) {
          ReactDOM.render(0, document.getElementById('tot_volume_aberto'))
        } else {
          ReactDOM.render(
            dotsSimpleFormatter(totvolumeaberto.sucess[0].volume),
            document.getElementById('tot_volume_aberto')
          )
        }
      }
    } catch (e) {
      await swal({ icon: 'info', text: e.message })
    }
  }

  const InforVolumeAceito = async () => {
    try {
      const idDistribuidor = localStorage.getItem('id_distribuidor')
      const totvolumeaceito = await getVolumeAceito(idDistribuidor)

      if (totvolumeaceito.token === null) {
        return
      }
      if (totvolumeaceito.status === 'error')
        throw new Error('Erro ao carregar o total de contraofertas.')
      if (document.getElementById('tot_volume_aceito')) {
        if (totvolumeaceito.sucess.length === 0) {
          ReactDOM.render(0, document.getElementById('tot_volume_aceito'))
        } else {
          ReactDOM.render(
            dotsSimpleFormatter(totvolumeaceito.sucess[0].volume),
            document.getElementById('tot_volume_aceito')
          )
        }
      }
    } catch (e) {
      await swal({ icon: 'info', text: e.message })
    }
  }

  const InforVolumeDisponivelEstoque = async () => {
    try {
      const idDistribuidor = localStorage.getItem('id_distribuidor')
      const totvolumedisponivelestoque = await getVolumeDisponivelEstoque(
        idDistribuidor
      )

      if (totvolumedisponivelestoque.token === null) {
        return
      }
      if (totvolumedisponivelestoque.status === 'error')
        throw new Error('Erro ao carregar o total de contraofertas.')

      if (document.getElementById('tot_volume_disponivel_estoque')) {
        if (totvolumedisponivelestoque.sucess.length === 0) {
          ReactDOM.render(
            0,
            document.getElementById('tot_volume_disponivel_estoque')
          )
        } else {
          ReactDOM.render(
            totvolumedisponivelestoque.sucess[0].volume
              .toString()
              .replace('.', '')
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'),
            document.getElementById('tot_volume_disponivel_estoque')
          )
        }
      }
    } catch (e) {
      await swal({ icon: 'info', text: e.message })
    }
  }

  /* FIM - ALR - Base */

  useEffect(() => {
    InforLanceAbertos()
    InforVolumeAberto()
    InforVolumeAceito()
    InforVolumeDisponivelEstoque()
  }, [cond])

  useInterval(() => {
    setCondLances(!condLances)
    // console.log('olá', cond)
  }, 60)

  useInterval(() => {
    setCond(!cond)
    // console.log('olá', cond)
  }, 60)

  // useEffect(() => {
  //   const fun = async () => {
  //     const img = await imgPerfil()
  //     const blob = new Blob([img])
  //     const file = URL.createObjectURL(blob)
  //     setImage(file)
  //   }
  //   fun()
  // }, [])
  // ALR
  useEffect(() => {
    const fun2 = async () => {
      try {
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const registros = await listaVolumeDisponivel(idDistribuidor)

        // console.log(registros)

        if (registros.token === null) {
          return
        }

        if (registros.status === 'error')
          throw new Error('Erro ao carregar a lista de registros.')

        const filtered = registros.sucess.filter((item) => item.id_status === 1)
        if (filtered.length > 0) {
          filtered.map(
            (detalhe, index) =>
              (detalhe.valor_1 = detalhe.valor_1
                .toString()
                .replace('.', '')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'))
          )
          filtered.map(
            (detalhe, index) =>
              (detalhe.valor_2 = detalhe.valor_2
                .toString()
                .replace('.', '')
                .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'))
          )
        }
        setRowDataVolumeDisponivel(filtered)
      } catch (e) {
        await swal({ icon: 'info', text: e.message })
      }
    }
    fun2()
  }, [cond])

  useEffect(() => {
    const fun3 = async () => {
      try {
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const registros = await listaHistoricoVendas(idDistribuidor)

        // console.log(registros)

        if (registros.token === null) {
          return
        }

        if (registros.status === 'error')
          throw new Error('Erro ao carregar a lista de registros.')

        setColHistoricoVendas(registros.grid.cols)
        setRowDataHistoricoVendas(registros.grid.rows)
        setHistoricoVendasReady(true)
      } catch (e) {
        await swal({ icon: 'info', text: e.message })
      }
    }
    fun3()
  }, [cond])

  useEffect(() => {
    const fun = async () => {
      try {
        // console.log('cheguei!')
        // const lances = await listaLances()
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const lances = await listaLancesDistribuidor(idDistribuidor)

        if (lances.token === null) {
          return
        }

        if (lances.status === 'error')
          throw new Error('Erro ao carregar a lista de contraofertas.')

        setRowData(
          lances.msg.filter((a) => a.aprovado === 3)
          // .map((a) => {
          //   // lances.msg.filter(a => a.aprovado === '0').map(a => {
          //   a.valor = `${formatCurrency('BRL')(parseFloat(a.valor))}`
          //   a.valor_unit = `${formatCurrency('BRL')(parseFloat(a.valor_unit))}`
          //   return a
          // })
        )
      } catch (e) {
        await swal({ icon: 'info', text: e.message })
      }
    }
    fun()
  }, [condLances])

  return (
    <>
      <Menu />
      <div className="container">
        {/* <div className='breadcrumb' style={{ height: 80 }}>
          <div>
            <h3>APROVAÇÃO AUTOMÁTICA</h3>
            <p>Lances <span><MdChevronRight /></span> <span>Aprovação Automática</span></p>
          </div>
          <span />
        </div> */}
        <div className="row" style={{ height: '100%' }}>
          <div className="col-12" style={{ height: '100%' }}>
            <StyledMainHome>
              <StyledCardContainer>
                <StyledCard>
                  <StyledCardIcon>
                    <GavelOutlined fontSize="large" />
                  </StyledCardIcon>
                  <StyledCardValueContainer>
                    <StyledCardTitle>
                      <Translate content="rot_home_dest_1" />
                    </StyledCardTitle>
                    <StyledCardValue>
                      <span id="tot_lances" />
                    </StyledCardValue>
                  </StyledCardValueContainer>
                </StyledCard>
                <StyledCard>
                  <StyledCardIcon>
                    <GiWeight size="36" />
                  </StyledCardIcon>
                  <StyledCardValueContainer>
                    <StyledCardTitle>
                      <Translate content="rot_home_dest_2" />
                    </StyledCardTitle>
                    <StyledCardValue>
                      <span id="tot_volume_aberto" />
                    </StyledCardValue>
                  </StyledCardValueContainer>
                </StyledCard>
                <StyledCard>
                  <StyledCardIcon>
                    <GiWeight size="36" />
                  </StyledCardIcon>
                  <StyledCardValueContainer>
                    <StyledCardTitle>
                      <Translate content="rot_home_dest_3" />
                    </StyledCardTitle>
                    <StyledCardValue>
                      <span id="tot_volume_aceito" />
                    </StyledCardValue>
                  </StyledCardValueContainer>
                </StyledCard>
                <StyledCard>
                  <StyledCardIcon>
                    <GiWeight size="36" />
                  </StyledCardIcon>
                  <StyledCardValueContainer>
                    <StyledCardTitle>
                      <Translate content="rot_home_dest_4" />
                    </StyledCardTitle>
                    <StyledCardValue>
                      <span id="tot_volume_disponivel_estoque" />
                    </StyledCardValue>
                  </StyledCardValueContainer>
                </StyledCard>
              </StyledCardContainer>

              <div
                className="grid-1 ag-theme-balham"
                style={{
                  height: '100%',
                  width: '100%',
                  minHeight: 200,
                  position: 'relative'
                }}
              >
                <div className="stretch">
                  <div className="grid-title">Volume disponível</div>
                  <AgGridReact
                    rowSelection="false"
                    gridOptions={fixedGridOptions}
                    onGridReady={onVolumeDisponivelGridReady}
                    rowData={rowDataVolumeDisponivel}
                    frameworkComponents={{
                      actionsRenderer: ActionsRenderer,
                      customTooltip: CustomTooltip
                    }}
                    tooltipShowDelay={0}
                    tooltipMouseTrack={true}
                    enableBrowserTooltips={false}
                    defaultColDef={{ tooltipComponent: 'customTooltip' }}
                    statusBar={{
                      statusPanels: []
                    }}
                  >
                    <AgGridColumn
                      headerName="Categoria"
                      tooltipField="categoria"
                      minWidth={160}
                      cellRenderer={VolumeDisponivelCategoriaCellRenderer}
                    />
                    <AgGridColumn
                      headerName="Origem"
                      tooltipField="origem"
                      minWidth={160}
                      cellRenderer={VolumeDisponivelOrigemCellRenderer}
                    />
                    <AgGridColumn
                      headerName="Prazo de validade"
                      tooltipField="validade"
                      minWidth={160}
                      cellRenderer={VolumeDisponivelPrazoValidadeCellRenderer}
                    />
                  </AgGridReact>
                </div>
              </div>

              <div
                className="grid-2 ag-theme-balham"
                style={{ height: '100%', width: '100%', minHeight: 200 }}
              >
                <div className="stretch">
                  <div className="grid-title">Histórico de vendas</div>
                  {historicoVendasReady ? (
                    <AgGridReact
                      rowSelection="false"
                      frameworkComponents={{
                        actionsRenderer: ActionsRenderer
                      }}
                      gridOptions={fixedGridOptions}
                      onGridReady={onHistoricoVendasGridReady}
                      rowData={rowDataHistoricoVendas}
                      statusBar={{
                        statusPanels: []
                      }}
                    >
                      <AgGridColumn
                        headerName="-"
                        field="label"
                        pinned="left"
                        minWidth="150"
                      />
                      {colHistoricoVendas.map((column) => (
                        <AgGridColumn
                          headerName={column}
                          key={column}
                          field={column}
                          minWidth="120"
                        />
                      ))}
                    </AgGridReact>
                  ) : (
                    ''
                  )}
                </div>
              </div>

              <div
                className="grid-3 ag-theme-balham"
                style={{ height: '100%', width: '100%', minHeight: 360 }}
              >
                <div className="stretch">
                  <div className="grid-title">ContraOfertas em Aberto</div>
                </div>
                <div className="margin-top-2em"></div>
                <AgGridReact
                  rowSelection="false"
                  frameworkComponents={{
                    actionsRenderer: ActionsRenderer
                  }}
                  gridOptions={homeGridOptions}
                  context={{ componentParent }}
                  onGridReady={onGridReady}
                  rowData={rowData}
                  statusBar={{
                    statusPanels: []
                  }}
                >
                  <AgGridColumn
                    floatingFilter
                    headerName="ID"
                    sort="asc"
                    sortable
                    field="id"
                  />
                  <AgGridColumn
                    floatingFilter
                    valueFormatter={cnpjFormatter}
                    headerName="CNPJ"
                    sortable
                    field="cnpj"
                  />
                  <AgGridColumn
                    floatingFilter
                    headerName="Cliente"
                    sortable
                    field="fantasia"
                  />
                  <AgGridColumn
                    floatingFilter={false}
                    cellRenderer={imageRenderer}
                    hide
                    headerName="Foto"
                    sortable
                    resizable={false}
                    field="foto"
                  />
                  <AgGridColumn
                    floatingFilter
                    headerName="Produto"
                    sortable
                    field="produto"
                  />
                  <AgGridColumn
                    floatingFilter
                    headerName="Marca"
                    sortable
                    field="marca"
                  />
                  <AgGridColumn
                    floatingFilter
                    headerName="Validade"
                    sortable
                    field="validade"
                  />
                  <AgGridColumn
                    floatingFilter
                    headerName="CD"
                    sortable
                    field="origem"
                  />
                  <AgGridColumn
                    floatingFilter
                    headerName="Data contraoferta"
                    sortable
                    field="data_inicio"
                  />
                  <AgGridColumn
                    floatingFilter
                    headerName="Valor contraoferta"
                    valueFormatter={currencyFormatter('BRL')}
                    sortable
                    field="valor_lance"
                  />
                  <AgGridColumn
                    floatingFilter
                    headerName="Total Caixas"
                    sortable
                    valueFormatter={dotsFormatter}
                    field="volume"
                  />

                  <AgGridColumn
                    floatingFilter
                    headerName="Valor total"
                    valueFormatter={currencyFormatter('BRL')}
                    sortable
                    field="valor"
                  />
                  <AgGridColumn
                    filter={false}
                    headerName="Ações"
                    sortable={false}
                    colId="params"
                    editable={false}
                    field="value"
                    cellRenderer="actionsRenderer"
                  />
                </AgGridReact>
              </div>
            </StyledMainHome>
          </div>
        </div>
      </div>
      <Footer />
      {showModal && currentModal ? (
        <ModalSwitcher
          modal={currentModal}
          data={modalData}
          onClose={closeModal}
        />
      ) : (
        ''
      )}
      <div className={loader ? 'loader show' : 'loader'}>
        <ClipLoader sizeUnit="px" size={90} color="#FB8501" loading />
      </div>
    </>
  )
}

export default Home
