import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { dotsSimpleFormatter } from '../../../utils/grid/formatters/decimal.formatter'

// eslint-disable-next-line react/prop-types
export default forwardRef((props, ref) => {
  const {
    api,
    rowIndex,
    color,
    colDef: { tooltipField }
  } = props || { colDef: {} }
  // eslint-disable-next-line react/prop-types

  const [data] = useState(api.getDisplayedRowAtIndex(rowIndex).data)

  const tooltipInfo = {
    categoria: 'categoriaTooltip',
    origem: 'origemTooltip',
    validade: 'validadeTooltip'
  }[tooltipField]

  // console.log(data[tooltipInfo])

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses() {
        return ['custom-tooltip']
      }
    }
  })

  return props.value ? (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: color || 'white' }}
    >
      {(data[tooltipInfo] || []).length ? (
        data[tooltipInfo].map((line) => (
          <p key={line.id}>
            {line.descricao} - <strong>{line.volume > 0 ? dotsSimpleFormatter(line.volume) : 0}</strong>
          </p>
        ))
      ) : (
        <p>Nenhuma informação disponível</p>
      )}
    </div>
  ) : (
    <></>
  )
})
