import React from 'react'

const DadosProduto = ({ data }) => {
  return (
    <>
      <ul className="ifefo-specs">
        <li className="ifefo-specs__item">
          <div className="ifefo-specs__label">Código</div>
          <div className="ifefo-specs__value">{data.sku}</div>
        </li>
        <li className="ifefo-specs__item">
          <div className="ifefo-specs__label">Nome</div>
          <div className="ifefo-specs__value">{data.produto}</div>
        </li>
        <li className="ifefo-specs__item">
          <div className="ifefo-specs__label">Categoria</div>
          <div className="ifefo-specs__value">{data.categoria}</div>
        </li>
        <li className="ifefo-specs__item">
          <div className="ifefo-specs__label">Marca</div>
          <div className="ifefo-specs__value">{data.marca}</div>
        </li>
        <li className="ifefo-specs__item">
          <div className="ifefo-specs__label">Medida</div>
          <div className="ifefo-specs__value">{data.medida}</div>
        </li>
        <li className="ifefo-specs__item">
          <div className="ifefo-specs__label">Peso caixa</div>
          <div className="ifefo-specs__value">{data.peso_caixa}</div>
        </li>
        <li className="ifefo-specs__item">
          <div className="ifefo-specs__label">Unid. por caixa</div>
          <div className="ifefo-specs__value">{data.qtd_itens_caixa}</div>
        </li>
      </ul>
    </>
  )
}

export default DadosProduto
