import React, { useState } from 'react'

import { editaCliente } from '../../../services/api'

export const StatusToggleCellRenderer = ({
  value,
  data,
  node,
  api,
  context
}) => {
  const [currentStatus, setCurrentStatus] = useState(value)
  const handleOnChange = (event) => {
    const isActive = event.target.checked
    setCurrentStatus(isActive ? 'ATIVO' : 'INATIVO')
    const statusComprador = isActive ? 1 : 2
    const { celular, email, responsavel } = data
    editaCliente(data.id_comprador, {
      celular,
      email,
      responsavel,
      status: statusComprador
    }).then((response) => {
      if ((response || {}).status === 'success') {
        // data.status = currentStatus
        // node.setData(data)
        node.setDataValue('status', currentStatus)
        api.refreshCells({
          rowNodes: [node]
        })
        context.componentParent.methods.reload()
        // api.applyTransaction({
        //   update: [data]
        // })
      }
    })
  }

  return (
    <label>
      <input
        name="status"
        type="checkbox"
        value="ATIVO"
        checked={currentStatus === 'ATIVO'}
        style={{ height: 'auto' }}
        onChange={handleOnChange}
      />{' '}
      {currentStatus}
    </label>
  )
}
