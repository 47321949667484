import React from 'react'
import { FaCheck, FaEdit, FaEye, FaInfo, FaKey, FaTimes } from 'react-icons/all'
import { FaLock as FaUserLock } from 'react-icons/fa'
import { ChangePass } from './image.renderer'

const ActionsRenderer = ({ context, node }) => {
  let { actions = [] } = (context || {}).componentParent || {}
  if (!Array.isArray(actions)) {
    actions = []
  }
  const currentPath = document.location.pathname
  console.log('force commit: ' + currentPath)

  const getIcon = (iconOrType) => {
    if (!iconOrType) {
      return <FaEdit />
    }
    if (typeof iconOrType !== 'string') {
      return iconOrType
    }
    switch (iconOrType) {
      case 'info':
        return <FaInfo />
      case 'preview':
        return <FaEye />
      case 'reject':
        return <FaTimes />
      case 'accept':
        return <FaCheck />
      case 'password':
        return <FaKey />
      case 'edit':
      default:
        return <FaEdit />
    }
  }

  return (
    <>
      {actions.map((action, index) => {
        return (
          action.hasOwnProperty('condition') ? action.condition(node) : true
        ) ? (
          <button
            title={action.tooltip || ''}
            type="button"
            key={action.id || index}
            style={{
              backgroundColor: action.color,
              fontSize: 16,
              lineHeight: '18px',
              margin: '0 4px 0 0',
              padding: '3px 6px',
              height: '24px',
              minHeight: '24px'
            }}
            onClick={(event) => {
              event.preventDefault()
              action.callback(node)
            }}
            className="btn btn-info"
          >
            {getIcon(action.type || action.icon)}
            {action.label ? <> {action.label}</> : ''}
          </button>
        ) : (
          ''
        )
      })}
      {currentPath === '/clientes' && (
        <button
          title={''}
          type="button"
          style={{
            backgroundColor: '#f77702',
            fontSize: 16,
            lineHeight: '18px',
            margin: '0 4px 0 0',
            padding: '3px 6px',
            height: '24px',
            minHeight: '24px'
          }}
          onClick={(event) => {
            event.preventDefault()
            ChangePass(node.data.id_comprador)
          }}
          className="btn btn-info"
        >
          <FaUserLock />
        </button>
      )}
    </>
  )
}

export default ActionsRenderer
