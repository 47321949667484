import React, { Component } from 'react'
import Switch from 'react-switch'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Swal from 'sweetalert'
import swal from 'sweetalert'
import * as _ from 'lodash'
import {
  dispararEngajamento,
  getCdsEngajamentos,
  getCNPJS,
  getListaBaseEngajamentos,
  getRascunho,
  getRepeticoesEngajamentos,
  salvaRascunho
} from '../../services/api'
import {
  MdArrowBack,
  MdArrowForward,
  MdCheck,
  MdChevronRight,
  MdErrorOutline,
  MdExpandLess,
  MdExpandMore
} from 'react-icons/md'
import { FaBell, FaSms, FaWhatsapp } from 'react-icons/fa'
import { Link, withRouter } from 'react-router-dom'
import { Combobox, Multiselect } from 'react-widgets'
import 'react-datepicker/dist/react-datepicker.css'
import '../../assets/css/multi-select-style.css'
import '../../assets/css/search-select.css'
import './style.css'
import moment from 'moment'
import Arrow from '../../img/chat/arrow.png'
import AttachFile from '../../img/chat/attach-file.png'
import Camera from '../../img/chat/camera.png'
import Check2 from '../../img/chat/check-2.png'
import Emo from '../../img/chat/emo.png'
import More from '../../img/chat/more.png'
import Mic from '../../img/chat/mic.png'
import Phone from '../../img/chat/phone.png'
import Video from '../../img/chat/video.png'
import Logo from '../../img/chat/logo.jpg'
import PP from '../../img/chat/pp.png'
import Footer from '../../components/Footer'
import { ClipLoader } from 'react-spinners'
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'rc-time-picker/assets/index.css'
import ptBr from 'date-fns/locale/pt-BR'
import TimePicker from 'rc-time-picker'

registerLocale('pt-BR', ptBr)

const href = '#'

class NovoEngajamento extends Component {
  state = {
    on: false,
    label: '',
    hashId: '',
    stepper: '1',
    listas: [],
    repeticoes: [],
    cds: [],
    cnpjs: [],
    alcance: 0,
    showDetails: false,
    loader: false,
    editando: false,
    verificaCheck: false,
    hoje: new Date(),
    debounce: null,
    checked: '',
    prevEngagement: '',
    showEmojisTitle: false,
    showEmojisBody: false,
    step1: {
      ok: '',
      titulo: '',
      frase: '',
      fl_whats: 0,
      fl_sms: 0,
      fl_push: 0,
      titleStart: 0,
      bodyStart: 0,
      titleLimit: 35,
      bodyLimit: 100,
      data_inicio: new Date(),
      hora_inicio: moment(),
      repeticao: '',
      link: 1,
      dt_imediato: true,
      hr_inicio: false,
      repetir: false,
      programado: false
    },
    step2: {
      ok: '',
      clientes: [],
      listas: [],
      cds: []
    },
    lastE: null
  }

  constructor(props) {
    super(props)
    this.setState({
      on: false
    })
    this.debounce = _.debounce(() => {
      // this.sendDataAfterModify()
    }, 1000)
    this.ref = React.createRef()
  }

  /** start new fncs step 1 */
  /**
   *
   * new functions
   */

  setCountListCds(e) {
    try {
      const sum = e.reduce((a, b) => a + b.qtde, 0)
      this.setState({ alcance: sum })
    } catch (e) {
      this.setState({ alcance: 0 })
    }
  }

  async setCountListCnpjs(e) {
    try {
      const sum = e.reduce((a, b) => a + b.qtde_registros, 0)
      this.setState({ alcance: sum })
    } catch (e) {
      this.setState({ alcance: 0 })
    }
  }

  changeValueDate = async (date) => {
    this.setState({ step1: { ...this.state.step1, data_inicio: date } })
  }

  changeValueHour = async (date) => {
    this.setState({ step1: { ...this.state.step1, hora_inicio: date } })
  }

  showEmojisTitle = (e) => {
    this.setState({
      showEmojisTitle: !this.state.showEmojisTitle
    })
  }

  showEmojisBody = (e) => {
    this.setState({
      showEmojisBody: !this.state.showEmojisBody
    })
  }

  addEmojiTitle = (e) => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach((el) => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    this.setState({
      step1: { ...this.state.step1, titulo: this.state.step1.titulo + emoji }
    })
  }

  addEmojiBody = (e) => {
    let sym = e.unified.split('-')
    let codesArray = []
    sym.forEach((el) => codesArray.push('0x' + el))
    let emoji = String.fromCodePoint(...codesArray)
    this.setState({
      step1: { ...this.state.step1, frase: this.state.step1.frase + emoji }
    })
  }

  handleSelectForms = () => {
    let sum = 0
    let current = !this.state.on
    if (current) {
      this.state.step2.cds.forEach((v) => {
        sum += v.qtde
      })
    } else {
    }
    this.setState({
      on: current,
      alcance: sum,
      step2: {
        ...this.state.step2,
        listas: !current ? this.state.step2.listas : [],
        cds: current ? this.state.step2.cds : []
      }
    })
  }

  callLancaCampanha = () => {
    Swal({
      icon: 'warning',
      title: 'Tem certeza?',
      text:
        'Deseja realmente lançar o engajamento? Uma vez lançado os disparos não poderão ser cancelados.',
      buttons: {
        cancel: {
          text: 'Cancelar',
          value: null,
          visible: true,
          className: '',
          closeModal: true
        },
        confirm: {
          text: 'Lançar Engajamento',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      }
    }).then((value) => {
      if (value) {
        this.lancaCampanha()
      }
    })
  }

  async componentDidMount() {
    await this.getListas()
    await this.getRepeticoesEngajamentos()
    await this.getCdsEngajamentos()

    const impulsionamento = window.location.pathname.split('/')

    if (impulsionamento[3] !== undefined && impulsionamento[3] !== '') {
      this.setState({ loader: true, hashId: impulsionamento[3] })
      const imp = await getRascunho(impulsionamento[3])
      if (imp.status === 'error')
        Swal({ icon: 'error', text: imp.msg }).then(() =>
          this.props.history.push('/impulsionamento')
        )
      /////////////////////////////////////
      await this.setState({
        step2: {
          listas: imp.msg.listas,
          cds: imp.msg.cds
        },
        step1: {
          ok: '',
          fl_whats: imp.msg.rasc[0].fl_whats,
          fl_sms: imp.msg.rasc[0].fl_sms,
          fl_push: imp.msg.rasc[0].fl_push,
          titulo: imp.msg.rasc[0].titulo,
          frase: imp.msg.rasc[0].frase,
          data_inicio: imp.msg.rasc[0].data_inicio,
          hora_inicio: imp.msg.rasc[0].hora_inicio,
          repeticao: imp.msg.rasc[0].repeticao
        }
      })
      this.setState({ loader: false })
    }
  }

  getListas = async () => {
    try {
      const listas = await getListaBaseEngajamentos()
      if (listas.token === null) {
        return
      }
      if (listas.status === 'error') throw new Error(listas.msg)
      await this.setState({ listas: listas.msg })
    } catch (e) {
      await Swal({ icon: 'info', text: e.message })
    }
  }

  getRepeticoesEngajamentos = async () => {
    try {
      const repeticoes = await getRepeticoesEngajamentos()
      if (repeticoes.token === null) return
      if (repeticoes.status === 'error') throw new Error(repeticoes.msg)
      await this.setState({ repeticoes: repeticoes.msg })
    } catch (e) {
      await Swal({ icon: 'info', text: e.message })
    }
  }

  getCdsEngajamentos = async () => {
    try {
      const cds = await getCdsEngajamentos()
      if (cds.token === null) return
      if (cds.status === 'error') throw new Error(cds.msg)
      await this.setState({ cds: cds.msg })
    } catch (e) {
      await Swal({ icon: 'info', text: e.message })
    }
  }

  toggleDetails = () => this.setState({ showDetails: !this.state.showDetails })

  toggleCanal = async (btn) => {
    await this.setState({
      step1: { ...this.state.step1, [btn]: this.state.step1[btn] === 1 ? 0 : 1 }
    })
  }

  handleMultiSelect = async (e, step, nome) => {
    await this.setState({
      [step]: {
        ...this.state[step],
        [nome]: Array.from(e, (item) => item.id_centro_distribuicao)
      }
    })
    if (step === 'step2') this.debounce()
  }

  handleMultiSelectListas = async (e, step, nome) => {
    await this.setState({
      [step]: {
        ...this.state[step],
        [nome]: Array.from(e, (item) => item.id)
      }
    })
    if (step === 'step2') this.debounce()
  }

  toggleStep = async (s, orig = null) => {
    switch (s) {
      // Informações
      case '1':
        this.setState({ stepper: '1', step1: { ...this.state.step1, ok: '' } })
        break

      // Destinatários
      case '2':
        await this.setState({
          step2: { ...this.state.step2, ok: '' },
          loader: true
        })
        try {
          const { fl_whats, fl_push, fl_sms, titulo, frase } = this.state.step1

          if (fl_whats === 0 && fl_push === 0 && fl_sms === 0)
            throw new Error(
              'Por favor selecione ao menos um canal para veiculação.'
            )
          if (titulo === '')
            throw new Error('Por favor informe o título da campanha.')

          if (frase === '')
            throw new Error('Por favor informe o corpo da campanha.')

          await this.setState({
            step1: { ...this.state.step1, ok: true },
            stepper: '2',
            loader: false
          })
        } catch (e) {
          this.setState({
            step1: { ...this.state.step1, ok: false },
            stepper: '1',
            loader: false
          })

          await Swal({
            text: e.message,
            icon: 'info'
          })
        }
        break
      default:
        break
    }
  }

  handleCheck = async () => {
    this.setState({ verificaCheck: !this.state.verificaCheck })
    await this.handleFiltros()
  }

  toggleLabel = (label) => this.setState({ label })

  getStep1 = () => {
    const { ok } = this.state.step1

    if (ok === '') return <span className="stepper-icon">1</span>

    if (ok === true)
      return (
        <span className="stepper-icon success">
          <MdCheck />
        </span>
      )

    if (ok === false)
      return (
        <span className="stepper-icon error">
          <MdErrorOutline />
        </span>
      )
  }

  getStep2 = () => {
    const { ok } = this.state.step2

    if (ok === '') return <span className="stepper-icon">2</span>

    if (ok === true)
      return (
        <span className="stepper-icon success">
          <MdCheck />
        </span>
      )

    if (ok === false)
      return (
        <span className="stepper-icon error">
          <MdErrorOutline />
        </span>
      )
  }

  lancaCampanha = async () => {
    this.setState({ loader: true })

    const {
      fl_whats,
      fl_sms,
      fl_push,
      titulo,
      frase,
      repeticao,
      data_inicio,
      hora_inicio,
      link
    } = this.state.step1
    const { cds, listas } = this.state.step2
    const { hoje, hashId } = this.state

    try {
      const id_distribuidor = localStorage.getItem('id_distribuidor')

      let formData = new FormData()
      if (hashId !== '') {
        formData.append('hashId', hashId)
      }

      let repeat = repeticao
      let date = moment(data_inicio).format('YYYY-MM-DD')
      let start = hora_inicio.format('HH:mm')

      const payload = {
        push: fl_push,
        sms: fl_sms,
        whats: fl_whats,
        titulo,
        frase,
        link,
        data_inicio: date,
        hora_inicio: start,
        repeticao: repeat,
        cds,
        listas
      }
      const dispara = await dispararEngajamento(payload)

      if (dispara.token === null) {
        return
      }

      if (dispara.status === 'error') throw new Error(dispara.msg)

      this.setState({ loader: false })

      Swal({
        icon: 'success',
        text: dispara.msg,
        buttons: {
          confirm: {
            value: true,
            text: 'Ok',
            closeModal: true,
            visible: true
          }
        }
      }).then((v) => {
        if (v) {
          this.props.history.push('/engajamento')
        }
      })
    } catch (e) {
      this.setState({ loader: false })

      await Swal({
        icon: 'info',
        text: e.message
      })
    }
  }

  salvarRascunho = async () => {
    this.setState({ loader: true })
    const {
      fl_whats,
      fl_sms,
      fl_push,
      titulo,
      frase,
      repeticao,
      data_inicio,
      hora_inicio,
      link
    } = this.state.step1
    const { cds, listas } = this.state.step2
    const { alcance, hashId } = this.state

    let body = {}
    if (hashId !== '') {
      body = {
        hashId,
        fl_whats,
        fl_sms,
        fl_push,
        titulo,
        frase,
        data_inicio,
        hora_inicio,
        link,
        alcance,
        cds: cds,
        listas: listas
      }
    } else {
      body = {
        fl_whats,
        fl_sms,
        fl_push,
        titulo,
        frase,
        data_inicio,
        hora_inicio,
        link,
        alcance,
        cds: cds,
        listas: listas
      }
    }

    try {
      const salva = await salvaRascunho(body)

      if (salva.token === null) {
        return
      }

      if (salva.status === 'error') throw new Error(salva.msg)
      this.setState({ loader: false })
      Swal({ icon: 'success', text: salva.msg }).then(() => {
        this.props.history.push('/impulsionamento')
      })
    } catch (e) {
      this.setState({ loader: false })
      await Swal({ icon: 'warning', text: e.message })
    }
  }

  changeAction = (e) => {
    this.setState({
      prevEngagement: e
    })
  }

  /** new functions */
  onEnabledRepeticao = () => {
    let action = false
    if (!this.state.step1.repetir) action = true
    this.setState({
      step1: {
        ...this.state.step1,
        repetir: action,
        dt_imediato: false,
        repeticao: ''
      }
    })
  }

  getStep1Title = () => {
    return this.state.step1.titulo.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
  }

  onEnabledImediato = () => {
    let action = false
    if (!this.state.step1.dt_imediato) action = true
    this.setState({
      step1: {
        ...this.state.step1,
        repetir: action !== true,
        hr_inicio: action !== true,
        dt_imediato: action,
        repeticao: action === true ? '' : this.state.step1.repeticao
      }
    })
  }

  render() {
    const { mail, fl_whats, fl_sms, fl_push } = this.state.step1
    const step = this.state.stepper
    const step1 = this.getStep1()
    const step2 = this.getStep2()

    return (
      <>
        <Menu menuDrop="26" />
        <Header />
        <div className="container">
          <div className="breadcrumb">
            <div>
              <h3>ENGAJAMENTOS</h3>
              <p>
                Engajamento{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                <Link to="/engajamentos" className="text-white link">
                  Novo Engajamento
                </Link>{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                Criar
              </p>
            </div>
            <span> </span>
          </div>
          <div className="row">
            <div className="col-7">
              <div className="box">
                <div className="box-title">
                  <h4>Novo Engajamento</h4>
                  <span> </span>
                </div>
                <div className="box-content">
                  <div className="stepper-box">
                    <div className="stepper-title">
                      <h5>{step1}Informações</h5>
                    </div>
                    <div
                      className={
                        step === '1' ? 'stepper-item' : 'stepper-item d-none'
                      }
                    >
                      <div className="form-group stepper-form">
                        <div className="form-item">
                          <label>Canais</label>
                          <div className="box-item">
                            <div
                              onClick={() => this.toggleCanal('fl_whats')}
                              className={
                                fl_whats === 1 ? 'item active' : 'item'
                              }
                            >
                              <span>
                                <FaWhatsapp />
                              </span>
                              <p>WhatsApp</p>
                            </div>
                            <div
                              className={fl_push === 1 ? 'item active' : 'item'}
                              onClick={() => this.toggleCanal('fl_push')}
                            >
                              <span>
                                <FaBell />
                              </span>
                              <p>Push</p>
                            </div>
                            <div
                              className={fl_sms === 1 ? 'item active' : 'item'}
                              onClick={() => this.toggleCanal('fl_sms')}
                            >
                              <span>
                                <FaSms />
                              </span>
                              <p>SMS</p>
                            </div>
                          </div>
                        </div>
                        <div className="form-item">
                          <label
                            onClick={() => {
                              this.toggleLabel('1')
                            }}
                            className={
                              this.state.step1.titulo !== '' ||
                              this.state.label === '1'
                                ? 'input-title active placeholder-title-active'
                                : 'input-title'
                            }
                          >
                            Título do engajamento *{' '}
                            <span className="text-placeholder">
                              Máximo de 35 caracteres permitidos{' '}
                            </span>
                            <span className="d-none-not-important text-placeholder placeholder-limit-title">
                              - {this.state.step1.titleStart} /{' '}
                              {this.state.step1.titleLimit} (restantes)
                            </span>
                          </label>
                          <input
                            value={this.state.step1.titulo}
                            onFocus={() => this.toggleLabel('1')}
                            onBlur={() => this.toggleLabel('')}
                            type="text"
                            className={
                              this.state.step1.titulo === ''
                                ? 'form-control bold'
                                : 'form-control check bold'
                            }
                            step="step1"
                            onChange={(e) => {
                              this.setState({
                                step1: {
                                  ...this.state.step1,
                                  titulo: e.target.value,
                                  titleStart: e.target.value.length,
                                  titleLimit: 35 - e.target.value.length
                                }
                              })
                            }}
                            maxLength="35"
                            name="titulo"
                          />
                          <div
                            className=""
                            style={{
                              marginTop: '-35px',
                              position: 'absolute',
                              right: '45%'
                            }}
                          >
                            {this.state.showEmojisTitle && (
                              <div
                                style={{
                                  position: 'absolute',
                                  bottom: 10,
                                  marginLeft: '35px',
                                  marginTop: '5%',
                                  zIndex: 999
                                }}
                                ref={(el) => (this.emojiPicker = el)}
                              >
                                <Picker
                                  onSelect={this.addEmojiTitle}
                                  emojiTooltip={true}
                                  title="😀"
                                />
                              </div>
                            )}
                            <p
                              style={{
                                cssFloat: 'right',
                                border: 'none',
                                marginTop: 0,
                                cursor: 'pointer'
                              }}
                              onClick={this.showEmojisTitle}
                            >
                              {String.fromCodePoint(0x1f60a)}
                            </p>
                          </div>
                        </div>
                        <div className="form-item">
                          <label
                            onClick={() => this.toggleLabel('2')}
                            className={
                              this.state.step1.frase !== '' ||
                              this.state.label === '2'
                                ? 'input-title active placeholder-title-active'
                                : 'input-title'
                            }
                          >
                            Frase chamativa para cliente *
                            <span className="text-placeholder">
                              Máximo de 100 caracteres permitidos{' '}
                            </span>
                            <span className="d-none-not-important text-placeholder placeholder-limit-title">
                              - {this.state.step1.bodyStart} /{' '}
                              {this.state.step1.bodyLimit} (restantes)
                            </span>
                          </label>
                          <input
                            value={this.state.step1.frase}
                            onFocus={() => this.toggleLabel('2')}
                            onBlur={() => this.toggleLabel('')}
                            type="text"
                            className={
                              this.state.step1.frase === ''
                                ? 'form-control'
                                : 'form-control check'
                            }
                            maxLength="100"
                            step="step1"
                            onChange={(e) =>
                              this.setState({
                                step1: {
                                  ...this.state.step1,
                                  frase: e.target.value,
                                  bodyStart: e.target.value.length,
                                  bodyLimit: 100 - e.target.value.length
                                }
                              })
                            }
                            name="frase"
                          />
                          <div
                            className=""
                            style={{
                              marginTop: '-35px',
                              position: 'absolute',
                              right: '45%'
                            }}
                          >
                            {this.state.showEmojisBody && (
                              <div
                                style={{
                                  position: 'absolute',
                                  bottom: 10,
                                  marginLeft: '35px',
                                  marginTop: '5%',
                                  zIndex: 999
                                }}
                                ref={(el) => (this.emojiPicker = el)}
                              >
                                <Picker
                                  onSelect={this.addEmojiBody}
                                  emojiTooltip={true}
                                  title="😀"
                                />
                              </div>
                            )}
                            <p
                              style={{
                                cssFloat: 'right',
                                border: 'none',
                                marginTop: 0,
                                cursor: 'pointer'
                              }}
                              onClick={this.showEmojisBody}
                            >
                              {String.fromCodePoint(0x1f60a)}
                            </p>
                          </div>
                        </div>

                        {/* start select link */}
                        <div className="col-12 pl-5 pb-0">
                          <label>
                            {' '}
                            <span className="font-size-0-85em">
                              Selecione o link:
                            </span>
                          </label>
                        </div>
                        <div className="col-4 pl-5 adjust-radio">
                          <div className="radio-group">
                            <span
                              className={
                                this.state.step1.link === 1
                                  ? 'rdio checked'
                                  : 'rdio'
                              }
                              onClick={(e) =>
                                this.setState({
                                  step1: {
                                    ...this.state.step1,
                                    link: 1
                                  }
                                })
                              }
                            ></span>
                            <label className="check-label">
                              WhatsApp Suporte
                            </label>
                          </div>
                        </div>
                        <div className="col-4 adjust-radio">
                          <div className="radio-group">
                            <span
                              className={
                                this.state.step1.link === 2
                                  ? 'rdio checked'
                                  : 'rdio'
                              }
                              onClick={(e) =>
                                this.setState({
                                  step1: {
                                    ...this.state.step1,
                                    link: 2
                                  }
                                })
                              }
                            ></span>
                            <label className="check-label">Loja ifefo</label>
                          </div>
                        </div>
                        <div className="col-4 adjust-radio">
                          <div className="radio-group">
                            <span
                              className={
                                this.state.step1.link === 3
                                  ? 'rdio checked'
                                  : 'rdio'
                              }
                              onClick={(e) =>
                                this.setState({
                                  step1: {
                                    ...this.state.step1,
                                    link: 3
                                  }
                                })
                              }
                            ></span>
                            <label className="check-label">Nenhum</label>
                          </div>
                        </div>
                        {/* end select link */}

                        {/* start date and hour dispatch */}
                        <div className="col-12 pl-5 pb-0">
                          <label>
                            {' '}
                            <span className="font-size-0-85em">
                              Selecione a data e hora de envio:
                            </span>
                          </label>
                        </div>

                        <div
                          className="col-4 pl-5 adjust-radio"
                          style={{ marginBottom: '-25px' }}
                        >
                          <div className="radio-group">
                            <span
                              className={
                                this.state.step1.dt_imediato
                                  ? 'rdio checked'
                                  : 'rdio'
                              }
                              onClick={this.onEnabledImediato}
                            ></span>
                            <label className="check-label">Imediato</label>
                          </div>
                        </div>
                        <div className="col-4 adjust-radio">
                          <div className="radio-group">
                            <span
                              className={
                                this.state.step1.hr_inicio
                                  ? 'rdio checked'
                                  : 'rdio'
                              }
                              onClick={() =>
                                this.setState({
                                  step1: {
                                    ...this.state.step1,
                                    hr_inicio: true,
                                    dt_imediato: false
                                  }
                                })
                              }
                            ></span>
                            <label className="check-label">Programado</label>
                          </div>
                        </div>
                        <div className="col-4 adjust-radio">
                          <div className="radio-group flex-align-center">
                            <input
                              type="checkbox"
                              className={
                                this.state.step1.repetir === true
                                  ? 'rdio checked'
                                  : 'rdio'
                              }
                              checked={
                                this.state.step1.repetir &&
                                !this.state.step1.dt_imediato
                              }
                              onClick={this.onEnabledRepeticao}
                              disabled={!this.state.step1.hr_inicio}
                            />
                            <label className="check-label">Repetição</label>
                          </div>
                        </div>
                        {/* end date and hour dispatch */}

                        {/* repetitions */}
                        <div className="col-12 pl-5 pb-0 adjust-column-repetitions">
                          <div className="col-4">
                            <div className="form-item">
                              <div className="row">
                                <div
                                  className="col-3"
                                  style={{ marginTop: '8px' }}
                                >
                                  <label>
                                    {' '}
                                    <span className="font-size-0-85em">
                                      Data:
                                    </span>
                                  </label>
                                </div>
                                <div className="col-9">
                                  <DatePicker
                                    selected={this.state.step1.data_inicio}
                                    dateFormat="dd/MM/yyyy"
                                    timeCaption="time"
                                    onChange={this.changeValueDate}
                                    className={
                                      (this.state.step1.dt_imediato === true
                                        ? ' background-grey '
                                        : '') +
                                      (!this.state.step1.data_inicio
                                        ? 'form-control'
                                        : 'form-control check')
                                    }
                                    name="data_inicio"
                                    disabled={this.state.step1.dt_imediato}
                                    locale={ptBr}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-item">
                              <div className="row">
                                <div
                                  className="col-3"
                                  style={{ marginTop: '8px' }}
                                >
                                  <label>
                                    {' '}
                                    <span className="font-size-0-85em">
                                      Hora:
                                    </span>
                                  </label>
                                </div>
                                <div className="col-9">
                                  <TimePicker
                                    className="form-control"
                                    onChange={this.changeValueHour}
                                    defaultValue={moment()}
                                    value={this.state.step1.hora_inicio}
                                    showSecond={false}
                                    inputReadOnly={false}
                                    name="hora_inicio"
                                    disabled={
                                      this.state.step1.dt_imediato === true
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-4">
                            <div className="form-item">
                              <div className="row">
                                <div className="col-12">
                                  <Combobox
                                    disabled={!this.state.step1.repetir}
                                    name="repeticao"
                                    className={
                                      !this.state.step1.repetir
                                        ? ' background-grey '
                                        : ''
                                    }
                                    data={this.state.repeticoes}
                                    valueField="id_tbl_tipo_repeticao"
                                    value={this.state.step1.repeticao}
                                    textField="tipo_repitcao"
                                    onChange={(value) => {
                                      this.setState({
                                        step1: {
                                          ...this.state.step1,
                                          repeticao: value.id_tbl_tipo_repeticao
                                        }
                                      })
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end repetitions */}

                        <div className="form-footer">
                          <button
                            className="btn"
                            onClick={() => {
                              this.toggleStep('2')
                            }}
                          >
                            Seguinte{' '}
                            <span>
                              <MdArrowForward />
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="stepper-title">
                      <h5>{step2} Destinatário</h5>
                    </div>

                    <div
                      className={
                        step === '2' ? 'stepper-item' : 'stepper-item d-none'
                      }
                    >
                      {/* step 2 - new design */}
                      <div className="form-group stepper-form" id="step-2">
                        <div className="row pt-0 pb-0">
                          <div
                            className="col-12 text-right pt-0 pb-0"
                            id="main-base-input-switch mb--20"
                          >
                            <Switch
                              checked={this.state.on}
                              onChange={this.handleSelectForms}
                              width={175}
                              offColor="#fb8501"
                              onColor="#109C33"
                              uncheckedIcon={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: 'white',
                                    paddingRight: 2
                                  }}
                                >
                                  Lista CNPJs
                                </div>
                              }
                              checkedIcon={
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: 'white',
                                    paddingRight: 2
                                  }}
                                >
                                  CDs cadastrados
                                </div>
                              }
                            />
                          </div>
                        </div>

                        <div
                          className="row adjust-spacement show-design-form"
                          id=""
                        >
                          <div className="">
                            <label
                              style={{
                                fontSize: '0.85em',
                                fontWeight: '500',
                                paddingLeft: '20px'
                              }}
                            >
                              Escolha: Envio por CD ou lista de CNPJ
                            </label>
                          </div>
                        </div>

                        {/* step 2 - new design files */}
                        {this.state.on && (
                          <div className="row pt-0 pb-0">
                            <div className="col-12 pt-0 pb-0">
                              <div className="row">
                                <div className="col-12 pt-0 pb-0">
                                  <div className="form-item">
                                    <label>CDs Cadastrados</label>
                                    {this.state.cds.length > 0 && (
                                      <Multiselect
                                        data={this.state.cds}
                                        minLength={1}
                                        valueField="id_centro_distribuicao"
                                        textField="cd"
                                        filter="contains"
                                        className={
                                          this.state.step2.cds.length === 0
                                            ? ''
                                            : 'check'
                                        }
                                        placeholder="Selecione a lista de CDs"
                                        onChange={(e) => {
                                          this.handleMultiSelect(
                                            e,
                                            'step2',
                                            'cds'
                                          )
                                          this.setCountListCds(e)
                                        }}
                                        defaultValue={this.state.step2.cds}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {/* step 2 - new design form */}
                        {!this.state.on && (
                          <>
                            <div className="row pt-0 pb-0">
                              <div className="col-12 pt-0 pb-0">
                                <div className="row">
                                  <div className="col-12 pt-0 pb-0">
                                    <div className="form-item">
                                      <label>Lista CNPJs</label>
                                      {this.state.listas.length > 0 && (
                                        <Multiselect
                                          data={this.state.listas}
                                          minLength={1}
                                          valueField="id"
                                          textField="lista"
                                          filter="contains"
                                          className={
                                            this.state.step2.listas.length === 0
                                              ? ''
                                              : 'check'
                                          }
                                          placeholder="Selecione a lista de CNPJs"
                                          onChange={async (e) => {
                                            await this.handleMultiSelectListas(
                                              e,
                                              'step2',
                                              'listas'
                                            )
                                            await this.setCountListCnpjs(e)
                                          }}
                                          defaultValue={this.state.step2.listas}
                                        />
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                        {/* end step 2 form */}

                        <div className="form-footer">
                          <button
                            className="btn btn-red"
                            name="cancelar"
                            onClick={() => {
                              Swal({
                                icon: 'warning',
                                title: 'Tem certeza?',
                                text:
                                  'Se optar por cancelar a campanha, todos os dados serão perdidos. Se preferir salve um rascunho antes.',
                                buttons: {
                                  confirm: {
                                    text: 'Sim',
                                    value: true,
                                    visible: true,
                                    closeModal: true
                                  },
                                  cancel: {
                                    text: 'Não',
                                    value: false,
                                    visible: true,
                                    closeModal: true
                                  }
                                }
                              }).then((value) => {
                                if (value) {
                                  this.props.history.push('/engajamento')
                                }
                              })
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn"
                            onClick={() => {
                              this.toggleStep('1')
                            }}
                          >
                            <span>
                              <MdArrowBack />
                            </span>{' '}
                            Passo 1
                          </button>
                          <button
                            className="btn btn-green"
                            name="lancar"
                            onClick={() => this.callLancaCampanha()}
                          >
                            Lançar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="box">
                <div className="box-title">
                  <h4 className="prev-h4">Prévia da Engajamento</h4>
                  <span className="prev-engagement-span">
                    <div className="row prev-engagement">
                      <div className="col-4 adjust-radio align-min-row">
                        <div className="radio-group">
                          <span
                            className={
                              this.state.prevEngagement === 'prevWhats'
                                ? 'rdio checked'
                                : 'rdio'
                            }
                            onClick={() => this.changeAction('prevWhats')}
                          ></span>
                          <label className="check-label">WhatsApp</label>
                        </div>
                      </div>
                      <div className="col-4 adjust-radio align-min-row">
                        <div className="radio-group">
                          <span
                            className={
                              this.state.prevEngagement === 'prevPush'
                                ? 'rdio checked'
                                : 'rdio'
                            }
                            onClick={() => this.changeAction('prevPush')}
                          ></span>
                          <label className="check-label">Push</label>
                        </div>
                      </div>
                      <div className="col-4 adjust-radio align-min-row">
                        <div className="radio-group">
                          <span
                            className={
                              this.state.prevEngagement === 'prevSMS'
                                ? 'rdio checked'
                                : 'rdio'
                            }
                            onClick={() => this.changeAction('prevSMS')}
                          ></span>
                          <label className="check-label">SMS</label>
                        </div>
                      </div>
                    </div>
                  </span>
                </div>
                <div className="box-content">
                  <div className="form-group">
                    <div className="form-item">
                      <div className="">
                        {this.state.prevEngagement === '' && (
                          <div className="container-phone-null">
                            <p>
                              Para visualizar a prévia selecione um dos canais
                              acima
                            </p>
                          </div>
                        )}

                        {this.state.prevEngagement === 'prevWhats' && (
                          <div className="container-phone">
                            <div className="phone">
                              <div className="screen">
                                <div className="base-phone">
                                  <div className="chat">
                                    <div className="chat-header">
                                      <div className="profile">
                                        <div className="left">
                                          <img
                                            src={Arrow}
                                            className="arrow"
                                            alt=""
                                          />
                                          <img src={PP} className="pp" alt="" />
                                          <h2>Ifefo</h2>
                                          <span>online</span>
                                        </div>
                                        <div className="right">
                                          <img
                                            src={Video}
                                            className="icon"
                                            alt=""
                                          />
                                          <img
                                            src={Phone}
                                            className="icon"
                                            alt=""
                                          />
                                          <img
                                            src={More}
                                            className="icon"
                                            alt=""
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="chat-box">
                                      {this.state.step1.titulo !== '' && (
                                        <div className="chat-r">
                                          <div className="sp"></div>
                                          <div className="mess mess-r">
                                            {this.state.step1.link !== 3 && (
                                              <div className="header-whatsapp-message">
                                                <div className="header-whatsapp-message-img">
                                                  <img src={Logo} alt="" />
                                                </div>
                                                <div className="header-whatsapp-content">
                                                  <p className="header-whatsapp-content-p">
                                                    Ifefo
                                                  </p>
                                                  <p className="header-whatsapp-content-p-big">
                                                    Conta comercial
                                                  </p>
                                                  <p className="header-whatsapp-content-p">
                                                    api.whatsapp.com
                                                  </p>
                                                </div>
                                              </div>
                                            )}
                                            <p className="mess-title">
                                              <span className="title">
                                                {this.state.step1.titulo}
                                              </span>
                                              <br />
                                              <span className="frase">
                                                {this.state.step1.frase}
                                              </span>
                                            </p>
                                            {this.state.step1.link !== 3 && (
                                              <div className="footer-whatsapp-message-link">
                                                {this.state.step1.link === 2 ? (
                                                  <span>
                                                    https://loja.ifefo.com.br
                                                  </span>
                                                ) : (
                                                  <span>
                                                    https://wa.me/5511937515266?text=
                                                    {this.getStep1Title()}
                                                  </span>
                                                )}
                                              </div>
                                            )}
                                            <div className="check-base">
                                              <span className="check-base-span">
                                                16:00hs
                                              </span>
                                              <img src={Check2} alt="" />
                                            </div>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    <div className="chat-footer">
                                      <img src={Emo} className="emo" alt="" />
                                      <textarea
                                        placeholder="Mensagem"
                                        disabled
                                      ></textarea>
                                      <div className="icons">
                                        <img src={AttachFile} alt="" />
                                        <img src={Camera} alt="" />
                                      </div>
                                      <img src={Mic} className="mic" alt="" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.prevEngagement === 'prevPush' && (
                          <div className="container-phone">
                            <div className="phone">
                              <div className="screen-push">
                                <div id="dialog-container-push">
                                  <div className="dialog-box-push">
                                    <div className="background-blur-push"></div>
                                    <div className="header-push">
                                      <div className="background-blur-push"></div>
                                      <div className="contents-push">
                                        <div className="left-push">
                                          <i className="far fa-comment"></i>
                                          <b>IFefo</b>
                                        </div>
                                        <div className="right-push">
                                          Seg 13:30
                                        </div>
                                      </div>
                                    </div>
                                    <div className="contents-push main-content-push">
                                      {this.state.step1.titulo.length > 0 ? (
                                        <span>
                                          {this.state.step1.titulo} <br />
                                          {this.state.step1.frase}
                                        </span>
                                      ) : (
                                        <span>Nenhum preview disponível</span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {this.state.prevEngagement === 'prevSMS' && (
                          <div className="container-phone">
                            <div className="phone">
                              <div className="screen">
                                <div id="content">
                                  {this.state.step1.titulo.length > 0 ? (
                                    <div className="chat-sms">
                                      <div className="sms-mine sms-messages">
                                        <div className="sms-message sms-last">
                                          <p>{this.state.step1.titulo}</p>
                                          <p>{this.state.step1.frase}</p>
                                          {this.state.step1.link !== 3 && (
                                            <div
                                              className="footer-whatsapp-message-link"
                                              style={{
                                                marginLeft: '5px',
                                                color: '#ffffff'
                                              }}
                                            >
                                              {this.state.step1.link === 2 ? (
                                                <span>
                                                  https://loja.ifefo.com.br
                                                </span>
                                              ) : (
                                                <span>
                                                  https://wa.me/5511937515266?text=
                                                  {this.getStep1Title()}
                                                </span>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="chat-sms">
                                      <div className="sms-mine sms-messages">
                                        <div className="sms-message sms-last">
                                          <p>Nenhum preview disponível</p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="content-prev">
                        <div className="form-item">
                          <h4>
                            {this.state.step1.titulo === ''
                              ? 'Título'
                              : this.state.step1.titulo}
                          </h4>
                          <p className="p-inline">
                            {this.state.step1.frase === ''
                              ? 'Frase'
                              : this.state.step1.frase}
                          </p>
                          <p></p>
                          <p>
                            <strong>Alcance real do engajamento: </strong>{' '}
                            {this.state.alcance !== undefined ? (
                              <span>{this.state.alcance} Clientes</span>
                            ) : (
                              '0'
                            )}
                          </p>
                        </div>
                        {this.state.showDetails ? (
                          <p
                            className="link text-right"
                            onClick={() => this.toggleDetails()}
                          >
                            Ver menos{' '}
                            <span>
                              <MdExpandLess />
                            </span>
                          </p>
                        ) : (
                          <p
                            className="link text-right"
                            onClick={() => this.toggleDetails()}
                          >
                            Ver mais{' '}
                            <span>
                              <MdExpandMore />
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                    <div className={this.state.showDetails ? '' : 'd-none'}>
                      <div className="form-item">
                        <h4>Informações</h4>
                        <p>
                          <strong>Canais: </strong>
                          {fl_whats === 1 ? (
                            <span className="icon-canal active">WhatsApp,</span>
                          ) : (
                            ''
                          )}

                          {fl_push === 1 ? (
                            <span className="icon-canal active">Push,</span>
                          ) : (
                            ''
                          )}

                          {fl_sms === 1 ? (
                            <span className="icon-canal active">SMS</span>
                          ) : (
                            ''
                          )}
                        </p>
                        <p>
                          <strong>Título: </strong>
                          {this.state.step1.titulo}
                        </p>
                        <p>
                          <strong>Frase: </strong>
                          {this.state.step1.frase}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}

export default withRouter(NovoEngajamento)
