import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { MdChevronRight } from 'react-icons/md'
import { getEstoque } from '../../services/api'

import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import pt from '../../lang/pt'
import es from '../../lang/es'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import gridOptions from '../../utils/grid/options/grid-options'
import useResize from '../../hooks/useResize'
import {
  imageRenderer,
  bookmarks
} from '../../utils/grid/renderers/image.renderer'
import { faixaRenderer } from '../../utils/grid/renderers/faixa.renderer'
import { centerCellStyle } from '../../utils/grid/cell-styles/center.cell-style'

counterpart.registerTranslations('pt', pt)
counterpart.registerTranslations('es', es)
counterpart.setLocale('pt')

const estoqueGridOptions = {
  ...gridOptions,
  rowHeight: 67
}

const Estoque = () => {
  const [rowData, setRowData] = useState([])
  const [gridApi, setGridApi] = useState(null)

  useEffect(() => {
    const fun = async () => {
      try {
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const estoque = await getEstoque(idDistribuidor)

        if (estoque.token === null) {
          return
        }

        if (estoque.status === 'error')
          throw new Error(
            'Erro ao carregar a lista de estoque, por favor tente novamente.'
          )

        setRowData(estoque.msg)
        console.log(estoque)
      } catch (e) {
        await swal({ icon: 'info', text: e.message })
      }
    }
    fun()
  }, [])

  const onGridReady = (params) => {
    setGridApi(params.api)

    params.api.sizeColumnsToFit()
  }

  useResize(() => {
    console.log('Starting resize listener')
    if (gridApi && gridApi.sizeColumnsToFit) {
      gridApi.sizeColumnsToFit()
    }
  })

  return (
    <>
      <Menu />
      <Header />
      <div className="container  container--flex">
        <div className="breadcrumb">
          <div>
            {/* <h3>LISTA DE PEDIDOS</h3> */}
            <Translate content="listadeestoque" component="h3" />
            <p>
              Estoque{' '}
              <span>
                <MdChevronRight />
              </span>{' '}
              <span>Lista de Estoque</span>
            </p>
          </div>
          <span />
        </div>
        <div className="container__body">
          <div id="listaEstoque" className="col-12 h100">
            <div className="box h100  box--flex">
              <div className="box-content scroll-table scroll-table--lonely">
                <div
                  className="ag-theme-balham"
                  style={{ height: '100%', width: '100%' }}
                >
                  <AgGridReact
                    rowSelection="false"
                    gridOptions={estoqueGridOptions}
                    onGridReady={onGridReady}
                    rowData={rowData}
                  >
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Faixa"
                      sortable={true}
                      filter="agSetColumnFilter"
                      cellRenderer={faixaRenderer}
                      cellStyle={centerCellStyle}
                      field="faixa"
                      width={100}
                    />
                    <AgGridColumn
                      floatingFilter={false}
                      width={85}
                      headerName="Foto"
                      sortable={true}
                      resizable={false}
                      cellRenderer={imageRenderer}
                      field="foto"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="SKU"
                      sortable={true}
                      sort="asc"
                      field="codigo_produto"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Produto"
                      sortable={true}
                      sort="asc"
                      field="produto"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="CD"
                      sortable={true}
                      field="centro_distribuicao"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Qtde caixas"
                      sortable={true}
                      sort="asc"
                      field="qtd_caixas"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Volume"
                      sortable={true}
                      field="volume"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Valor indústria"
                      sortable={true}
                      field="valor_industria"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Valor ifefo"
                      sortable={true}
                      field="valor_ifefo"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Valor corte"
                      sortable={true}
                      field="valor_corte"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Data fabricação"
                      sortable={true}
                      field="data_fabircao"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Data vencimento"
                      sortable={true}
                      field="data_vencimento"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Validade"
                      sortable={true}
                      field="validade"
                    />
                    {/*<AgGridColumn*/}
                    {/*  floatingFilter={true}*/}
                    {/*  width={85}*/}
                    {/*  headerName="Destaque"*/}
                    {/*  field="lote"*/}
                    {/*  resizable={false}*/}
                    {/*  cellRenderer={bookmarks}*/}
                    {/*/>*/}
                    <AgGridColumn
                      floatingFilter={true}
                      width={85}
                      headerName="Lote"
                      sortable={true}
                      field="lote"
                    />
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Estoque
