import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import {
  MdAddCircleOutline,
  MdCheck,
  MdCheckCircle,
  MdChevronRight,
  MdCloudOff,
  MdCloudUpload,
  MdDeleteForever
} from 'react-icons/md'
import { FaEdit } from 'react-icons/fa'
import { TiCloudStorage } from 'react-icons/ti'
import Swal from 'sweetalert'
import { ClipLoader } from 'react-spinners'
import { Multiselect } from 'react-widgets'
import _ from 'lodash'
import myTreeData from './tree.json'
import './style.css'
import {
  getDestinatariosCaptura,
  inserirInteracoesCaptura
} from '../../services/api'

export default class CriarInteracao extends Component {
  constructor(props) {
    super(props)

    this.state = {
      titulo: '',
      layout: {
        whatsapp: false,
        sms: false,
        email: true
      },
      listas: [],
      recebeLista: [],
      myTreeData: [],
      emailData: [],
      emailTitle: '',
      emailMessage: '',
      emailImage: '',
      emailArquivo: '',
      stepper: 1,
      stepper4_5: false,
      label: '',
      textareaHeight: '0px',
      decisionTreeScreen: false,
      phoneViewerScreen: false,
      pergunta: '',
      upload: '',
      sim: '',
      nao: '',
      layoutSelect: '',

      decisionTree: [],
      p1: '',
      proximo: '',
      resposta: '',
      p2: '',
      imgP1: '',
      imgP2: '',
      blockUpload: false,
      stepArquivo: '',
      arquivo: '',
      nomeArquivo: '',
      perguntaAtiva: '',
      loader: false
    }
  }

  handleMultiSelect = (e) => {
    this.setState({ recebeLista: e })
  }

  textareaAutoResize = () => {
    let tx = document.getElementsByTagName('textarea')
    this.setState({
      textareaHeight: 'auto'
    })
    this.setState({
      textareaHeight: `${tx[0].scrollHeight}px`
    })
  }

  async componentDidMount() {
    await this.getListas()

    this.setState({ myTreeData: _.cloneDeep(myTreeData) })
    let tx = document.getElementsByTagName('textarea')
    tx[0].addEventListener('input', this.textareaAutoResize, false)

    this.textareaAutoResize()

    this.setState({ layoutSelect: 'sms' })
  }

  componentWillUnmount() {
    this.setState({ myTreeData: [] })
  }

  decisionScreen = (step) => {
    if (step === 'proximo') {
      if (this.state.stepper < 3) {
        this.setState({ stepper: this.state.stepper + 1 })
      } else {
        this.setState({
          decisionTreeScreen: true,
          stepper: 4
        })
      }
    }
    if (step === 'voltar') {
      if (this.state.stepper <= 3 && this.state.stepper !== 1) {
        this.setState({ stepper: this.state.stepper - 1 })
      } else if (this.state.stepper === 1) {
      } else {
        this.setState({
          decisionTreeScreen: false,
          phoneViewerScreen: false,
          p1: '',
          p2: '',
          resposta: '',
          stepper: 3,
          stepper4_5: false
        })
      }
    }
  }

  decisionScreenEmail = (step) => {
    if (step === 'proximo' && this.state.stepper < 3) {
      this.setState({ stepper: this.state.stepper + 1 })
    }
    if (
      step === 'voltar' &&
      this.state.stepper <= 3 &&
      this.state.stepper !== 1
    ) {
      this.setState({ stepper: this.state.stepper - 1 })
    } else if (this.state.stepper === 1) {
    }
  }

  lancaEmail = async () => {
    const layout = this.state.layout
    layout.rascunho = false

    const bodySize = (this.state.emailMessage || '').length

    if (bodySize < 50 || bodySize > 100) {
      Swal({
        icon: 'error',
        text: 'o corpo do e-mail deve conter entre 50 e 100 caracteres'
      })
      return
    }
    this.setState({ loader: true })

    const dados = {
      assunto: this.state.emailTitle,
      corpo: this.state.emailMessage
    }

    const listas = this.state.recebeLista.map((e) => e.id)

    let body = new FormData()

    body.append('file', this.state.emailArquivo)
    body.append('titulo', this.state.titulo)
    body.append('flags', JSON.stringify(layout))
    body.append('dados', JSON.stringify(dados))
    body.append('lista', JSON.stringify(listas))

    this.setState({ loader: false })

    Swal({ icon: 'success', text: 'Lançado com sucesso!' }).then(() => {
      this.props.history.push('/interacaocliente')
    })
  }

  salvarRascunhoEmail = async () => {
    this.setState({ loader: true })
    const layout = this.state.layout
    layout.rascunho = true

    const dados = {
      assunto: this.state.emailTitle,
      corpo: this.state.emailMessage
    }

    const listas = this.state.recebeLista.map((e) => e.id)

    let body = new FormData()

    body.append('file', this.state.emailArquivo)
    body.append('titulo', this.state.titulo)
    body.append('flags', JSON.stringify(layout))
    body.append('dados', JSON.stringify(dados))
    body.append('lista', JSON.stringify(listas))

    this.setState({ loader: false })

    Swal({ icon: 'success', text: 'Rascunho salvo com sucesso!' })
  }

  lancaCampanhaWhatsappSMS = async () => {
    this.setState({ loader: true })
    const layout = this.state.layout
    layout.rascunho = false

    const listas = this.state.recebeLista.map((e) => e.id)

    let body = new FormData()

    body.append('file', this.state.stepArquivo)
    body.append('titulo', this.state.titulo)
    body.append('flags', JSON.stringify(layout))
    body.append('dados', JSON.stringify(this.state.myTreeData))
    body.append('lista', JSON.stringify(listas))

    await inserirInteracoesCaptura(body)

    this.setState({ loader: false })

    Swal({ icon: 'success', text: 'Lançado com sucesso!' }).then(() => {
      this.props.history.push('/interacaocliente')
    })
  }

  salvarRascunhoWhatsappSMS = async () => {
    this.setState({ loader: true })
    const layout = this.state.layout
    layout.rascunho = true

    const listas = this.state.recebeLista.map((e) => e.id)

    let body = new FormData()

    body.append('file', this.state.stepArquivo)
    body.append('titulo', this.state.titulo)
    body.append('flags', JSON.stringify(layout))
    body.append('dados', JSON.stringify(this.state.myTreeData))
    body.append('lista', JSON.stringify(listas))

    await inserirInteracoesCaptura(body)
    this.setState({ loader: false })
    Swal({ icon: 'success', text: 'Rascunho salvo com sucesso!' })
  }

  verDialogoSim = (e, d) => {
    e.preventDefault()
    this.setState({
      p1: '',
      p2: '',
      resposta: ''
    })
    const select = document.getElementById(`select-rota-sim-${d.id}`)
    select.value
      ? this.state.myTreeData.map((data) => {
          if (data.id === parseInt(select.value)) {
            this.setState({
              p1: d.pergunta,
              imgP1: d.uploadMaleavel,
              p2: data.pergunta,
              imgP2: data.uploadMaleavel,
              resposta: 'Sim'
            })
          }
          return data
        })
      : this.setState({
          p1: d.pergunta,
          imgP1: d.uploadMaleavel
        })
    this.setState({
      phoneViewerScreen: true,
      stepper4_5: true
    })
  }

  verDialogoNao = (e, d) => {
    e.preventDefault()
    this.setState({
      p1: '',
      p2: '',
      resposta: ''
    })
    const select = document.getElementById(`select-rota-nao-${d.id}`)
    select.value
      ? this.state.myTreeData.map((data) => {
          if (data.id === parseInt(select.value)) {
            this.setState({
              p1: d.pergunta,
              imgP1: d.uploadMaleavel,
              p2: data.pergunta,
              imgP2: data.uploadMaleavel,
              resposta: 'Não'
            })
          }
          return data
        })
      : this.setState({
          p1: d.pergunta,
          imgP1: d.uploadMaleavel
        })
    this.setState({
      phoneViewerScreen: true,
      stepper4_5: true
    })
  }

  handleLayout = (l) => {
    if (l === 'email' && this.state.layout.email === false) {
      this.setState({
        layout: { sms: false, whatsapp: false, email: !this.state.layout[l] }
      })
    } else if (l !== 'email' && this.state.layout.email === true) {
      this.setState({ layout: { ...this.state.layout, [l]: false } })
    } else {
      this.setState({
        layout: { ...this.state.layout, [l]: !this.state.layout[l] }
      })
    }
    if (this.state.layout[l] === false) {
      this.setState({
        layoutSelect: l
      })
    } else if (l === 'whatsapp' && this.state.layout.sms === true) {
      this.setState({
        layoutSelect: 'sms'
      })
    } else if (l === 'sms' && this.state.layout.whatsapp === true) {
      this.setState({
        layoutSelect: 'whatsapp'
      })
    }
  }

  handleAddPergunta = () => {
    this.setState({
      myTreeData: [
        ...this.state.myTreeData,
        {
          id: this.state.myTreeData.length + 1,
          pergunta: 'Nova Pergunta',
          upload: '',
          uploadMaleavel: '',
          sim: '',
          nao: ''
        }
      ]
    })
  }

  handleEditPergunta = (index) => {
    const newItems = _.cloneDeep(this.state.myTreeData)
    newItems[index].pergunta = this.state.pergunta
      ? this.state.pergunta
      : newItems[index].pergunta
    newItems[index].upload = this.state.upload
      ? this.state.upload.name
      : newItems[index].upload
    if (this.state.blockUpload) {
    } else if (this.state.upload) {
      newItems[index].uploadMaleavel = URL.createObjectURL(this.state.upload)
      this.setState({
        blockUpload: true,
        stepArquivo: this.state.upload
      })
    }
    newItems[index].arquivo = this.state.upload ? this.state.upload : ''

    this.setState({
      myTreeData: _.cloneDeep(newItems),
      upload: '',
      pergunta: '',
      perguntaAtiva: ''
    })
  }

  changeEmailImg = (e) => {
    const imagem = e
    this.setState({
      emailImage: imagem === undefined ? '' : URL.createObjectURL(imagem),
      nomeArquivo: imagem === undefined ? '' : imagem.name,
      emailArquivo: imagem === undefined ? '' : imagem
    })
  }

  removeUpload = (index) => {
    const newItems = _.cloneDeep(this.state.myTreeData)
    newItems[index].upload = ''
    newItems[index].uploadMaleavel = ''
    this.setState({
      myTreeData: _.cloneDeep(newItems),
      blockUpload: false,
      stepArquivo: ''
    })
  }

  removePergunta = (index) => {
    const newItems = _.cloneDeep(this.state.myTreeData)
    newItems.splice(index, 1)
    this.setState({
      myTreeData: _.cloneDeep(newItems)
    })
  }

  toggleLabel = (label) => this.setState({ label })

  getListas = async () => {
    try {
      const listas = await getDestinatariosCaptura(2)

      if (listas.token === null) {
        return
      }

      // Consulta listas
      if (listas.status === 'error') throw new Error(listas.msg)
      // Se erro gera mensagem de erro
      await this.setState({ listas: listas.msg })
      // seta listas
    } catch (e) {
      Swal({ icon: 'info', text: e.message })
    }
  }

  captalize = (value) => {
    if (!(value || '').length) return ''
    return value[0].toUpperCase() + value.slice(1).toLowerCase()
  }

  render() {
    return (
      <>
        <Menu menuDrop="3" />
        <Header />
        <div className="container pb-0">
          <div className="breadcrumb">
            <div>
              <h3>LISTA DE INTERAÇÕES</h3>
              <p>
                Captura{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                <span>Criar Interação</span>
              </p>
            </div>
          </div>
          <div className="panel-with-preview">
            <div
              className={
                this.state.layout.email
                  ? 'panel-md'
                  : this.state.decisionTreeScreen === false
                  ? 'panel-sm'
                  : 'panel-sm screen-desactive'
              }
            >
              <div className="box" style={{ overflow: 'hidden' }}>
                <div className="box-content">
                  <div className="stepper-box">
                    <div className="stepper-title">
                      <h4 className="stepper-icon-group">
                        {this.state.stepper > 1 ? (
                          <span className="stepper-icon success">
                            <MdCheck />
                          </span>
                        ) : (
                          <span className="stepper-icon interacao">1</span>
                        )}
                        Informação
                      </h4>
                    </div>
                    <div
                      className={
                        this.state.stepper === 1
                          ? 'stepper-item'
                          : 'stepper-item d-none'
                      }
                    >
                      <div className="form-item pb-0 pt-0">
                        <label
                          className={
                            this.state.label === '1' || this.state.titulo !== ''
                              ? 'input-title active'
                              : 'input-title'
                          }
                        >
                          Título
                        </label>
                        <input
                          className={
                            this.state.titulo !== ''
                              ? 'form-control check'
                              : 'form-control'
                          }
                          value={this.state.titulo}
                          onChange={(e) =>
                            this.setState({ titulo: e.target.value })
                          }
                          onFocus={() => this.toggleLabel('1')}
                          onBlur={() => this.toggleLabel('')}
                        />
                      </div>
                      <div className="box-item interacao">
                        {/*<div className={this.state.layout.email ? 'item desactive' : this.state.layout.whatsapp ? 'item active' : 'item'} onClick={() => this.handleLayout('whatsapp')}>
													<span><FaWhatsapp /></span>
													<p>WhatsApp</p>
												</div>
												*/}
                        {/* <div className='item desactive'>
													<span><FaWhatsapp /></span>
													<p>WhatsApp</p>
												</div> */}
                        {/* <div className={this.state.layout.email ? 'item desactive' : this.state.layout.sms ? 'item active' : 'item'} onClick={() => this.handleLayout('sms')}>
													<span><FaSms /></span>
													<p>SMS</p>
												</div> */}
                        {/*<div
                          className={
                            this.state.layout.email ? 'item active' : 'item'
                          }
                          onClick={() => this.handleLayout('email')}
                        >
                          <span>
                            <FaEnvelope />
                          </span>
                          <p>E-mail</p>
                        </div>*/}
                      </div>
                    </div>

                    <div className="stepper-title">
                      <h4 className="stepper-icon-group">
                        {this.state.stepper > 2 ? (
                          <span className="stepper-icon success">
                            <MdCheck />
                          </span>
                        ) : (
                          <span className="stepper-icon interacao">2</span>
                        )}
                        Destinatários
                      </h4>
                    </div>
                    <div
                      className={
                        this.state.stepper === 2
                          ? 'stepper-item'
                          : this.state.stepper === 3 || this.state.stepper === 4
                          ? 'stepper-item screen-desactive'
                          : 'stepper-item d-none'
                      }
                    >
                      <div className="form-item multiselect">
                        <Multiselect
                          disabled={
                            this.state.stepper === 3 || this.state.stepper === 4
                              ? true
                              : false
                          }
                          data={this.state.listas}
                          minLength={3}
                          valueField="id"
                          textField="nomeLista"
                          filter="contains"
                          className={
                            this.state.recebeLista.length === 0 ? '' : 'check'
                          }
                          placeholder="Destinatários"
                          onChange={(e) => this.handleMultiSelect(e)}
                          messages={{ emptyList: 'Nenhuma lista' }}
                          style={{ fontSize: '12px' }}
                        />
                      </div>
                    </div>

                    <div className="stepper-title" style={{ marginBottom: 5 }}>
                      <h4 className="stepper-icon-group">
                        {this.state.layout.email ? (
                          <>
                            {this.state.stepper > 3 ? (
                              <span className="stepper-icon success">
                                <MdCheck />
                              </span>
                            ) : (
                              <span className="stepper-icon interacao">3</span>
                            )}
                            Informações do E-mail
                          </>
                        ) : (
                          <>
                            {this.state.stepper > 3 ? (
                              <span className="stepper-icon success">
                                <MdCheck />
                              </span>
                            ) : (
                              <span className="stepper-icon interacao">3</span>
                            )}
                            Perguntas
                          </>
                        )}
                      </h4>
                    </div>
                    <div
                      className={
                        this.state.stepper === 3 || this.state.stepper === 4
                          ? 'stepper-item'
                          : 'stepper-item d-none'
                      }
                    >
                      <div
                        className={
                          this.state.layout.email
                            ? 'form-group text-email'
                            : 'form-group text-email d-none'
                        }
                      >
                        <label htmlFor="title-email">Assunto:</label>
                        <input
                          style={{ marginBottom: 20 }}
                          type="text"
                          className="form-control"
                          value={this.state.emailTitle}
                          id="title-email"
                          name="title-email"
                          onChange={(e) => {
                            const emailTitle = this.captalize(e.target.value)
                            this.setState({ emailTitle })
                          }}
                          placeholder="Assunto"
                        />
                        <label htmlFor="text-email">Corpo do E-mail:</label>
                        <textarea
                          placeholder="Corpo do E-mail"
                          onChange={(e) => {
                            const emailMessage = this.captalize(e.target.value)
                            this.setState({
                              emailMessage
                            })
                          }}
                          id="text-email"
                          name="text-email"
                          className="form-control"
                          minLength={50}
                          maxLength={100}
                          style={{
                            height:
                              this.state.textareaHeight === '0px'
                                ? '40px'
                                : this.state.textareaHeight
                          }}
                        >
                          {this.state.emailMessage}
                        </textarea>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <small
                            style={{
                              fontSize: 12,
                              color: '#999',
                              flexGrow: 1,
                              flexShrink: 1
                            }}
                          >
                            Deve conter entre 50 e 100 caracteres.
                          </small>
                          <small style={{ fontSize: 12, color: '#999' }}>
                            {(this.state.emailMessage || '').length}/100
                          </small>
                        </div>
                        <div className="form-item">
                          <label className="upload" htmlFor="up">
                            <span>
                              {this.state.emailImage === '' ? (
                                <>
                                  <i id="icon-upload">
                                    <TiCloudStorage />
                                  </i>
                                  Clique aqui para adicionar imagem
                                </>
                              ) : (
                                this.state.nomeArquivo
                              )}
                            </span>
                          </label>
                          <input
                            type="file"
                            id="up"
                            className="d-none"
                            onChange={(e) =>
                              this.changeEmailImg(e.target.files[0])
                            }
                          />
                          <br />
                          {/*<p><strong>Atenção: </strong>Envie somente arquivos em .CSV que contenham cnpj's com 14 digitos e estejam classificados como texto.</p>*/}
                        </div>
                      </div>

                      {this.state.layout.email ? (
                        <></>
                      ) : (
                        this.state.myTreeData.map((t, index) => (
                          <div /*onDragStart={e => this.onDragStart(e, index)} onDragOver={() => this.onDragOver(index)}*/
                          >
                            <div
                              key={t.id}
                              className="pergunta-container" /*onDragEnd={(e) => this.onDragEnd()} draggable="true"*/
                            >
                              {/*<input type="radio" id={`pergunta${t.id}`} name="edit-pergunta" className="toggle d-none" />*/}
                              <div className="d-flex justify-content-space-between align-items-center">
                                <h4
                                  style={{ overflow: 'hidden', height: 20 }}
                                >{`${t.id} - ${t.pergunta}`}</h4>
                                <p
                                  style={{ marginLeft: 'auto' }}
                                  title="remover"
                                  className="icon-interacao-red"
                                  onClick={() => this.removePergunta(index)}
                                >
                                  <span>
                                    <i>
                                      <MdDeleteForever />
                                    </i>
                                  </span>
                                </p>
                                <label
                                  style={{ marginBottom: -1 }}
                                  htmlFor={`pergunta${t.id}`}
                                  className={
                                    this.state.perguntaAtiva ===
                                    `pergunta${t.id}`
                                      ? 'icon-interacao editar d-none'
                                      : 'icon-interacao editar'
                                  }
                                  onClick={() =>
                                    this.setState({
                                      pergunta: '',
                                      perguntaAtiva: `pergunta${t.id}`
                                    })
                                  }
                                >
                                  <span>
                                    <i>
                                      <FaEdit />
                                    </i>
                                  </span>
                                </label>
                                <label
                                  htmlFor={`pergunta${t.id}`}
                                  onClick={(e) =>
                                    this.handleEditPergunta(index, t.id)
                                  }
                                  className={
                                    this.state.perguntaAtiva ===
                                    `pergunta${t.id}`
                                      ? 'icon-interacao salvar'
                                      : 'icon-interacao salvar d-none'
                                  }
                                >
                                  <span>
                                    <i>
                                      <MdCheckCircle />
                                    </i>
                                  </span>
                                </label>
                              </div>
                              <div
                                className={
                                  this.state.perguntaAtiva === `pergunta${t.id}`
                                    ? 'edit-pergunta d-flex'
                                    : 'edit-pergunta d-none'
                                }
                              >
                                <div className="form-item pt-0 pb-0">
                                  <label
                                    className={
                                      this.state.label === '2' ||
                                      this.state.pergunta !== ''
                                        ? 'input-title active'
                                        : 'input-title'
                                    }
                                  >
                                    Insira a pergunta
                                  </label>
                                  <input
                                    className={
                                      this.state.layout.sms === false &&
                                      this.state.pergunta === ''
                                        ? 'form-control up'
                                        : this.state.layout.sms === false &&
                                          this.state.pergunta !== ''
                                        ? 'form-control up check'
                                        : this.state.layout.sms === true &&
                                          this.state.pergunta === ''
                                        ? 'form-control'
                                        : 'form-control check'
                                    }
                                    value={this.state.pergunta}
                                    onChange={(e) =>
                                      this.setState({
                                        pergunta: e.target.value
                                      })
                                    }
                                    onFocus={() => this.toggleLabel('2')}
                                    onBlur={() => this.toggleLabel('')}
                                  />
                                  {!t.upload ? (
                                    <>
                                      <label
                                        className={
                                          this.state.layout.sms ||
                                          this.state.blockUpload
                                            ? 'upload-interacao d-none-fix'
                                            : this.state.upload
                                            ? 'upload-interacao up'
                                            : 'upload-interacao'
                                        }
                                        htmlFor={`up-${t.id}`}
                                      >
                                        <span>
                                          <i>
                                            <MdCloudUpload />
                                          </i>
                                        </span>
                                      </label>
                                      <input
                                        type="file"
                                        id={`up-${t.id}`}
                                        className="d-none"
                                        disabled={this.state.layout.sms}
                                        onChange={(e) =>
                                          this.setState({
                                            upload: e.target.files[0]
                                          })
                                        }
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <p
                                        className={
                                          this.state.layout.sms
                                            ? 'upload-interacao d-none'
                                            : 'upload-interacao'
                                        }
                                        onClick={() => this.removeUpload(index)}
                                      >
                                        <span>
                                          <i>
                                            <MdCloudOff />
                                          </i>
                                        </span>
                                      </p>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                      {!this.state.layout.email ? (
                        <div className="d-flex justify-content-flex-end">
                          <button
                            onClick={() => this.handleAddPergunta()}
                            className="btn-icon"
                            style={{ fontSize: '1.4em' }}
                          >
                            <span>
                              <MdAddCircleOutline />
                            </span>
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>

                  <div className="proximo-decision-tree">
                    {this.state.layout.email ? (
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <button
                          className="btn btn-red"
                          onClick={() =>
                            Swal({
                              icon: 'warning',
                              title: 'Tem certeza?',
                              text:
                                'Se optar por cancelar a captura, todos os dados serão perdidos. Se preferir salve um rascunho antes.',
                              buttons: {
                                cancel: {
                                  text: 'Cancelar',
                                  value: false,
                                  visible: true,
                                  closeModal: true
                                }
                                /*,
															confirm: {
																text: 'Salvar Rascunho',
																value: true,
																visible: true,
																closeModal: true
															}
															*/
                              }
                            }).then((value) => {
                              //	if (value) this.salvarRascunhoEmail()

                              if (!value)
                                this.props.history.push('/interacaocliente')
                            })
                          }
                        >
                          Cancelar
                        </button>
                        {/* {this.state.stepper === 3 && this.state.emailTitle !== '' && this.state.emailMessage !== '' ?


												<button className={this.state.stepper === 3 ? "btn btn-gray" : "btn btn-gray screen-desactive"} onClick={
													() => Swal({
														icon: 'warning',
														title: 'Deseja salvar um rascunho?',
														text: 'Um rascunho será salvo para ser editado e lançado mais tarde.',
														buttons: {
															cancel: {
																text: 'Cancelar',
																value: false,
																visible: true,
																closeModal: true,
															},
															confirm: {
																text: 'Salvar Rascunho',
																value: true,
																visible: true,
																closeModal: true
															}
														}
													}).then((value) => {
														//if (value) this.salvarRascunhoEmail()
													})}>
														Rascunho
													</button> : <button className="btn btn-gray screen-desactive">Rascunho</button>} */}
                        <button
                          className={
                            this.state.stepper === 1
                              ? 'btn screen-desactive'
                              : 'btn'
                          }
                          onClick={() => this.decisionScreenEmail('voltar')}
                        >
                          Voltar
                        </button>
                        {this.state.stepper === 3 &&
                        this.state.emailTitle !== '' &&
                        this.state.emailMessage !== '' ? (
                          <button
                            className={
                              this.state.emailTitle !== '' &&
                              this.state.emailMessage !== ''
                                ? 'btn btn-green'
                                : 'btn btn-green screen-desactive'
                            }
                            onClick={() =>
                              Swal({
                                icon: 'warning',
                                title: 'Tem certeza?',
                                text:
                                  'Deseja realmente lançar a captura? Uma vez lançado os disparos não poderão ser cancelados.',
                                buttons: {
                                  cancel: {
                                    text: 'Cancelar',
                                    value: null,
                                    visible: true,
                                    className: '',
                                    closeModal: true
                                  },
                                  confirm: {
                                    text: 'Lançar Captura',
                                    value: true,
                                    visible: true,
                                    className: '',
                                    closeModal: true
                                  }
                                }
                              }).then((value) => {
                                if (value) {
                                  this.lancaEmail()
                                }
                              })
                            }
                          >
                            Lançar
                          </button>
                        ) : (
                          <button
                            className={
                              this.state.stepper === 1 &&
                              this.state.titulo !== ''
                                ? 'btn btn-green'
                                : this.state.stepper === 2 &&
                                  this.state.recebeLista.length !== 0
                                ? 'btn btn-green'
                                : this.state.stepper === 3 &&
                                  this.state.emailTitle !== '' &&
                                  this.state.emailMessage !== ''
                                ? 'btn btn-green'
                                : 'btn btn-green screen-desactive'
                            }
                            disabled={
                              this.state.stepper === 1 &&
                              this.state.titulo !== ''
                                ? false
                                : this.state.stepper === 2 &&
                                  this.state.recebeLista.length !== 0
                                ? false
                                : (this.state.stepper === 3 &&
                                    this.state.emailTitle !== '') ||
                                  this.state.emailMessage !== ''
                                ? false
                                : true
                            }
                            onClick={() => this.decisionScreenEmail('proximo')}
                          >
                            Seguinte
                          </button>
                        )}
                      </div>
                    ) : (
                      <>
                        <div
                          className="d-flex justify-content-flex-end"
                          style={{ width: '100%' }}
                        >
                          <div>
                            <button
                              className={
                                this.state.stepper === 1
                                  ? 'btn screen-desactive'
                                  : 'btn'
                              }
                              onClick={() => this.decisionScreen('voltar')}
                            >
                              Voltar
                            </button>
                            <button
                              className={
                                this.state.perguntaAtiva
                                  ? 'btn btn-green screen-desactive'
                                  : this.state.decisionTreeScreen
                                  ? 'btn btn-green screen-desactive'
                                  : this.state.layout.sms === false &&
                                    this.state.layout.whatsapp === false
                                  ? 'btn btn-green screen-desactive'
                                  : this.state.myTreeData.length === 0
                                  ? 'btn btn-green screen-desactive'
                                  : this.state.stepper === 1 &&
                                    this.state.titulo === ''
                                  ? 'btn btn-green screen-desactive'
                                  : this.state.stepper === 2 &&
                                    this.state.recebeLista.length === 0
                                  ? 'btn btn-green screen-desactive'
                                  : this.state.stepper === 3 &&
                                    this.state.myTreeData.length === 0
                                  ? 'btn btn-green screen-desactive'
                                  : 'btn btn-green'
                              }
                              disabled={
                                this.state.perguntaAtiva
                                  ? true
                                  : this.state.decisionTreeScreen
                                  ? true
                                  : this.state.layout.sms === false &&
                                    this.state.layout.whatsapp === false
                                  ? true
                                  : this.state.myTreeData.length === 0
                                  ? true
                                  : this.state.stepper === 1 &&
                                    this.state.titulo === ''
                                  ? true
                                  : this.state.stepper === 2 &&
                                    this.state.recebeLista.length === 0
                                  ? true
                                  : this.state.stepper === 3 &&
                                    this.state.myTreeData.length === 0
                                  ? true
                                  : false
                              }
                              onClick={() => this.decisionScreen('proximo')}
                            >
                              Siguiente
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.layout.email
                  ? 'd-none'
                  : this.state.decisionTreeScreen
                  ? 'panel-sm'
                  : 'panel-sm screen-desactive'
              }
            >
              <div className="box">
                <div className="box-content arvore-decisao">
                  <div className="stepper-box">
                    <div className="stepper-title">
                      <h4 className="stepper-icon-group">
                        {this.state.stepper4_5 === true ? (
                          <span className="stepper-icon success">
                            <MdCheck />
                          </span>
                        ) : (
                          <span className="stepper-icon interacao">4</span>
                        )}
                        Fluxo de Decisão
                      </h4>
                    </div>
                  </div>
                  <div className="stepper-item">
                    {this.state.myTreeData.map((d, index) => (
                      <div key={`d-${d.id}`} className="d-flex">
                        <div className="idPergunta col-3">{d.id} -</div>
                        <div className="col-9">
                          <div className="col-6 pt-0">
                            <div className="decision-select">
                              <form onSubmit={(e) => this.verDialogoSim(e, d)}>
                                {/*}
																	<select className="form-control">
																		<option value={false}>--</option>
																		<option value={true}>Sim</option>
																	</select>
																	*/}
                                <select
                                  id={`select-rota-sim-${d.id}`}
                                  onChange={(event) => {
                                    const newItems = _.cloneDeep(
                                      this.state.myTreeData
                                    )
                                    let verificarRepeticao = false
                                    for (let i = 0; i < newItems.length; i++) {
                                      if (
                                        newItems[i].sim ===
                                          event.target.value &&
                                        newItems[i].sim !== ''
                                      ) {
                                        verificarRepeticao = true
                                        break
                                      }
                                    }
                                    if (verificarRepeticao === false) {
                                      newItems[index].sim = event.target.value
                                      this.setState({
                                        myTreeData: _.cloneDeep(newItems)
                                      })
                                    } else {
                                      event.target.value = ''
                                      verificarRepeticao = false
                                      Swal({
                                        title: 'Repetição de step!',
                                        icon: 'warning',
                                        text: 'Este step já foi selecionado.'
                                      })
                                    }
                                  }}
                                  className="form-control"
                                >
                                  <option value="">--</option>
                                  {this.state.myTreeData.map((ops) =>
                                    d.id === ops.id ? (
                                      <></>
                                    ) : (
                                      <option
                                        key={`ops${d.id}-${ops.id}`}
                                        value={ops.id}
                                        selected={
                                          ops.id === parseInt(d.sim)
                                            ? true
                                            : false
                                        }
                                      >
                                        {ops.id}
                                      </option>
                                    )
                                  )}
                                </select>
                                <input
                                  id={`rota-sim-${d.id}`}
                                  type="submit"
                                  className="d-none"
                                />
                              </form>
                            </div>
                            <div className="layout-viewer-button">
                              <label
                                htmlFor={`rota-sim-${d.id}`}
                                className="link"
                              >
                                Ver Sim
                              </label>
                            </div>
                          </div>
                          <div className="col-6 pt-0">
                            <div className="decision-select">
                              <form onSubmit={(e) => this.verDialogoNao(e, d)}>
                                {/*
																	<select className="form-control">
																		<option value={false}>--</option>
																		<option value={true}>Não</option>
																	</select>
																	*/}
                                <select
                                  id={`select-rota-nao-${d.id}`}
                                  onChange={(event) => {
                                    const newItems = _.cloneDeep(
                                      this.state.myTreeData
                                    )
                                    let verificarRepeticao = false
                                    for (let i = 0; i < newItems.length; i++) {
                                      if (
                                        newItems[i].nao ===
                                          event.target.value &&
                                        newItems[i].nao !== ''
                                      ) {
                                        verificarRepeticao = true
                                        break
                                      }
                                    }
                                    if (verificarRepeticao === false) {
                                      newItems[index].nao = event.target.value
                                      this.setState({
                                        myTreeData: _.cloneDeep(newItems)
                                      })
                                    } else {
                                      event.target.value = ''
                                      verificarRepeticao = false
                                      Swal({
                                        title: 'Repetição de step!',
                                        icon: 'warning',
                                        text: 'Este step já foi selecionado.'
                                      })
                                    }
                                  }}
                                  className="form-control"
                                >
                                  <option value="">--</option>
                                  {this.state.myTreeData.map((opn) =>
                                    d.id === opn.id ? (
                                      <></>
                                    ) : (
                                      <option
                                        key={`opn${d.id}-${opn.id}`}
                                        value={opn.id}
                                        selected={
                                          opn.id === parseInt(d.nao)
                                            ? true
                                            : false
                                        }
                                      >
                                        {opn.id}
                                      </option>
                                    )
                                  )}
                                </select>
                                <input
                                  id={`rota-nao-${d.id}`}
                                  type="submit"
                                  className="d-none"
                                />
                              </form>
                            </div>
                            <div className="layout-viewer-button">
                              <label
                                htmlFor={`rota-nao-${d.id}`}
                                className="link"
                              >
                                Ver Não
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="clear"></div>
                    <div className="proximo-decision-tree salvar">
                      <button
                        className="btn btn-red"
                        onClick={() =>
                          Swal({
                            icon: 'warning',
                            title: 'Tem certeza?',
                            text:
                              'Se optar por cancelar a captura, todos os dados serão perdidos. Se preferir salve um rascunho antes.',
                            buttons: {
                              cancel: {
                                text: 'Cancelar',
                                value: false,
                                visible: true,
                                closeModal: true
                              }
                              /*,
														confirm: {
															text: 'Salvar Rascunho',
															value: true,
															visible: true,
															closeModal: true
														}*/
                            }
                          }).then((value) => {
                            //	if (value) this.salvarRascunhoWhatsappSMS()

                            if (!value)
                              this.props.history.push('/interacaocliente')
                          })
                        }
                      >
                        Cancelar
                      </button>
                      {/* <button className="btn btn-gray" onClick={
												() => Swal({
													icon: 'warning',
													title: 'Deseja salvar um rascunho?',
													text: 'Um rascunho será salvo para ser editado e lançado mais tarde.',
													buttons: {
														cancel: {
															text: 'Cancelar',
															value: false,
															visible: true,
															closeModal: true,
														},
														confirm: {
															text: 'Salvar Rascunho',
															value: true,
															visible: true,
															closeModal: true
														}
													}
												}).then((value) => {
													if (value) this.salvarRascunhoWhatsappSMS()
												})}>Rascunho</button> */}

                      <button
                        className="btn"
                        onClick={() => this.decisionScreen('voltar')}
                      >
                        Voltar
                      </button>
                      <button
                        className="btn btn-green"
                        onClick={() =>
                          Swal({
                            icon: 'warning',
                            title: 'Tem certeza?',
                            text:
                              'Deseja realmente lançar a captura? Uma vez lançado os disparos não poderão ser cancelados.',
                            buttons: {
                              cancel: {
                                text: 'Cancelar',
                                value: null,
                                visible: true,
                                className: '',
                                closeModal: true
                              },
                              confirm: {
                                text: 'Lançar Captura',
                                value: true,
                                visible: true,
                                className: '',
                                closeModal: true
                              }
                            }
                          }).then((value) => {
                            if (value) {
                              this.lancaCampanhaWhatsappSMS()
                            }
                          })
                        }
                      >
                        Lançar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                this.state.phoneViewerScreen || this.state.layout.email
                  ? 'panel-sm layout-viewer'
                  : 'panel-sm layout-viewer screen-desactive-phone screen-desactive'
              }
              style={{ position: 'sticky !important', top: 0 }}
            >
              <div className="box">
                <div className="box-content">
                  <div className="iphone-mock">
                    <div className="iphone-mock__skeleton" />
                    <div className="iphone-mock__inner">
                      <div className="iphone-mock__header">
                        <div className="iphone-mock__icons">
                          <p>{this.state.emailTitle}</p>
                          <p>{this.state.layout.email}</p>
                        </div>
                      </div>
                      <div className="iphone-mock__body">
                        <div className="iphone-mock__body-wrapper">
                          <img
                            alt=""
                            className="email-image"
                            src={this.state.emailImage}
                          />
                          <div className="email-text">
                            <p style={{ fontSize: 'calc(0.5rem + 0.5vmax)' }}>
                              {this.state.emailMessage}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="iphone-mock__footer">footer</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}
