import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../img/logo ifefo-02.png'
import LogoRe9 from '../../img/logo-re9.png'
import {
  FaBullhorn,
  FaChevronDown,
  FaChevronRight,
  FaClipboardList,
  FaCubes,
  FaHome,
  FaShoppingBag,
  FaShoppingBasket,
  FaTachometerAlt,
  FaUser,
  FaUserPlus,
  FaUsers
} from 'react-icons/fa'
import {
  MdCheckCircle,
  MdExitToApp,
  MdFileDownload,
  MdFileUpload,
  MdSwapHoriz
} from 'react-icons/md'
import { GoDashboard } from 'react-icons/go'
import './style.css'

import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import pt from '../../lang/pt'
import es from '../../lang/es'

counterpart.registerTranslations('pt', pt)
counterpart.registerTranslations('es', es)

const CryptoJS = require('crypto-js')
const SECRET_KEY = 'xreydcvuhgbnlkjçlmiy6txsrwed25165'

const decrypt = (id) => {
  const data = CryptoJS.AES.decrypt(id.toString(), SECRET_KEY)
  const decryptData = data.toString(CryptoJS.enc.Utf8)

  return decryptData
}

const permissoes = (idTela) => {
  const permissao = localStorage.getItem('permissoes')
  const permissaoParse = JSON.parse(decrypt(permissao))
  const existe =
    permissaoParse.filter((elem) => elem.id_tela === idTela).length > 0
  // console.log(permissaoParse)
  if (existe) {
    return { id: idTela }
  }
}

export default class Menu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: window.location.pathname.split('/'),
      menuDrop: '',
      permissao: [{}]
    }
  }

  componentDidMount() {
    this.setState({ menuDrop: this.props.menuDrop })
  }

  toggleMenu = (menu) => {
    if (this.state.menuDrop === menu) {
      this.setState({ menuDrop: '0' })
    } else {
      this.setState({ menuDrop: menu })
    }
  }

  render() {
    return (
      <>
        <div className="menu">
          <div className="logo-menu">
            <img src={Logo} alt="Logo Ifefo" />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <div style={{ flex: '1' }}>
              <ul>
                {/* inicio */}
                {permissoes(1) && (
                  <li
                    className={
                      this.state.page[1] === '' || this.state.page === '#/'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link to="/">
                      <span>
                        <FaHome />
                      </span>
                      <Translate content="rot_mnu_inicio" />
                    </Link>
                  </li>
                )}
                {/* inicio */}

                {/* Dashboards */}
                {permissoes(24) && (
                  <li className={this.state.page[1] === 'pbi' ? 'active' : ''}>
                    <Link to="/pbi">
                      <span>
                        <GoDashboard />
                      </span>
                      <Translate content="rot_mnu_dash" />
                    </Link>
                  </li>
                )}
                {/* Dashboards */}

                {/* lance */}
                {permissoes(11) || permissoes(18) ? (
                  <li
                    onClick={() => this.toggleMenu('6')}
                    className={
                      this.state.menuDrop === '6'
                        ? 'menu-button menu-button-active'
                        : 'menu-button'
                    }
                  >
                    <span>
                      {this.state.menuDrop === '6' ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                    <Translate content="rot_mnu_lance" />
                  </li>
                ) : (
                  ''
                )}
                <li
                  className={
                    this.state.menuDrop === '6'
                      ? 'padding-0 dropdown dropdown-active'
                      : 'padding-0 dropdown'
                  }
                >
                  <ul className="padding-0">
                    {permissoes(11) || permissoes(18) ? (
                      <li
                        className={
                          this.state.page[1] === 'contraofertas' ||
                          this.state.page === '#/contraofertas'
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/contraofertas">
                          <span>
                            <FaClipboardList />
                          </span>
                          <Translate content="rot_mnu_lance_lista" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(3) ? (
                      <li
                        className={
                          this.state.page[1] === 'aprovacao-automatica'
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/aprovacao-automatica">
                          <span>
                            <MdCheckCircle />
                          </span>
                          <Translate content="rot_mnu_lance_aprovacao" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </li>
                {/* lance */}
                {/* captura */}
                {permissoes(5) || permissoes(6) ? (
                  <li
                    onClick={() => this.toggleMenu('3')}
                    className={
                      this.state.menuDrop === '3'
                        ? 'menu-button menu-button-active'
                        : 'menu-button'
                    }
                  >
                    <span>
                      {this.state.menuDrop === '3' ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                    <Translate content="rot_mnu_captura" />
                  </li>
                ) : (
                  ''
                )}
                <li
                  className={
                    this.state.menuDrop === '3'
                      ? 'padding-0 dropdown dropdown-active'
                      : 'padding-0 dropdown'
                  }
                >
                  <ul className="padding-0">
                    {permissoes(5) ? (
                      <li
                        className={
                          this.state.page[1] === 'upload' ? 'active' : ''
                        }
                      >
                        <Link to="/upload">
                          <span>
                            <MdFileUpload />
                          </span>
                          <Translate content="rot_mnu_captura_upload" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(6) ? (
                      <li
                        className={
                          this.state.page[1] === 'interacaocliente'
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/interacaocliente">
                          <span>
                            <MdSwapHoriz />
                          </span>
                          <Translate content="rot_mnu_captura_interacao" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(21) ? (
                      <li
                        className={
                          this.state.page[1] === 'relatorio' ? 'active' : ''
                        }
                      >
                        <Link to="/relatorio">
                          <span>
                            <FaClipboardList />
                          </span>
                          <Translate content="rot_mnu_captura_relatorio" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </li>
                {/* captura */}
                {/* campanha */}
                {permissoes(10) || permissoes(16) || permissoes(17) ? (
                  <li
                    onClick={() => this.toggleMenu('10')}
                    className={
                      this.state.menuDrop === '10'
                        ? 'menu-button menu-button-active'
                        : 'menu-button'
                    }
                  >
                    <span>
                      {this.state.menuDrop === '10' ||
                      this.state.menuDrop === '19' ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                    <Translate content="rot_mnu_campanha" />
                  </li>
                ) : (
                  ''
                )}
                <li
                  className={
                    this.state.menuDrop === '10' || this.state.menuDrop === '19'
                      ? 'padding-0 dropdown dropdown-active'
                      : 'padding-0 dropdown'
                  }
                >
                  <ul className="padding-0">
                    {permissoes(1) ||
                    permissoes(2) ||
                    permissoes(3) ||
                    permissoes(4) ? (
                      <li
                        className={
                          this.state.page[1] === 'campanhasimportar'
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/campanhasimportar">
                          <span>
                            <FaUserPlus />
                          </span>
                          <Translate content="rot_mnu_conversao_importar" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(1) ||
                    permissoes(2) ||
                    permissoes(3) ||
                    permissoes(4) ? (
                      <li
                        className={
                          this.state.page[1] === 'campanhas' ||
                          (this.state.page.length === 3 &&
                            this.state.page[1] === 'campanhas' &&
                            this.state.page[2] === 'criar')
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/campanhas">
                          <span>
                            <MdCheckCircle />
                          </span>
                          <Translate content="rot_campanhas_buttonTitleAdd" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(1) ||
                    permissoes(2) ||
                    permissoes(3) ||
                    permissoes(4) ? (
                      <li
                        className={
                          this.state.page[1] === 'campanhascockpit' ||
                          this.state.page === '#/campanhascockpit'
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/campanhascockpit">
                          <span>
                            <FaTachometerAlt />
                          </span>
                          <Translate content="rot_mnu_conversao_cockpit" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </li>
                {/* campanha */}
                {/* engajamento */}
                {permissoes(25) || permissoes(26) || permissoes(27) ? (
                  <li
                    onClick={() => this.toggleMenu('25')}
                    className={
                      this.state.menuDrop === '25'
                        ? 'menu-button text-uppercase menu-button-active'
                        : 'menu-button text-uppercase'
                    }
                  >
                    <span>
                      {this.state.menuDrop === '25' ||
                      this.state.menuDrop === '26' ||
                      this.state.menuDrop === '27' ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                    <Translate content="rot_mnu_engajamento" />
                  </li>
                ) : (
                  ''
                )}
                <li
                  className={
                    this.state.menuDrop === '25' ||
                    this.state.menuDrop === '27' ||
                    this.state.menuDrop === '26'
                      ? 'padding-0 dropdown dropdown-active'
                      : 'padding-0 dropdown'
                  }
                >
                  <ul className="padding-0">
                    {permissoes(1) ||
                    permissoes(2) ||
                    permissoes(3) ||
                    permissoes(4) ? (
                      <li
                        className={
                          this.state.page[1] === 'engajamento-importar'
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/engajamento-importar">
                          <span>
                            <FaUserPlus />
                          </span>
                          <Translate content="rot_mnu_conversao_importar" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(1) ||
                    permissoes(2) ||
                    permissoes(3) ||
                    permissoes(4) ? (
                      <li
                        className={
                          this.state.page[1] === 'engajamento' ||
                          (this.state.page[1] === 'engajamento' &&
                            this.state.page[2] === 'criar')
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/engajamento">
                          <span>
                            <FaBullhorn />
                          </span>
                          <Translate content="rot_engajamento_buttonTitleAdd" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </li>
                {/* engajamento */}
                {/* conversao */}
                {permissoes(2) ||
                permissoes(3) ||
                permissoes(4) ||
                permissoes(7) ? (
                  <li
                    onClick={() => this.toggleMenu('1')}
                    className={
                      this.state.menuDrop === '1'
                        ? 'menu-button menu-button-active'
                        : 'menu-button'
                    }
                  >
                    <span>
                      {this.state.menuDrop === '1' ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                    <Translate content="rot_mnu_conversao" />
                  </li>
                ) : (
                  ''
                )}
                <li
                  className={
                    this.state.menuDrop === '1'
                      ? 'padding-0 dropdown dropdown-active'
                      : 'padding-0 dropdown'
                  }
                >
                  <ul className="padding-0">
                    {permissoes(3) ? (
                      <li
                        className={
                          this.state.page[1] === 'importar' ? 'active' : ''
                        }
                      >
                        <Link to="/importar">
                          <span>
                            <FaUserPlus />
                          </span>
                          <Translate content="rot_mnu_conversao_importar" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(2) ? (
                      <li
                        className={
                          this.state.page[1] === 'impulsionamento' ||
                          this.state.page === '#/impulsionamento/criar'
                            ? 'active'
                            : ''
                        }
                      >
                        <Link to="/impulsionamento">
                          <span>
                            <FaBullhorn />
                          </span>
                          <Translate content="rot_mnu_conversao_impulsiona" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(4) ? (
                      <li
                        className={
                          this.state.page[1] === 'interacoes' ? 'active' : ''
                        }
                      >
                        <Link to="/interacoes">
                          <span>
                            <MdSwapHoriz />
                          </span>
                          <Translate content="rot_mnu_conversao_interacoes" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(2) ? (
                      <li
                        className={
                          this.state.page[1] === 'cockpit' ? 'active' : ''
                        }
                      >
                        <Link to="/cockpit">
                          <span>
                            <FaTachometerAlt />
                          </span>
                          <Translate content="rot_mnu_conversao_cockpit" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </li>
                {/* conversao */}
                {/* pedidos */}
                {permissoes(12) && (
                  <li
                    className={this.state.page[1] === 'pedidos' ? 'active' : ''}
                  >
                    <Link to="/pedidos">
                      <span>
                        <FaShoppingBag />
                      </span>
                      <Translate content="rot_mnu_pedidos" />
                    </Link>
                  </li>
                )}
                {/* pedidos */}
                {/* estoques */}
                {permissoes(13) && (
                  <li
                    className={this.state.page[1] === 'estoque' ? 'active' : ''}
                  >
                    <Link to="/estoque">
                      <span>
                        <FaShoppingBasket />
                      </span>
                      <Translate content="rot_mnu_estoques" />
                    </Link>
                  </li>
                )}
                {/* estoques */}
                {/* produtos */}
                {permissoes(14) && (
                  <li
                    className={
                      this.state.page[1] === 'produtos' ? 'active' : ''
                    }
                  >
                    <Link to="/produtos">
                      <span>
                        <FaCubes />
                      </span>
                      <Translate content="rot_mnu_produtos" />
                    </Link>
                  </li>
                )}
                {/* produtos */}
                {/* clientes */}
                {permissoes(20) && (
                  <li
                    onClick={() => this.toggleMenu('20')}
                    className={
                      this.state.page[1] === 'clientes' ||
                      this.state.page === '#/clientes'
                        ? 'active'
                        : ''
                    }
                  >
                    <Link to="/clientes">
                      <span>
                        <FaUsers />
                      </span>
                      <Translate content="rot_mnu_clientes" />
                    </Link>
                  </li>
                )}
                {/* clientes */}
                {/* configuracoes */}
                {permissoes(8) ? (
                  <li
                    onClick={() => this.toggleMenu('2')}
                    className={
                      this.state.menuDrop === '2'
                        ? 'menu-button menu-button-active'
                        : 'menu-button'
                    }
                  >
                    <span>
                      {this.state.menuDrop === '2' ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                    <Translate content="rot_mnu_configuracoes" />
                  </li>
                ) : (
                  ''
                )}
                <li
                  className={
                    this.state.menuDrop === '2'
                      ? 'padding-0 dropdown dropdown-active'
                      : 'padding-0 dropdown'
                  }
                >
                  <ul className="padding-0">
                    {permissoes(8) ? (
                      <li
                        className={
                          this.state.page[1] === 'usuarios' ? 'active' : ''
                        }
                      >
                        <Link to="/usuarios">
                          <span>
                            <FaUser />
                          </span>
                          <Translate content="rot_mnu_configuracoes_usuarios" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </li>
                {/* configuracoes */}
                {/* integracao */}
                {permissoes(22) || permissoes(23) ? (
                  <li
                    onClick={() => this.toggleMenu('22')}
                    className={
                      this.state.menuDrop === '22'
                        ? 'menu-button menu-button-active'
                        : 'menu-button'
                    }
                  >
                    <span>
                      {this.state.menuDrop === '22' ? (
                        <FaChevronDown />
                      ) : (
                        <FaChevronRight />
                      )}
                    </span>
                    <Translate content="rot_mnu_captura_integracao" />
                  </li>
                ) : (
                  ''
                )}
                <li
                  className={
                    this.state.menuDrop === '22'
                      ? 'padding-0 dropdown dropdown-active'
                      : 'padding-0 dropdown'
                  }
                >
                  <ul className="padding-0">
                    {permissoes(22) ? (
                      <li
                        className={
                          this.state.page[1] === 'relatorio' ? 'active' : ''
                        }
                      >
                        <Link to="/integracao/upload">
                          <span>
                            <MdFileUpload />
                          </span>
                          <Translate content="rot_mnu_captura_upload" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                    {permissoes(23) ? (
                      <li
                        className={
                          this.state.page[1] === 'relatorio' ? 'active' : ''
                        }
                      >
                        <Link to="/integracao/download">
                          <span>
                            <MdFileDownload />
                          </span>
                          <Translate content="rot_mnu_captura_download" />
                        </Link>
                      </li>
                    ) : (
                      ''
                    )}
                  </ul>
                </li>
                {/* integracao */}
                {/* sair */}
                <li>
                  <Link to="/login">
                    <span>
                      <MdExitToApp />
                    </span>
                    <Translate content="rot_mnu_sair" />
                  </Link>
                </li>
                {/* sair */}
              </ul>
            </div>
            <div className="menu-rodape">
              <img src={LogoRe9} alt="RE9IT - Your Experience" />
              <p>
                <Translate content="rot_mnu_rodape_dev" style={{ margin: 0 }} />{' '}
                <a
                  href="https://re9it.inf.br"
                  target="_blank"
                  className="link"
                  title="RE9IT - Your Experience"
                  rel="noopener noreferrer"
                >
                  RE9IT
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }
}
