import 'intl'
import 'intl/locale-data/jsonp/pt-BR'

const formatCurrency = (value) =>
  new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' })
    .format(Number(value))
    .replace('R$', '')
    .replace(/\s/g, '')

export default formatCurrency
