import React from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import Home from './pages/Home'
import Campanhas from './pages/Campanhas'
import DashboardCampanha from './pages/DashboardCampanhas'
import UploadCampanha from './pages/UploadCampanhas'
import NovoImpulsionamentoCampanha from './pages/NovoImpulsionamentoCampanhas'
import Engajamento from './pages/Engajamento'
import Lances from './pages/Lances'
import LancesAprovacaoAutomatica from './pages/LancesAprovacaoAutomatica'
import Dashboard from './pages/Dashboard'
import Login from './pages/Login'
import Cadastro from './pages/Cadastro'
import Impulsionamento from './pages/Impulsionamento'
import NovoImpulsionamento from './pages/NovoImpulsionamento'
import Upload from './pages/Upload'
import { isAuthenticated } from './services/auth'
import Usuarios from './pages/Usuarios'
import AlteracaoUsuario from './pages/AlteracaoUsuario'
import Interacoes from './pages/Interacoes'
import UploadCaptura from './pages/UploadCaptura'
import Logs from './pages/Logs'
import InteracaoCliente from './pages/InteracaoCliente'
import CriarInteracao from './pages/CriarInteracao'
import EditarInteracao from './pages/EditarInteracao'
import PdfViewer from './pages/PdfViewer'
import Pedidos from './pages/Pedidos'
import Estoque from './pages/Estoque'
import Produtos from './pages/Produtos'
import Clientes from './pages/Clientes'
import Relatorio from './pages/Relatorio'
import IntegracaoUpload from './pages/IntegracaoUpload'
import IntegracaoDownload from './pages/IntegracaoDownload'
import UploadEngajamentos from './pages/UploadEngajamentos'
import NovoEngajamento from './pages/NovoEngajamento'
import PowerBi from './pages/Relatorio/PowerBi'

const PrivateRoute = ({ component: Component, idTela, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (isAuthenticated()) {
        const telaPermissao = permissoes(idTela)
        if (telaPermissao.id === idTela) {
          return <Component {...props} />
        } else {
          return (
            <Redirect
              to={{
                pathname: `/${telaPermissao.name}`,
                state: { from: props.location }
              }}
            />
          )
        }
      } else {
        return (
          <Redirect
            to={{ pathname: '/login', state: { from: props.location } }}
          />
        )
      }
    }}
  />
)

const CryptoJS = require('crypto-js')
const SECRET_KEY = 'xreydcvuhgbnlkjçlmiy6txsrwed25165'

const decrypt = (id) => {
  const data = CryptoJS.AES.decrypt(id.toString(), SECRET_KEY)
  return data.toString(CryptoJS.enc.Utf8)
}

const permissoes = (idTela) => {
  const permissao = localStorage.getItem('permissoes')
  const permissaoParse = JSON.parse(decrypt(permissao))
  const existe =
    permissaoParse.filter((elem) => elem.id_tela === idTela).length > 0
  if (existe) {
    return { id: idTela }
  } else {
    const id = permissaoParse[0].id_tela
    const telas = rotasTelas(id)
    return { id: id, name: `${telas}` }
  }
}

const rotasTelas = (id) => {
  switch (id) {
    case 1:
      return ''
    case 2:
      return 'impulsionamento'
    case 3:
      return 'importar'
    case 4:
      return 'interacoes'
    case 5:
      return 'upload'
    case 6:
      return 'interacaocliente'
    case 7:
      return 'multicanalidade'
    case 8:
      return 'usuarios'
    case 9:
      return 'logs'
    case 10:
      return 'campanhas'
    case 11:
      return 'lances'
    case 12:
      return 'pedido'
    case 13:
      return 'estoque'
    case 14:
      return 'produtos'
    case 15:
      return 'cadastros'
    case 16:
      return 'campanhascockpit'
    case 17:
      return 'campanhasimportar'
    case 19:
      return 'campanhas/criar'
    case 20:
      return 'clientes'
    case 26:
      return 'engajamento'
    case 27:
      return 'engajamento/criar'
    case 28:
      return 'engajamento-importar'
    default:
      return ''
  }
}

// 10 - cadastro = Campanhas - testes

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute exact path="/" component={Home} idTela={1} />
      {/* <Route path='/tracker' component={tracker} /> */}
      <PrivateRoute
        exact
        path="/contraofertas"
        component={Lances}
        idTela={11}
      />
      <PrivateRoute
        exact
        path="/aprovacao-automatica"
        component={LancesAprovacaoAutomatica}
        idTela={11}
      />
      <PrivateRoute exact path="/cockpit" component={Dashboard} idTela={1} />
      <PrivateRoute exact path="/campanhas" component={Campanhas} idTela={10} />
      <PrivateRoute exact path="/clientes" component={Clientes} idTela={20} />
      <PrivateRoute
        exact
        path="/campanhascockpit"
        component={DashboardCampanha}
        idTela={16}
      />
      <PrivateRoute
        exact
        path="/campanhasimportar"
        component={UploadCampanha}
        idTela={17}
      />
      <PrivateRoute
        path="/campanhas/criar"
        component={NovoImpulsionamentoCampanha}
        idTela={19}
      />
      <PrivateRoute
        exact
        path="/impulsionamento"
        component={Impulsionamento}
        idTela={1}
      />
      <PrivateRoute
        path="/impulsionamento/criar"
        component={NovoImpulsionamento}
        idTela={2}
      />
      <PrivateRoute exact path="/importar" component={Upload} idTela={3} />
      <PrivateRoute
        exact
        path="/interacoes"
        component={Interacoes}
        idTela={4}
      />
      <PrivateRoute exact path="/upload" component={UploadCaptura} idTela={5} />
      <PrivateRoute exact path="/relatorio" component={Relatorio} idTela={21} />
      <PrivateRoute
        exact
        path="/interacaocliente"
        component={InteracaoCliente}
        idTela={6}
      />
      {/* <PrivateRoute exact path='/multicanalidade' component={Multicanalidade} idTela={7} /> */}
      <PrivateRoute exact path="/usuarios" component={Usuarios} idTela={8} />
      <PrivateRoute exact path="/logs" component={Logs} idTela={9} />
      <PrivateRoute
        exact
        path="/usuarios/criar"
        component={Cadastro}
        idTela={8}
      />
      <PrivateRoute
        exact
        path="/usuarios/alterar/:id"
        component={AlteracaoUsuario}
        idTela={8}
      />
      <PrivateRoute
        exact
        path="/interacaocliente/criar"
        idTela={6}
        component={CriarInteracao}
      />
      <PrivateRoute
        exact
        path="/interacaocliente/editar/:id"
        component={EditarInteracao}
        idTela={6}
      />
      <PrivateRoute
        exact
        path="/pdfviewer/:id"
        idTela={6}
        component={PdfViewer}
      />

      <PrivateRoute
        exact
        path="/integracao/upload"
        component={IntegracaoUpload}
        idTela={22}
      />
      <PrivateRoute
        exact
        path="/integracao/download"
        component={IntegracaoDownload}
        idTela={23}
      />

      <PrivateRoute exact path="/pedidos" component={Pedidos} idTela={12} />
      <PrivateRoute exact path="/estoque" component={Estoque} idTela={13} />
      <PrivateRoute exact path="/produtos" component={Produtos} idTela={14} />
      <PrivateRoute exact path="/pbi" component={PowerBi} idTela={24} />

      <PrivateRoute
        exact
        path="/engajamento"
        component={Engajamento}
        idTela={25}
      />
      <PrivateRoute
        exact
        path="/engajamento/criar"
        component={NovoEngajamento}
        idTela={26}
      />
      <PrivateRoute
        exact
        path="/engajamento-importar"
        component={UploadEngajamentos}
        idTela={27}
      />
    </Switch>
  </BrowserRouter>
)

export default Routes
