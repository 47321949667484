const CryptoJS = require('crypto-js')
const SECRET_KEY = 'xreydcvuhgbnlkjçlmiy6txsrwed25165'
export const TOKEN_KEY = '@clientes-digitais'
export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null
export const getToken = () => localStorage.getItem(TOKEN_KEY)
export const login = (token, data = null, cond = false) => {
  token === null || token === undefined
    ? localStorage.removeItem(TOKEN_KEY)
    : localStorage.setItem(TOKEN_KEY, token)
  if (cond) {
    localStorage.setItem('nome', data.nome)
    localStorage.setItem(
      'img',
      data.img !== undefined && data.img !== null ? data.img : ''
    )
    localStorage.setItem(
      'permissoes',
      data.permissao !== undefined && data.permissao !== null
        ? encrypt(data.permissao)
        : ''
    )
    localStorage.setItem('id_usuario', data.id_usuario)
    localStorage.setItem('id_distribuidor', data.id_distribuidor)
    localStorage.setItem('version_message', data.message)
  }
}

const encrypt = (id) => {
  const data = CryptoJS.AES.encrypt(id, SECRET_KEY)
  const encryptData = data.toString()

  return encryptData
}

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY)
  localStorage.removeItem('img')
  localStorage.removeItem('permissoes')
  localStorage.removeItem('nome')
}
