import React, { Component } from 'react'
import './style.css'

export default class Footer extends Component {
  state = {
    year: new Date().getFullYear()
  }

  render() {
    return (
      <div className="footer">
        <p>
          &copy; 2020{' '}
          <a
            title="RE9IT - Your Experience"
            className="link"
            href="https://re9it.inf.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            RE9IT
          </a>{' '}
          - {localStorage.getItem('version_message')}
        </p>
      </div>
    )
  }
}
