import styled from 'styled-components'

export const ProductInfoWrapper = styled.div`
  padding: 24px;

  .product-info {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &__data {
      display: grid;
      grid: 'image title' auto 'image sub' auto / 64px 1fr;
      gap: 6px 12px;
      place-items: center stretch;
    }

    &__title {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 30px;
      font-weight: 700;
      font-family: 'BenchNine', Arial, Helvetica, sans-serif;
      color: #333;
      grid-area: title;
    }

    &__description {
      text-transform: uppercase;
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
      font-family: 'BenchNine', Arial, Helvetica, sans-serif;
      color: #666;
      letter-spacing: 0.04em;
      grid-area: sub;
    }

    &__figure {
      display: block;
      width: 64px;
      height: 64px;
      grid-area: image;
    }

    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: bottom;
      border-radius: 50%;
      box-shadow: 0 0 0 1px #ccc;
    }
  }
`
