import { useEffect, useRef } from 'react'

const useInterval = (handler, time = 4) => {
  const savedHandler = useRef()

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const fire = () => {
      savedHandler.current()
    }
    const interval = setInterval(fire, time * 1000)

    return () => {
      clearInterval(interval)
    }
  }, [time])
}

export default useInterval
