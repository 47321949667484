import React, { useEffect, useState } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { MdChevronRight } from 'react-icons/md'
import { getProdutosHome } from '../../services/api'

import counterpart from 'counterpart'
import Translate from 'react-translate-component'
import pt from '../../lang/pt'
import es from '../../lang/es'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import gridOptions from '../../utils/grid/options/grid-options'
import { imageRenderer } from '../../utils/grid/renderers/image.renderer'
import ActionsRenderer from '../../utils/grid/renderers/actionsRenderer'
import useResize from '../../hooks/useResize'
import ModalSwitcher from './ModalSwitcher'

const produtosGridOptions = {
  ...gridOptions,
  rowHeight: 67
}

counterpart.registerTranslations('pt', pt)
counterpart.registerTranslations('es', es)
counterpart.setLocale('pt')

const Produtos = () => {
  const [rowData, setRowData] = useState([])

  const [gridApi, setGridApi] = useState(null)

  const [showModal, setShowModal] = useState(false)
  const [currentModal, setCurrentModal] = useState('')
  const [modalData, setModalData] = useState({})

  const closeModal = () => {
    setModalData({})
    setCurrentModal('')
    setShowModal(false)
  }

  const componentParent = {
    actions: [
      {
        type: 'edit',
        color: '#f77603',
        callback(rowNode) {
          setCurrentModal('editaProduto')
          setModalData(rowNode.data)
          setShowModal(true)
        }
      }
    ]
  }

  useEffect(() => {
    const fun = async () => {
      try {
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const produto = await getProdutosHome(idDistribuidor)

        if (produto.token === null) {
          return
        }

        if (produto.status === 'error')
          throw new Error(
            'Erro ao carregar a lista de produtos, por favor tente novamente.'
          )

        setRowData(produto.msg)
      } catch (e) {
        swal({ icon: 'info', text: e.message })
      }
    }
    fun()
  }, [])

  const onGridReady = (params) => {
    setGridApi(params.api)

    params.api.sizeColumnsToFit()
  }

  useResize(() => {
    if (gridApi && gridApi.sizeColumnsToFit) {
      gridApi.sizeColumnsToFit()
    }
  })

  return (
    <>
      <Menu />
      <Header />
      <div className="container  container--flex">
        <div className="breadcrumb">
          <div>
            <Translate content="listadeprodutos" component="h3" />
            <p>
              Produtos{' '}
              <span>
                <MdChevronRight />
              </span>{' '}
              <span>Lista de Produtos</span>
            </p>
          </div>
          <span />
        </div>
        <div className="container__body">
          <div id="listaEstoque" className="col-12 h100">
            <div className="box h100  box--flex">
              <div className="box-content scroll-table scroll-table--lonely">
                <div
                  className="ag-theme-balham"
                  style={{ height: '100%', width: '100%' }}
                >
                  <AgGridReact
                    rowSelection="false"
                    frameworkComponents={{
                      actionsRenderer: ActionsRenderer
                    }}
                    gridOptions={produtosGridOptions}
                    context={{ componentParent }}
                    onGridReady={onGridReady}
                    rowData={rowData}
                  >
                    <AgGridColumn
                      floatingFilter={true}
                      width={150}
                      headerName="SKU"
                      sortable={true}
                      sort="asc"
                      filter="agSetColumnFilter"
                      field="sku"
                    />
                    <AgGridColumn
                      floatingFilter={false}
                      width={125}
                      headerName="Imagem"
                      sortable={false}
                      resizable={false}
                      cellRenderer={imageRenderer}
                      field="foto"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={450}
                      headerName="Nome do produto"
                      sortable={true}
                      filter="agSetColumnFilter"
                      field="produto"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={250}
                      headerName="Categoria"
                      sortable={true}
                      filter="agSetColumnFilter"
                      field="categoria"
                    />

                    <AgGridColumn
                      floatingFilter={true}
                      width={150}
                      headerName="Marca"
                      sortable={true}
                      filter="agSetColumnFilter"
                      field="marca"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={150}
                      headerName="Peso caixa"
                      sortable={true}
                      filter="agSetColumnFilter"
                      field="peso_caixa"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      width={125}
                      headerName="Medida"
                      sortable={true}
                      filter="agSetColumnFilter"
                      field="medida"
                    />
                    <AgGridColumn
                      filter={false}
                      headerName="Ações"
                      sortable={false}
                      colId="params"
                      editable={false}
                      field="value"
                      cellRenderer="actionsRenderer"
                    />
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {showModal && currentModal ? (
        <ModalSwitcher
          modal={currentModal}
          data={modalData}
          onClose={closeModal}
        />
      ) : (
        ''
      )}
    </>
  )
}

export default Produtos
