import React, { useContext, useState } from 'react'

export const EditaProdutoContext = React.createContext([{}, () => {}])

export const EditaProdutoProvider = ({ children }) => {
  const [gestores, setGestores] = useState([])
  const [canSubmit, setCanSubmit] = useState(false)

  return (
    <EditaProdutoContext.Provider
      value={{ gestores, setGestores, canSubmit, setCanSubmit }}
    >
      {children}
    </EditaProdutoContext.Provider>
  )
}

export const useEditaProdutoContext = () => useContext(EditaProdutoContext)
