import StarBlack from '../../../assets/black-star.svg'
import StarYellow from '../../../assets/yellow-star.svg'
import React from 'react'
import { resetPassword } from '../../../services/api'
import swal from 'sweetalert'
import Swal from 'sweetalert'

export const imageRenderer = (params) => {
  return `<div style="text-align: center;"><img src="${
    (params || {}).value
  }" style="width: 64px; height: 64px; object-fit: cover;" alt="" /></div>`
}

export const bookmarks = (params) => {
  const v = undefined
  if (v === undefined) {
    return ` 
      <a href='#'>
        <img src="${StarBlack}" style="height: 30px; width: 30px; margin-top: 18%;" alt='' />
      </a>
     `
  }
  return `
    <a href='#'>
      <img src="${StarYellow}" style="height: 30px; width: 30px; margin-top: 18%;" alt='' />
    </a>
  `
}

const addMaskPhone = (value) => {
  const clear = value.replace(/\D/g, '')
  if (clear.length === 11) {
    return clear
      .replace(/\D/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d)(\d{4})(\d)/, '$1 $2-$3')
      .replace(/(-\d{4})(\d+?)$/, '$1')
  }
  if (clear.length === 10) {
    const regex = new RegExp(
      '^\\s*(\\d{2}|\\d{0})[-. ]?(\\d{5}|\\d{4})[-. ]?(\\d{4})[-. ]?\\s*$'
    )
    return regex.exec(clear)
  }
  return ''
}

export const maskPhone = (params) => {
  try {
    const v = (params || {}).value
    if (v && v.length > 0) {
      return addMaskPhone((params || {}).value)
    }
  } catch (e) {
    console.log(e)
    return ''
  }
}

const addMaskMinPhone = (value) => {
  return value !== undefined
    ? value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d)(\d{4})(\d)/, '$1 $2-$3')
        .replace(/(-\d{4})(\d+?)$/, '$1')
    : ''
}

export const ChangePass = async (id) => {
  if (id > 0) {
    Swal({
      icon: 'warning',
      title: 'Tem certeza?',
      text: 'Deseja realmente redefinir a senha?',
      buttons: {
        cancel: {
          text: 'Não',
          value: null,
          visible: true,
          className: '',
          closeModal: true
        },
        confirm: {
          text: 'Sim',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      }
    }).then((value) => {
      if (value) {
        doAction(id)
      }
    })
  } else {
    await swal({
      icon: 'error',
      title: 'Error',
      text: 'Erro ao redefinir senha. Tente novamente mais tarde!'
    })
  }
}

const doAction = async (id) => {
  const send = await resetPassword(id)
  if (send.status && send.status === 'success') {
    await swal({
      icon: 'success',
      title: 'Sucesso',
      text: 'Senha redefinida com sucesso'
    })
  } else {
    await swal({
      icon: 'error',
      title: 'Error',
      text: 'Erro ao redefinir senha. Tente novamente mais tarde!'
    })
  }
}
