import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import './style.css'
import { listaLancesNew } from '../../services/api'
import { MdChevronRight } from 'react-icons/md'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { ClipLoader } from 'react-spinners'
import { cnpjFormatter } from '../../utils/grid/formatters/cnpj.formatter'
import { currencyFormatter } from '../../utils/grid/formatters/currency.formatter'
import { imageRenderer } from '../../utils/grid/renderers/image.renderer'
import ModalSwitcher from './ModalSwitcher'
import ActionsRenderer from '../../utils/grid/renderers/actionsRenderer'
import useResize from '../../hooks/useResize'
import gridOptions from '../../utils/grid/options/grid-options'
import { decimalFormatter } from '../../utils/grid/formatters/decimal.formatter'
import { statusPedidoCellStyle } from '../../utils/grid/cell-styles/statusPedido-cell-style'

let statusLance = 'TODOS'

const Clientes = (props) => {
  const [gridApi, setGridApi] = useState(null)
  const [, setColumnApi] = useState(null)
  const [rowData, setRowData] = useState([])
  const [loader, setLoader] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [stateStatusLance, setStateStatusLance] = useState(statusLance)

  const [showModal, setShowModal] = useState(false)
  const [currentModal, setCurrentModal] = useState('')
  const [modalData, setModalData] = useState({})

  const closeModal = () => {
    setModalData({})
    setCurrentModal('')
    setShowModal(false)
  }

  const onGridReady = (params) => {
    setGridApi(params.api)
    setColumnApi(params.columnApi)

    params.api.sizeColumnsToFit()
  }

  const externalFilterChanged = (newValue) => {
    statusLance = newValue
    gridApi.onFilterChanged()
    setStateStatusLance(newValue)
  }

  const isExternalFilterPresent = () => {
    return statusLance !== 'TODOS'
  }

  const doesExternalFilterPass = (node) => {
    if (node === 'TODOS') return true
    return node.data.aprovado_desc === statusLance
  }

  const componentParent = {
    actions: [
      {
        type: 'info',
        color: '#573631',
        callback(rowNode) {
          setCurrentModal('detalhesLances')
          setModalData(rowNode.data)
          setShowModal(true)
        }
      }
    ]
  }

  useResize(() => {
    console.log('Starting resize listener')
    if (gridApi && gridApi.sizeColumnsToFit) {
      gridApi.sizeColumnsToFit()
    }
  })

  useEffect(() => {
    ;(async () => {
      setLoader(true)
      try {
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const ads = await listaLancesNew(idDistribuidor)

        if (ads.token === null) {
          return
        }

        const { msg: gotLances = [] } = ads || { msg: [] }

        const reOrder = gotLances
        setRowData(gotLances)

        const gotStatuses = reOrder.reduce((acc, curr) => {
          const { aprovado_desc: lance, status: idStatus } = curr
          if (!~acc.findIndex((item) => item.idStatus === idStatus))
            acc.push({ aprovado_desc: lance, idStatus })
          return acc
        }, [])
        gotStatuses.sort((a, b) => {
          return a.aprovado_desc > b.aprovado_desc ? 1 : -1
        })
        if (gotStatuses.length) {
          gotStatuses.unshift({ aprovado_desc: 'TODOS', idStatus: -1 })
        }

        setStatuses(gotStatuses)

        setLoader(false)
      } catch (e) {
        setLoader(false)
        await swal({ icon: 'warning', text: e.message })
      }
    })()
  }, [])

  return (
    <>
      <Menu menuDrop="20" />
      <Header />
      <div className="container  container--flex">
        <div className="breadcrumb" id="breadcrumb">
          <div>
            <h3>ContraOfertas</h3>
            <p>
              ContraOfertas{' '}
              <span>
                <MdChevronRight />
              </span>{' '}
              Listagem
            </p>
          </div>
          <div>
            <div className="grid-tabs">
              {statuses.map((status, index) => (
                <button
                  key={status.idStatus + index}
                  className={`grid-tabs__button${
                    stateStatusLance === status.aprovado_desc
                      ? ' grid-tabs__button--active'
                      : ''
                  }`}
                  type="button"
                  name={status.aprovado_desc}
                  style={statusPedidoCellStyle(
                    { data: { status: status.idStatus } },
                    true,
                    true
                  )}
                  onClick={(event) => {
                    externalFilterChanged(event.target.name)
                  }}
                >
                  {status.aprovado_desc}
                </button>
              ))}
            </div>
          </div>
        </div>
        <div className="container__body">
          <div id="ListaClientes" className="col-12 h100">
            <div className="box h100  box--flex">
              <div className="box-content scroll-table scroll-table--lonely">
                <div
                  className="ag-theme-balham"
                  style={{ height: '100%', width: '100%' }}
                >
                  <AgGridReact
                    rowSelection="false"
                    frameworkComponents={{
                      actionsRenderer: ActionsRenderer
                    }}
                    gridOptions={{
                      ...gridOptions,
                      statusBar: {
                        statusPanels: [
                          {
                            statusPanel: 'agTotalAndFilteredRowCountComponent',
                            align: 'left'
                          }
                        ]
                      }
                    }}
                    context={{ componentParent }}
                    onGridReady={onGridReady}
                    rowData={rowData}
                    animateRows={true}
                    isExternalFilterPresent={isExternalFilterPresent}
                    doesExternalFilterPass={doesExternalFilterPass}
                  >
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="ID"
                      sortable={true}
                      field="id"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Status contraoferta"
                      sortable={true}
                      cellStyle={statusPedidoCellStyle}
                      field="aprovado_desc"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Cód. indústria"
                      sortable={true}
                      field="codigo_industria"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="ID pedido"
                      sortable={true}
                      field="id_pedido"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      valueFormatter={cnpjFormatter}
                      headerName="CNPJ"
                      sortable={true}
                      field="cnpj"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Cliente"
                      sortable={true}
                      field="fantasia"
                    />
                    <AgGridColumn
                      floatingFilter={false}
                      cellRenderer={imageRenderer}
                      hide={true}
                      headerName="Foto"
                      sortable={true}
                      resizable={false}
                      field="foto"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Produto"
                      sortable={true}
                      field="produto"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Marca"
                      sortable={true}
                      field="marca"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Validade"
                      sortable={true}
                      field="validade"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="CD"
                      sortable={true}
                      field="origem"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Data contraoferta"
                      sortable={true}
                      field="data_inicio"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Valor contraoferta"
                      valueFormatter={currencyFormatter('BRL')}
                      sortable={true}
                      field="valor_lance"
                    />
                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Total caixas"
                      sortable={true}
                      valueFormatter={decimalFormatter}
                      field="volume"
                    />

                    <AgGridColumn
                      floatingFilter={true}
                      headerName="Valor total"
                      valueFormatter={currencyFormatter('BRL')}
                      sortable={true}
                      field="valor"
                    />
                    <AgGridColumn
                      filter={false}
                      headerName="Ações"
                      sortable={false}
                      colId="params"
                      editable={false}
                      field="value"
                      cellRenderer="actionsRenderer"
                    />
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className={loader ? 'loader show' : 'loader'}>
        <ClipLoader
          sizeUnit={'px'}
          size={90}
          color={'#FB8501'}
          loading={true}
        />
      </div>
      {showModal && currentModal && (
        <ModalSwitcher
          modal={currentModal}
          data={modalData}
          onClose={closeModal}
        />
      )}
    </>
  )
}

export default Clientes
