import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import SelectSearch from 'react-select-search'
import swal from 'sweetalert'
import formatCurrency from '../../components/util/formatCurrency'
import {
  FaBullhorn,
  FaBullseye,
  FaDollarSign,
  FaWeightHanging
} from 'react-icons/fa'
import { MdChevronRight } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import {
  cockpit,
  cockpitDetalhes,
  cockpitFaturamentoCD,
  cockpitTicketTipologias,
  cockpitTopCinco,
  cockpitTotais
} from '../../services/api'
import './style.css'
import '../../assets/css/search-select.css'

const Highcharts = require('highcharts')

export default class DashCampanha extends Component {
  // eslint-disable-next-line
  constructor(props, context) {
    super(props, context)
  }

  state = {
    detalhamento: [],
    impulsionamentos: [],
    impSelected: '',
    ticketTipologia: [],
    faturamentoCd: [],
    conversoes: 0,
    faturamento: 0,
    volume: 0,
    ticketMedio: 0,
    loader: false,
    top5: []
  }

  async componentDidMount() {
    // await this.permissoes(1)
    this.getDash()
    this.getTop5()
  }

  // permissoes = async (idTela) => {
  //     const permissao = await getPermissoesUsuarios()
  //     console.log(permissao.msg, idTela)
  //     const existe = permissao.msg.filter(elem => elem.id_tela === idTela).length > 0
  //     console.log('existe', existe)

  //     if (!existe) {
  //         this.props.history.push('/impulsionamento')
  //     }
  // }

  getDash = async () => {
    // Função para pegar dados da dash
    try {
      this.setState({ loader: true }) // Inicia loader
      const dash = await cockpit(true)
      if (dash.token === null) {
        return
      }
      // Pega dados da dash
      if (dash.status === 'error') throw new Error(dash.msg)
      // se não pega dash gera msg de erro
      this.setState({ impulsionamentos: dash.msg })
      // Cria variável de estado com os impulsionamentos ativos
      if (dash.msg.length > 0 && dash.msg[0].id !== undefined) {
        this.setState({ impSelected: dash.msg[0].id })
        this.getTotais(dash.msg[0].id)
        this.getTicketTipologia(dash.msg[0].id)
        this.getFaturamentoCd(dash.msg[0].id)
        this.getDetalhamento()
      }
      this.setState({ loader: false })
    } catch (e) {
      this.setState({ loader: false })
      swal({ icon: 'info', text: e.message })
    }
  }

  handleSelect = async (e) => {
    try {
      this.setState({ loader: true })
      this.setState({ impSelected: e.id })
      this.getTotais(e.id)
      this.getTicketTipologia(e.id)
      this.getFaturamentoCd(e.id)
      this.setState({ loader: false })
    } catch (err) {
      this.setState({ loader: false })
      swal({ icon: 'info', text: err.message })
    }
  }

  getDados = (idImpulsionamento) => {
    this.setState({ impSelected: idImpulsionamento })
    this.getTotais(idImpulsionamento)
    this.getTicketTipologia(idImpulsionamento)
    this.getFaturamentoCd(idImpulsionamento)
  }

  getTotais = async (idImpulsionamento) => {
    try {
      const totais = await cockpitTotais(idImpulsionamento, true)
      if (totais.token === null) {
        return
      }
      if (totais.status === 'error') throw new Error(totais.msg)
      this.setState({
        conversoes: totais.msg.conversoes,
        faturamento: totais.msg.faturamento,
        volume: totais.msg.volume,
        ticketMedio: totais.msg.ticketMedio
      })
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
  }

  getTicketTipologia = async (idImpulsionamento) => {
    try {
      const tips = await cockpitTicketTipologias(idImpulsionamento, true)
      if (tips.token === null) {
        return
      }
      if (tips.status === 'error') throw new Error(tips.msg)
      await this.setState({ ticketTipologia: tips.msg })
      this.geraGraficoTicketTipologia()
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
  }

  getFaturamentoCd = async (idImpulsionamento) => {
    try {
      const cds = await cockpitFaturamentoCD(idImpulsionamento, true)
      // console.log('CD===============================================>',cds)
      if (cds.token === null) {
        return
      }
      if (cds.status === 'error') throw new Error(cds.msg)
      await this.setState({ faturamentoCd: cds.msg })
      this.geraGraficoFaturamentoPorCD()
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
  }

  getDetalhamento = async () => {
    // Gera tabela de detalhamento
    try {
      let ids = []
      if (this.state.impulsionamentos.length > 0) {
        ids = await this.state.impulsionamentos.map((i) => {
          return i.id
        })
      }
      // pega impulsionamentos ativos e transforma em array
      if (ids.length > 0) {
        const detalhes = await cockpitDetalhes(ids, true)
        // Pega detalhamento geral dos impulsionamentos ativos
        if (detalhes.status === 'error') throw new Error(detalhes.msg)
        // Se erro gera mensagem de erro
        if (detalhes.msg.length > 0) {
          this.setState({ detalhamento: detalhes.msg })
        }
        // Se há dados
      }
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
  }

  getTop5 = async () => {
    // Gera tabela de detalhamento
    try {
      const top5 = await cockpitTopCinco(null, true)
      if (top5.token === null) {
        return
      }
      // Pega top5 Produtos mais impulsionados
      if (top5.status === 'error') throw new Error(top5.msg)
      // Se erro ao pegar top 5 gera mensagem de erro
      if (top5.msg.length > 0) await this.setState({ top5: top5.msg })
      // Se há dados seta variável de estado
      this.geraGraficoTop5()
      // Gera grafico top5
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
  }

  geraGraficoFaturamentoPorCD = () => {
    try {
      Highcharts.chart('graficoBarra', {
        chart: {
          zoomType: 'xy'
        },
        title: {
          text: 'Faturamento e volume por centro de distribuição.'
        },
        xAxis: [
          {
            categories:
              this.state.faturamentoCd.nomeCd === undefined
                ? ['Não há CDs listados para este impulsionamento']
                : this.state.faturamentoCd.nomeCd,
            crosshair: true
          }
        ],
        yAxis: [
          {
            // Primary yAxis
            labels: {
              format: '{value} Kg',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            },
            title: {
              text: 'Volume',
              style: {
                color: Highcharts.getOptions().colors[1]
              }
            }
          },
          {
            // Secondary yAxis
            title: {
              text: 'Faturamento',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            labels: {
              format: '$ {value}',
              style: {
                color: Highcharts.getOptions().colors[0]
              }
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        legend: {
          layout: 'horizontal',
          align: 'center',
          x: 0,
          verticalAlign: 'bottom',
          y: 0,
          backgroundColor:
            (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
            'rgba(255,255,255,1)'
        },
        series: [
          {
            name: 'Faturamento',
            type: 'column',
            yAxis: 1,
            data:
              this.state.faturamentoCd.faturamento === undefined
                ? [0]
                : this.state.faturamentoCd.faturamento,
            tooltip: {
              valuePrefix: 'R$ '
            }
          },
          {
            name: 'Volume',
            type: 'column',
            data:
              this.state.faturamentoCd.volume === undefined
                ? [0]
                : this.state.faturamentoCd.volume,
            tooltip: {
              valueSuffix: ' Kg'
            }
          }
        ]
      })
    } catch (e) {}
  }

  geraGraficoTicketTipologia = () => {
    try {
      Highcharts.chart('graficoPizza', {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: 'R$ / Kg por Tipologia (%)'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f} %</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false
            },
            showInLegend: true
          }
        },
        series: [
          {
            name: 'Tipologias',
            colorByPoint: true,
            data:
              this.state.ticketTipologia.length === 0
                ? [{ name: 'Não há dados para exibir', y: 0 }]
                : this.state.ticketTipologia
          }
        ]
      })
    } catch (e) {}
  }

  geraGraficoTop5 = () => {
    try {
      Highcharts.chart('graficoTopCinco', {
        chart: {
          type: 'bar',
          height: 400
        },
        title: {
          text: 'Top 5 Produtos'
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Impulsionamentos',
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        xAxis: {
          categories: [''],
          title: {
            text: null
          },
          height: 200
        },
        tooltip: {
          valueSuffix: ' Impulsionamento(s)'
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          }
        },
        legend: {
          layout: 'horizontal',
          align: 'right',
          verticalAlign: 'bottom',
          x: 0,
          y: -70,
          floating: true,
          borderWidth: 1,
          backgroundColor:
            (Highcharts.theme && Highcharts.theme.legendBackgroundColor) ||
            '#FFFFFF',
          shadow: true
        },
        credits: {
          enabled: false
        },
        series:
          this.state.top5.length === 0
            ? [{ name: 'Não há dados para exibir', data: [0] }]
            : this.state.top5
      })
    } catch (e) {}
  }

  render() {
    const detalhamento =
      this.state.detalhamento.length > 0 ? (
        this.state.detalhamento.map((d, index) => (
          <tr key={index} className="table-detalhamento">
            <td>{d.titulo}</td>
            <td>{d.produto.toLowerCase()}</td>
            <td>R$ {formatCurrency(d.faturamento)}</td>
            <td>R$ {formatCurrency(d.ticketMedio)}</td>
            <td>{d.volume} Kg</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="5">Não há detalhes para mostrar</td>
        </tr>
      )

    const imps =
      this.state !== undefined &&
      this.state.impulsionamentos !== undefined &&
      this.state.impulsionamentos.length > 0
        ? this.state.impulsionamentos.map((i) => {
            return {
              id: i.id,
              value: i.id,
              name: i.titulo
            }
          })
        : [
            {
              id: '',
              value: '',
              name: 'Não há impulsos ativos'
            }
          ]

    return (
      <>
        <Menu menuDrop="16" />
        <Header />
        <div className="container pb-0">
          <div className="breadcrumb">
            <div>
              <h3>COCKPIT</h3>
              <p>
                Conversão{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                Impulsionamentos Ativos
              </p>
            </div>
            <span></span>
          </div>
          <div className="row pb-0">
            <div className="col-12 pd-0 pl-0">
              <div className="col-2">
                <div className="box-pop">
                  <div className="pop-icon min">
                    <span>
                      <FaBullhorn />
                    </span>
                  </div>
                  <div className="pop-content min">
                    <h6>Veiculando</h6>
                    <p>{this.state.impulsionamentos.length}</p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="box-pop">
                  <div className="pop-icon min">
                    <span>
                      <FaBullseye />
                    </span>
                  </div>
                  <div className="pop-content min">
                    <h6>Conversões</h6>
                    <p>
                      {this.state.conversoes === undefined ||
                      this.state.conversoes === null
                        ? 0
                        : this.state.conversoes}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-2">
                <div className="box-pop">
                  <div className="pop-icon min">
                    <span>
                      <FaDollarSign />
                    </span>
                  </div>
                  <div className="pop-content min">
                    <h6>R$ / Kg</h6>
                    <p>
                      {this.state.ticketMedio === undefined ||
                      this.state.ticketMedio === null
                        ? formatCurrency(0)
                        : formatCurrency(this.state.ticketMedio)}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="box-pop">
                  <div className="pop-icon">
                    <span>
                      <FaWeightHanging />
                    </span>
                  </div>
                  <div className="pop-content">
                    <h6>Volume (Kg)</h6>
                    <p>
                      {this.state.volume === undefined ||
                      this.state.volume === null
                        ? 0
                        : this.state.volume}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-3">
                <div className="box-pop">
                  <div className="pop-icon">
                    <span>
                      <FaDollarSign />
                    </span>
                  </div>
                  <div className="pop-content">
                    <h6>Faturamento (R$)</h6>
                    <p>
                      {this.state.faturamento === undefined ||
                      this.state.faturamento === null
                        ? formatCurrency(0)
                        : formatCurrency(this.state.faturamento)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex filtro">
                <div className="form-item" style={{ height: 80 }}>
                  <SelectSearch
                    options={imps}
                    placeholder="Impulsionamento"
                    onChange={(e) => this.handleSelect(e)}
                    value={this.state.impSelected}
                  />
                </div>
              </div>
            </div>
            <div
              className={
                this.state.impulsionamentos.length > 0 ? 'col-3' : 'd-none'
              }
            >
              <div className="box tamanhoGrafico">
                <div className="graficoPizza" id="graficoPizza"></div>
              </div>
            </div>
            <div
              className={
                this.state.impulsionamentos.length > 0 ? 'col-9' : 'd-none'
              }
            >
              <div className="box tamanhoGrafico">
                <div className="graficoBarra" id="graficoBarra"></div>
              </div>
            </div>
            <div className="col-3">
              <div className="box tamanhoGrafico">
                <div className="graficoTopCinco" id="graficoTopCinco"></div>
              </div>
            </div>
            <div className="col-9">
              <div className="box tamanhoGrafico detalhamento-title-fix">
                <div className="box-title">
                  <h4 className="b-none">Detalhes impulsionamento ativos</h4>
                  <span> </span>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nome do Kit</th>
                        <th>Produto</th>
                        <th>Faturamento</th>
                        <th>R$/Kg</th>
                        <th>Volume</th>
                      </tr>
                    </thead>
                    <tbody>{detalhamento}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}
