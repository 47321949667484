import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const StyledTableToggle = styled(Paper)`
  && {
    width: 100%;
    min-height: 100%;
    max-height: 100%;
  }
`

export const StyledTableToggleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2px;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  padding-top: 30px;
`

export const StyledTableToggleBody = styled.div`
  transition: max-height linear 0.2s;
  height: 274px;
  margin-top: 24px;
  overflow: auto;
`

export const StyledTableToggleTitle = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 1.25rem;
  font-weight: bold;
  justify-content: center;
  width: 100%;
`

export const StyledTableContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledTable = styled.div`
  text-transform: uppercase;
`

export const StyledTableTitle = styled.h5`
  margin-bottom: 2px;
`

export const StyledTableRow = styled.div`
  display: flex;
  border-top: 1px solid ${(props) => props.theme.palette.hr};
  height: 40px;
  align-items: center;
  min-width: 140px;
  box-sizing: border-box;
  padding: 0 5px;
  justify-content: space-between;

  @media (min-width: 1260px) {
    min-width: 150px;
  }
`

export const StyledTableCellTitle = styled.p`
  font-size: 10px;
  font-style: italic;

  @media (min-width: 1260px) {
    font-size: 12px;
  }
`

export const StyledTableCellContent = styled.p`
  font-size: 10px;
  font-weight: bold;
  color: ${(props) => props.theme.palette.primary.main};

  @media (min-width: 1260px) {
    font-size: 12px;
  }
`

export const StyledStatusAcertoContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const StyledStatusAcerto = styled.div`
  width: 10px;
  height: 10px;
  background: green;
  border-radius: 10px;
`

export const StyledUploadContainer = styled.div`
  display: flex;
  margin-bottom: 20px;

  input {
    background: #fff;
    height: 40px;
    width: 80%;
    box-sizing: border-box;
    padding: 8px;
    border-radius: 5;
  }
`

export const StyledCardContainer = styled.div`
  grid-column: 1 / -1;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 11px;
`

export const StyledCard = styled.div`
  display: flex;
  width: 200px;
  height: 80px;
  align-items: center;
  box-shadow: 0 0 5px -2.5px ${(props) => props.theme.palette.shadow};
  position: relative;
`

export const StyledCardIcon = styled.div`
  color: ${(props) => props.theme.palette.primary.contrast};
  background: ${(props) => props.theme.palette.primary.main};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
`

export const StyledCardValueContainer = styled.div`
  background: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 60%;
`

export const StyledCardTitle = styled.p`
  margin: 2.5px 0;
  font-size: 12px;
`

export const StyledCardValue = styled.p`
  margin: 2.5px 0;
  font-size: 16px;
  font-weight: bold;
`

export const StyledDualTable = styled.div`
  // display: flex;
  // max-width: 100%;
  // flex-direction: row;
  // justify-content: space-between;

  max-width: calc(100vw - 300px);
  display: grid;
  grid: auto auto / repeat(2, 50.5%);
  gap: 10px;
`

export const StyledCardTable = styled.div``
