export const editableCellStyle = (params) => {
  return {
    boxShadow: 'inset 0 0 0 1px #95a5a6',
    cursor: 'text'
  }
}

export const editableGreenCellStyle = (params) => {
  return {
    boxShadow: 'inset 0 0 0 1px #95a5a6',
    cursor: 'text',
    color: '#009900'
  }
}

export const editableRedCellStyle = (params) => {
  return {
    boxShadow: 'inset 0 0 0 1px #95a5a6',
    cursor: 'text',
    color: '#bf0000'
  }
}
