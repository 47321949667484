import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
import { TiCloudStorage } from 'react-icons/ti'
import { MdChevronRight } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Menu from '../../components/Menu'
import {
  getIntegracaoUploadHistory,
  UploadArquivoIntegracao
} from '../../services/api'
import './style.css'
import { FaBarcode, FaDolly, FaShippingFast, FaUsers } from 'react-icons/fa'
import { format, parseISO } from 'date-fns'

const IntegracaoUpload = () => {
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('clients')
  const [file, setFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [history, setHistory] = useState([])

  useEffect(() => {
    loadHistory()
  }, [])

  const handleUpload = async () => {
    try {
      setLoading(true)
      if (!type.length)
        throw new Error(
          'Por favor, informe o tipo de upload que está realizando'
        )

      if (!file) throw new Error('Por favor, selecione um arquivo para envio')

      const formData = new FormData()
      formData.append('type', type)
      formData.append('file', file)

      const response = await UploadArquivoIntegracao(formData)
      if (response.status === 'error') throw new Error(response.msg)

      toggleType('clients')
      setLoading(false)
      loadHistory()
      swal({
        icon: 'success',
        text: response.msg
      })
    } catch (e) {
      setLoading(false)
      swal({
        icon: 'error',
        text: (e || { message: 'Ocorreu um erro, tente novamente mais tarde.' })
          .message
      })
    }
  }

  const fakeLoader = async (e) => {
    setLoading(true)
    const [f] = e.target.files
    setFile(f)
    setFileName(
      f !== undefined
        ? f.name
        : 'Upload cancelado, por favor carregue um arquivo'
    )
    setTimeout(() => setLoading(false), 1000)
  }

  const toggleType = (type) => {
    setType(type)
    setFile(null)
    setFileName('')
  }

  const convertModel = (type) => {
    switch (type) {
      case 'clients':
        return 'Clientes'
      case 'fefo':
        return 'FEFO'
      case 'products':
        return 'Produtos'
      case 'status-order':
        return 'St. Pedido'
      default:
        return type
    }
  }

  const loadHistory = async () => {
    const response = await getIntegracaoUploadHistory()
    setHistory(response.status === 'sucess' ? response.msg || [] : [])
  }

  return (
    <>
      <Menu menuDrop="22" />
      <Header />
      <div className="container">
        <div className="breadcrumb">
          <div>
            <h3>INTEGRAÇÃO</h3>
            <p>
              Integração&nbsp;
              <span>
                <MdChevronRight />
              </span>
              &nbsp; Upload
            </p>
          </div>
          <span />
        </div>
        <div className="row pb-0">
          <div className="col-12">
            <div className="box">
              <div className="box-title">
                <h4>Upload do arquivo</h4>
                <button onClick={() => handleUpload()} className="btn">
                  Salvar
                </button>
              </div>
              <div className="box-content">
                <div className="form-group">
                  <div className="form-item" style={{ width: '100%' }}>
                    <label>Tipo</label>
                    <div
                      className="box-item"
                      style={{
                        margin: 0,
                        padding: 0,
                        height: 100,
                        justifyContent: 'flex-start'
                      }}
                    >
                      <div
                        className={type === 'clients' ? 'item active' : 'item'}
                        onClick={() => toggleType('clients')}
                      >
                        <span>
                          <FaUsers />
                        </span>
                        <p>{convertModel('clients')}</p>
                      </div>
                      <div
                        className={type === 'fefo' ? 'item active' : 'item'}
                        onClick={() => toggleType('fefo')}
                      >
                        <span>
                          <FaDolly />
                        </span>
                        <p>{convertModel('fefo')}</p>
                      </div>
                      <div
                        className={type === 'products' ? 'item active' : 'item'}
                        onClick={() => toggleType('products')}
                      >
                        <span>
                          <FaBarcode />
                        </span>
                        <p>{convertModel('products')}</p>
                      </div>
                      <div
                        className={
                          type === 'status-order' ? 'item active' : 'item'
                        }
                        onClick={() => toggleType('status-order')}
                      >
                        <span>
                          <FaShippingFast />
                        </span>
                        <p>{convertModel('status-order')}</p>
                      </div>
                    </div>
                  </div>
                  <div className="form-item">
                    <label className="upload" htmlFor="up">
                      <span>
                        {!file ? (
                          <>
                            <i id="icon-upload">
                              <TiCloudStorage />
                            </i>
                            Clique aqui para adicionar
                          </>
                        ) : (
                          fileName
                        )}
                      </span>
                    </label>
                    <input
                      type="file"
                      id="up"
                      name="file"
                      className="d-none"
                      onChange={(e) => fakeLoader(e)}
                      accept=".csv"
                    />
                    <br />
                    <p>
                      <strong>Atenção: </strong>Envie somente arquivos em .CSV.
                    </p>
                    <p>
                      <strong>Modelos de arquivos:</strong>{' '}
                      <a
                        href={process.env.PUBLIC_URL + '/download/clientes.csv'}
                        download
                      >
                        Clientes
                      </a>{' '}
                      /{' '}
                      <a
                        href={process.env.PUBLIC_URL + '/download/fefo.csv'}
                        download
                      >
                        Fefo
                      </a>{' '}
                      /{' '}
                      <a
                        href={process.env.PUBLIC_URL + '/download/produtos.csv'}
                        download
                      >
                        Produtos
                      </a>{' '}
                      /{' '}
                      <a
                        href={process.env.PUBLIC_URL + '/download/pedidos.csv'}
                        download
                      >
                        Dados Pedido
                      </a>{' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            <div className="box">
              <div className="box-title">
                <h4>Histórico</h4>
              </div>
              <div className="box-content">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Usuário</th>
                      <th>Módulo</th>
                      <th>QTD de linhas</th>
                      <th>Data Cadastro</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!history.length && (
                      <tr>
                        <td colSpan="4">Nenhum registro</td>
                      </tr>
                    )}
                    {history.map((i, k) => (
                      <tr key={k}>
                        <td>{i.nome}</td>
                        <td>{convertModel(i.modulo)}</td>
                        <td>{i.qtd_linhas}</td>
                        <td>{format(parseISO(i.data), 'dd/MM/yyyy HH:mm')}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className={loading ? 'loader show' : 'loader'}>
        <ClipLoader sizeUnit="px" size={90} color="#FB8501" loading />
      </div>
    </>
  )
}

export default IntegracaoUpload
