import React, { Component, Fragment } from 'react'
import { Link } from 'react-router-dom'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { MdAdd, MdChevronRight, MdClose, MdRemove } from 'react-icons/md'
import { FaFilter } from 'react-icons/fa'
import { ClipLoader } from 'react-spinners'
import _ from 'lodash'
import tipologias from './tipologias.json'
import {
  getListaInteracoes,
  removerRascunhoInteracoes
} from '../../services/api'
import swal from 'sweetalert'
import './style.css'

export default class InteracaoCliente extends Component {
  state = {
    titulo: '',
    nome: '',
    tabAtiva: '',
    topoAtiva: '',
    rascunho: '',
    detalhes: [],
    interacoes: [],
    filtroIsOpen: false,
    filtroBlock: false,
    loader: false,
    exibirDetalhes: false
  }
  async componentDidMount() {
    await this.getInteracoes()
    // this.setState({
    // 	interacoes: interacoesItem
    // })
  }

  removerRascunho = async (id) => {
    this.setState({ loader: true })
    try {
      const deletar = await removerRascunhoInteracoes(id)

      if (!deletar) {
        throw new Error('Ocorreu um erro ao remover o rascunho!')
      }

      this.getInteracoes()
      swal({ icon: 'success', text: 'Rascunho removido com sucesso!' })
    } catch (e) {
      swal({ icon: 'error', text: e.message })
    }
    this.setState({ loader: false })
    // this.filtrar()
  }

  async getInteracoes() {
    this.setState({ loader: true })
    try {
      const listaInt = await getListaInteracoes()

      if (listaInt.token === null) {
        return
      }

      if (listaInt.status === 'error')
        throw new Error(
          'Erro ao carregar a lista de interações, por favor tente novamente.'
        )

      this.setState({ interacoes: listaInt.msg })
    } catch (e) {
      swal({ icon: 'info', text: e.message })
    }
    this.setState({ loader: false })
  }

  removeAcento = (text) => {
    text = text.toLowerCase()
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a')
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e')
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i')
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o')
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u')
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c')
    return text
  }

  toggleFiltro = () => {
    // Abre e fecha menu de filtros
    if (!this.state.filtroBlock)
      this.setState({ filtroIsOpen: !this.state.filtroIsOpen })
    // Se não estiver bloqueado por detalhe aberto reseta o filtro na abertura e fechamento
  }

  toggleTopo = async (e) =>
    this.setState({
      topoAtiva: { ...this.state.topoAtiva, [e]: !this.state.topoAtiva[e] }
    })

  verDetalhes = (id) => {
    // Função para exibir detalhes do impulsionamento dinamicamente
    this.setState({ loader: true })
    // Inicia loader durante a execução da requisição

    const newItems = _.cloneDeep(tipologias)
    let newDetalhes = []

    for (let i = 0; i < newItems.length; i++) {
      if (newItems[i].id_interacao === id) {
        newDetalhes = [...newDetalhes, newItems[i]]
      }
    }

    this.setState({ detalhes: newDetalhes })

    this.setState({
      exibirDetalhes: true,
      filtroBlock: true,
      filtroIsOpen: false,
      loader: false
    })
    document.getElementById('breadcrumb').scrollIntoView()
  }

  fecharDetalhes = () => {
    this.setState({
      exibirDetalhes: false,
      filtroBlock: false,
      filtroIsOpen: false,
      loader: false
    })
    document.getElementById('breadcrumb').scrollIntoView()
  }

  render() {
    const listaDetalhes =
      this.state.detalhes.length === 0 ? (
        <tr>
          <td colSpan="5">Não há tipologias para exibir</td>
        </tr>
      ) : (
        this.state.detalhes.map((t, index) => (
          <Fragment key={index}>
            <tr
              className={
                this.state.topoAtiva[index] ? 'topoAtiva active' : 'topoAtiva'
              }
            >
              <td className="text-left td-fix"> {t.tipologia.toUpperCase()}</td>
              <td className="text-center td-fix">{t.enviados}</td>
              <td className="text-center td-fix">{t.entregues}</td>
              <td className="text-center td-fix">{t.respondidos}</td>
              <td className="text-right">
                <button
                  className="btn-icon"
                  onClick={() => this.toggleTopo(index)}
                >
                  <span>
                    {this.state.topoAtiva[index] ? <MdRemove /> : <MdAdd />}
                  </span>
                </button>
              </td>
            </tr>
            <tr
              className={
                this.state.topoAtiva[index] ? 'topoAtiva' : 'topoAtiva d-none'
              }
            >
              <td className="text-right td-fix">
                <em>SMS</em>
              </td>
              <td className="text-center td-fix">{t.sms.enviados}</td>
              <td className="text-center td-fix">{t.sms.entregues}</td>
              <td className="text-center td-fix">{t.sms.respondidos}</td>
              <td className="text-right td-fix"></td>
            </tr>
            <tr
              className={
                this.state.topoAtiva[index] ? 'topoAtiva' : 'topoAtiva d-none'
              }
            >
              <td className="text-right td-fix">
                <em>E-MAIL</em>
              </td>
              <td className="text-center td-fix">{t.email.enviados}</td>
              <td className="text-center td-fix">{t.email.entregues}</td>
              <td className="text-center td-fix">{t.email.respondidos}</td>
              <td className="text-right td-fix"></td>
            </tr>
            <tr
              className={
                this.state.topoAtiva[index] ? 'topoAtiva' : 'topoAtiva d-none'
              }
            >
              <td className="text-right td-fix">
                <em>WHATSAPP</em>
              </td>
              <td className="text-center td-fix">{t.whatsapp.enviados}</td>
              <td className="text-center td-fix">{t.whatsapp.entregues}</td>
              <td className="text-center td-fix">{t.whatsapp.respondidos}</td>
              <td className="text-right td-fix"></td>
            </tr>
          </Fragment>
        ))
      )

    const interacoesItem =
      this.state.interacoes.length === 0 ? (
        <tr>
          <td colSpan="5">Não há interações criadas</td>
        </tr>
      ) : (
        this.state.interacoes
          .filter((i) => {
            if (
              this.removeAcento(i.titulo).includes(
                this.removeAcento(this.state.titulo)
              ) &&
              String(i.rascunho).includes(this.state.rascunho) &&
              this.removeAcento(i.nome).includes(
                this.removeAcento(this.state.nome)
              ) /*&& i.data_envio.includes(this.state.data_envio)*/
            ) {
              return true
            }
            return false
          })
          .map((i, index) => (
            <Fragment key={index}>
              <tr
                className={
                  this.state.tabAtiva === index ? 'listaTr active' : 'listaTr'
                }
              >
                <td>{i.titulo}</td>
                <td>{i.cobertura}</td>
                <td>{i.nome}</td>
                <td>
                  {i.rascunho === 1 ? (
                    <span className="label-warning">Rascunho </span>
                  ) : (
                    <span className="label-success">Lançado</span>
                  )}
                </td>
              </tr>
              <tr style={{ height: 'auto' }}>
                <td colSpan="6">
                  <input
                    type="checkbox"
                    name="toggle"
                    id={`input-${index}`}
                    className="input-toggle"
                  />
                  <div className="tab-ativa">
                    <div className="box-desc-campanha">
                      <div>
                        <div className="data-desc-campanha">
                          <p>
                            <strong>Alcance</strong>: {i.alcance}
                          </p>
                          <p>
                            <strong>Canais</strong>: {i.canais}
                          </p>
                        </div>
                        <div className="acao-desc-campanha">
                          <button
                            className="btn"
                            onClick={() => this.verDetalhes(i.id)}
                          >
                            Ver Detalhes
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </Fragment>
          ))
      )
    return (
      <>
        <Menu menuDrop="3" />
        <Header />
        <div className="container pb-0">
          <div className="breadcrumb" id="breadcrumb">
            <div>
              <h3>INTERAÇÃO COM O CLIENTE</h3>
              <p>
                Captura{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                <span>Lista de Interações </span>
              </p>
            </div>
            <Link to="/interacaocliente/criar">
              <button className="btn">
                <span></span> Criar Interação
              </button>
            </Link>
          </div>
          <div className="row pb-0">
            <div className={this.state.exibirDetalhes === true ? 'col-7' : ''}>
              <div className="box table-logs">
                <div className="box-title">
                  <h4>Lista de Interações</h4>
                  <div>
                    {this.state.filtroIsOpen ? (
                      <button
                        className="btn btn-red"
                        onClick={() => this.toggleFiltro()}
                      >
                        <span>
                          <MdClose />
                        </span>{' '}
                        Fechar Filtro
                      </button>
                    ) : (
                      <button
                        className="btn"
                        onClick={() => this.toggleFiltro()}
                      >
                        <span>
                          <FaFilter />
                        </span>{' '}
                        Filtrar
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className={this.state.filtroIsOpen ? 'box-content' : 'd-none'}
                >
                  <div className="d-flex-sb filtro-box">
                    <input
                      name="titulo"
                      className="form-control col-4"
                      type="text"
                      onChange={(e) =>
                        this.setState({ titulo: e.target.value })
                      }
                      value={this.state.titulo}
                      placeholder="Título"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      name="usuario"
                      className="form-control col-4"
                      placeholder="Usuário"
                      onChange={(e) => this.setState({ nome: e.target.value })}
                      value={this.state.nome}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      defaultValue={this.state.rascunho}
                      name="status"
                      onChange={(e) =>
                        this.setState({ rascunho: e.target.value })
                      }
                      className="form-control col-3"
                    >
                      <option value="">Status</option>
                      <option value="0">Lançados</option>
                    </select>
                  </div>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Título</th>
                        <th>Cobertura</th>
                        <th>Usuário</th>
                        <th>Status</th>
                        <th width="43"></th>
                        <th width="43"></th>
                      </tr>
                    </thead>
                    <tbody>{interacoesItem}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              id="DetalhesCampanha"
              className={
                this.state.exibirDetalhes === true ? 'col-5' : 'd-none'
              }
            >
              <div className="box">
                <div className="box-title">
                  <h4>{this.state.detaque}</h4>
                  <button
                    className="btn btn-red"
                    onClick={() => this.fecharDetalhes()}
                  >
                    <span>
                      <MdClose />
                    </span>
                  </button>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-center">Enviados</th>
                        <th className="text-center">Entregues</th>
                        <th className="text-center">Respondidos</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{listaDetalhes}</tbody>
                    <tfoot>
                      <tr></tr>
                      <tr>
                        <td colSpan="5">
                          <small></small>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}
