import styled from 'styled-components'

export const DialogSheetWrapper = styled.div`
  .dialog__sheet {
    grid: auto / repeat(3, 1fr);

    &-item {
      padding-left: 12px !important;
      padding-right: 12px !important;
    }
  }

  strong {
    color: #666 !important;
  }
`

export const IFefoTableWrapper = styled.div`
  img {
    width: 48px;
    height: 48px;
    object-fit: cover;
  }

  tr {
    th {
      padding: 12px !important;
    }
  }
`

export const LancesInfo = styled.div`
  display: grid;
  grid: auto / 4fr 3fr 1.5fr 1.5fr;

  strong {
    color: #666;
  }
`

export const DetalhesLanceHighlightWrapper = styled.div`
  margin-bottom: 24px;

  .detalhes-lance-highlight {
    display: grid;
    grid: auto / auto 1fr;
    gap: 24px;
    place-items: center stretch;

    &__title {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      font-family: 'BenchNine', Arial, Helvetica, sans-serif;

      &.laranja {
        color: #ff8f02;
      }

      &.vermelho {
        color: #e50f10;
      }

      &.verde {
        color: #00a200;
      }
    }

    &__box {
      background-color: #e2e2e2;
      border: 1px solid #bbb;
      border-radius: 5px;
      padding: 20px;
      font-size: 18px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      span {
        font-size: 13px;
      }
    }

    &__icon {
      margin-right: 12px;
    }
  }

  &.laranja {
    .detalhes-lance-highlight {
      &__box {
        background-color: #ffdab3;
        border: 1px solid #ff8f02;
        color: #272b2d;
      }
    }
  }

  &.vermelho {
    .detalhes-lance-highlight {
      &__box {
        background-color: #ffd9d9;
        border: 1px solid #e20606;
        color: #272b2d;
      }
    }
  }

  &.verde {
    .detalhes-lance-highlight {
      &__box {
        background-color: #d0e7cd;
        border: 1px solid #009b4a;
        color: #272b2d;
      }
    }
  }
`

export const DetalhesLanceWrapper = styled.div`
  hr {
    appearance: none;
    width: 100%;
    height: 2px;
    background-color: #989898;
    line-height: 2px;
    margin: 24px 0;
    border: none;
  }
`
