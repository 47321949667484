import React, { useCallback } from 'react'
import { FaGavel, FaTimes } from 'react-icons/fa'
import * as S from './styled'
import { formatCurrency } from '../../utils/grid/formatters/currency.formatter'
import { formatCNPJ } from '../../utils/grid/formatters/cnpj.formatter'
import { formatYesNo } from '../../utils/grid/formatters/yesNo.formatter'
import { dotsSimpleFormatter } from '../../utils/grid/formatters/decimal.formatter'

const ModalDetalhesLances = ({ data, onClose }) => {
  const getStatusInfo = useCallback((status) => {
    switch (status) {
      case 3:
        return {
          colorClass: 'laranja',
          phrase: (
            <>
              ContraOferta em aberto: <span>Aguardando a avaliação da contraoferta</span>
            </>
          )
        }
      case 4:
      case 15:
      case 24:
        return {
          colorClass: 'verde',
          phrase: (
            <>
              ContraOferta aprovada{' '}
              <span>
                O valor da contraoferta foi aceito. A indústria analisará a
                disponibilidade de estoque.
              </span>
            </>
          )
        }
      case 8:
      default:
        return {
          colorClass: 'vermelho',
          phrase: (
            <>
              ContraOferta rejeitada{' '}
              <span>
                A contraoferta foi rejeitada pela indústria. Utilize o histórico de
                contraofertas na página do produto para saber os valores que estão
                sendo aceitos.
              </span>
            </>
          )
        }
    }
  }, [])

  return (
    <>
      <div className="dialog__header">
        <div className="dialog__header-left">Detalhes da contraoferta: #{data.id}</div>
        <div className="dialog__header-right">
          <button className="dialog__close-button" onClick={onClose}>
            <FaTimes />
          </button>
        </div>
      </div>
      <div className="dialog__body p24">
        <S.DetalhesLanceWrapper>
          <div className="detalhes-lance mb24">
            <S.DetalhesLanceHighlightWrapper
              className={getStatusInfo(data.status).colorClass}
            >
              <div className="detalhes-lance-highlight">
                <div
                  className={`detalhes-lance-highlight__title ${
                    getStatusInfo(data.status).colorClass
                  }`}
                >
                  CONTRAOFERTA
                </div>
                <div className="detalhes-lance-highlight__box">
                  <div className="detalhes-lance-highlight__icon">
                    <FaGavel />
                  </div>
                  <div>{getStatusInfo(data.status).phrase}</div>
                </div>
                {/*<pre>
                LANCE [ lance em aberto ] 3 laranja 4/24 verde 8 vermelho
                qualquer outro vermelho
              </pre>*/}
              </div>
            </S.DetalhesLanceHighlightWrapper>

            <S.DialogSheetWrapper>
              <div className="dialog__sheet mb48">
                <div className="dialog__sheet-item">
                  <strong>Origem:</strong>
                  <span>{data.centro_distribuicao}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Caixas:</strong>
                  <span>{data.caixas}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Valor total:</strong>
                  <span>{formatCurrency('BRL')(data.preco)}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Realizado em:</strong>
                  <span>{data.data_inicio}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Itens Caixa:</strong>
                  <span>{data.qtd_itens_caixa}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Validade:</strong>
                  <span>{data.validade}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Válido até:</strong>
                  <span>{data.data_fim}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Peso:</strong>
                  <span>{data.peso}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Aceita parcial?</strong>
                  <span>{formatYesNo(data.aceite_parcial)}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Status:</strong>
                  <span>{data.aprovado_desc}</span>
                </div>
                <div className="dialog__sheet-item">
                  <strong>Valor (unid.):</strong>
                  <span>{formatCurrency('BRL')(data.valor_kg)}</span>
                </div>
              </div>
            </S.DialogSheetWrapper>

            <hr />

            <section id="cliente">
              <div className="dialog__title mt12 mb24">Cliente</div>
              <S.LancesInfo>
                <div>
                  <div>
                    <strong>Razão social</strong>
                  </div>
                  <div>{data.razao_social}</div>
                </div>
                <div>
                  <div>
                    <strong>CNPJ</strong>
                  </div>
                  <div>{formatCNPJ(data.cnpj)}</div>
                </div>
                <div>
                  <div>
                    <strong>Saldo</strong>
                  </div>
                  <div>{formatCurrency('BRL')(data.saldo)}</div>
                </div>
                <div>
                  <div>
                    <strong>Status</strong>
                  </div>
                  <div>{data.status_cliente}</div>
                </div>
              </S.LancesInfo>
            </section>

            <hr />

            <section id="cliente">
              <div className="dialog__title mt12 mb24">Estoque</div>

              <S.IFefoTableWrapper>
                <table className="ifefo-table w100">
                  <thead>
                    <tr>
                      <th>Foto</th>
                      <th>Cód. produto</th>
                      <th>Produto</th>
                      <th>Valor (unid.)</th>
                      <th>Valor mínimo</th>
                      <th>Caixas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">
                        <img src={data.foto} alt={data.produto} />
                      </td>
                      <td>{data.sku}</td>
                      <td>{data.produto}</td>
                      <td className="text-center">
                        {formatCurrency('BRL')(data.preco_fefo)}
                      </td>
                      <td className="text-center">
                        {formatCurrency('BRL')(data.valor_minimo)}
                      </td>
                      <td className="text-center">{data.volume_fefo > 0 ? dotsSimpleFormatter(data.volume_fefo) : 0}</td>
                    </tr>
                  </tbody>
                </table>
              </S.IFefoTableWrapper>
            </section>
          </div>
        </S.DetalhesLanceWrapper>
      </div>
      <div className="dialog__footer">
        <div className="dialog__footer-left">
          <button onClick={onClose} type="button" className="ifefo__button">
            Cancelar
          </button>
        </div>
        <div className="dialog__footer-right">
          {/*{formVisible &&
          <button type="button" className="ifefo__button primary" onClick={() => {
            refForm.current.dispatchEvent(new Event('submit'))
          }}>Salvar
          </button>
          }*/}
        </div>
      </div>
    </>
  )
}

export default ModalDetalhesLances
