import React, { Component } from 'react'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import swal from 'sweetalert'
import { FaEye, FaFilePdf, FaFilter } from 'react-icons/fa'
import { MdAdd, MdChevronRight, MdClose, MdRemove } from 'react-icons/md'
import { Link } from 'react-router-dom'
import {
  filtraImpulsionamento,
  getDetalhes,
  getPdf,
  listaRascunhos,
  removerRascunho
} from '../../services/api'
import { ClipLoader } from 'react-spinners'
import './style.css'

import formatCurrency from '../../components/util/formatCurrency'

export default class ImpulsionamentoCampanhas extends Component {
  constructor(props) {
    super(props)

    this.state = {
      tabAtiva: '',
      topoAtiva: [],
      filtros: {
        titulo: '',
        produto: '',
        status: ''
      },
      anuncios: [],
      detalhes: [],
      rascunhos: [],
      exibirDetalhes: false,
      filtroIsOpen: false,
      filtroBlock: false,
      loader: false
    }
  }

  componentDidMount() {
    const asyncMount = async () => {
      // Função com execução assim que o component é carregado
      this.setState({ loader: true })
      // Exibe o loader
      try {
        // const ads = await getImpulsionamentos()
        const ads = await filtraImpulsionamento('', '', '', true)

        if (ads.token === null) {
          return
        }
        // Função para pegar os impulsionamentos
        if (ads.status === 'success') {
          this.setState({
            anuncios: ads.msg.impulsionamentos,
            rascunhos: ads.msg.rascunhos
          })
        } // Se sucesso seta variável de estado
        else {
          swal({ icon: 'info', text: ads.msg })
        } // Se erro exibe alerta

        const rasc = await listaRascunhos(true)
        if (rasc.token === null) {
          return
        }
        if (rasc.status === 'success') this.setState({ rascunhos: rasc.msg })

        this.setState({ loader: false })
      } catch (e) {
        this.setState({ loader: false })
        swal({ icon: 'warning', text: e.message })
      }
      // Fecha o loader
    }

    asyncMount().finally(() => {})
  }

  exportar = async (i) => {
    this.setState({ loader: true })
    try {
      const arquivo = await getPdf(this.state.anuncios[i].id_impulsionamento)

      // console.log('PDF ====> ', arquivo)

      const file = new Blob([arquivo], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(file)
      window.open(pdfUrl)
      this.setState({ loader: false })
    } catch (e) {
      swal({ icon: 'info', text: 'Falha ao exportar pdf' })
      this.setState({ loader: false })
    }
  }

  toggleDesc = async (e) => {
    // Função para exibir o ver mais de cada impulsionamento
    if (this.state.exibirDetalhes) return

    if (e === this.state.tabAtiva) {
      this.setState({ tabAtiva: '' })
    } else {
      this.setState({ tabAtiva: e })
    }
  }

  toggleFiltro = () => {
    // Abre e fecha menu de filtros
    if (!this.state.filtroBlock)
      this.setState({
        filtroIsOpen: !this.state.filtroIsOpen,
        filtros: { titulo: '', produto: '', status: '' }
      })
    // Se não estiver bloqueado por detalhe aberto reseta o filtro na abertura e fechamento
    this.filtrar()
    // Limpa os filtros
  }

  toggleTopo = async (e) =>
    this.setState({
      topoAtiva: { ...this.state.topoAtiva, [e]: !this.state.topoAtiva[e] }
    })

  handleFiltro = async (e) => {
    await this.setState({
      filtros: { ...this.state.filtros, [e.target.name]: e.target.value }
    })
    // console.log(this.state.filtros)
    this.filtrar()
  }
  // Função para lidar com alteração nos inputs do filtro

  filtrar = async () => {
    // filtrar anuncios da página
    const { titulo, produto, status } = this.state.filtros
    this.setState({ loader: true })

    try {
      const filtro = await filtraImpulsionamento(titulo, produto, status, true)

      if (filtro.token === null) {
        return
      }
      // Executa filtro
      if (filtro.status === 'error') throw new Error(filtro.msg)
      // Se erro gera mensagem de erro ocorrido
      // console.log(filtro)
      this.setState({
        anuncios: filtro.msg.impulsionamentos,
        rascunhos: filtro.msg.rascunhos,
        loader: false
      })
      // Estancia anuncios filtrados
    } catch (e) {
      swal({ icon: 'error', text: e.message })
      this.setState({ loader: false })
    }
  }

  removerRascunhoDb = async (rascunho) => {
    this.setState({ loader: true })
    await removerRascunho(rascunho.id_impulsionamento)
    this.filtrar()
  }

  verDetalhes = async (d) => {
    // Função para exibir detalhes do impulsionamento dinamicamente
    this.setState({ loader: true })
    // Inicia loader durante a execução da requisição
    if (d !== '') {
      try {
        this.setState({ detaque: this.state.anuncios[d].titulo })
        // Seta titulo do impulsionamento para exibição
        const detalhes = await getDetalhes(
          this.state.anuncios[d].id_impulsionamento,
          true
        )
        if (detalhes.token === null) {
          return
        }
        // Pega detalhes do impulsionamento selecionado
        if (detalhes.status === 'error') throw new Error(detalhes.msg)
        // Erro caso falha na solicitação
        this.setState({ detalhes: detalhes.msg })
        // Seta variavel de estado detalhes com o conteúdo retornado na consulta da variável detalhes
      } catch (e) {
        swal({ icon: 'info', text: e.message })
        //Alert contendo msg de erro
      }
    }
    this.setState({
      exibirDetalhes: true,
      filtroBlock: true,
      filtroIsOpen: false,
      loader: false
    })
    document.getElementById('breadcrumb').scrollIntoView()
  }

  fecharDetalhes = () => {
    this.setState({
      exibirDetalhes: false,
      filtroBlock: false,
      filtroIsOpen: false,
      loader: false
    })
    document.getElementById('breadcrumb').scrollIntoView()
  }

  render() {
    const { tabAtiva, topoAtiva, exibirDetalhes, detalhes } = this.state

    const listaDetalhes =
      detalhes.length === 0 ? (
        <tr>
          <td colSpan="5">Não há topologias para exibir</td>
        </tr>
      ) : (
        detalhes.map((t, index) => (
          <>
            <tr
              key={index}
              className={topoAtiva[index] ? 'topoAtiva active' : 'topoAtiva'}
            >
              <td className="text-left td-fix"> {t.tipologia}</td>
              <td className="text-center td-fix">{t.volume}</td>
              <td className="text-center td-fix">
                $ {formatCurrency(t.valor_medio_kg)}
              </td>
              <td className="text-center td-fix">
                $ {formatCurrency(t.faturamento)}
              </td>
              <td className="text-right">
                <button
                  className="btn-icon"
                  onClick={() => this.toggleTopo(index)}
                >
                  <span>{topoAtiva[index] ? <MdRemove /> : <MdAdd />}</span>
                </button>
              </td>
            </tr>
            {t.cds.map((c) => (
              <tr
                key={index + this.state.topoAtiva.length}
                className={topoAtiva[index] ? 'topoAtiva' : 'topoAtiva d-none'}
              >
                <td className="text-right td-fix">
                  <em>{c.cd}</em>
                </td>
                <td className="text-center td-fix">{c.volume}</td>
                <td className="text-center td-fix">
                  R$ {formatCurrency(c.valor_kg)}
                </td>
                <td className="text-center td-fix">
                  R$ {formatCurrency(c.faturamento)}
                </td>
                <td className="text-right td-fix"></td>
              </tr>
            ))}
          </>
        ))
      )

    const anuncios =
      this.state.anuncios.length === 0 ? (
        <tr>
          <td colSpan="5">Não há lançamentos liberados para mostrar</td>
        </tr>
      ) : (
        this.state.anuncios.map((a, index) => (
          <>
            <tr
              key={index}
              className={tabAtiva === index ? 'listaTr active' : 'listaTr'}
            >
              <td>{a.titulo}</td>
              <td>{a.produto.toLowerCase()}</td>
              <td className="text-center">
                R$ {formatCurrency(a.faturamento)}
              </td>
              <td className="text-center">
                {a.status ? (
                  <span className="label-success">Ativo</span>
                ) : (
                  <span className="label-red">Encerrado</span>
                )}
              </td>
              <td className="text-right">
                <button
                  className="btn btn-gray btnFix"
                  title="Exportar"
                  onClick={() => this.exportar(index)}
                >
                  <span>
                    <FaFilePdf />
                  </span>
                </button>
              </td>
              <td className="btnGroupFix">
                <label
                  htmlFor={`input-${index}`}
                  className="btn btnFix multi-toggle"
                  title="Ver mais"
                >
                  <span>
                    <FaEye />
                  </span>
                </label>
              </td>
            </tr>

            <tr key={index + 1}>
              <td colSpan="6">
                <input
                  type="checkbox"
                  name="toggle"
                  id={`input-${index}`}
                  className="input-toggle"
                />
                <div className="tab-ativa">
                  <div className="box-desc-campanha">
                    <div>
                      <div className="data-desc-campanha">
                        <p>
                          <strong>Cobertura</strong>: {a.cobertura}
                        </p>
                        <p>
                          <strong>Alcance</strong>: {a.alcance}
                        </p>
                        <p>
                          <strong>Conversões</strong>: {a.conversao}
                        </p>
                      </div>
                      <div className="data-desc-campanha">
                        <p>
                          <strong>Canais</strong>: {a.canais}
                        </p>
                        <p>
                          <strong>R$ / Kg</strong>:{' '}
                          {formatCurrency(a.ticket_medio)}
                        </p>
                      </div>
                      <div className="acao-desc-campanha">
                        <button
                          className="btn"
                          onClick={() => this.verDetalhes(index)}
                        >
                          Ver detalles
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </>
        ))
      )

    return (
      <>
        <Menu menuDrop="10" />
        <Header />
        <div className="container">
          <div className="breadcrumb" id="breadcrumb">
            <div>
              <h3>Campanha</h3>
              <p>
                Campanha{' '}
                <span>
                  <MdChevronRight />
                </span>{' '}
                Criar Campanha
              </p>
            </div>
            <Link to="/campanhas/criar">
              <button className="btn">
                <span>
                  <MdAdd />
                </span>{' '}
                Criar campanha
              </button>
            </Link>
          </div>
          <div className="row pb-0">
            <div
              id="ListaCampanhas"
              className={exibirDetalhes === true ? 'col-7' : 'col-12'}
            >
              <div className="box">
                <div className="box-title">
                  <h4>Lista de campanhas</h4>
                  <div>
                    {this.state.filtroIsOpen ? (
                      <button
                        className="btn btn-red"
                        onClick={() => this.toggleFiltro()}
                      >
                        <span>
                          <MdClose />
                        </span>{' '}
                        Criar filtro
                      </button>
                    ) : (
                      <button
                        className="btn"
                        onClick={() => this.toggleFiltro()}
                      >
                        <span>
                          <FaFilter />
                        </span>{' '}
                        Filtro
                      </button>
                    )}
                  </div>
                </div>
                <div
                  className={this.state.filtroIsOpen ? 'box-content' : 'd-none'}
                >
                  <div className="d-flex-sb filtro-box">
                    <input
                      value={this.state.filtros.titulo}
                      name="titulo"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-6"
                      type="text"
                      placeholder="Título"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <input
                      value={this.state.filtros.produto}
                      name="produto"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-6"
                      type="text"
                      placeholder="Produto"
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <select
                      defaultValue={this.state.filtros.status}
                      name="status"
                      onChange={(e) => this.handleFiltro(e)}
                      className="form-control col-3"
                    >
                      <option value="">Status</option>
                      <option value="Ativo">Ativos</option>
                      <option value="Encerrado">Encerrado</option>
                    </select>
                  </div>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Nome do kit</th>
                        <th>Produto</th>
                        <th className="text-center">Faturamento</th>
                        <th className="text-center">Status</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{anuncios}</tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              id="DetalhesCampanha"
              className={exibirDetalhes === true ? 'col-5' : 'd-none'}
            >
              <div className="box">
                <div className="box-title">
                  <h4>{this.state.detaque}</h4>
                  <button
                    className="btn btn-red"
                    onClick={() => this.fecharDetalhes()}
                  >
                    <span>
                      <MdClose />
                    </span>
                  </button>
                </div>
                <div className="box-content scroll-table">
                  <table className="table">
                    <thead>
                      <tr>
                        <th></th>
                        <th className="text-center">Volume(Kg)</th>
                        <th className="text-center">R$/Kg*</th>
                        <th className="text-center">Faturamento</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>{listaDetalhes}</tbody>
                    <tfoot>
                      <tr></tr>
                      <tr>
                        <td colSpan="5">
                          <small>
                            * O preço / kg varia em valor médio quando aparece
                            em tipologia e valor total quando o CD está na
                            lista.
                          </small>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <div className={this.state.loader ? 'loader show' : 'loader'}>
          <ClipLoader
            sizeUnit={'px'}
            size={90}
            color={'#FB8501'}
            loading={true}
          />
        </div>
      </>
    )
  }
}
