import React, { useEffect, useState } from 'react'
import swal from 'sweetalert'
import Swal from 'sweetalert'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import './style.css'
import { MdChevronRight } from 'react-icons/md'
import { ClipLoader } from 'react-spinners'
import { gerarSenhaLote, listCapturas } from '../../services/api'
import Menu from '../../components/Menu'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { cnpjFormatter } from '../../utils/grid/formatters/cnpj.formatter'
import ActionsRenderer from '../../utils/grid/renderers/actionsRenderer'
import useResize from '../../hooks/useResize'
import gridOptions from '../../utils/grid/options/grid-options'
import { phoneFormatter } from '../../utils/grid/formatters/phone.formatter'
import { yesNoFormatter } from '../../utils/grid/formatters/yesNo.formatter'
import { yesNoCellStyle } from '../../utils/grid/cell-styles/yesNo.cell-style'
import { cpfFormatter } from '../../utils/grid/formatters/cpf.formatter'
import promised from '../../utils/promised'

const Relatorio = () => {
  const [gridApi, setGridApi] = useState(null)
  const [, setColumnApi] = useState(null)
  const [rowData, setRowData] = useState([])
  const [loader, setLoader] = useState(false)

  const [reload, setReload] = useState(false)

  const onGridReady = (params) => {
    setGridApi(params.api)
    setColumnApi(params.columnApi)

    params.api.sizeColumnsToFit()
  }

  const componentParent = {
    actions: [
      {
        condition(rowNode) {
          return rowNode.data.cadastro === 1 && rowNode.data.envio_senha === 0
        },
        type: 'password',
        tooltip: 'Enviar senha',
        callback(rowNode) {
          Swal({
            title: 'Enviar dados de acesso?',
            text: 'Enviaremos um e-mail com os dados para recuperar o acesso!',
            icon: 'warning',
            buttons: true,
            dangerMode: true
          }).then(async (confirmed) => {
            if (confirmed) {
              const [error] = await promised(gerarSenhaLote(rowNode.data.id))
              if (error) {
                swal('Ocorreu um erro, tente novamente mais tarde.')
                return
              }
              setReload(!reload)
              swal('Enviado com sucesso!', {
                icon: 'success'
              })
            }
          })
        }
      }
    ]
  }

  useResize(() => {
    if (gridApi && gridApi.sizeColumnsToFit) {
      gridApi.sizeColumnsToFit()
    }
  })

  useEffect(() => {
    ;(async () => {
      setLoader(true)
      try {
        const idDistribuidor = localStorage.getItem('id_distribuidor')
        const service = await listCapturas(idDistribuidor)

        if (service.token === null) {
          return
        }

        const { msg: capturas = [] } = service || { msg: [] }

        setRowData(capturas)

        setLoader(false)
      } catch (e) {
        setLoader(false)
        swal({ icon: 'warning', text: e.message })
      }
    })()
  }, [reload])

  return (
    <>
      <Menu menuDrop="21" />
      <Header />
      <div className="container  container--flex">
        <div className="breadcrumb" id="breadcrumb">
          <div>
            <h3>Relatório</h3>
            <p>
              Relatório{' '}
              <span>
                <MdChevronRight />
              </span>{' '}
              Relatório de interações
            </p>
          </div>
        </div>

        <div className="container__body">
          <div id="ListaClientes" className="col-12 h100">
            <div className="box h100  box--flex">
              <div className="box-content scroll-table scroll-table--lonely">
                <div
                  className="ag-theme-balham"
                  style={{ height: '100%', width: '100%' }}
                >
                  <AgGridReact
                    rowSelection="false"
                    frameworkComponents={{
                      actionsRenderer: ActionsRenderer
                    }}
                    gridOptions={{
                      ...gridOptions,
                      statusBar: {
                        statusPanels: [
                          {
                            statusPanel: 'agTotalAndFilteredRowCountComponent',
                            align: 'left'
                          }
                        ]
                      }
                    }}
                    context={{ componentParent }}
                    onGridReady={onGridReady}
                    rowData={rowData}
                  >
                    <AgGridColumn
                      floatingFilter
                      width={150}
                      valueFormatter={cnpjFormatter}
                      headerName="CNPJ"
                      sortable
                      field="cnpj"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="Razão Social"
                      sortable
                      field="razao_social"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="Fantasia"
                      sortable
                      field="fantasia"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="Responsável"
                      sortable
                      field="responsavel"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="CPF"
                      valueFormatter={cpfFormatter}
                      sortable
                      field="cpf"
                    />
                    <AgGridColumn
                      floatingFilter
                      width={120}
                      headerName="E-mail"
                      sortable
                      field="email"
                    />
                    <AgGridColumn
                      floatingFilter
                      valueFormatter={phoneFormatter}
                      headerName="Telefone"
                      sortable
                      field="telefone"
                    />
                    <AgGridColumn
                      floatingFilter
                      valueFormatter={phoneFormatter}
                      headerName="Celular"
                      sortable
                      field="celular"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="E-mail enviado?"
                      sortable
                      valueFormatter={yesNoFormatter}
                      cellStyle={yesNoCellStyle}
                      filter="agSetColumnFilter"
                      field="envio"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="Spam?"
                      sortable
                      valueFormatter={yesNoFormatter}
                      cellStyle={yesNoCellStyle}
                      filter="agSetColumnFilter"
                      field="spam"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="Entregue?"
                      sortable
                      valueFormatter={yesNoFormatter}
                      cellStyle={yesNoCellStyle}
                      filter="agSetColumnFilter"
                      field="entregue"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="Clicou?"
                      sortable
                      valueFormatter={yesNoFormatter}
                      cellStyle={yesNoCellStyle}
                      filter="agSetColumnFilter"
                      field="clique"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="Cadastrou?"
                      sortable
                      valueFormatter={yesNoFormatter}
                      cellStyle={yesNoCellStyle}
                      filter="agSetColumnFilter"
                      field="cadastro"
                    />
                    <AgGridColumn
                      floatingFilter
                      headerName="Acessou o iFefo?"
                      sortable
                      valueFormatter={yesNoFormatter}
                      cellStyle={yesNoCellStyle}
                      filter="agSetColumnFilter"
                      field="acesso"
                    />
                    {/*<AgGridColumn*/}
                    {/*  filter={false}*/}
                    {/*  headerName="Ações"*/}
                    {/*  sortable={false}*/}
                    {/*  colId="params"*/}
                    {/*  editable={false}*/}
                    {/*  field="value"*/}
                    {/*  cellRenderer="actionsRenderer"*/}
                    {/*/>*/}
                  </AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <div className={loader ? 'loader show' : 'loader'}>
        <ClipLoader sizeUnit="px" size={90} color="#FB8501" loading />
      </div>
    </>
  )
}

export default Relatorio
