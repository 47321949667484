/**
 * Devolve sim e não (binário)
 * @param {any} value
 * @returns {string}
 */
export const formatYesNo = (value) => {
  return !!value ? 'Sim' : 'Não'
}

export const yesNoFormatter = (params) => {
  return formatYesNo(params.value)
}
