import styled from 'styled-components'

export const ModalDetalhesPedidoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-flow: column nowrap !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  position: relative !important;

  td.motivo {
    .motivo-flex {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
    }
  }
  .font-size-1-1 {
    font-size: 1.1em;
  }

  .denied td {
    position: relative;
  }

  tr.denied {
    background-color: rgba(255,0,0,0.2) !important;
  }
  
  tr.denied td {
    //color:red !important;
  }
  tr.denied td:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid darkred !important;
    width: 100%;
    z-index: 10;
  }

  tr.denied td:after {
    content: "\\00B7";
    font-size: 1px;
  }

  tr.denied p {
    margin-top: 14px;
  }

  tr.denied img {
    //filter: grayscale(100%);
    //z-index: 1;
  }
`
