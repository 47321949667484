import { getNumber } from '../../parsers/get-number'

export const formatDecimal = (value) => {
  if (typeof value === 'undefined' || value === null) return ''
  const valueNum = getNumber(value)
  if (isNaN(valueNum)) return ''
  return valueNum.toFixed(2).replace(/0+$/gm, '').replace(/\.$/gm, '')
}

export const decimalFormatter = (params) => {
  return formatDecimal(params.value)
}

export const dotsFormatter = (params) => {
  if (params.value > 0) {
    return params.value.toString().replace(".", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  } else {
    return 0;
  }
}

export const dotsSimpleFormatter = (value) => {
  if (value > 0) {
    return value.toString().replace(".", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  } else {
    return 0;
  }
}
export const currencyTest = (value) => {
  if (value > 0) {
    return value.toString().replace(".", "").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  } else {
    return 0;
  }
}
