import React, { forwardRef, useImperativeHandle, useState } from 'react'

// eslint-disable-next-line react/prop-types
export default forwardRef((props, ref) => {
  const {
    api,
    rowIndex,
    color,
    colDef: { tooltipField }
  } = props || { colDef: {} }
  // eslint-disable-next-line react/prop-types

  const [data] = useState(api.getDisplayedRowAtIndex(rowIndex).data)
  const tooltipInfo = {
    produtos_detalhe: 'produtos_detalhe'
  }[tooltipField]

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses () {
        return ['custom-tooltip']
      }
    }
  })

  const getDataTooltip = (line) => {
    return line.toString().split('|,')
  }

  return props.value ? (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: color || 'white' }}
    >
      {(data[tooltipInfo] || undefined).toString().length ? (
        getDataTooltip(data[tooltipInfo]).map((line, x) => (
          <p key={x}>
            {line.toString().replace("|", "")}
          </p>
        ))
      ) : (
        <p>Nenhuma informação disponível</p>
      )}
    </div>
  ) : (
    <></>
  )
})
