import { createGlobalStyle } from 'styled-components'

const createSpacing = (...values) => {
  return values.map((val) => {
    return {
      [`.m${val}`]: {
        margin: `${val}px`
      },
      [`.mt${val}`]: {
        'margin-top': `${val}px`
      },
      [`.mb${val}`]: {
        'margin-bottom': `${val}px`
      },
      [`.ml${val}`]: {
        'margin-left': `${val}px`
      },
      [`.mr${val}`]: {
        'margin-right': `${val}px`
      },
      [`.mv${val}`]: {
        'margin-top': `${val}px`,
        'margin-bottom': `${val}px`
      },
      [`.mh${val}`]: {
        'margin-left': `${val}px`,
        'margin-right': `${val}px`
      },
      [`.pt${val}`]: {
        'padding-top': `${val}px`
      },
      [`.p${val}`]: {
        padding: `${val}px`
      },
      [`.pb${val}`]: {
        'padding-bottom': `${val}px`
      },
      [`.pl${val}`]: {
        'padding-left': `${val}px`
      },
      [`.pr${val}`]: {
        'padding-right': `${val}px`
      },
      [`.pv${val}`]: {
        'padding-top': `${val}px`,
        'padding-bottom': `${val}px`
      },
      [`.ph${val}`]: {
        'padding-left': `${val}px`,
        'padding-right': `${val}px`
      }
    }
  })
}

const GlobalStyles = createGlobalStyle`
  .dialog {
    &__header,
    &__footer {
      flex: 0 0 72px;
      background-color: #f8f8f8;
      height: 72px;
      width: 100%;
      padding: 12px 24px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
    }

    &__header {
      &-left {
        text-transform: uppercase;
        //font-size: 24px;
        font-size: 1.5rem;
        line-height: 36px;
        font-weight: 700;
        font-family: 'BenchNine', Arial, Helvetica, sans-serif;
      }
    }

    &__body {
      overflow: auto;
    }

    &__close-button {
      width: 36px;
      height: 36px;
      background-color: transparent;
      appearance: none;
      text-align: center;
      font-size: 24px;
      font-family: 'BenchNine', Arial, Helvetica, sans-serif;
      color: #aaaaaa;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        color: #212121;
      }
    }

    &__navigation {
      flex: 0 0 72px;
      height: 72px;
      padding: 12px 18px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      .dialog-nav {
        &__list {
          list-style: none inside;
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }

    &__body {
      flex: 1 1 auto;
      width: 100%;
      position: relative;
    }

    &-form {
      width: 100%;

      &__wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        display: grid;
        grid: auto / repeat(2, 1fr);
      }

      &__left {
        padding: 24px;
        overflow-y: auto;
      }

      &__right {
        padding: 24px;
        background-color: #f8f8f8;
        overflow-y: auto;
      }

      &__avatar {
        text-align: center;

        img {
          width: 96px;
          height: 96px;
          border-radius: 50%;
        }
      }

      &__full {
        padding: 24px;
        grid-column: 1 / -1;
      }

      &__field {
        margin-bottom: 24px;
      }

      &__label {
        font-size: .75rem !important;
        line-height: 1.3333333333em !important;
        color: #333333;
        font-weight: 700;
        display: inline-block;
        margin-bottom: 6px;
      }

      &__error {
        display: inline-block;
        font-size: 12px;
        color: #ff0000;
      }

      &__text {
        appearance: none;
        background-color: #ffffff;
        color: #4e555b;
        border: solid 1px #cdd4d9;
        width: 100%;
        height: 36px;
        font-size: 13px;
        line-height: 24px;
        padding: 6px;
        border-radius: 18px;
      }
    }

    &__title {
      text-transform: uppercase;
      font-size: 24px;
      line-height: 36px;
      font-weight: 700;
      font-family: 'BenchNine', Arial, Helvetica, sans-serif;
      color: #666;
    }

    &__sheet {
      display: grid;
      grid: auto / 3fr 4fr;
      place-items: stretch;
      gap: 24px 0;
      position: relative;

      &::after {
        content: '';
        height: 1px;
        background-color: #ebebeb;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -12px;
      }

      strong {
        color: #666;
      }

      &-item {
        display: grid;
        grid: auto / 200px 1fr;
        place-items: stretch;
        gap: 24px;
        padding-left: 12px;
        padding-right: 12px;
        position: relative;

        &:nth-child(2n) {
          padding-right: 0;
        }

        &:nth-child(2n + 1) {
          padding-left: 0;
        }

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          bottom: -12px;
          height: 1px;
          background-color: #ebebeb;
        }
      }

      &-field {
        height: 30px;
        line-height: 20px;
        padding: 5px 10px;
        border: 1px solid #d8dde1;
        border-radius: 15px;
        margin-top: -5px;
        margin-bottom: -5px;
      }
    }

    &__overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(255, 255, 255, .4);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
    }
  }

  .ifefo-data {
    &__list {
      list-style: none inside;
      display: grid;
      grid: auto / 1fr;
      gap: 6px;
      place-items: center stretch;

      &-item {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        padding: 12px;
        position: relative;

        &:not(:last-child)::after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: #e2e2e2;
          position: absolute;
          left: 0;
          bottom: -3px;
        }
      }
    }

    &__label {
      flex: 0 0 100px;
      margin-right: 12px;
      font-weight: 700;
      white-space: nowrap;
      font-size: .75rem !important;
      line-height: 1.3333333333em !important;
    }

    &__value {
      flex: 1 1 auto;
      font-size: .75rem !important;
      line-height: 1.3333333333em !important;
    }
  }

  .ifefo {
    &__button {
      appearance: none;
      background-color: #eeeeee;
      color: #7b7b7b;
      border: none;
      border-radius: 18px;
      height: 36px;
      padding: 12px;
      font-size: 1.25rem;
      line-height: 1.2;
      text-transform: uppercase;
      cursor: pointer;
      font-family: 'BenchNine', Arial, Helvetica, sans-serif;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      &:not(.active):hover {
        background-color: #e1e1e1;
      }

      &:not(:last-child) {
        margin-right: 12px;
      }

      &:disabled {
        background-color: #cccccc;
        color: #333333;
      }

      &.primary {
        background-color: #ff931a;
        color: #ffffff;

        &:not(:disabled):hover {
          background-color: #ff8700;
        }
      }

      &.active {
        background-color: #ff931a;
        color: #ffffff;
        cursor: default !important;

      }
    }
  }

  .ifefo-typo {
    &__title {
      text-transform: uppercase;
      font-size: 30px;
      line-height: 36px;
      font-weight: 700;
      font-family: 'BenchNine', Arial, Helvetica, sans-serif;
      margin-bottom: 12px;
    }

    &__subtitle {
      font-size: 24px;
      line-height: 30px;
      font-weight: 500;
      font-family: 'BenchNine', Arial, Helvetica, sans-serif;
      margin-bottom: 12px;
    }
  }

  .ifefo-table {
    box-sizing: border-box;
    border-collapse: collapse;

    thead {
      tr {
        background-color: #f9f9f9;

        th {
          color: #6a6a6a;
          border: solid 1px #ccc;
          box-sizing: border-box;
          border-collapse: collapse;
          padding: 6px 12px;
        }
      }
    }

    tbody {
      tr {
        td {
          color: #6a6a6a;
          padding: 6px 12px;
          border: solid 1px #ccc;
        }
      }
    }

    tfoot {
      tr {
        td {
          color: #6a6a6a;
          padding: 6px 12px;
          border: solid 1px #ccc;
        }
      }
    }
  }

  .ifefo-page {
    &__navigation {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  @media (max-width: 767px), (max-height: 524px) {
    .dialog {
      &__header,
      &__footer,
      &__navigation {
        flex: 0 0 48px;
        height: 42px;
        padding: 6px 10px;
      }

      &-form {
        &__left,
        &__right {
          padding: 10px;
        }

        &__field {
          margin-bottom: 12px;
        }

        &__label {
          font-size: 13px;
          line-height: 16px;
        }

        &__error {
          font-size: 10px;
        }

        &__text {
          height: 28px;
          font-size: 13px;
          line-height: 16px;
          padding: 6px;
          border-radius: 14px;
        }
      }
    }

    .ifefo {
      &-data {
        &__list {
          gap: 6px;

          &-item {
            padding: 3px;

            &:not(:last-child)::after {
              bottom: -3px;
            }
          }
        }

        &__label,
        &__value {
          font-size: 13px;
          line-height: 15px;
        }
      }

      &__button {
        font-size: 18px;
        line-height: 18px;
        border-radius: 15px;
        height: 30px;
        padding: 6px 12px;
        //font-family: sans-serif, Arial, Helvetica;
      }
    }
  }

  @media (max-width: 600px) {
    .ifefo {
      &-data {
        &__list {
          &-item {
            display: block;
          }
        }
      }
    }

    .dialog {
      &-form {
        &__wrapper {
          grid: auto / 1fr;
        }

        &__left,
        &__right {
          overflow-y: visible;
        }
      }
    }
  }

  .ag-input-wrapper {
    .ag-text-field-input {
      height: 24px;
    }
  }

  .layout-viewer-container .iphone.iphone {
    height: 494.306px !important;
    background-image: url(https://ifefo.s3.amazonaws.com/iphone.4de6231c.png) !important;
    margin: 8px auto !important;
  }

  .layout-viewer-container .iphone-header.iphone-header {
    height: 20.2% !important;
    margin: 8px auto !important;

  }

  .layout-viewer-container .iphone-header.iphone-header > p {
    padding-top: 31% !important;
    padding-left: 20.5% !important;
    margin: 8px !important;
  }

  .layout-viewer-container .email-message.email-message {
    display: flex !important;
    flex-flow: column nowrap !important;
  }

  .layout-viewer-container .email-message.email-message > img {
    order: 1 !important;
  }

  .layout-viewer-container .email-message.email-message > pre {
    order: 0 !important;
  }

  .row-container-aside {
    display: grid;
    grid: auto / 3fr minmax(300px, 1fr);
    gap: 24px;
  }

  .iphone-mock {
    position: relative;

    &::after {
      content: '';
      display: block;
      padding-top: 199.79057666667%;
    }

    &__icons {
      p {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

    &__inner {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      //padding: 10%;
      display: flex;
      flex-flow: column nowrap;
      padding: 30% 10% 10%;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background-size: calc(100% - 64px);
        background-color: transparent;
        background-image: url(https://re9it-ifefo.s3.us-east-1.amazonaws.com/email-header.b5ebb82f.png);
        background-repeat: no-repeat;
        background-attachment: scroll;
        background-position: center 9%;
        pointer-events: none;
      }
    }

    &__header {
      margin-top: 20%;
      //background-color:yellow;
      flex: 0 0 auto;
      padding-bottom: 24px;
      position: relative;
      z-index: 1;
    }

    &__body {
      position: relative;
      z-index: 1;
      flex: 1 1 auto;
      display: flex;
      flex-flow: column nowrap;

      &-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        overflow: auto;
        display: grid;
        grid: 'gridtext' auto' gridimage' auto / 1fr;
        grid-template-rows: min-content;
        gap: 12px;

        > .email-image {
          max-width: 100%;
          margin: 0 auto 12px;
          display: block;
          grid-area: gridimage;
        }

        > .email-text {
          grid-area: gridtext
        }
      }
    }

    &__footer {
      position: relative;
      z-index: 1;
      display: none;
      flex: 0 0 auto;
    }

    &__skeleton {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: transparent url(https://ifefo.s3.us-east-1.amazonaws.com/iphone.png);
      background-size: cover;
      pointer-events: none;
      user-select: none;
      z-index: 2;
    }
  }

  .ifefo-action {
    &__button {
      font-size: 16px !important;
      line-height: 18px !important;
      margin: 0 8px 0 0 !important;
      padding: 0 !important;
      width: 24px !important;
      height: 24px !important;
      min-height: 24px !important;
      border-radius: 50% !important;

      &:not(.active):hover {
        background-color: #ccc !important;
      }

      &--cancel {
        background-color: #eee !important;
        color: #ba0003 !important;

        &.active {
          background-color: #f67302 !important;
          color: #ffffff !important;
        }
      }

      &--accept {
        background-color: #eee !important;
        color: #00e300 !important;

        &.active {
          background-color: #f67302 !important;
          color: #ffffff !important;
        }
      }

      &.inactive {
        color: #666 !important;
      }
    }
  }

  .text {
    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }

  ${createSpacing(6, 10, 12, 24, 48)}
  .box-content .ag-theme-balham {
    //margin-top: 40px;
  }

  .box-actions {
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: -20px;

    h2 {
      margin-top: -10px !important;
    }

    &&__formgroup {
      //max-width: 16.66666%;
    }
    &&__formgroup label {
      margin-top: 10px;
    }

    &__title {
      position: relative;
      left: 0;
      top: 0;
      width: auto;
      padding: 8px;
      background-color: #f97b02;
      color: #ffffff;
      font-size: 10px;
      text-transform: uppercase;
      border-radius: 5px 0 5px 0;
      margin-right: 15px;
      min-width: 100px;
    }

    &__form {
      flex: 3 3 auto;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: flex-end;
      margin-top: 52px;
      font-size: 12px;

      &group {
        margin-right: 12px;

        label {
          margin-right: 8px;
        }

        input[type=radio] {
          vertical-align: middle;
          height: 30px;
        }
      }
    }
  }

  .input-default {
    appearance: none;
    border: 1px solid #ecebeb;
    background-color: #ffffff;
    color: #464646;
    font-size: 13px;
    line-height: 16px;
    height: 30px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 15px;
    outline: none;
    max-width: 50%;
    margin-right: 10px;
  }

  .btn-default {
    appearance: none;
    outline: none;
    -webkit-touch-callout: none;
    cursor: pointer;
    background: #f97b02;
    color: #ffffff;
    font-size: 13px;
    line-height: 18px;
    height: 30px;
    border-radius: 15px;
    padding-left: 8px;
    padding-right: 8px;
    border: none;
    min-width: 160px;
  }

  .ifefo-specs {
    list-style: none inside;
    display: grid;
    grid: auto / repeat(auto-fill, minmax(400px, 1fr));
    gap: 24px;

    &__item {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      position: relative;

      &::after {
        content: '';
        height: 1px;
        background-color: #ebebeb;
        position: absolute;
        left: 0;
        right: 0;
        bottom: -12px;
      }
    }

    &__label {
      flex: 0 0 140px;
      font-weight: 700;
      width: 140px;

      &::after {
        content: ":";
      }
    }

    &__value {
      flex: 1 1 auto;
    }

    &__actions {
      a {
        text-decoration: none;
      }
    }
  }

  .double-cell {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    gap: 12px;
  }

  .ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    box-orient: vertical;
    -webkit-box-orient: vertical;
    white-space: nowrap;
  }

  .stretch {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
  @media (max-width: 1366px) {
    .btn-default {
      font-size: 11px;
      min-width: 140px;
    }
    .width-less {
      min-width: 22%;
    }
    .width-less-values {
      width: 30%;
      margin-right: -5%;
    }
  }
`

export default GlobalStyles
