import React from 'react'
import DialogIfefo from '../../../components/DialogIfefo'
import ModalEditaProduto from './ModalEditaProduto'

const ModalSwitcher = ({ modal, data, onClose }) => {
  return (
    <DialogIfefo aria-labelledby="simple-dialog-title" open={true}>
      {modal &&
        {
          editaProduto: <ModalEditaProduto data={data} onClose={onClose} />
        }[modal]}
    </DialogIfefo>
  )
}

export default ModalSwitcher
