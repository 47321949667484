import React, { useEffect } from 'react'
import swal from 'sweetalert'
import { listaGestoresProduto } from '../../../../services/api'
import { useEditaProdutoContext } from './EditaProdutoContext/edita-produto.context'

const GestoresProduto = ({ data }) => {
  const { gestores, setGestores, setCanSubmit } = useEditaProdutoContext()
  useEffect(() => {
    listaGestoresProduto(data.id_produto)
      .then((response) => {
        const { msg: managers } = response
        managers.map((manager) => {
          manager.isActive = manager.fl_ativo
          return manager
        })
        if (setGestores) {
          setGestores(managers)
        }
      })
      .catch(() => {
        swal({
          icon: 'error',
          text:
            'Não foi possível baixar a lista de gestores, tente novamente mais tarde.'
        })
      })
  }, [data.id_produto, setGestores])

  const toggleGestorAtivo = (event, gestor) => {
    event.preventDefault()
    const newState = [...gestores]
    const found = newState.find(
      (g) => g.id_usuario && g.id_usuario === gestor.id_usuario
    )
    if (found) {
      found.isActive = !found.isActive
      setGestores(newState)
    }
  }

  useEffect(() => {
    if (setCanSubmit) {
      const found = gestores.find((g) => {
        return !!g.fl_ativo !== !!g.isActive
      })
      setCanSubmit(!!found)
    }
  }, [gestores, setCanSubmit])

  return (
    <>
      <ul className="ifefo-specs">
        {(gestores || []).map((gestor, index) => (
          <li className="ifefo-specs__item" key={index}>
            <div className="ifefo-specs__value">{gestor.Nome}</div>
            <div className="ifefo-specs__actions">
              <a href="/" onClick={(event) => toggleGestorAtivo(event, gestor)}>
                {gestor.isActive ? '✅' : '❌'}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default GestoresProduto
