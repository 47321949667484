import { getNumber } from '../../parsers/get-number'

export const resolveLanceValues = (key, params) => {
  const valorIfefo = getNumber(params.data.valor_ifefo)
  const isPerc = (key || '').startsWith('perc_')
  const target = isPerc ? (key || '').replace('perc_', '') : `perc_${key}`
  const val = getNumber(params.newValue)
  const calc = isPerc ? (valorIfefo * val) / 100 : (val / valorIfefo) * 100
  params.node.setData({
    ...params.node.data,
    ...{
      [target]: calc
    }
  })
}
