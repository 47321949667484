import axios from 'axios'
import { getToken, isAuthenticated, login } from './auth'
import swal from 'sweetalert'
import promised from '../utils/promised'
import _ from 'lodash'
import { v4 as uuidV4 } from 'uuid'

const FileDownload = require('js-file-download')

const api = axios.create({
  // baseURL: 'http://clientes-digitais-1973904067.us-east-1.elb.amazonaws.com:3901' // End Produção
  // baseURL: 'http://clientes-digitais-hml-1817647198.us-east-1.elb.amazonaws.com:3901' // End de Homologação
  // baseURL: 'http://localhost:3901' // process.env.REACT_APP_API_URL
  // baseURL: `http://clientesdigitaishomolog-2003635.us-east-1.elb.amazonaws.com:3901`
  baseURL: 'https://portal.ifefo.com.br:3901'
})

api.interceptors.request.use(async (config) => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = token
  }

  return config
})

export const listaLances = async () => {
  const url = `listaLances`
  const { data } = await api.get(url)
  return data
}

export const resetPassword = async (id) => {
  try {
    const url = `clientes/${id}/reset-password`
    const { data } = await api.put(url, {})
    return data
  } catch (e) {
    return null
  }
}

export const listaLancesNew = async (id_distribuidor) => {
  const url = `listaLancesNew`
  const body = { id_distribuidor }
  const { data } = await api.post(url, body)
  return data
}

export const listaLancesDistribuidor = async (id_distribuidor) => {
  const url = `listaLancesDistribuidor`
  const body = { id_distribuidor }
  const { data } = await api.post(url, body)

  return data
}

export const sendAcaoAprovaRejeitarLance = async (idLance, acao) => {
  // const url = 'lances-aceitar-rejeitar'
  const url = 'lances-aceitar-rejeitar-new'
  const body = { idLance, acao }
  const { data } = await api.put(url, body)
  // login(data.token)
  // if (!isAuthenticated()) {
  //   swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => { return window.location.href = '/login' })
  // }
  return data
}

export const getListaLancesAutomaticAproval = async () => {
  const url = `getListaLancesAutomaticAproval`
  const { data } = await api.get(url)
  // login(data.token)
  // if (!isAuthenticated()) {
  //   swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => { return window.location.href = '/login' })
  // }
  return data
}

export const getListaLancesAutomaticAprovalNew = async (id_distribuidor) => {
  const url = `getListaLancesAutomaticAprovalNew`
  const body = { id_distribuidor }
  const { data } = await api.post(url, body)
  return data
}

export const inserirAprovacaoAuto = async (lista, max, min) => {
  const url = 'inserirAprovacaoAuto'
  const body = { lista, max, min }
  const { data } = await api.post(url, body)
  // login(data.token)
  // if (!isAuthenticated()) {
  //   swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => { return window.location.href = '/login' })
  // }
  return data
}

export const inserirAprovacaoAutoNew = async (lista, max, min) => {
  const url = 'inserirAprovacaoAutoNew'
  const body = { lista, max, min }
  const { data } = await api.post(url, body)
  return data
}

export const inserirAprovacaoAutoNewest = async (rows) => {
  const url = 'inserirAprovacaoAutoNewest'
  const body = { lista: rows }
  const { data } = await api.post(url, body)
  return data
}

export const getCampanha = async () => {
  const url = `getCampanha`
  const { data } = await api.get(url)
  // login(data.token)
  // if (!isAuthenticated()) {
  //   swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => { return window.location.href = '/login' })
  // }
  return data
}

export const getPedido = async () => {
  const url = `getPedido`
  const { data } = await api.get(url)
  return data
}

export const getPedidoHome = async (id_distribuidor) => {
  const url = `getPedidoHome`
  const body = { id_distribuidor }
  const { data } = await api.post(url, body)
  return data
}

export const getPedidoHomeDetalhes = async (id_pedido) => {
  const url = `getPedidoHomeDetalhes`
  const body = { id_pedido }
  const { data } = await api.post(url, body)
  return data
}

export const getMotivosCancelamento = async () => {
  const url = `/pedido/cancelamento/motivos`
  const { data } = await api.get(url)
  return data
}

export const aceitarPedido = async ({ idItemPedido }) => {
  const url = `/pedido/aceitar`
  const body = { id_item_pedido: idItemPedido }
  const { data } = await api.post(url, body)
  return data
}

export const rejeitarPedido = async ({
  idItemPedido,
  idMotivoCancelamento
}) => {
  const url = `/pedido/recusar`
  const body = {
    id_item_pedido: idItemPedido,
    id_motivo_cancelamento: idMotivoCancelamento
  }
  const { data } = await api.post(url, body)
  return data
}

export const getEstoque = async (id_distribuidor) => {
  const url = `getEstoque`
  const body = { id_distribuidor }
  const { data } = await api.post(url, body)
  return data
}

export const getProdutosHome = async (id_distribuidor) => {
  const url = `getProdutosHome`
  const body = { id_distribuidor }
  const { data } = await api.post(url, body)
  return data
}

export const insertCampanha = async (nome, dataIni, dataFim, lista, foto) => {
  const url = 'insertCampanha'
  const body = { nome, dataIni, dataFim, lista, foto }
  const { data } = await api.post(url, body)
  // login(data.token)
  // if (!isAuthenticated()) {
  //   swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => { return window.location.href = '/login' })
  // }
  return data
}

export const dataVolumeCategory = async () => {
  const url = 'dataVolumeCategory'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataVolumeDistCenter = async () => {
  const url = 'dataVolumeDistCenter'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataVolumeWeeks = async () => {
  const url = 'dataVolumeWeeks'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataVolumeAvailable = async () => {
  const url = 'dataVolumeAvailable'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataVolumeOpen = async () => {
  const url = 'dataVolumeOpen'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataThrowTable = async () => {
  const url = 'dataThrowTable'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataAceptVolume = async () => {
  const url = 'dataAceptVolume'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataHistorySumVol = async () => {
  const url = 'dataHistorySumVol'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataHistoryNegotiatedVol = async () => {
  const url = 'dataHistoryNegotiatedVol'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataHistoryRequest = async () => {
  const url = 'dataHistoryRequest'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dataHistoryClient = async () => {
  const url = 'dataHistoryClient'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const Login = async (mail, pass) => {
  const url = 'login'
  const body = {
    mail,
    pass
  }

  const { data } = await api.post(url, body)

  login(data.token, data, true)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const removerRascunho = async (id) => {
  const url = 'removerRascunho'
  const body = {
    id
  }
  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getFefo = async (cds) => {
  const url = 'fefo'

  const body = {
    cds
  }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getFefo2 = async (cds) => {
  const url = 'fefo2'

  const body = {
    cds
  }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getCNPJS = async (listas) => {
  const url = 'cnpjs'

  const body = {
    listas
  }
  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getTipologia = async (cnpjs) => {
  if (cnpjs) {
  }
  const url = 'tipologias'
  const body = {}

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getEstados = async () => {
  const url = 'cnpjs/estados'

  const { data } = await api.get(url)
  return data
}

export const getCidades = async (estados) => {
  const url = 'cnpjs/estados'
  const body = {
    estados
  }
  const { data } = await api.post(url, body)
  return data
}

export const customImage = async (info) => {
  const url = 'custom-image'
  const body = {
    slots: info
  }
  const { data } = await api.post(url, body)
  return data
}

export const getProdutosList = async () => {
  const url = 'cnpjs/produtos'
  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const sendDataAfterModifyPost = async (dados) => {
  const url = '/cnpjs/filtro'
  const body = dados
  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }
  const { data } = await api.post(url, body, opt)
  return data
}

export const CadastroUsuario = async (dados) => {
  const url = 'cadastrousuario'

  const body = dados

  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }

  const { data } = await api.post(url, body, opt)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const UpdadeUser = async (dados) => {
  const url = 'updateSenha'

  const body = dados

  const { data } = await api.put(url, body)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const updatePermicoesUsuario = async (idUser, listaPermissoes) => {
  const url = 'updatePermicoesUsuario'

  const body = { id: idUser, listaPermissoes }
  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }

  const { data } = await api.post(url, body, opt)

  login(data.token)

  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const ativaInativaUsuario = async (dados) => {
  const url = 'ativainativausuario'
  const body = dados
  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }

  const { data } = await api.post(url, body, opt)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const Upload2 = async (dados) => {
  const url = 'upload'

  const body = dados

  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }

  const { data } = await api.post(url, body, opt)
  return data
}

export const Upload = async (dados, campanha = false) => {
  let url = 'upload'
  if (campanha) {
    url += '?campanha=true'
  }
  const body = dados

  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }

  const { data } = await api.post(url, body, opt)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const UploadEngajamento = async (dados) => {
  let url = 'v2/engajamento/listas'
  const body = dados

  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }

  const { data } = await api.post(url, body, opt)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const UploadCaptura = async (dados) => {
  const url = 'uploadCaptura'

  const body = dados

  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }

  const { data } = await api.post(url, body, opt)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const UploadArquivoIntegracao = async (payload) => {
  const { data } = await api.post('integracao/upload', payload, {
    headers: {
      contentType: 'multipart/form-data'
    }
  })
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getDestinatarios = async (tipoLista, campanha = false) => {
  let url = 'listas'
  if (campanha) {
    url += '?campanha=true'
  }
  const body = {
    tipoLista
  }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getDestinatariosCaptura = async (tipoLista) => {
  const url = 'listasCaptura'

  const body = {
    tipoLista
  }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getProdutos = async () => {
  const url = 'produtos'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getFiltro = async (
  cnpjs,
  historico,
  dataIni,
  dataFim,
  tipologia,
  condicao,
  condicaoOp,
  produtos,
  fl_filtro
) => {
  const url = 'filtrar'

  const body = {
    cnpjs,
    historico,
    dataIni,
    dataFim,
    tipologia,
    condicao,
    condicaoOp,
    produtos,
    fl_filtro
  }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getFiltroUsuarios = async (usuario, email, status) => {
  const url = 'filtrousuarios'

  const body = {
    usuario,
    email,
    status
  }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getCD = async (produto, semana, cnpjs) => {
  const url = 'cds'

  const body = {
    produto,
    semana,
    cnpjs
  }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getCD2 = async (id_distribuidor, arrProdSem, cnpjs) => {
  const url = 'cds2'

  const body = {
    id_distribuidor,
    arrProdSem,
    cnpjs
  }

  const { data } = await api.post(url, body)
  return data
}

export const dispararCampanha = async (body) => {
  const url = 'impulsionamento'

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dispararCampanhaNova = async (formData) => {
  const { data } = await api.post('campanha', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const dispararEngajamento = async (payload) => {
  const { data } = await api.post('v2/engajamento', payload, {})
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getImpulsionamentos = async () => {
  const url = 'anuncios'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const listaRascunhos = async (campanha = false) => {
  let url = 'rascunhos'
  if (campanha) {
    url += '?campanha=true'
  }

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const filtraImpulsionamento = async (
  titulo,
  produto,
  status,
  campanha = false
) => {
  let url = 'filtraanuncios'

  if (campanha) {
    url += '?campanha=true'
  }

  const body = { titulo, produto, status }

  const { data } = await api.post(url, body)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const filtraUsuario = async (usuario, email, dataCriacao, status) => {
  const url = 'filtrausuarios'

  const body = { usuario, email, dataCriacao, status }

  const { data } = await api.get(url, body)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = 'login')
    })
  }
  return data
}

export const getRascunho = async (hashId) => {
  const url = 'getrascunho'

  const body = { hashId }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getRascunhosInteracao = async (idFluxo) => {
  const url = `getfluxo/${idFluxo}`

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getDetalhes = async (idCampanha, campanha = false) => {
  let url = 'anuncios'
  if (campanha) {
    url += '?campanha=true'
  }
  const body = { idCampanha }

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const salvaRascunho = async (body) => {
  const url = 'salvarrascunho'

  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getPdf = async (id) => {
  const url = 'exportPdf'

  const body = { id }

  const { data } = await api.post(url, body, { responseType: 'arraybuffer' })
  // login(data.token)
  // if (!isAuthenticated()) {
  //   swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => { return window.location.href = '/login' })
  // }
  return data
}

export const getPdfClienteCd = async (idCD, cnpjs, titulo, periodo) => {
  const url = 'exportClientCd'

  const body = { idCD, cnpjs, titulo, periodo }

  const { data } = await api.post(url, body, { responseType: 'arraybuffer' })
  return data
}

/** ************************************** Cockpit Conversões ****************************************/

export const cockpit = async (campanha = false) => {
  let url = 'getconversao'
  if (campanha) {
    url += '?campanha=true'
  }
  const { data } = await api.get(url)

  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const cockpitTicketTipologias = async (id, campanha = false) => {
  let url = 'getconversaotickettipoligia'
  if (campanha) {
    url += '?campanha=true'
  }
  const body = { id }

  const { data } = await api.post(url, body)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const cockpitFaturamentoCD = async (id, campanha = false) => {
  let url = 'getconversaofaturamentocd'
  if (campanha) {
    url += '?campanha=true'
  }
  const body = { id }

  const { data } = await api.post(url, body)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}
export const getListaUsuariosCriados = async () => {
  const url = 'getlistausuarioscriados'

  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const cockpitTotais = async (id, campanha = false) => {
  let url = 'getconversaototais'
  if (campanha) {
    url += '?campanha=true'
  }
  const body = { id }

  const { data } = await api.post(url, body)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const cockpitDetalhes = async (ids, campanha = false) => {
  let url = 'getdetalhesconversao'
  if (campanha) {
    url += '?campanha=true'
  }
  const body = { ids }
  const { data } = await api.post(url, body)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const cockpitTopCinco = async (id, campanha = false) => {
  let url = 'getcockpittopcinco'
  if (campanha) {
    url += '?campanha=true'
  }
  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getListaUsuarios = async (campanha = false) => {
  let url = 'getlistausuarios'
  if (campanha) {
    url += '?campanha=true'
  }
  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getListaEngajamentos = async () => {
  let url = 'v2/engajamento/listas/relatorio'
  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getListaBaseEngajamentos = async () => {
  let url = 'v2/engajamento/listas'
  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getCdsEngajamentos = async () => {
  let url = 'v2/engajamento/cds'
  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getEngajamentosRelatorios = async () => {
  let url = 'v2/engajamento/relatorio'
  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getEngajamentosRelatoriosById = async (id) => {
  let url = `v2/engajamento/relatorio/${id}`
  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const setStatusEngajamento = async (id, status) => {
  let url = `v2/engajamento/${id}/${status}`
  const { data } = await api.post(url, {})
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getRepeticoesEngajamentos = async () => {
  let url = 'v2/engajamento/repeticoes'
  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getListaUsuariosCaptura = async () => {
  const url = 'getlistausuariosCaptura'

  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getIntegracaoUploadHistory = async () => {
  const { data } = await api.get('/integracao/upload')
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getSaldoInteracoes = async () => {
  const url = 'getsaldointeracoes'

  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getHistoricoInteracoes = async () => {
  const url = 'gethistoricointeracoes'

  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const imgPerfil = async () => {
  const url = 'getimgperfil'
  const { data } = await api.get(url, { responseType: 'arraybuffer' })
  return data
}

export const getPermissoes = async () => {
  const url = 'permissoestelas'

  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const checkPermissionsForUpdate = async (idUser) => {
  const url = `/verifyPermissionUser/${idUser}`

  const { data } = await api.get(url)

  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getLogs = async () => {
  const url = 'getlogs'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getPermissoesUsuarios = async (userId) => {
  const url = 'getpermissoesusuario'
  // const body = { userId }

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const inserirInteracoesCaptura = async (dados) => {
  const url = 'inserirInteracoesCaptura'

  const body = dados

  const opt = {
    headers: {
      contentType: 'multipart/form-data'
    }
  }

  const { data } = await api.post(url, body, opt)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getListaInteracoes = async () => {
  const url = 'getinteracoes'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const getDadosPdfCaptura = async (id) => {
  if (id <= 0) return null
  // const url = `http://localhost:3901/getDadosPDF/${id}`;
  const url = `https://portal.ifefo.com.br:3901/getDadosPDF/${id}`
  //const url = `getDadosPDF/${id}`
  // const { data } = await api.get(url, { params: id })
  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  if (data) return data
  return null
}

export const getListaRascunhos = async () => {
  const url = 'getrascunhos'

  const { data } = await api.get(url)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

export const removerRascunhoInteracoes = async (id) => {
  const url = 'removerRascunhoInteracoes'
  const body = {
    id
  }
  const { data } = await api.post(url, body)
  login(data.token)
  if (!isAuthenticated()) {
    swal({ icon: 'warning', text: 'Sessão expirada' }).then(() => {
      return (window.location.href = '/login')
    })
  }
  return data
}

/* ALR - Finalizando portal */

export const getLancesAbertos = async (id_distribuidor) => {
  const url = '_getLanceAbertos'
  const body = {
    id_distribuidor
  }
  const { data } = await api.post(url, body)
  return data
}

export const getVolumeAberto = async (id_distribuidor) => {
  const url = '_getVolumeAberto'
  const body = {
    id_distribuidor
  }
  const { data } = await api.post(url, body)
  return data
}

export const getVolumeAceito = async (id_distribuidor) => {
  const url = '_getVolumeAceito'
  const body = {
    id_distribuidor
  }
  const { data } = await api.post(url, body)
  return data
}

export const getVolumeDisponivelEstoque = async (id_distribuidor) => {
  const url = '_getVolumeDisponivelEstoque'
  const body = {
    id_distribuidor
  }
  const { data } = await api.post(url, body)
  return data
}

export const listaVolumeDisponivel = async (id_distribuidor) => {
  const url = '_listaVolumeDisponivel'
  const body = {
    id_distribuidor
  }
  const [error, response] = await promised(api.post(url, body))

  if (error || (response || {}).status === 'error') {
    return response
  }

  const { data } = response

  const children = await Promise.all(
    ((data || {}).sucess || []).reduce((acc, curr) => {
      const { categoria, origem, validade } = curr || {}
      if (categoria && categoria.toLowerCase().trim() !== 'n/a') {
        acc.push(topCategorias(encodeURIComponent(categoria)))
      }
      if (origem && origem.toLowerCase().trim() !== 'n/a') {
        acc.push(topOrigens(encodeURIComponent(origem)))
      }
      if (validade && validade.toLowerCase().trim() !== 'n/a') {
        acc.push(topValidades(encodeURIComponent(validade)))
      }
      return acc
    }, [])
  )

  const output = await children.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      if ((key || '').toLowerCase().trim() === 'n/a') {
        return acc
      }
      if (!acc.hasOwnProperty(key)) acc[key] = {}
      Object.assign(acc[key], curr[key])
    })
    return acc
  }, {})

  ;((data || {}).sucess || []).map((item) => {
    _.set(item, `categoriaTooltip`, (output.categorias || {})[item.categoria])
    _.set(item, `origemTooltip`, (output.origens || {})[item.origem])
    _.set(item, `validadeTooltip`, (output.validades || {})[item.validade])
    return item
  })

  return data
}
export const topCategorias = async (categoria) => {
  const url = `/top/categorias?term=${categoria}`
  const data = await api.get(url).then((response) => ({
    categorias: {
      [decodeURIComponent(categoria)]: (
        ((response || {}).data || {}).msg || []
      ).reduce((acc, curr) => {
        if (curr.volume) {
          acc.push({
            id: uuidV4(),
            volume: curr.volume,
            descricao: curr.descricao
          })
        }
        return acc
      }, [])
    }
  }))
  return data
}

export const topOrigens = async (origem) => {
  const url = `/top/origens?term=${origem}`
  const data = await api.get(url).then((response) => ({
    origens: {
      [decodeURIComponent(origem)]: ((response.data || {}).msg || []).reduce(
        (acc, curr) => {
          if (curr.volume) {
            acc.push({
              id: uuidV4(),
              volume: curr.volume,
              descricao: curr.descricao
            })
          }
          return acc
        },
        []
      )
    }
  }))
  return data
}

export const topValidades = async (validade) => {
  const url = `/top/validades?term=${validade}`
  const data = await api.get(url).then((response) => ({
    validades: {
      [decodeURIComponent(validade)]: ((response.data || {}).msg || []).reduce(
        (acc, curr) => {
          if (curr.volume) {
            acc.push({
              id: uuidV4(),
              volume: curr.volume,
              descricao: curr.descricao
            })
          }
          return acc
        },
        []
      )
    }
  }))
  return data
}

export const listaHistoricoVendas = async (id_distribuidor) => {
  const url = '_listaHistoricoVendas'
  const body = {
    id_distribuidor
  }
  const { data } = await api.post(url, body)
  if (Array.isArray((data || {}).sucess)) {
    data.sucess.map((item) => {
      item.id = uuidV4()
      return item
    })
  }

  const FormatKilogram = () => (value) => {
    let middle = value.toString().replace('.', '')
    if (middle.length <= 3) {
      return middle
    } else {
      let parts = middle.match(/.{1,3}/g)
      return parts.join('.')
    }
  }

  const output = Object.entries(_.groupBy(data.sucess, 'data')).reduce(
    (acc, curr) => {
      const [date, [item]] = curr
      if (curr.volume_total < 0) return acc
      acc.gridColumns.push(date)
      acc.gridRaw.volumeTotal[date] = item.volume_total
        .toString()
        .replace('.', '')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      acc.gridRaw.volumeNegociado[date] = item.volume_negociado
        .toString()
        .replace('.', '')
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      acc.gridRaw.pedidos[date] = item.qtd_pedidos
      acc.gridRaw.clientes[date] = item.clientes
      return acc
    },
    {
      gridColumns: [],
      gridRaw: {
        volumeTotal: {
          label: 'Volume total'
        },
        volumeNegociado: {
          label: 'Volume negociado'
        },
        pedidos: {
          label: 'Pedidos (qtd)'
        },
        clientes: {
          label: 'Clientes'
        }
      }
    }
  )

  data.grid = {
    cols: output.gridColumns,
    rows: Object.values(output.gridRaw)
  }

  console.log(data)

  return data
}

export const listCapturas = async (id_distribuidor) => {
  const url = '/impulsionamentos/capturas'
  const queryString = {
    id_distribuidor
  }
  const { data } = await api.get(
    `${url}?id_distribuidor=${queryString.id_distribuidor}`
  )
  return data
}

export const listaClientes = async (id_distribuidor, string = '') => {
  const url = 'clientes'
  const queryString = {
    id_distribuidor,
    string
  }
  const { data } = await api.get(
    `${url}?id_distribuidor=${queryString.id_distribuidor}${queryString.string}`
  )

  return data
}

export const listaClientesV2 = async (
  id_distribuidor,
  take,
  page,
  search = '',
  filters
) => {
  return new Promise(async (resolve, reject) => {
    try {
      filters = Object.entries(filters)

      if (filters.length >= 1) {
        page = 1
      }

      const {
        data: { total, total_registros },
        data
      } = await api.get(
        `clientes?id_distribuidor=${id_distribuidor}&limit=${take}&page=${page}${search}`
      )

      if (total <= 0) {
        resolve({ total: 0, total_registros, msg: [], status: 'success' })

        throw new Error('Finish pagination.')
      }

      resolve(data)
    } catch (error) {
      console.log(`ERROR`, error)
      reject(error)
    }
  })
}

/**
 * Edita um cliente
 * @param {number} clienteId
 * @param {{telefone: string, email: string, responsavel: string}} model
 * @returns {Promise<any>}
 */
export const editaCliente = async (clienteId, model) => {
  const url = `clientes/${clienteId}`
  const payload = {
    celular:
      model.celular !== null && (model.celular || '').toString().length > 1
        ? model.celular.replace(/\D+/gi, '')
        : '',
    telefone:
      (model.telefone || '').toString().length > 1
        ? model.telefone.replace(/\D+/gi, '')
        : '',
    email: model.email,
    responsavel: model.responsavel,
    status_comprador: parseInt(model.status, 10)
  }
  const { data } = await api.put(`${url}`, payload)
  return data
  // return {
  //   ...data,
  //   msg: data.msg.sort((a, b) => a.id < b.id)
  // }
}

export const alterarCodigoIndustria = async (idPedido, model) => {
  const dateFormat = (dt) => dt.replace(/(\d{4}).(\d{2}).(\d{2})/, '$3/$2/$1')
  const url = `pedido/${idPedido}/codigoIndustria`
  const payload = {
    v_codigo_industria: model.codigoIndustria,
    v_data_embarque: dateFormat(model.dataEmbarque),
    v_data_entrega: dateFormat(model.dataEntrega)
  }
  const { data } = await api.post(`${url}`, payload)
  return data
}

export const notifyPartner = async (idItem) => {
  const url = `notify-partner/${idItem}`
  const payload = {}
  await api.post(`${url}`, payload)
}

export const editarVolumeItem = async (idItem, volume) => {
  const url = `lance/${idItem}/volume`
  const payload = {
    volume
  }
  const { data } = await api.post(`${url}`, payload)
  return data
}

export const listaGestoresProduto = async (idProduto) => {
  const url = `produto/${idProduto}/gestor`
  const { data } = await api.get(url)
  return data
}

/**
 * Grava gestores do produto
 * @param {number} idProduto - Id do produto
 * @param {number[]} idGestores - Array com ids dos gestores
 * @returns {Promise<*>}
 */
export const adicionaGestoresProduto = async (idProduto, ...idGestores) => {
  const url = `produto/${idProduto}/gestor/`
  const idGestoresArray = Array.prototype.concat.apply([], idGestores) || []
  return Promise.all([
    ...(Array.isArray(idGestoresArray)
      ? idGestoresArray.map(async (id) => {
          const gestorUrl = `${url}${id}`
          const { data } = await api.post(gestorUrl)
          return data
        })
      : [await Promise.resolve()])
  ])
}

/**
 * Remove gestores do produto
 * @param {number} idProduto - Id do produto
 * @param {number[]} idRelacionamentos
 * @returns {Promise<*>}
 */
export const removeGestoresProduto = async (
  idProduto,
  ...idRelacionamentos
) => {
  const url = `produto/${idProduto}/gestor/`
  return Promise.all([
    ...(Array.isArray(idRelacionamentos)
      ? idRelacionamentos.map(async (id) => {
          const gestorUrl = `${url}${id}`
          const { data } = await api.delete(gestorUrl)
          return data
        })
      : [await Promise.resolve()])
  ])
}

/**
 * Grava gestores do produto
 * @param {number} idProduto
 * @param {{toAdd: number[], toRemove: number[]}} gestores
 * @returns {Promise<unknown[]>}
 */
export const gravaGestoresProduto = async (idProduto, gestores) => {
  return Promise.all([
    ...(gestores.toAdd.length
      ? [await adicionaGestoresProduto(idProduto, gestores.toAdd)]
      : []),
    ...(gestores.toRemove.length
      ? [await removeGestoresProduto(idProduto, gestores.toRemove)]
      : [])
  ])
    .then(() => {
      return { success: true }
    })
    .catch((err) => {
      console.error(err)
      return err
    })
}

/**
 * Gera senhas em lote (envia recuperação por e-mail)
 * @param {number[]} ids
 * @returns {Promise<any>}
 */
export const gerarSenhaLote = async (...ids) => {
  const url = `/_gerar-senha-lote`
  const idsArr = Array.prototype.concat
    .call([], ids)
    // eslint-disable-next-line no-bitwise
    .filter((id) => id >>> 0 > 0)
  if (!idsArr.length) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject({
      status: 'error',
      error: 'Invalid id'
    })
  }
  const { data } = await api.post(url, {
    ids: idsArr
  })
  return data
}

export const downloadPedido = async (startDate, endDate, xls) => {
  try {
    const url = `/integracao/download/pedidos?startDate=${startDate}&endDate=${endDate}${
      xls ? '&type=xls' : ''
    }`
    if (xls) {
      const { data } = await api.request({
        url,
        method: 'GET',
        responseType: 'blob'
      })
      FileDownload(
        data,
        'pedidos.xlsx',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      )
    } else {
      const { data } = await api.get(url)
      FileDownload('\uFEFF' + data, 'pedidos.csv', 'text/csv')
    }
  } catch (e) {
    swal({
      icon: 'error',
      text: 'Nenhum dado disponível para a data selecionada'
    })
  }
}

export const getPowerBiDemo = async () => {
  const { data } = await api.get(`/pbi/token`)
  return data
}
